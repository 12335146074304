<modal #crewSearchModal (onClose)="onClose($event)">

    <modal-header>
        <h4>Crew search</h4>
    </modal-header>

    <modal-content>
        <div *ngIf="displayFlightSearch">
            <p>Select the flight to use for the crew search.</p>
            <p>Your selection here will pre-populate flight details on the Flight page.</p>
            <hr class="margin-10">
            <app-flight-search-generic *ngIf="!close" (flightDetails$)="flightSearch($event)">
            </app-flight-search-generic>
        </div>
        <div *ngIf="!displayFlightSearch">
            <table>
                <tr>
                    <td class="padding-right-10">Flight number: </td>
                    <td>
                        {{ flightResults.flightNumber }}
                    </td>
                </tr>
                <tr>
                    <td class="padding-right-10">Departure date: </td>
                    <td>{{ flightResults.flightDate | date:'MM/dd/yyyy' : 'UTC'}}</td>
                </tr>
                <tr>
                    <td class="padding-right-10">Departure station:</td>
                    <td>{{ flightResults.departureStation }}</td>
                </tr>
            </table>
        </div>
        <app-aa-spinner [loading]="isSearching"></app-aa-spinner>
        <div *ngIf="!isSearching">
            <div *ngIf="crewList && crewList.length > 0">
                <hr>
                <input type="text" class="form-control marginBottom" (keyup)="onQuickFilterChanged()"
                    [(ngModel)]="quickSearchValue" placeholder="Filter..." />
                <ag-grid-angular #agGrid id="paxResultsGrid" [ngStyle]="style" class="ag-theme-balham marginBottom"
                    [rowData]="crewList" [gridOptions]="gridOptions" (rowSelected)="onRowSelected($event)"
                    (gridReady)="onGridReady($event)">
                </ag-grid-angular>
            </div>
            <div *ngIf="(crewList && crewList.length <= 0) || searchError">
                <hr>
                <label class="fontRed">There is no crew list to display. Verify flight details.</label>
            </div>
        </div>
    </modal-content>

    <modal-footer *ngIf="selectedCrew">
        <label class="footer-left">Selection:
            {{ this.selectedCrew | formatCrewNameDisplay }}</label>
        <button type="submit" class="btn-primary footer-right" (click)="populatePersonData()">Select</button>

    </modal-footer>
</modal>
