import { Component, OnInit, SkipSelf, Optional, forwardRef, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Question, Answer, RecursiveQAComponent } from '@shared/common-ux/models/QuestionAnswer';
import { Guid } from '@shared/utilities/guid';
import { ExpandCollapseService } from '@shared/common-ux/components/component-services/expand-collapse.service';
import { DynamicQuestionsService } from '@shared/SSEHubClient/dynamic-questions.service';
// store
import { State } from '@app/store';
import { Store } from '@ngrx/store';
import * as Actions from '@app/admin/category-management/category-management-store/detail';
import { getOpenedQuestions, getOpenedAnswers } from '@app/admin/category-management/category-management-store';
import * as _ from 'lodash';
import { take, takeUntil } from 'rxjs/operators';
import { LoadSelectionsForTemplate } from '../category-management/category-management-store/template';
import { ExtractQuestionUrls } from 'app/shared/common-ux/models/ExtractQuestionUrls';
import { Subject } from 'rxjs';
import { HighlightService } from '../category-management/highlight/highligh.service';

class DynamicDisplayObject {
    text: string;
    inputType?: string;
    attrs: object;
    obj: any;
}

@Component({
    selector: 'app-question-answer-dynamic-component',
    templateUrl: './question-answer-dynamic.component.html',
    styleUrls: ['./question-answer-dynamic.component.scss'],
    providers: [{ provide: RecursiveQAComponent, useExisting: forwardRef(() => QuestionAnswerDynamicComponent) }]
})
export class QuestionAnswerDynamicComponent implements OnInit, RecursiveQAComponent, OnDestroy {
    @Input() profileId: number;
    @Input() categoryId: number;
    @Input() group: string;
    @Input() question: Question;
    @Input() answer: Answer;
    @Input() index: number;
    @Input() context: string;
    @Output() actionEditMetaData = new EventEmitter();
    @Output() actionEditQuestion = new EventEmitter();
    @Output() actionRemoveQuestion = new EventEmitter();
    @Output() actionAddQuestion = new EventEmitter();
    @Output() actionAddTemplate = new EventEmitter();
    @Output() actionEditAnswer = new EventEmitter();
    @Output() actionRemoveAnswer = new EventEmitter();
    @Output() actionAddAnswer = new EventEmitter();
    @Output() actionEditGroup = new EventEmitter();
    @Output() actionRemoveGroup = new EventEmitter();
    @Output() actionAddGroup = new EventEmitter();
    @Output() actionEditGroupDetails = new EventEmitter();
    @Output() actionRemoveGroupDetails = new EventEmitter();
    @Output() actionAddGroupDetails = new EventEmitter();
    @Output() actionSortQuestion = new EventEmitter();
    @Output() actionSortAnswer = new EventEmitter();
    @Output() actionManageTags = new EventEmitter();
    @ViewChild('answersPanel') answersPanel;
    @ViewChild('section', { static: true }) sectionElem: ElementRef;

    displayObj: DynamicDisplayObject = {
        text: '',
        inputType: null,
        attrs: {},
        obj: null
    };
    iconStyle = 'icon-expand';
    componentId: string;
    answers: Array<Answer> = [];
    followupQuestions: Array<Question> = [];
    isOpen = false;
    isLoading = false;
    isReady = true;
    hasError = false;
    childContext: string;
    answerContext: string;
    isAnswerGroup = false;
    highlighted = false;

    private parent: RecursiveQAComponent;
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        @SkipSelf() @Optional() recursiveParent: RecursiveQAComponent,
        private expandCollapseService: ExpandCollapseService,
        private store: Store<State>,
        private dynamicQuestionsService: DynamicQuestionsService,
        private highlightSevice: HighlightService
    ) {
        this.parent = recursiveParent;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    ngOnInit() {
        this.assignId();
        if (this.question) {
            this.displayObj.text = this.question.questionText;
            this.displayObj.inputType = this.question.answerInputType;
            this.displayObj.obj = this.question;
            this.answers = this.parseAnswers(this.question.answers);
            // if (this.question.answerInputType.toLowerCase() === 'multi') {
            //     this.answers = this.sortAnswers(this.answers);
            // }
            this.answers.forEach(answer => {
                answer.answerInputType = this.displayObj.inputType;
                // if (answer.answerInputType.toLowerCase() === 'multi') {
                //     const attrs = this.question.attrs ?? {};
                //     if (attrs && attrs['dataType']) {
                //         answer.answerInputType = answer.answerInputType + '-' + attrs['dataType'];
                //     }
                // }
            });
            this.followupQuestions = this.question.followupQuestions || [];
            this.setContext_question();

            this.store.select(getOpenedQuestions).pipe(take(1)).pipe(takeUntil(this.destroy$)).subscribe(questions => {
                if (questions && this.question && this.question.questionId && questions[this.question.questionId]) {
                    this.isOpen = questions[this.question.questionId].opened;
                    this.iconStyle = (this.isOpen) ? 'icon-collapse' : 'icon-expand';
                    if (this.isOpen) {
                        this.expandCollapseService.expand(this.sectionElem);
                    }
                }
            });
            this.highlightSevice.hightlight$.pipe(takeUntil(this.destroy$)).subscribe(id => this.highlighted = id === this.question.questionId);
        } else if (this.answer) {
            this.displayObj.text = this.answer.answerText;
            this.displayObj.inputType = null;
            this.displayObj.obj = this.answer;
            this.setContext_answer();

            // open follow-up question by default if the answer is an involvement type
            if (this.answerContext === 'question-group-answer' || this.answerContext === 'involvement-answer' || this.answerContext === 'answer-involvement' || this.answerContext === 'answer-group') {    // was also checking 'answer-involvement'?????
                this.getFollowupQuestions();
                this.onExpandChildPanel();
            }

            this.store.select(getOpenedAnswers).pipe(take(1)).pipe(takeUntil(this.destroy$)).subscribe(answers => {
                if (answers && this.answer && this.answer.answerId && answers[this.answer.answerId]) {
                    this.isOpen = answers[this.answer.answerId].opened;
                    this.iconStyle = (this.isOpen) ? 'icon-collapse' : 'icon-expand';
                    this.getFollowupQuestions();
                    if (this.isOpen) {
                        this.expandCollapseService.expand(this.sectionElem);
                    }
                }
            });
            this.highlightSevice.hightlight$.pipe(takeUntil(this.destroy$)).subscribe(id => this.highlighted = id === this.answer.answerId);
        }
    }

    callAction(e, o?) {
        const _object = o || this.displayObj.obj;

        if ((e === 'answerAdd' || e === 'addTemplate') && !this.isOpen) {
            this.expandCollapseService.expand(this.sectionElem);
            this.onExpandChildPanel();
        }
        if (this.parent) {
            // Recursive compnent, balloon out to parent
            this.parent.callAction(e, _object);
        } else {
            // Root component call proper function
            switch (e) {
                case 'editQuestion':
                    this.actionEditQuestion.emit(_object);
                    break;
                case 'editAnswer':
                    this.actionEditAnswer.emit(_object);
                    break;
                case 'editGroup':
                    this.actionEditGroup.emit(_object);
                    break;
                case 'editGroupDetails':
                    this.actionEditGroupDetails.emit(_object);
                    break;
                case 'editMetaData':
                    this.actionEditMetaData.emit(_object);
                    break;
                case 'addQuestion':
                    this.actionAddQuestion.emit(_object);
                    break;
                case 'addTemplate':
                    this.store.dispatch(new LoadSelectionsForTemplate({
                        profileId: this.profileId,
                        categoryId: this.categoryId,
                        group: this.group,
                        answer: _object
                    }));
                    this.actionAddTemplate.emit(_object);
                    break;
                case 'answerAdd':
                    this.actionAddAnswer.emit(_object);
                    break;
                case 'addGroup':
                    this.actionAddGroup.emit(_object);
                    break;
                case 'addGroupDetails':
                    this.actionAddGroupDetails.emit(_object);
                    break;
                case 'removeQuestion':
                    this.actionRemoveQuestion.emit(_object);
                    break;
                case 'removeAnswer':
                    this.actionRemoveAnswer.emit(_object);
                    break;
                case 'removeGroup':
                    this.actionRemoveGroup.emit(_object);
                    break;
                case 'removeGroupDetails':
                    this.actionRemoveGroupDetails.emit(_object);
                    break;
                case 'sortQuestion': {
                    _object.profileId = this.profileId;
                    _object.categoryId = this.categoryId;
                    _object.group = this.group;
                    _object.context = this.context;
                    this.actionSortQuestion.emit(_object);
                    break;
                }
                case 'sortAnswer': {
                    _object.profileId = this.profileId;
                    _object.categoryId = this.categoryId;
                    _object.group = this.group;
                    _object.context = this.context;
                    this.actionSortAnswer.emit(_object);
                    break;
                }
                case 'manageTags': {
                    this.actionManageTags.emit(_object);
                    break;
                }
            }
        }
    }

    private assignId(): void {
        const guid = Guid.newGuid();
        this.componentId = `DynQA_${guid}`;
    }

    onExpandChildPanel() {
        this.toggleIcon();
        if (this.question) {
            this.store.dispatch(new Actions.SetQuestionOpenState(this.question.questionId, this.group, this.isOpen));
        } else if (this.answer) {
            this.store.dispatch(new Actions.SetAnswerOpenState(this.answer.answerId, this.group, this.isOpen));
            this.getFollowupQuestions();
        }
    }
    private hasAnswerDefinitions(answerInputType: string): boolean {
        answerInputType = answerInputType.toLowerCase();
        return (answerInputType === 'dropdown' || answerInputType === 'checkbox' || answerInputType === 'radio'); // || answerInputType === 'multi');
    }

    private parseAnswers(a: Array<Answer>) {
        let parsed = [];
        if (a && a.length) {
            if (a[0].attrs &&
                a[0].attrs['multiInputType']?.toLowerCase() === 'userinputaddress') {
                return a;
            }
            // Remove default answer for text based questions
            if (!(a.length === 1 && !a[0].answerText.trim())) {
                parsed = a;
            }
        }
        return parsed;
    }

    // this is for sorting AMS address answers
    private sortAnswers(answers: Array<Answer>) {
        return _.sortBy(answers, a => a.attrs['multiInputType']);
    }

    private toggleIcon(): void {
        if (this.iconStyle === 'icon-expand') {
            this.iconStyle = 'icon-collapse';
            this.isOpen = true;
        } else {
            this.iconStyle = 'icon-expand';
            this.isOpen = false;
        }
    }

    checkIfRequired(attrObject) {
        if (attrObject) {
            const attr = attrObject;
            if (attr['isRequired']) {
                return attr.isRequired;
            } else {
                return false;
            }
        }
    }

    private getFollowupQuestions(): void {
        if (this.followupQuestions.length > 0) {
            return;
        }
        this.startLoadingState();
        this.dynamicQuestionsService.loadFollowUpQuestions(this.profileId, this.categoryId, this.group, this.answer.answerId)
            .pipe(takeUntil(this.destroy$)).subscribe(
                (result) => {
                    if (result) {
                        this.followupQuestions = result.questions;
                        this.followupQuestions.forEach(question => {
                            if (question.answerInputType.toUpperCase() === 'RADIO') {
                                const newexUtil = new ExtractQuestionUrls(question);
                                if (newexUtil && newexUtil !== undefined) {
                                    question.hyperLinks = newexUtil.hyperLinks;
                                    question.displayQuestionText = newexUtil.displayQuestionText;
                                }
                            }

                            question.parentId = this.answer.answerId;
                        });
                        this.onReady();
                    } else {
                        this.onError();
                    }
                },
                (error) => {
                    console.log('Error ' + error);
                    this.onError();
                }
            );
    }

    private setChildContext(): void {
        if (this.context === 'group') {
            this.childContext = 'group-detail';
        } else if (this.context === 'flight-followup-question') {
            this.childContext = 'flight-followup-question-dropdown';
        } else {
            this.childContext = this.context;
        }
    }

    private setContext_question() {
        if (this.question && this.question.answerInputType && this.question.answerInputType.toLowerCase() === 'involvement') {
            this.context = 'question-involvement';
        }
        if (this.question && this.question.answerInputType && this.question.answerInputType.toLowerCase() === 'questiongroup') {
            this.context = 'question-group';
        }
        if (this.hasAnswerDefinitions(this.question.answerInputType) && !this.context.includes('-dropdown')) {
            this.context = `${this.context}-dropdown`;
        }
        this.setChildContext();
    }

    private setContext_answer() {
        if (this.group === 'Flight') {
            this.context = 'flight-followup-question';
            this.answerContext = 'answer-for-flight';
        } else {
            this.answerContext = 'answer';
            if (this.context === 'question-involvement') {
                this.answerContext = 'answer-involvement';
                this.isAnswerGroup = true;
            }
            if (this.context === 'question-group') {
                this.answerContext = 'answer-group';
                this.isAnswerGroup = true;
            }
            this.context = 'followup-question';
        }
        // if (this.answer && this.answer.answerInputType && this.answer.answerInputType.toLowerCase().startsWith('multi')) {
        //     this.answerContext = 'multi-limited';
        // }
        this.setChildContext();
    }

    private startLoadingState() {
        this.isLoading = true;
        this.isReady = false;
        this.hasError = false;
    }

    private onError() {
        this.isLoading = false;
        this.isReady = false;
        this.hasError = true;
    }

    private onReady() {
        this.isLoading = false;
        this.isReady = true;
        this.hasError = false;
    }

    isAnswerMandatory() {
        let isRequired = false;
        if (this.group === 'Flight' && this.answers.length < 3) {
            if (this.answer.answerText.toLowerCase() === 'a flight' && !_.some(this.answers, function (ans: any) {
                return ans.answerText === 'An Aircraft, but not a Flight';
            })) {
                isRequired = true;
            }
        }
        return isRequired;
    }
}

export class MenuItem {
    constructor(private Name: string, private CallBackFunction: string) { }
}
