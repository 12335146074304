import { Component, EventEmitter, ViewChild, AfterContentChecked, Input } from '@angular/core';
import { ControlContainer, NgForm, FormControl, NgModel, NgModelGroup } from '@angular/forms';
import { IDynamicQuestion } from '../../new-report-store/wizard';
import * as _ from 'lodash';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['../dynamic-form.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})

export class CheckboxComponent implements AfterContentChecked {
    question: IDynamicQuestion;
    profileId: number;
    categoryId: number;
    groupName: string;
    answerEvent$: EventEmitter<any>;
    questionAttrs: object = {};
    @ViewChild('form') form: NgModel;
    @ViewChild('formGroup', { static: true }) formGroup: NgModelGroup;

    ngAfterContentChecked() {
        this.updateValidity();
    }

    showReload() {
        const attrs = this.question.attrs === null ? {} : this.question.attrs;
        const failedApi = attrs['failedAPICall'];

        return failedApi === undefined ? false : failedApi;
    }
    onChange(answer: any) {
        if (this.questionAttrs['isRequired']) {
            this.updateValidity();
        }

        this.answerEvent$.emit({
            question: this.question,
            answer: answer,
            myform: this.form
        });
    }

    updateValidity() {
        if (this.form && this.form.control && this.form.control.parent) {
            _.forEach(this.form.control.parent.controls, (val: FormControl, key) => {
                val.updateValueAndValidity();
            });
        }
    }

    childAnswerEvent(event) {
        // bubble the child events up to parent
        this.answerEvent$.emit(event);
    }
}
