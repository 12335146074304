<kendo-window title="CREATE NEW TAG" *ngIf="opened" autoFocusedElement="#systemRequired" (close)="onDiscard()"
    [minWidth]="470" [minHeight]="includeSystemRequired ? 530 : 475">
    <form class="k-form">
        <fieldset>
            <form [formGroup]="newTagFormGroup">
                <div *ngIf="includeSystemRequired" class="create-new-tag-input-padding d-flex justify-content-between align-items-center pb-2">
                    <kendo-label #systemRequired [labelCssClass]="'k-label-required'" text="Is this system required">
                        <sup><span class="bi bi-asterisk star"></span></sup>
                    </kendo-label>
                    <kendo-switch #systemRequired [onLabel]="'Yes'" [offLabel]="'No'"
                        formControlName="systemRequired"></kendo-switch>
                </div>

                <div class="create-new-tag-input-padding pt-1">
                    <kendo-label [labelCssClass]="'k-label-required'" text="Tag name">
                        <sup><span class="bi bi-asterisk star"></span></sup>
                    </kendo-label>
                    <kendo-textbox title="Tag name" #name resizable="none" formControlName="name" required
                        (valueChange)="onNameValueChange($event)" [maxlength]="nameMaxLength" />
                    <div class="d-flex justify-content-between align-items-center">
                        <form-control-error controlName="name" [overrideError]="nameError" />
                        <kendo-formhint>
                            <span class="pull-right">{{ nameMaxLength - nameCharCount }} characters remaining</span>
                        </kendo-formhint>
                    </div>
                </div>

                <div class="create-new-tag-input-padding">
                    <kendo-label [labelCssClass]="'k-label-required'" text="Display text">
                        <sup><span class="bi bi-asterisk star"></span></sup>
                    </kendo-label>
                    <kendo-textbox title="Display text" #display resizable="none" formControlName="display"
                        (valueChange)="onDisplayValueChange($event)" [maxlength]="displayMaxLength" />
                    <div class="d-flex justify-content-between align-items-center">
                        <form-control-error controlName="display" [overrideError]="displayError" />
                        <kendo-formhint>
                            <span class="pull-right">{{ displayMaxLength - displayCharCount }} characters
                                remaining</span>
                        </kendo-formhint>
                    </div>
                </div>

                <div class="create-new-tag-input-padding pb-3">
                    <kendo-label [labelCssClass]="'k-label-required'" text="Description">
                        <sup><span class="bi bi-asterisk star"></span></sup>
                    </kendo-label>
                    <kendo-textarea title="Description" #description resizable="none" formControlName="description"
                        (valueChange)="onDescriptionValueChange($event)" [maxlength]="descriptionMaxLength" />
                    <div class="d-flex justify-content-between align-items-center">
                        <form-control-error controlName="description" [overrideError]="descriptionError" />
                        <kendo-formhint>
                            <span class="pull-right">{{ descriptionMaxLength - descriptionCharCount }} characters
                                remaining</span>
                        </kendo-formhint>
                    </div>
                </div>

                <div class="create-new-tag-input-padding d-flex justify-content-between align-items-center">
                    <kendo-label [labelCssClass]="'k-label-required'" text="Is this PII data?">
                        <sup><span class="bi bi-asterisk star"></span></sup>
                    </kendo-label>
                    <kendo-switch #pii [onLabel]="'Yes'" [offLabel]="'No'" formControlName="pii"></kendo-switch>
                </div>
            </form>

        </fieldset>
    </form>

    <div>
        <div class="k-actions k-actions-end d-flex justify-content-between px-0">
            <button kendoButton class="button-secondary discard-button" (click)="onDiscard()">
                <span>Discard</span>
            </button>
            <div>
                <button kendoButton class="button-tertiary save-apply-button" (click)="onSaveAndApply()" *ngIf="showSaveAndApplyButton">
                    <span>Save & Apply</span>
                </button>
                <button kendoButton class="button-primary apply-button" (click)="onSave()">Save</button>
            </div>
        </div>
    </div>
</kendo-window>

<kendo-dialog *ngIf="confirmExitDialogOpened" [minWidth]="250" [width]="550" (close)="onConfirmExit(false)">
    <kendo-dialog-titlebar>
        <div class="confirm-title-dialog">
            <span class="icon-sec-exclamation-circle-fill"></span>
            <div class="warning-title-dialog">WARNING: Are you sure?</div>
        </div>
    </kendo-dialog-titlebar>
    <p style="margin: 30px; text-align: center; font-size: 17px;">
        There are unsaved changes. Are you sure you want to exit?</p>

    <kendo-dialog-actions>
        <button kendoButton (click)="onConfirmExit(false)" class="button-secondary">Go back</button>
        <button kendoButton (click)="onConfirmExit(true)" class="button-primary">Exit</button>
    </kendo-dialog-actions>
</kendo-dialog>
