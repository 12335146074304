import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '@app/store';
import { getUser, User } from 'app/store/user';
import { takeUntil } from 'rxjs/operators';

export class CategoryDetailIdentifier {
    profileId: number;
    categoryId: number;
    selectedInvolvementType: any;
    selectedInvolvementDetail: any;
    selectedQuestion: any;
    selectedAnswer: any;
    hasEditAccess = false;
    questionsNtFound: any;
    canAdminFillOutReport = false; // feature flag for admin capabilities for the new fill out report (i.e. templates/involvements/etc)
}

@Injectable()
export class CategoryDetailService implements OnDestroy {
    destroy$: Subject<void> = new Subject<void>();
    // Observable string sources
    private categoryInitializedSource = new Subject<string>();
    private answerAddedSource = new Subject<any>();

    private questionAddedorModifiedSource = new Subject<string>();

    // Observable string streams
    categoryInitialized$ = this.categoryInitializedSource.asObservable();
    answerAdded$ = this.answerAddedSource.asObservable();
    questionAddedorModified$ = this.questionAddedorModifiedSource.asObservable();

    // Persist some details about selected category detail so we dont need to pass it around from component to component.
    state: CategoryDetailIdentifier = new CategoryDetailIdentifier();

    constructor(private store: Store<State>) {
        this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(user =>
            this.state.canAdminFillOutReport = (User.hasPrivilege(user, 'FLAG_WEB_ADMIN_FILLOUTREPORT'))
        );
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public initializeCategory(data: any) {
        this.categoryInitializedSource.next(data);
    }

    public answerAdded(data: any) {
        this.answerAddedSource.next(data);
    }

    public questionAddedorModified(data: any) {
        this.questionAddedorModifiedSource.next(data);
    }

    public setState(profileId: number, categoryId: number) {
        this.state.profileId = profileId;
        this.state.categoryId = categoryId;
    }
    public clearState() {
        this.state.profileId = 0;
        this.state.categoryId = 0;
        this.state.selectedInvolvementType = null;
    }

    public setSelectedQuestion(question: any) {
        this.state.selectedQuestion = question;
    }
    public setSelectedAnswer(answer: any) {
        this.state.selectedAnswer = answer;
    }
    public clearSelectedAnswer() {
        this.state.selectedAnswer = null;
    }
    public setEditAccess(hasAccess: any) {
        this.state.hasEditAccess = hasAccess;
    }
}


