<div *ngFor="let reportQuestion of questionsAndAnswers"
    [ngClass]="{'question-container' : reportQuestion.validateRules | hideFromReportDetailPipe}">
    <ng-container *ngIf="reportQuestion.validateRules | hideFromReportDetailPipe">
        <span class="question">
            <div *ngIf="reportQuestion.recurrence > 0" class="recurrence-number">{{reportQuestion.recurrence}}.
            </div>
            {{reportQuestion.question}}
        </span>
        <span class="answer" *ngIf="reportQuestion.answer.toLowerCase() !== 'question-group-answer'"
            [ngClass]="{'question-group': reportQuestion.recurrence > 0}">{{ reportQuestion.answer }}</span>
    </ng-container>
    <span *ngIf="isEmailsDataType(reportQuestion.validateRules)">
        <span class="question">{{reportQuestion.question}}</span>
        <div class="list-basic">
            <li *ngFor="let email of reportQuestion.answer.split(';')">{{email}} </li>
        </div>
    </span>
    <div *ngIf="reportQuestion.followupQuestions">
        <div class="question-group">
            <app-question-list [questionsAndAnswers]="reportQuestion.followupQuestions"></app-question-list>
        </div>
    </div>
</div>
