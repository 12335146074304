import { Pipe, PipeTransform } from '@angular/core';
import { ValRule, inputTypeDictionary, descriptionDictionary } from './question-edit.component';

@Pipe({ name: 'viewOnlyDescription' })
export class ViewOnlyDescriptionPipe implements PipeTransform {

    transform(rule: ValRule): string {
        if (rule.viewOnly && rule.inputType === 'dropdown') {
            return descriptionDictionary[rule.val];
        }
        return rule.val;
    }
}
