<div class="dynamicInputDiv" #formGroup='ngModelGroup' [(ngModelGroup)]="question.questionMappingId">
    <label>
        <i *ngIf="questionAttrs['isRequired']" class="icon-cers-required"></i>
        {{ question.questionText | sentenceCasePipe}}
    </label> <br>
    <div class="errorBox form-check-padding">
        <div class="form-check" *ngFor="let answer of question.answers">
            <input type="checkbox" [name]="answer.answerText" [value]="answer" class="form-check-input"
                id="{{question.questionMappingId}}-{{answer.answerId}}" [(ngModel)]="answer.isChecked"
                [appDynamicValidation]="questionAttrs" (change)="onChange(answer)"
                [appDynamicValidation]="questionAttrs"
                [appFocus]="{questionId: question.questionId, questionMappingId: question.questionMappingId}"
                #form="ngModel" />
            <label class="form-check-label" for="{{question.questionMappingId}}-{{answer.answerId}}">
                {{answer.answerText}}</label>
            <br />
            <div class="childForm">
                <app-dynamic-form *ngIf="answer.followupQuestions && answer.isChecked"
                    (answerEvent$)="childAnswerEvent($event)" [questions]="answer.followupQuestions"
                    [profileId]="profileId" [categoryId]="categoryId" [groupName]="groupName">
                </app-dynamic-form>
            </div>
            <div *ngFor="let error of form.errors | keyvalue">
                <label class="errorText" *ngIf="error.key !== 'isRequired'"> {{error.value}} </label>
            </div>
            <div *ngIf="showReload()">
                <a id="recall" (click)="onChange(answer)">Click here to reload follow-up questions</a>
            </div>
        </div>
    </div>
</div>