import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '@app/store';
import { IUser, getUser } from '../../store/user';
import { of, Subject } from 'rxjs';
import { tap, filter, take, switchMap, takeUntil } from 'rxjs/operators';
import { InitializeUser as InitUser } from '@app/store/user/user.actions';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';
import { ErrorHandlerService } from 'app/shared/error-handler/error-handler.service';


@Injectable()
export class ReportHistoryRerouteAuthGuard implements CanActivate, OnDestroy {
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<State>,
        private authService: PingAuthenticationService,
        private errorHandlerService: ErrorHandlerService,
    ) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        return this.getFromStore().pipe(
            switchMap(u => this.getUserAuthorization(u, route).toPromise())
        ).toPromise();
    }

    private getUserAuthorization(user: IUser, route: ActivatedRouteSnapshot) {
        if (user == null || route == null) {
            return of(false);
        }
        return of(true);
    }

    public getFromStore() {
        return this.store.select(getUser).pipe(
            tap(u => {}),
            filter(user => user),
            take(1)
        );
    }

}