<div class="main-email-container" [ngClass]="{'hasError': (form.errors != null && form.control.dirty) || (form.invalid && form.touched) }" (click)="focusOnLastInput()">
    <div class="email-container" *ngFor="let input of emailInputs; index as i">
        <input *ngIf="input.editing"
            class="email-input"
            id="email-{{i}}-{{name}}"
            name="email-{{i}}"
            type="text"
            (input)="onChange($event, i)"
            (click)="stopPropagation($event)"
            (blur)="onChange($event, i)"
            (keyup)="processKeySelections($event, i)"
            [(ngModel)]="input.email">

        <div *ngIf="!input.editing"
            class="email-display"
            [ngClass]="{'valid': input.valid, 'invalid': !input.valid}">
            <span class="email-text" (click)="editEmail($event, i)">
                {{input.email}}
            </span>
            <span class="cancel" (click)="removeEmail(i)">x</span>
        </div>
    </div>
</div>
