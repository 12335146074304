import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'jsonToObjectPipe' })
export class JsonToObjectPipe implements PipeTransform {
    transform(json: string): any {
        if (json && json.length > 0) {
            try {
                return JSON.parse(json || '{}');
            } catch (e) {
                return {};
            }
        }
        return {};
    }
}
