import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'app/shared/error-handler-notify/services';
import { Store } from '@ngrx/store';
import { getUser, State } from 'app/store';
import * as Actions from '../category-management-store/tag/tag.actions';
import { ITagDetails } from '../category-management-store/tag';
import { take, takeUntil } from 'rxjs/operators';
import { getCreateTagSuccess, getCreateTagError } from '../category-management-store';
import { Subject } from 'rxjs';
import { User } from 'app/store/user';

@Component({
    selector: 'app-create-new-tag-modal',
    templateUrl: './create-new-tag-modal.component.html',
    styleUrl: './create-new-tag-modal.component.scss'
})

export class CreateNewTagModalComponent implements OnInit {
    @Input() showSaveAndApplyButton: boolean = true;
    @Output() actionAddTagMapping: EventEmitter<any> = new EventEmitter();
    @Output() onClose: EventEmitter<any> = new EventEmitter();

    destroy$: Subject<void> = new Subject<void>();

    opened: boolean;    // indicates if the main window is open or closed
    confirmExitDialogOpened: boolean;    // indicates if the confirm exit popup us open or closed
    newTagFormGroup!: FormGroup;     // new tag form group


    // default values
    nameDefault = '';
    displayDefault = '';
    descriptionDefault = '';
    systemRequiredDefault = false;
    piiDefault = false;
    includeSystemRequired = false;  // only IT superusers can change this

    // for tag name: only allow letters, numbers, dash, and understore and require 1 to 50 characters
    nameRegex = new RegExp('^[a-zA-Z0-9_-]{1,50}$');

    // max length values for the fields
    nameMaxLength = 50;
    displayMaxLength = 100;
    descriptionMaxLength = 200;

    // current character count for the fields
    nameCharCount: number = 0;
    displayCharCount: number = 0;
    descriptionCharCount: number = 0;

    // error text to display below the field
    descriptionError = '';
    displayError = '';
    nameError = '';

    constructor(public notificationService: NotificationService, private store: Store<State>,) { }
    ngOnInit() {
        this.store.select(getUser).pipe(take(1)).subscribe(user => {
            this.includeSystemRequired = User.hasPrivilege(user, 'WEB_SUADMIN');
        });
        this.open();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private open() {
        this.resetErrorHints();
        this.store.dispatch(new Actions.ResetCreateTag());  // clear the store
        this.newTagFormGroup = new FormGroup({
            name: new FormControl(this.nameDefault, { nonNullable: true, validators: [Validators.required, Validators.maxLength(this.nameMaxLength), Validators.pattern(this.nameRegex), Validators.minLength(1)] }),
            display: new FormControl(this.displayDefault, { nonNullable: true, validators: [Validators.required, Validators.maxLength(this.displayMaxLength), Validators.minLength(1)] }),
            description: new FormControl(this.descriptionDefault, { nonNullable: true, validators: [Validators.required, Validators.maxLength(this.descriptionMaxLength), Validators.minLength(1)] }),
            systemRequired: new FormControl(this.systemRequiredDefault, { nonNullable: true, validators: [Validators.required] }),
            pii: new FormControl(this.piiDefault, { nonNullable: true, validators: [Validators.required] })
        });
        this.opened = true; // opens the window
    }

    private close(): void {
        this.store.dispatch(new Actions.ResetCreateTag());
        this.onClose.emit();
    }

    private resetErrorHints() {
        this.displayError = '';
        this.descriptionError = '';
        this.nameError = '';
    }

    // updates for the char counts
    public onNameValueChange(ev: string): void {
        this.nameCharCount = ev.length;
    }
    public onDisplayValueChange(ev: string): void {
        this.displayCharCount = ev.length;
    }
    public onDescriptionValueChange(ev: string): void {
        this.descriptionCharCount = ev.length;
    }

    // save, save & apply, and discard actions
    public onSaveAndApply() {
        this.onSave(true);
    }
    public onSave(mapQuestion?: boolean) {
        this.resetErrorHints();

        if (!this.validateForm()) {
            this.newTagFormGroup.markAllAsTouched();
            this.notificationService.showError("Please complete all required fields.");
            return;
        }

        const newTag: ITagDetails = {
            tagVal: this.newTagFormGroup.get('name')?.value,
            tagDescription: this.newTagFormGroup.get('description')?.value,
            tagDisplayTxt: this.newTagFormGroup.get('display')?.value,
            systemRequired: this.includeSystemRequired ? this.newTagFormGroup.get('systemRequired')?.value : false, // double verify that only IT superusers can set system_required to true
            piiInd: this.newTagFormGroup.get('pii')?.value
        }

        this.store.dispatch(new Actions.CreateTag({ tag: newTag }));

        this.store.select(getCreateTagSuccess).pipe(takeUntil(this.destroy$)).subscribe(success => {
            if (success) {
                if (mapQuestion) {
                    this.actionAddTagMapping.emit(newTag.tagVal);
                }
                this.close();
            }
        });

        this.store.select(getCreateTagError).pipe(takeUntil(this.destroy$)).subscribe(errors => {
            for (const key of Object.keys(errors)) {
                if (errors[key] && errors[key].length > 0) {
                    // display the first error for these fields
                    if (key == 'TagDescription') {
                        this.descriptionError = errors[key][0];
                    }
                    if (key == 'TagDisplayTxt') {
                        this.displayError = errors[key][0];
                    }
                    if (key == 'TagVal') {
                        this.nameError = errors[key][0];
                    }
                }
            }
        });
    }

    public onDiscard() {
        // check if any fields have been changed from their default values
        // if so, then display a popup asking if the user really wants to navigate away and lose their changes
        for (let key of Object.keys(this.newTagFormGroup.controls)) {
            const control = this.newTagFormGroup.controls[key] as FormControl;
            if (control.value !== control.defaultValue) {
                this.confirmExitDialogOpened = true;
                this.opened = false;
                return;
            }
        }
        this.close();
    }

    // yes or no exit the main popup. this comes from the confirm exit popup
    public onConfirmExit(exit: boolean) {
        if (exit) { // we are exiting out of the process
            this.confirmExitDialogOpened = false; // close the dialog
            this.close();
            return;
        }
        // we want to return to the create tag popup
        this.confirmExitDialogOpened = false;
        this.opened = true;

    }

    private validateForm(): boolean {
        const isNameValid = this.newTagFormGroup.controls['name'].errors === null;
        const isDisplayValid = this.newTagFormGroup.controls['display'].errors === null;
        const isDescriptionValid = this.newTagFormGroup.controls['description'].errors === null;
        const isSystemRequiredValid = this.newTagFormGroup.controls['systemRequired'].errors === null;
        const isPiiValid = this.newTagFormGroup.controls['pii'].errors === null;

        return isNameValid && isDisplayValid && isDescriptionValid && isSystemRequiredValid && isPiiValid;
    }
}
