import { Injectable } from '@angular/core';

@Injectable()
export class UtilitiesService {

    constructor(
    ) { }

    // removes the key/value pair if the value parameter is false or null/empty
    modifyJson(originalJson: object, key: string, value: any): object {
        const attributes = originalJson || {};
        if (attributes && attributes.hasOwnProperty(key) && (!value || value.toString().length < 1)) {  // false or empty
            delete attributes[key];
        } else {
            if (value) {   // double check this check!
                attributes[key] = value;
            }
        }
        return Object.keys(attributes).length > 0 ? attributes : null;
    }

    isNullOrEmpty(inputString: string): boolean {
        return (!inputString || inputString.length === 0);
    }

}

