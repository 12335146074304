import { Component, OnInit, Input, OnChanges, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { SSEHubReportHistoryService } from '../../../shared/SSEHubClient/report-history.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Attachment } from '../../report-history';
import { Store } from '@ngrx/store';
import { State } from '@app/store';
import {
    getReportDetailsReportId,
    getReportDetailsAttachments,
    getReportDetailsReportStatusDesc,
    AddAttachment
} from 'app/report-history/report-history-store/detail';
import { getUser } from 'app/store/user';
import { Observable, Subject } from 'rxjs';
import { ExpandCollapseService } from 'app/shared/common-ux/components/component-services/expand-collapse.service';
import { NotificationService } from 'app/shared/error-handler-notify/services';
import { AttachmentSummary } from '@shared/common-ux/models/AttachmentSummary';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-attachment-summary',
    templateUrl: './attachment-summary.component.html',
    styleUrls: ['./attachment-summary.component.scss', '../summary.component.scss']
})
export class AttachmentSummaryComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() allExpandState: Subject<any>;
    @ViewChild('section', { static: true }) sectionElem: ElementRef;
    responderEmployeeId: string;
    hasError = false;
    isReady = false;
    iconStyle = 'icon-expand icon-large';
    fileExtension: string;
    imageType = ['png', 'jpg', 'gif', 'jpeg'];
    pdfType = ['pdf'];

    reportId: number;
    reportStatusDesc: any;
    sectionAddAttachment$: Observable<any>;
    destroy$: Subject<void> = new Subject<void>();
    constructor(
        private store: Store<State>,
        private sanitizer: DomSanitizer,
        private expandCollapseService: ExpandCollapseService,
        private notificationService: NotificationService,
        private sseHubReportHistoryService: SSEHubReportHistoryService,
        public attSummary: AttachmentSummary
    ) {
        this.sectionAddAttachment$ = this.store.select(getReportDetailsAttachments);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(user => this.responderEmployeeId = user.id);
        this.store.select(getReportDetailsAttachments).pipe(takeUntil(this.destroy$)).subscribe(result => this.attSummary.attachments = result);
        this.store.select(getReportDetailsReportId).pipe(takeUntil(this.destroy$)).subscribe(result => this.reportId = result);
        this.store.select(getReportDetailsReportStatusDesc).pipe(takeUntil(this.destroy$)).subscribe(result => this.reportStatusDesc = result);
        this.allExpandState.pipe(takeUntil(this.destroy$)).subscribe(event => {
            if (event) {
                this.expandCollapseService.expand(this.sectionElem);
            } else {
                this.expandCollapseService.collapse(this.sectionElem);
            }
        });
    }

    ngAfterViewInit() {
        this.sectionAddAttachment$.pipe(takeUntil(this.destroy$)).subscribe(detail => this.attSummary.loadAttachments((detail)));
    }

    onSave(attachment: Attachment) {
        this.attSummary.isLoading = true;
        if (attachment) {
            this.store.dispatch(new AddAttachment(this.reportId, attachment));
        } else {
            this.notificationService.showError('Error adding attachment');
        }
    }

    isReportClosed() {
        const reportStatusDesc = 'Closed';
        if (this.reportStatusDesc === reportStatusDesc) {
            return true;
        }
        return false;
    }
}
