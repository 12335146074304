import { Component, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { forkJoin, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { getUser, IUser, User } from '@app/store/user'; // user models
import { NotificationService } from 'app/shared/error-handler-notify/services';
import {
    getAllCategories, getCategoriesByProfileId, getCategories, getProfilesList, getSelectedProfile
} from './category-management-store';
import { IProfile, ICategory, CategoriesModel } from './category-management-store/list';
import * as Actions from './category-management-store/list/list.actions';
import { SelectCategory } from './category-management-store/detail';
import { SSEHubCategoriesService } from '@shared/SSEHubClient/categories.service';
import { State } from '@app/store';
import { HttpErrorResponse } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { NgbPaginationModule, NgbTypeaheadModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-category-management',
    templateUrl: './category-management.component.html',
    styleUrls: ['./category-management.component.scss']
})
export class CategoryManagementComponent implements OnDestroy {
    user$: Observable<IUser>;
    selectedProfile$: Observable<IProfile>;
    profileList$: Observable<Array<IProfile>>;
    categories$: Observable<CategoriesModel>;
    categoriesAll$: Observable<Array<ICategory>>;
    categoriesByProfile$: Observable<Array<ICategory>>;
    destroy$: Subject<void> = new Subject<void>();

    @ViewChild('confirmCategoryRemove', { static: true }) modal;
    @ViewChild('copyCategoryForProfile', { static: true }) copyModal;
    @ViewChild('addCategoryForProfile', { static: true }) addModal;
    @ViewChild('createCategoryForProfile', { static: true }) createModal;

    private user: IUser;
    selectedProfile: IProfile;
    selectedProfileCopyCategory: IProfile;
    categoryId: string;
    categoryIdForCopyCategory: string;
    dtOptions: any = {};
    hasEditAccess = false;
    hasAdminAccess = false;
    responseMessage: string;
    isCopying = false;
    categoriesAll: Array<ICategory>;
    searchText:string = '';
    // ngb pagination
    page = 1;
	pageSize = 10;
    collectionSize:number;

    // create category
    createCategoryDescription = '';
    createCategoryStatus = {
        loading: false,
        success: false,
        error: ''
    };

    constructor(
        private router: Router,
        private store: Store<State>,
        private sseHubCategoriesService: SSEHubCategoriesService,
        public notificationService: NotificationService,
        private modalService: NgbModal,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.dtOptions = {
            pagingType: 'full_numbers',
            order: [],
            'columns': [
                {
                    'name': 'id',
                    'title': 'Category Description',
                    'class': 'dtleft'
                },
                {
                    'targets': 'no-sort',
                    'orderable': false,
                    'searchable': false
                }]
        };
        this.user$ = this.store.select(getUser);
        this.selectedProfile$ = this.store.select(getSelectedProfile);
        this.profileList$ = this.store.select(getProfilesList);
        this.categories$ = this.store.select(getCategories);
        this.categoriesAll$ = this.store.select(getAllCategories);
        this.categoriesByProfile$ = this.store.select(getCategoriesByProfileId);
        this.selectedProfile$.pipe(takeUntil(this.destroy$)).subscribe(selected => {
            this.selectedProfile = selected;
            if (this.selectedProfile) {
                this.store.dispatch(new Actions.LoadCategoriesByProfile(this.selectedProfile.profileId));
            }
        });

        this.user$.pipe(takeUntil(this.destroy$)).subscribe(user => {
            this.user = user;
            this.store.dispatch(new Actions.LoadProfiles());
        });

        // Complicated chain of observables to initialize page properly
        this.profileList$.pipe(takeUntil(this.destroy$)).subscribe((profiles: IProfile[]) => {
            if (profiles && profiles.length > 0) {
                if (this.selectedProfile) {
                    const findProfile = profiles.find(profile => profile.profileId === this.selectedProfile.profileId);
                    this.selectedProfile = findProfile ?? profiles[0];
                } else {
                    // If no selected profile, or no access to sessions selected profile default and load categories
                    this.selectedProfile = profiles[0];
                    this.selectionChanged();
                }
                this.setEditPermissions();
                this.setAdminPermissions();

            }
        });
        this.refreshCategories();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    selectionChanged(): void {
        this.store.dispatch(new Actions.SelectProfile(this.selectedProfile));
        this.setEditPermissions();
    }

    refreshCategories(){
        this.categoriesAll$.subscribe(data =>{
            let fiteredData = data.filter((category) => category.description.toLowerCase().match(this.searchText.toLowerCase()));
            this.collectionSize = fiteredData.length;
            this.categoriesAll = fiteredData.map((category, i) => ({ id: i + 1, ...category })).slice(
                (this.page-1) * this.pageSize,
                (this.page-1) * this.pageSize + this.pageSize,
            );
        })
    }

    addModalClose(): void {
        this.searchText = '';
        this.pageSize = 10,
        this.page = 1;
        this.addModal.close();
    }
 
    updateCategoryActive(category) {
        const newStatus = !category.categoryActive;

        this.sseHubCategoriesService.updateCategoryActive(this.selectedProfile.profileId, category.id, newStatus)
            .pipe(takeUntil(this.destroy$)).subscribe((value) => {
                category.categoryActive = newStatus;
            }, (error) => {
                const message = newStatus ? 'Unable to activate category status' : 'Unable to deactivate category status';
                this.notificationService.showError(message);
                console.error(error);
            });

        if (category != null) {
            this.store.dispatch(new SelectCategory(category));
        }
    }

    gotoCategoryDetail(category) {
        if (category != null) {
            this.store.dispatch(new SelectCategory(category));
            this.router.navigate(['/admin/categorymanagement/profile/' + this.selectedProfile.profileId + '/category/' + category.id + '/summary/']);
        }
    }

    setCategorySelectionForCopyCategory(category) {
        this.categoryIdForCopyCategory = category.id;
        this.responseMessage = '';
    }

    onCopyCategory(): boolean {
        let targetProfileId = null;
        if (this.selectedProfileCopyCategory === null || this.selectedProfileCopyCategory === undefined) {
            this.responseMessage = 'Please select profile.';
            return false;
        } else {
            targetProfileId = this.selectedProfileCopyCategory.profileId;
            this.responseMessage = '';
        }
        const sourceProfileId = this.selectedProfile.profileId;
        const categoryId = this.categoryIdForCopyCategory;

        this.isCopying = true;

        // does category already exist for this profile?
        let alreadyExists = false;
        const jsonResult = this.sseHubCategoriesService.getByProfileIdForCategoryCopy(targetProfileId)
            .pipe(takeUntil(this.destroy$)).subscribe(
                (result) => {
                    if (result) {
                        result.forEach(a => {
                            if (a.hasOwnProperty('Id')) {
                                if (a.id === categoryId) {
                                    alreadyExists = true;
                                }
                            }
                        });

                        if (alreadyExists) {
                            this.isCopying = false;
                            if (!confirm('This category already exists for this profile and will be overwritten. Do you wish to continue?')) {
                                // stop process and exit
                                this.copyModal.close();
                                this.selectedProfileCopyCategory = null;
                                return false;
                            }
                        }

                        // api call to copy
                        this.isCopying = true;
                        const result2 = this.sseHubCategoriesService.copyCategory(sourceProfileId, categoryId, targetProfileId)
                            .pipe(takeUntil(this.destroy$)).subscribe(
                                (copyResult) => {
                                    this.isCopying = false;
                                    this.responseMessage = copyResult.message;
                                    this.selectedProfileCopyCategory = null;
                                    if (!copyResult.success) {
                                        this.notificationService.showError(copyResult.message);
                                    }
                                    if (copyResult.success) {
                                        this.notificationService.showSuccess(copyResult.message);
                                    }
                                    this.copyModal.close();
                                },
                                (error) => {
                                    this.isCopying = false;
                                    this.responseMessage = 'Failed to copy.';
                                    this.selectedProfileCopyCategory = null;
                                    this.copyModal.close();
                                }
                            );
                    } else {
                        // write error message and stop process
                        this.isCopying = false;
                        this.responseMessage = 'Failed to copy.';
                        this.selectedProfileCopyCategory = null;
                    }
                },
                (error) => {
                    // write error message and stop process
                    this.isCopying = false;
                    this.responseMessage = 'Failed to copy.';
                    this.selectedProfileCopyCategory = null;
                }
            );

        return true;
    }

    confirm() {
        this.modal.close();
    }

    // remove category for a selected profile
    onCategoryDelete() {
        this.store.dispatch(new Actions.DeleteCategory({
            profileId: this.selectedProfile.profileId,
            categoryId: this.categoryId
        }));
    }

    // load all categories. Loads the categories which haven't been added to current profile
    loadAllCategories(): void {
        this.store.dispatch(new Actions.LoadCategories());
    }

    addCategories(category): void {
        this.store.dispatch(new Actions.AddCategory({
            category: category,
            profileId: this.selectedProfile.profileId
        }));
    }

    createCategoryModal(): void {

        this.createCategoryDescription = '';
        // if (!this.createCategoryStatus.loading) {
        //     this.createCategoryStatus = { loading: false, success: false, error: null };
        // }
        this.addModalClose();
        this.createModal.open();
    }

    closeCreateModal() {
        this.createCategoryDescription = '';
        this.createModal.close();
        if (this.createCategoryStatus.success) {
            this.store.dispatch(new Actions.LoadCategoriesByProfile(this.selectedProfile.profileId));
        }
    }

    createNewCategory() {
        // check for empty category name
        if (!this.createCategoryDescription) {
            this.createCategoryStatus.error = 'Enter a category name.';
            return;
        }

        // reset variables
        this.createCategoryStatus.loading = true;
        this.createCategoryStatus.error = null;

        this.sseHubCategoriesService.createCategory(this.selectedProfile.profileId, this.createCategoryDescription)
            .pipe(takeUntil(this.destroy$)).subscribe((result) => {
                this.createCategoryStatus = {
                    loading: false,
                    success: result.success,
                    error: result.success ? '' : result.message
                };

                if (result.success) {
                    this.notificationService.showSuccess(`Category successfully created and mapped to profile ${this.selectedProfile.profileId}`);
                    this.closeCreateModal();
                }

                if (!result.success && result.message.length > 0) {
                    this.notificationService.showError(result.message);
                }

            }, (err: HttpErrorResponse) => {
                this.createCategoryStatus.loading = false;
                this.notificationService.showError('Unable to create and map category.');
            });

        this.createCategoryDescription = '';
    }

    setCategorySelection(category) {
        this.categoryId = category.id;
    }
    private setAdminPermissions() {
        if (this.user && this.selectedProfile) {
            this.hasAdminAccess = User.hasPrivilege(this.user, 'WEB_SUADMIN' );
        }
    }


    private setEditPermissions() {
        if (this.user && this.selectedProfile) {
            this.hasEditAccess = User.hasPrivilegeArray(this.user, [
                'WEB_ADMIN_CATEGORYMGNT_EDIT_' + this.selectedProfile.profileId,
                'WEB_ADMIN_CATEGORYMGNT_EDIT_ALL',
                'WEB_SUADMIN'
            ]);
        }
    }
}
