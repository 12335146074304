<div class="main-text">
    <div class="menu-header">
        <div class="logo" ui-sref="home">
            <a routerLink="home">
                <img alt="CERS" height="70" src="assets/cers-icon.png" />
            </a>
        </div>
        <p class="title"><a routerLink="home" style="text-decoration: none;">CERS</a></p>
    </div>
    <h4>
        {{text}}
    </h4>
</div>
