import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import * as _ from 'lodash';

@Injectable()
export class FormComponentService {
    // mark children components of a form as touch to display errors if they exits
    public validateAllFormFields(formGroup: FormGroup) {
        // iterate through the forms child controls
        _.forEach(formGroup.controls, (value, key) => {
            const control = value;
            if (control instanceof FormGroup) {
                control.markAsTouched({ onlySelf: true });
                this.validateAllFormFields(control); // recursevely iterate through its children
            } else if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            }
        });
    }
}
