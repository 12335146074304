import { Component, OnDestroy, ViewChild } from '@angular/core';
import { State } from 'app/store';
import * as Actions from '../category-management-store/tag/tag.actions';
import { CompositeFilterDescriptor, filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import { FilterService, GridComponent } from '@progress/kendo-angular-grid';
import { Store } from '@ngrx/store';
import { getAllTags } from '../category-management-store';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { ITagDetails } from '../category-management-store/tag';
import { availableTagDescription } from '../question-tag-management/question-tag-management.component';

@Component({
    selector: 'app-tag-management',
    templateUrl: './tag-management.component.html',
    styleUrl: './tag-management.component.scss'
})
export class TagManagementComponent implements OnDestroy {
    destroy$: Subject<void> = new Subject<void>();
    availableTags: ITagDetails[] = []; // Original data
    filteredTags: ITagDetails[] = []; // Filtered data for grid
    searchTerm: string = '';
    statusOptions = [{ text: 'Active', value: true }, { text: 'Inactive', value: false }];
    booleanOptions = [{ text: 'Yes', value: true }, { text: 'No', value: false }];
    defaultOption = { text: 'Select', value: null };
    isCreateNewTagModalOpen = false;
    isEditTagModalOpen = false;
    selectedEditTag: availableTagDescription;
    gridHeight: number; // Height of the grid set dynamically.
    gridPageSize: number = 10; // Defaul number of items to display in the grid.
    fileredTagsLength: number;
    @ViewChild('grid') grid: GridComponent;

    constructor(
        private store: Store<State>,
        private router: Router,
    ) { }

    ngOnInit() {
        // Dispatch action to load all tags.
        this.store.dispatch(new Actions.LoadAllTags({}));

        // Select the data from the store.
        this.store.select(getAllTags).pipe(
            takeUntil(this.destroy$)
        ).subscribe(tags => {
            this.availableTags = tags.map(tag => ({
                ...tag
            }));
            this.searchTags(false);
            // Initial grid size calculation.
            this.fileredTagsLength = this.filteredTags.length;
            this.calculateGridHeight();
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    gotoCategories() {
        this.router.navigate(['/admin/categorymanagement']);
    }

    searchTags(resetGrid: boolean): void {
        if (!this.searchTerm) {
            this.filteredTags = [...this.availableTags];
        } else {
            const searchFilters: FilterDescriptor[] = [{
                field: "tagVal",
                operator: "contains",
                value: this.searchTerm
            },
            {
                field: "tagDisplayTxt",
                operator: "contains",
                value: this.searchTerm
            },
            {
                field: "tagDescription",
                operator: "contains",
                value: this.searchTerm
            }];

            let compositeFilter: CompositeFilterDescriptor = { logic: "or", filters: searchFilters };
            this.filteredTags = filterBy(this.availableTags, compositeFilter);
        }
        if (resetGrid) {
            this.grid.pageChange.emit({ skip: 0, take: this.grid.pageSize });
        }
    }

    getCurrentFilterValue(
        filter: CompositeFilterDescriptor,
        fieldName: string
    ): any {
        const currentFilter = (filter.filters as FilterDescriptor[]).find(
            (f) => f.field === fieldName
        ) as FilterDescriptor;

        return currentFilter ? currentFilter.value : null;
    }

    onFilterChange(
        value: boolean,
        filterService: FilterService,
        fieldName: string
    ): void {
        filterService.filter({
            filters: [{ field: fieldName, operator: 'eq', value: value }],
            logic: 'or',
        });
    }

    public toggleCreateNewTagDialog(open: boolean): void {
        this.isCreateNewTagModalOpen = open;
    }

    public onEdit(dataItem: any): void {
        if (!this.isEditTagModalOpen) {
            this.isEditTagModalOpen = true;
            this.selectedEditTag = dataItem;
        }
    }

    public handleEditModalClose() {
        this.isEditTagModalOpen = false;
    }

    // Flexible grid function.
    calculateGridHeight() {
      const rowHeight = 64; // Taken directly from the scss file, row height + padding.
      const minHeight = 600; // Slightly different from bootstrap.scss file.
      
      // Calculate the number of rows being displayed.
      const numRowsDisplayed = this.fileredTagsLength > 0 ? Math.min(this.gridPageSize, this.filteredTags.length) : this.gridPageSize;
      // Calculate max height based on the number of rows displayed.
      const calculateHeight = numRowsDisplayed * rowHeight;

      // Set the grid height to the max of calculated height and min height.
      this.gridHeight = Math.min(calculateHeight, minHeight);
    }

    onGridPageSizeChange(newPageSize: number) {
      this.gridPageSize = newPageSize;
      this.calculateGridHeight();
    }

    itemDisabled(itemArgs: { dataItem: any; index: number }) {
        return itemArgs.dataItem.value === null;
    }
}
