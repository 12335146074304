import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'indefiniteArticlePipe' })
export class IndefiniteArticlePipe implements PipeTransform {
    transform(phrase: any): string {
        if (phrase) {
            let word = '';
            // Getting the first word
            const match = /\w+/.exec(phrase);
            if (match) {
                word = match[0];
            } else {
                return 'an';
            }

            // Specific start of words that should be preceeded by 'an'
            const alt_cases = ['honest', 'hour', 'hono'];
            for (const i in alt_cases) {
                if (word.indexOf(alt_cases[i]) === 0) {
                    return 'an';
                }
            }

            // Single letter word which should be preceeded by 'an'
            if (word.length === 1) {
                if ('aedhilmnorsx'.indexOf(word) >= 0) {
                    return 'an';
                } else {
                    return 'a';
                }
            }

            // Capital words which should likely be preceeded by 'an'
            if (word.match(/(?!FJO|[HLMNS]Y.|RY[EO]|SQU|(F[LR]?|[HL]|MN?|N|RH?|S[CHKLMNPTVW]?|X(YL)?)[AEIOU])[FHLMNRSX][A-Z]/)) {
                return 'an';
            }

            // Special cases where a word that begins with a vowel should be preceeded by 'a'
            const regexes = [/^e[uw]/, /^onc?e\b/, /^uni([^nmd]|mo)/, /^u[bcfhjkqrst][aeiou]/];
            for (const i in regexes) {
                if (word.match(regexes[i])) {
                    return 'a';
                }
            }

            // Special capital words (UK, UN)
            if (word.match(/^U[NK][AIEO]/)) {
                return 'a';
            } else if (word === word.toUpperCase()) {
                if ('AEDHILMNORSX'.indexOf(word[0]) >= 0) {
                    return 'an';
                } else {
                    return 'a';
                }
            }

            // Basic method of words that begin with a vowel being preceeded by 'an'
            if ('aeiou'.indexOf(word[0]) >= 0) {
                return 'an';
            }

            // Instances where y follwed by specific letters is preceeded by 'an'
            if (word.match(/^y(b[lor]|cl[ea]|fere|gg|p[ios]|rou|tt)/)) {
                return 'an';
            }

            return 'a';
        }
        return '';
    }
}
