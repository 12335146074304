import { Routes } from '@angular/router';

import { AppAuthGuard } from '@app/app.guard';
import { NewReportComponent } from './new-report.component';
import { WizardComponent } from './wizard/wizard.component';
import { FlightComponent } from './wizard/steps/flight.component';
import { QuestionsComponent } from './wizard/steps/questions.component';
import { NotesComponent } from './wizard/steps/notes.component';
import { SummaryComponent } from './wizard/steps/summary.component';
import { CanDeactivateGuard } from 'app/can-deactivate.guard';
import { NewReportRerouteComponent } from './new-report-reroute/new-report-reroute.component';
import { PingAuthGuard } from '@techops-ui/ping-authentication';
import { NewReportRerouteAuthGuard } from './new-report-reroute/new-report-reroute.guard';

export const wizardRoutes: Routes = [
    {
        path: 'newreport',
        component: NewReportComponent,
        canActivate: [AppAuthGuard]
    },
    {
        path: 'newreport/:categoryId',
        component: NewReportRerouteComponent,
        canActivate: [AppAuthGuard, PingAuthGuard, NewReportRerouteAuthGuard]
    },
    {
        path: 'newreport/wizard',
        component: WizardComponent,
        children: [
            {
                path: 'flight',
                component: FlightComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'questions',
                component: QuestionsComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'notes',
                component: NotesComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'summary',
                component: SummaryComponent,
                canDeactivate: [CanDeactivateGuard]
            }
        ]
    }
];
