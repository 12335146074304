import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { SSEHubClient } from './SSEHubClient.service';

@Injectable()
export class SSEHubProfileService {
    constructor(
        private http: HttpClient,
        private sseHubClient: SSEHubClient,
    ) { }

    getAll() {
        return this.http.get(this.sseHubClient.serviceUrl() + '/api/profiles/all');
    }

    get(id) {
        return this.http.get(this.sseHubClient.serviceUrl() + '/api/profile/info/' + encodeURIComponent(id) + '');
    }

    getProfilePrivileges(profileId): Observable<any> {
        return this.http.get(this.sseHubClient.serviceUrl() + '/api/profiles/' + encodeURIComponent(profileId) + '/privileges');
    }
}
