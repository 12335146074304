<div class="menu-container" [ngClass]="{'menu-container-reponsive': isResponsive}">
    <div class="menu-header">
        <div class="menu" (click)="toggleResponsive(!isResponsive)">
            <span></span>
            <span></span>
            <span></span>
        </div>
        <p class="title">CERS</p>
    </div>
</div>
<div @sidenavAnim @fadeAnim *ngIf="isResponsive" class="aa-tab-bar clearfix navbar"
    [ngClass]="{'five': menuService.items.admin.active, 'four': !menuService.items.admin.active }">
    <div class="user">
        <p>{{user.firstName}},</p>
        <p>{{user.lastName}}</p>
        <p>{{user.id}}</p>
    </div>
    <ul class="nav sidenav" [ngClass]="{'responsive': isResponsive}">
        <li routerLink="home" routerLinkActive="active">
            <a>
                <div>{{menuService.items.home.text}}</div>
            </a>
        </li>
        <li routerLink="newreport" routerLinkActive="active">
            <a>
                <div>{{menuService.items.newreport.text}}</div>
            </a>
        </li>
        <ng-container *ngIf="!iodFlag">
            <li routerLink="reporthistory" routerLinkActive="active">
                <a>
                    <div>{{menuService.items.reporthistory.text}}</div>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="iodFlag">
            <li routerLink="reporthistory/myreports" routerLinkActive="active"
                [ngClass]="{'active': reportHistoryActive}">
                <a>
                    <div id="history_default">{{menuService.items.reporthistory.text}}</div>
                </a>
            </li>
            <li routerLink="reporthistory/myreports" routerLinkActive="active"
                *ngIf="isReportManagementAccess || isGeneralMgr">
                <a>
                    <div class="indented" id="history_my_reports">My Reports</div>
                </a>
            </li>
            <li routerLink="reporthistory/allreports" routerLinkActive="active" *ngIf="isReportManagementAccess">
                <a>
                    <div class="indented" id="history_all_reports">All Reports</div>
                </a>
            </li>
            <li routerLink="reporthistory/reportsearch" routerLinkActive="active" *ngIf="isGeneralMgr">
                <a>
                    <div class="indented" id="history_search_reports">Report search</div>
                </a>
            </li>
        </ng-container>
        <li *ngIf="canViewFillOutReport" routerLink="todo" routerLinkActive="active">
            <a>
                <div>{{menuService.items.todo.text}}</div>
            </a>
        </li>
        <li routerLink="{{menuService.items.admin.route}}" routerLinkActive="active"
            *ngIf="menuService.items.admin.active">
            <a>
                <div>{{menuService.items.admin.text}}</div>
            </a>
        </li>
        <li routerLink="{{menuService.items.offlinesync.route}}" routerLinkActive="active" *ngIf="isPWA">
            <a>
                <div>{{menuService.items.offlinesync.text}}</div>
            </a>
        </li>
        <li routerLink="help" routerLinkActive="active">
            <a>
                <div>Help</div>
            </a>
        </li>
        <li>
            <a>
                <div routerLink="/logout">Logout</div>
            </a>
        </li>
        <li (click)="proxyMenuItemHide()" class="proxy" *ngIf="isProxyMenuItem && isProxyUser && !(proxyMode$ | async)">
            <a>
                <div>Proxy</div>
            </a>
        </li>
        <li class="proxy" *ngIf="isProxyMenuItemInput && isProxyUser && !(proxyMode$ | async)">
            <a (click)="proxyMenuItemShow()">
                <div>Proxy</div>
                <div class="user-search-control" (click)="$event.stopPropagation();">
                    <input type="text" placeholder="Enter user id" [(ngModel)]="proxyUserId"
                        (keyup.enter)="loadProxyUser()">
                    <button (click)="loadProxyUser()">Go</button>
                </div>
            </a>
        </li>
        <li *ngIf="(proxyMode$ | async)">
            <a>
                <div (click)="exitProxyMode()">Exit Proxy Mode</div>
            </a>
        </li>
    </ul>
</div>
<div @fadeAnim *ngIf="isResponsive" class="overlay" (click)="close()"></div>
