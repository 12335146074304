<div class="panel-group" id="accordion">
    <app-report-detail-section [section]="'Activity'" [title]="'Activity list'" [allExpandState]="allExpandState">
    </app-report-detail-section>
    <div #section id="Activity" class="panel-collapse collapse">
        <div class="panel-body">
            <p class="errorText" *ngIf="(activityLog$ | async).error">{{ (activityLog$ | async).error }}</p>
            <app-aa-spinner [loading]="(activityLog$ | async).isLoading"></app-aa-spinner>
            <div *ngIf="!(activityLog$ | async).isLoading && !(activityLog$ | async).error && activityLogList"
                class="table-fixed-height">
                <table datatable [dtOptions]="dtOptions" class="reportHistory-table table table-hover table-striped"
                    style="width:100%;">
                    <thead>
                        <tr>
                            <th *ngFor="let column of dtOptions.columns">
                                {{column.title}} </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let rpa of activityLogList">
                            <td>{{rpa.createDate | date : "MM/dd/yyyy hh:mm a" : 'UTC'}} </td>
                            <td>{{rpa.firstName}} {{rpa.lastName}}</td>
                            <td>{{rpa.employeeId}}</td>
                            <td>{{rpa.station}}</td>
                            <td>{{rpa.department}}</td>
                            <td>{{rpa.jobTitle}}</td>
                            <td>{{rpa.profileDesc}}</td>
                            <td>{{rpa.action}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="additionalEmailList && additionalEmailList.length > 0">
                <h3>Additional Emails</h3>
                <table datatable [dtOptions]="additionalEmailOptions"
                    class="reportHistory-table table table-hover table-striped" style="width:100%;">
                    <thead>
                        <tr>
                            <th *ngFor="let column of additionalEmaiOptions.columns">
                                {{column.title}} </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let rpa of additionalEmailList">
                            <td>{{rpa.createDate | date : "MM/dd/yyyy hh:mm a" : 'UTC'}} </td>
                            <td>{{rpa.additionalEmail}}</td>
                            <td>{{rpa.action}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>