import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ConfirmationPopupComponent } from 'app/shared/common-ux/components/confirmation-popup/confirmation-popup.component';
import { environment } from 'environments/environment';
import { SSEHubEmployeesService } from 'app/shared/SSEHubClient';
import { Store } from '@ngrx/store';
import { State } from 'app/store';
import { getUser } from 'app/store/user';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.scss']
})
export class TimeoutComponent implements OnInit, OnDestroy {
    userId: string;
    // timer with the time idle or session are set to expire
    idleTimer: Date;
    sessionTimer: Date;

    // intervalID for pinging services and checking idle and session times
    idleInterval: number;
    sessionInterval: number;

    // time in minutes when sessions expire
    idleTimeout = 120;
    sessionTimeout = 240;

    // warning for time left in milliseconds
    idleTimeLeft = 60000;
    sessionTimeLeft = 300000;

    // warning text
    idleWarning = 'Your session will timeout in one minute. Continue to keep session active.';
    sessionWarning = 'Your session will timeout in 5 minutes. Please submit your report to avoid losing entered data. Upon session timeout, you will be logged out.';

    @ViewChild('idle', { static: true }) idleModal: ConfirmationPopupComponent;
    @ViewChild('session', { static: true }) sessionModal: ConfirmationPopupComponent;
    destroy$: Subject<void> = new Subject<void>();
    constructor(
        private employeeService: SSEHubEmployeesService,
        private store: Store<State>
    ) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        if (!window.navigator.onLine) return; // exit if user is offline

        // set the time for when idle/session times out
        this.idleTimer = this.getTimeout(this.idleTimeout);
        this.sessionTimer = this.getTimeout(this.sessionTimeout);

        // start timers for idle time and session time
        this.idleInterval = this.startTimer(this.idleTimer, this.idleTimeLeft, this.idleModal);
        this.sessionInterval = this.startTimer(this.sessionTimer, this.sessionTimeLeft, this.sessionModal);

        // listen for user activity to reset idle time
        document.onmousedown = () => this.continueSession();
        document.ontouchstart = () => this.continueSession();

        this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(u =>  this.userId = (u) ? u.userId : null);
    }

    startTimer(timeEnd: Date, timeLeftWarning: number, modal: ConfirmationPopupComponent) {
        let displayed = false;
        const interval = window.setInterval(() => { // update every second
            const timeNow = new Date();
            const timeDiff = timeEnd.getTime() - timeNow.getTime();

            // if there is little time left, display a warning to user
            if (!displayed && timeDiff < timeLeftWarning) {
                displayed = true;
                modal.open();
            }

            // timeout happened. close app and redirect to login
            if (timeDiff <= 0) {
                clearInterval(interval);
                window.location.href = environment.loginUrl;
            }
        }, 1000);
        return interval;
    }

    getTimeout(minutes: number) {
        // gets the time of day when session/idle time expires
        const timeout = new Date();
        timeout.setHours(timeout.getHours() + (minutes / 60));
        if (minutes % 60) timeout.setMinutes(timeout.getMinutes() + (minutes % 60));
        return timeout;
    }


    continueSession() { // reset user session
        this.idleTimer = this.getTimeout(this.idleTimeout);
        clearInterval(this.idleInterval);
        this.idleInterval = this.startTimer(this.idleTimer, this.idleTimeLeft, this.idleModal);
    }

}
