<modal #editQuestionMetadata (onOpen)="onOpen($event)" (onClose)="onClose($event)">
    <modal-header>
        <h4>Edit Question</h4>
    </modal-header>
    <modal-content>
        <form #form="ngForm">
            <app-aa-spinner [loading]="isLoading"></app-aa-spinner>
            <div *ngIf="!isLoading && hasError" class="panel-body">
                <p>Error loading Meta Data</p>
            </div>
            <div *ngIf="!isLoading && !hasError">
                <div class="text-normal" *ngIf="!isQuestionEditMode">
                    <h5>{{questionText}}</h5>
                    <div (click)="startQuestionTextEdit()" class="edit-section-icon">
                        <i class="icon-medium icon-edit"></i>
                    </div>
                </div>
                <div *ngIf="isQuestionEditMode">
                    <input type="text" [(ngModel)]="questionText" name="questionText">
                    <div *ngIf="!questionText || questionText.length === 0" class="error">
                        Question cannot be empty
                    </div>
                </div>
                <table *ngIf="!isLoading" class="category-table table table-hover table-striped">
                    <tbody>
                        <tr>
                            <td class="ruleName">Input type</td>
                            <td class="ruleValue">
                                <div class="text-normal">
                                    <h5 *ngIf="!canEdit">{{ questionMetaData.inputType | capitalizeInputType }}</h5>
                                    <div *ngIf="(questionMetaData.inputType.toLowerCase() === 'checkbox' || questionMetaData.inputType.toLowerCase() === 'radio')
                                        && !updateInputType && !currentQuestion.mandatory"
                                        (click)="updateInputType = true;" class="edit-section-icon">
                                        <i class="icon-medium icon-edit"></i>
                                    </div>
                                </div>
                                <div *ngIf="updateInputType">
                                    <select name="changeInputType" id="changeInputType" class="form-control dropdown"
                                        [(ngModel)]="questionInputType" name="inputType">
                                        <option value="CheckBox">{{ 'Checkbox' }}</option>
                                        <option value="radio">{{ 'Radio' }}</option>
                                    </select>
                                </div>
                            </td>
                            <td class="ruleRemove"></td>
                        </tr>
                        <tr *ngIf="isInvolvement">
                            <td class="ruleName">Involvement type:</td>
                            <td class="ruleValue">
                                <div>{{ questionMetaData.involvement }}</div>
                            </td>
                            <td class="ruleRemove"></td>
                        </tr>
                        <ng-container *ngFor="let rule of rules; let i = index">
                            <tr>
                                <td class="ruleName"> {{ rule.description }}</td>
                                <td class="ruleValue">
                                    <div *ngIf="!rule.viewOnly">
                                        <ng-container [ngSwitch]="rule.inputType.toLowerCase()">
                                            <ng-container *ngSwitchCase="'checkbox'">
                                                <input type="checkbox" [disabled]="rule.viewOnly" name="{{rule.key}}"
                                                    id="{{rule.key}}" class="checkbox" [(ngModel)]="rule.val"
                                                    #form="ngModel"
                                                    (ngModelChange)="isRequiredNaOption(rule); validate();">
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'dropdown'">
                                                <select [disabled]="rule.viewOnly" name="{{rule.key}}" id="{{rule.key}}"
                                                    class="form-control dropdown"
                                                    (ngModelChange)="deleteDataTypeOptionalRules(rule)"
                                                    [(ngModel)]="rule.val" #form="ngModel"
                                                    (ngModelChange)="isRequiredNaOption(rule); validate();"
                                                    (change)="addDataTypeOptionalRules(rule)">
                                                    <option *ngFor="let val of rule.vals" [ngValue]="val.value">
                                                        {{ val.description }}
                                                    </option>
                                                </select>
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                <input [type]="rule.inputType" [disabled]="rule.viewOnly"
                                                    name="{{rule.key}}" id="{{rule.key}}" class=""
                                                    [(ngModel)]="rule.val" #form="ngModel"
                                                    (ngModelChange)="validate()" />
                                            </ng-container>
                                        </ng-container>
                                        <div *ngIf="rule.errorMessage" class="input-mandatory">
                                            <label>{{rule.errorMessage}}</label>
                                        </div>
                                    </div>
                                    <div *ngIf="rule.viewOnly" class="disabled">
                                        {{ rule | viewOnlyDescription }}
                                    </div>
                                </td>
                                <td class="ruleRemove"></td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>

                <ng-container *ngIf="selectedRules.length > 0 || displayUnselectedRules">
                    <h5>Validation rules</h5>

                    <div *ngIf="displayUnselectedRules" class="addRule">
                        <i class='icon-small icon-add'></i>
                        <a class="icon-link" (click)="addRule()">Add validation rule</a>
                    </div>
                    <table *ngIf="!isLoading" class="category-table table table-hover table-striped">
                        <tbody>
                            <ng-container *ngFor="let rule of selectedRules">
                                <tr>
                                    <td class="ruleName"> {{ rule.description }}</td>
                                    <td class='ruleValue'>
                                        <div *ngIf="!rule.viewOnly">
                                            <ng-container *ngIf="rule.inputType.toLowerCase() === 'checkbox'">
                                                <input type="checkbox" [disabled]="rule.viewOnly" [(ngModel)]="rule.val"
                                                    name="{{rule.key}}" id="{{rule.key}}" class="checkbox" #r="ngModel"
                                                    (ngModelChange)="validate()" />
                                            </ng-container>
                                            <ng-container *ngIf="rule.inputType.toLowerCase() !== 'checkbox'">
                                                <input [type]="rule.inputType" [disabled]="rule.viewOnly"
                                                    [(ngModel)]="rule.val" name="{{rule.key}}" id="{{rule.key}}"
                                                    class="" #r="ngModel" (ngModelChange)="validate()" />
                                            </ng-container>
                                            <div *ngIf="rule.errorMessage" class="input-mandatory">
                                                <label>{{rule.errorMessage}}</label>
                                            </div>
                                        </div>
                                        <div *ngIf="rule.viewOnly" class="disabled">
                                            {{ rule.val }}
                                        </div>
                                    </td>
                                    <td class='ruleRemove'>
                                        <div *ngIf="!rule.viewOnly" class="del-icon" (click)="removeRule(rule)">
                                            <i class="icon-small icon-delete"></i>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                            <tr *ngIf="addOptionalRule">
                                <td class='ruleName'>
                                    <select class="form-control dropdown " (change)="addRuleEvent(selectedRule)"
                                        [(ngModel)]="selectedRule" name="addRule">
                                        <option [ngValue]="null" disabled>-- Select a rule --</option>
                                        <option *ngFor="let rule of displayFilterRules(unselectedRules)"
                                            [ngValue]="rule">
                                            {{ rule.description }}
                                        </option>
                                    </select>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>

                <!-- Kendo related item. -->
                <ng-container>
                    <h5>Tags</h5>
                    <app-tags-view [tagsList]="tags" [questionMappingId]="questionMetaData.questionMappingId"></app-tags-view>
                </ng-container>

            </div>
        </form>
    </modal-content>
    <modal-footer>
        <button type="button" class="btn btn-cancel" (click)="modal.close()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="updateQuestionsMetaData()">Save</button>
    </modal-footer>
</modal>