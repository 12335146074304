import { Component, Output, EventEmitter, Input, OnInit, OnDestroy } from '@angular/core';
import { NgModel } from '@angular/forms';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

interface EmailInput {
    email: string;
    valid: boolean;
    editing: boolean;
}

@Component({
    selector: 'app-emails-input',
    templateUrl: './emails-input.component.html',
    styleUrls: ['./emails-input.component.scss']
})
export class EmailsInputComponent implements OnInit, OnDestroy {
    @Output() emails: EventEmitter<string> = new EventEmitter();
    @Input() form: NgModel = new NgModel(null, null, null, null);
    @Input() focusEvent: EventEmitter<boolean>;
    name = ''; // used to make input unique per question.
    destroy$: Subject<void> = new Subject<void>();
    emailInputs: EmailInput[] = [{
        email: '',
        valid: false,
        editing: true
    }];

    constructor() { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        if (this.focusEvent) {
            this.focusEvent.pipe(takeUntil(this.destroy$)).subscribe(() => {
                this.focusOnLastInput();
            });
        }
        if (this.form && this.form.name) this.name = this.form.name;
        if (this.form && this.form.value) {
            const emailList = this.form.value.split(';');
            this.emailInputs = [];
            emailList.map(e => {
                this.emailInputs.push({
                    email: e,
                    valid: this.isEmailValid(e),
                    editing: false
                });
            });
            this.form.control.markAsDirty();
        }
    }

    onChange(event: any, index: number) {
        let input = null; // validate event element
        if (event && event.srcElement) input = event.srcElement; else return;

        // update the width
        this.calculateWidth(index);

        // get input with change
        const selectedInput = this.emailInputs[index];

        selectedInput.email = selectedInput.email.trim();
        selectedInput.valid = this.isEmailValid(selectedInput.email);

        // if input isn't the last one and focus is removed and the email is valid then turn off editing
        if (event.type && event.type.includes && event.type.includes('blur') && (selectedInput.email !== '')) {
            selectedInput.editing = false;
            this.form.control.markAsDirty(); // if focus lost, mark as dirty

            // if empty then remove
            if (selectedInput.email === '') {
                this.emailInputs = this.emailInputs.slice(0, index).concat(this.emailInputs.slice(index + 1, this.emailInputs.length));
            }

            // emit event to update valid emails list
            this.updateValidEmailList();
        }

        // check for key that signals complete: space, coma, semi-colon, space, tab, or enter
        if (/[,\;\ \	\n\/]/.test(event.data) && selectedInput.valid) {
            this.form.control.markAsDirty(); // mark as dirty after finishing an input
            selectedInput.editing = false;

            // remove the comma or semi-colon at the end
            if ((event.data === ',' || event.data === ';') && input.value) {
                selectedInput.email = input.value.slice(0, input.value.length - 1);
            }

            // create next input
            this.emailInputs.push({
                email: '',
                valid: false,
                editing: true
            });

            // set focus on next input with delay to allow time for rendering
            setTimeout(() => {
                const el = document.getElementById(`email-${index + 1}-${this.name}`);
                if (el && el.focus) el.focus();
            }, 100);

            // emit event to update valid emails list
            this.updateValidEmailList();
        }

    }

    isEmailValid(email: string) {
        return (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
    }

    updateValidEmailList() {
        const emailList = [];
        this.emailInputs.map(e => {
            if (e.email.trim() !== '') {
                emailList.push(e.email);
            }
        });
        this.emails.emit(emailList.join(';'));
    }

    editEmail(event: Event, index: number) {
        this.emailInputs.map(e => {
            // set all editing to false with the exception for whitespace
            e.editing = /^\s+$/.test(e.email) || !e.email;
        });
        if (this.emailInputs && this.emailInputs.length) {
            this.emailInputs[index].editing = true;
            setTimeout(() => {
                const el = document.getElementById(`email-${index}-${this.name}`);
                this.calculateWidth(index);
                if (el && el.focus) el.focus();
            }, 50);
        }
        if (event) event.stopPropagation();
    }

    focusOnLastInput() {
        this.emailInputs.map(e => e.editing = false);
        if (this.emailInputs) {
            // if there are no inputs add one
            if (!this.emailInputs.length) {
                this.addNewEmailInput(0);
                return;
            }
            // focus on lalst input
            const i = this.emailInputs.length - 1;
            if (!this.emailInputs[i].valid) {
                this.emailInputs[i].editing = true;
                const el = document.getElementById(`email-${i}-${this.name}`);
                if (el && el.focus) el.focus();
                this.calculateWidth(i);
            } else {
                this.addNewEmailInput(this.emailInputs.length);
            }
        }

    }

    addNewEmailInput(index) {
        // create new input
        this.emailInputs.push({
            email: '',
            valid: false,
            editing: true
        });

        // set focus on next input with delay to allow time for rendering
        setTimeout(() => {
            const el = document.getElementById(`email-${index}-${this.name}`);
            if (el && el.focus) el.focus();
        }, 200);
    }

    removeEmail(index: number) {
        const newList = [];
        this.emailInputs.forEach((e, i) => {
            if (i !== index) newList.push(e);
        });
        this.emailInputs = newList;
        this.updateValidEmailList();
    }

    // prevent click events from bubbling out
    // of email divs and selecting the current input
    stopPropagation($event: Event) {
        event.stopPropagation();
    }

    // update width based on length of the word manually
    calculateWidth(index) {
        const el = document.getElementById(`email-${index}-${this.name}`);
        if (el && this.emailInputs[index].email && this.emailInputs[index].email.length) {
            el.style.width = `${this.emailInputs[index].email.length * 12}px`;
        }
    }

    // if user clicks backspace and left arrow while cursors
    // is at the start of the input field, select previous email input
    processKeySelections(event: KeyboardEvent, index: number) {
        const target: any = event.target;

        // if user is at start of input field and pressing left/backspace, navigate to previous input
        if ((event.key === 'ArrowLeft' || event.key === 'Backspace') && target && target.selectionStart === 0 && target.selectionEnd === 0) {
            this.emailInputs[index].email = this.emailInputs[index].email.trim();
            this.emailInputs[index].valid = this.isEmailValid(this.emailInputs[index].email);

            // if empty then remove
            if (this.emailInputs[index].email === '') {
                this.emailInputs = this.emailInputs.slice(0, index).concat(this.emailInputs.slice(index + 1, this.emailInputs.length));
            }

            if (index > 0) this.editEmail(null, index - 1);
            this.updateValidEmailList();
        }

        // if user is at end of input field and pressing right, navigate to next input
        const len = (this.emailInputs[index] && this.emailInputs[index].email) ? this.emailInputs[index].email.length : 0;
        if (event.key === 'ArrowRight' && target.selectionStart === len && target.selectionEnd === len) {
            this.emailInputs[index].email = this.emailInputs[index].email.trim();
            this.emailInputs[index].valid = this.isEmailValid(this.emailInputs[index].email);

            // if empty then remove
            if (this.emailInputs[index].email === '') {
                this.emailInputs = this.emailInputs.slice(0, index).concat(this.emailInputs.slice(index + 1, this.emailInputs.length));
            }

            if (index < this.emailInputs.length && this.emailInputs[index + 1]) {
                this.editEmail(null, index + 1);
                setTimeout(() => {
                    const el: any = document.getElementById(`email-${index + 1}-${this.name}`);
                    if (el && el.setSelectionRange) el.setSelectionRange(0, 0);
                }, 50);
            }
            this.updateValidEmailList();
        }
    }
}
