import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ErrorHandlerService } from '@shared/error-handler/error-handler.service';
import { Store } from '@ngrx/store';
import { getProxyMode, getUser } from '@app/store/user/user.reducer';
import { IUser, InitializeUser, User } from '@app/store/user';
import { State } from '@app/store';

import { Observable, of, Subject } from 'rxjs';
import { tap, filter, take, switchMap, takeUntil } from 'rxjs/operators';
import { SyncDatabaseService } from './shared/report-services/sync-database.service';
import { InitializeUser as InitUser } from '@app/store/user/user.actions';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';

@Injectable()
export class AppAuthGuard implements CanActivate, OnDestroy {
    maintenance = false;
    destroy$: Subject<void> = new Subject<void>();
    userAaId: string;

    constructor(
        private errorHandlerService: ErrorHandlerService,
        private store: Store<State>,
        private syncDB: SyncDatabaseService,
        private authService: PingAuthenticationService
    ) {
        // loading user aa id then loading it into store
        this.authService.profile$.pipe(takeUntil(this.destroy$)).subscribe(data => {
            this.userAaId = data.uid;
            this.store.dispatch(new InitUser(this.userAaId));
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.getFromStoreOrAPI().pipe(
            switchMap(u => this.isCERSUser(u).toPromise())).toPromise();
    }

    private getFromStoreOrAPI(): Observable<any> {
        return this.store.select(getUser).pipe(
            tap(async u => {
                if (!u) {
                    if (!window.navigator.onLine) { // user is offline
                        try {
                            const usr: any = await this.syncDB.loadUser();
                            this.store.dispatch(new InitializeUser(usr));
                        } catch (err) {
                            console.error('error loading user from idb');
                            this.errorHandlerService.Error();
                        }
                    }
                }
            }),
            filter(user => user),
            take(1)
        );
    }

    private isCERSUser(user: IUser): Observable<boolean> {
        let is = false;
        let proxyMode = false;

        this.store.select(getProxyMode).pipe(take(1)).subscribe(proxymode => proxyMode = proxymode);

        if (!user) {
            this.errorHandlerService.Error();
        } else if (typeof (user.profileId) === 'number' && user.firstName) {
            if (!User.isActiveEmployee(user) && !proxyMode) {
                this.errorHandlerService.InactiveEmployee(user.status);
            } else {
                is = true;
            }
        } else {
            this.errorHandlerService.UnAuthorized();
        }

        return of(is);
    }
}
