import { Injectable, EventEmitter } from '@angular/core';
import * as _ from 'lodash';

export const employeeStatusText = {
    1: 'Your id is currently showing that you are on a leave of absence. Please contact your supervisor.',
    2: 'Your id is currently showing as inactive. Please contact your supervisor.'
};
export const employeeStatusTextProxy = {
    1: 'is showing on a leave of absence.',
    2: 'is showing as inactive.'
};

@Injectable()
export class ErrorHandlerService {
    public appError$: EventEmitter<any>;
    public authError$: EventEmitter<any>;
    public inactiveEmployeeError$: EventEmitter<any>;

    constructor() {
        this.appError$ = new EventEmitter();
        this.authError$ = new EventEmitter();
        this.inactiveEmployeeError$ = new EventEmitter();
    }

    public Error(message?: string) {
        this.appError$.emit(message || '');
    }

    public UnAuthorized(message?: string) {
        this.authError$.emit(message || '');
    }

    public InactiveEmployee(employeeStatus: number) {
        this.inactiveEmployeeError$.emit(employeeStatusText[employeeStatus] || '');
    }

    public getErrorTextProxy(employeeId: number | string, status: number): string {
        return employeeId + ' ' + employeeStatusTextProxy[status] || 'is not authorized';
    }
}

