import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SSEHubNotification } from './SSEHubNotification.service';

@Injectable()
export class SSEHubNotificationService {

    constructor(
        private http: HttpClient,
        private sseHubClient: SSEHubNotification
    ) { }

    getPhoneNumbers(id: string): Observable<any> {
        return this.http.get(
            `${this.sseHubClient.serviceUrl()}/api/employees/${encodeURIComponent(id)}/phoneNumbers`);
    }
    acceptOPTStatus(phoneNumber: string, employeeNumber: string): Observable<any> {
        return this.http.post(
            `${this.sseHubClient.serviceUrl()}/api/employees/${employeeNumber}/${phoneNumber}/ACPT/one`, null);
    }
    declineOPTStatus(phoneNumber: string, employeeNumber: string): Observable<any> {
        return this.http.post(
            `${this.sseHubClient.serviceUrl()}/api/employees/${employeeNumber}/${phoneNumber}/DCLD/one`, null);
    }
    sendDeleteNotificationRequest(employeeNumber: string, notificationValue: string) {
        return this.http.post(`${this.sseHubClient.serviceUrl()}/api/SendDeleteRequestEmail`, {
            employeeId: employeeNumber,
            notificationValue: notificationValue,
            description: ''
        });
    }
}
