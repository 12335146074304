import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoggerService } from '@shared/error-handler-notify/services';
import { ToasterComponent } from './toaster/toaster.component';
import { ToastComponent } from './toast/toast.component';
import { NotificationService } from './services/notification.service';



@NgModule({
    declarations: [
        ToasterComponent,
        ToastComponent
    ],
    imports: [
        CommonModule,
    ],
    providers: [
        LoggerService,
        NotificationService
    ],
    exports: [
        ToasterComponent
    ]
})

export class ErrorHandlerModule { }

