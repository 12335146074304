<ol class="breadcrumb">
    <li><a routerLink="/home"><i class="icon-home"></i> Home</a></li>
    <li class="active">Fill out report</li>
</ol>

<div class="category-list-container">
    <h1>Fill out report</h1>
    <h5 *ngIf="((categoryList$ | async)?.list | categoryInactive).length > 0"> *Click on a category to begin filling out
        your report. <a href="https://cers.aa.com/newreport" target="_blank">For all other reports click here.</a>
    </h5>
    <h5 *ngIf="!(((categoryList$ | async)?.list | categoryInactive).length > 0)"> *Click on a category to begin filling
        out your report.</h5>
    <app-aa-spinner [loading]="(categoryList$ | async).isLoading"></app-aa-spinner>
    <div id="errorText" *ngIf="(categoryList$ | async).hasError">
        <h4>Error loading the list of categories </h4>
    </div>

    <div class="category-list" *ngIf="!(categoryList$ | async).isLoading">

        <mat-action-list *ngIf="(categoryList$ | async).list | categoryListHasAdditionalInfo">
            <ng-container *ngFor="let category of (categoryList$ | async).list; let i = index">
                <ng-container *ngIf="category.categoryActive">
                    <div style="display: flex;">
                        <a href="#{{i}}" *ngIf="category?.attrs?.categoryAdditionalInfo" data-bs-toggle="collapse"
                            (click)="category.IconExpand = !category.IconExpand"><i class="icon-position"
                                [class]="category.IconExpand ? 'icon-expand' : 'icon-collapse'"></i></a>
                        <div [class.no-additional-info]="!category?.attrs?.categoryAdditionalInfo"></div>
                        <button mat-list-item class="mat-list-item-padding" (click)="onCategoryClick(category)">
                            <h4>{{category.description}}</h4>
                        </button>
                    </div>

                    <div id="{{i}}" class="collapse additional-info-position">
                        <div appSafeHtml htmlString="{{category?.attrs?.categoryAdditionalInfo}}" class="block"></div>
                    </div>
                </ng-container>
            </ng-container>
        </mat-action-list>

        <mat-action-list *ngIf="!((categoryList$ | async).list | categoryListHasAdditionalInfo)">
            <ng-container *ngFor="let category of (categoryList$ | async).list">
                <button mat-list-item *ngIf="category.categoryActive" (click)="onCategoryClick(category)">
                    <h4>{{category.description}}</h4>
                </button>
            </ng-container>
        </mat-action-list>

    </div>
</div>