<div [hidden]="!appReady" class="container">
    <app-aa-nav-bar *ngIf="!isMobile"></app-aa-nav-bar>
    <app-aa-sidenav *ngIf="isMobile"></app-aa-sidenav>
    <div class="content-container" [ngClass]="{'open-sidenav': isSidenavOpen}">
        <div class="content-main">
            <router-outlet></router-outlet>
        </div>
    </div>
    <app-timeout></app-timeout>
</div>
<app-aa-spinner [loading]="isLoading"></app-aa-spinner>

<div class="container">
    <app-maintenance *ngIf="underMaintenance"></app-maintenance>
</div>

<app-toaster></app-toaster>

<div *ngIf="appError" class="container">
    <div class="col-lg-12">
        <h1 class="errorText">Error Loading application</h1>
        <p>Your session has ended. In order to access your account, you will need to <a (click)="signIn()">sign in</a>
            again.
            <br>Please contact the help desk if the problem persists
        </p>
    </div>
</div>

<div *ngIf="authError" class="container">
    <div class="col-lg-12">
        <h1 class="errorText">Not authorized</h1>
        <p>
            You attempted to access content you do not have the authorization to view. If you believe this is in error,
            please contact the CERS administrator.
        </p>
    </div>
</div>

<div *ngIf="inactiveEmployeeError" class="container">
    <div class="col-lg-12">
        <h1 class="errorText">Not authorized</h1>
        <p>{{ errorMessage }}</p>
    </div>
</div>