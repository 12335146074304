import { Component, EventEmitter, ViewChild, Output, Input } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { IDynamicQuestion } from '../../new-report-store/wizard';

@Component({
    selector: 'app-multi-group',
    templateUrl: './multi-group.component.html',
    styleUrls: ['../dynamic-form.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})

export class MultiGroupComponent {
    @Output() multiGroupEvent$: EventEmitter<any>;
    question: IDynamicQuestion;
    questionAttrs: object = {};
    @ViewChild('form') form: NgModel;

    constructor() { }

    onClick() {
        this.multiGroupEvent$.emit(this.question);
    }
}
