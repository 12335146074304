import { Component, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { WizardService } from '@newreport/wizard/wizard.service';
import { IFlightSequence } from '@newreport/new-report-store/wizard';

@Component({
    selector: 'app-confirm-flight-change',
    template: `
    <modal modalClass="modal-md" #confirmFlightChange>
        <modal-header>
            <h4>Confirm flight change</h4>
        </modal-header>
        <modal-content>
            <p>Changing flight information will reset all questions on the Questions page. Do you want to proceed?</p>
        </modal-content>
        <modal-footer>
            <button class="btn-cancel" (click)="cancel()">Cancel</button>
            <button class="btn-primary leftMargin remove" type="button" (click)="confirm()">Confirm</button>
        </modal-footer>
    </modal>
    `,
    styleUrls: ['./modals.component.scss']
})

export class ConfirmFlightChangeComponent {

    @ViewChild('confirmFlightChange', { static: true }) public modal;
    @Output() public cancelUpdate = new EventEmitter();
    @Output() public confirmUpdate = new EventEmitter<IFlightSequence>();
    @Input() public flightDetail: IFlightSequence;


    constructor(
        private wizardService: WizardService
    ) { }

    openModal() {
        this.modal.open();
    }

    // reset the general questions page
    confirm() {
        this.modal.close();
        this.wizardService.resetGeneralQuestions();
        this.confirmUpdate.emit(this.flightDetail);
    }

    // don't cancel anything, leave the user where they are
    cancel() {
        this.modal.close();
        this.cancelUpdate.emit();
    }
}
