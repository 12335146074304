<ol class="breadcrumb">
    <li>
        <a routerLink="/home">
            <i class="icon-home"></i> Home</a>
    </li>
    <li>
        <a routerLink="/reporthistory">Report history</a>
    </li>
    <li>Report search</li>
</ol>
<h1>Report search - employee injury on duty</h1>

<form [formGroup]="searchForm" (ngSubmit)="search()" class="element-spacing">
    <div class="input-display">
        <div class="input-spacing">
            <label class="search-name"><i class="icon-cers-required"></i>Injured employee's ID:</label>
            <input class="form-control search-value" type="number" maxlength="8" pattern="[0-9]*" id="employeeId"
                formControlName="employeeId">
            <div *ngIf="searchForm.get('employeeId').invalid && searchForm.get('employeeId').touched" class="errorText">
                Enter a valid employee ID.</div>
        </div>
        <label class="search-name"><i class="icon-cers-required"></i>Report ID or ETN number:</label>
        <input class="form-control search-value" type="number" maxlength="10" pattern="[0-9]*" id="reportId"
            formControlName="reportId">
        <div *ngIf="searchForm.get('reportId').invalid && searchForm.get('reportId').touched" class="errorText">Enter a
            10 digit report id or 6 digit ETN number.</div>
        <div class="element-spacing">
            <button type="button" [disabled]="!searchForm.valid" class="btn-primary" (click)="search()">Search</button>
        </div>
    </div>
</form>
