<ul *ngIf="attSummary && attSummary.attachments && attSummary.attachments.length > 0" class="checkbox attachments"
    [ngClass]="{'can-edit': canEdit, 'list-basic': !canEdit}">
    <li *ngFor="let attachment of attSummary.attachments" class="checkbox" [ngClass]="{'list-basic': !canEdit}">
        <div>
            <i *ngIf="canEdit" (click)="confirm(attachment)" class="icon-medium icon-delete"></i>
            <a class="leftMargin" (click)="attSummary.viewAttachment(attachment)">{{attachment.fileName}}</a>
        </div>
        <p *ngIf="attachment.description" class="attachment-description"> {{ attachment.description }}</p>
    </li>
</ul>
<modal modalClass="modal-md" #confirmRemove>
    <modal-header>
        <h4>Remove attachment</h4>
    </modal-header>
    <modal-content>
        <p *ngIf="toRemove && toRemove.fileName">Do you want to remove attachment <span class="fontRed">{{
                toRemove.fileName }}</span> from the
            report?</p>
    </modal-content>
    <modal-footer>
        <button class="btn-cancel" (click)="confirmRemove.close()">No</button>
        <button class="btn-primary leftMargin remove" type="button" (click)="remove()">Yes</button>
    </modal-footer>
</modal>
