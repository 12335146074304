<div class="dynamicInputDiv" [ngModelGroup]="question.questionMappingId">
    <label>
        <i *ngIf="questionAttrs['isRequired']" class="icon-cers-required"></i>
        {{ question.questionText | sentenceCasePipe }}
    </label>
    <div>
        <button *ngIf="questionAttrs['naOption']" type="button" class="btn-dynamic-form"
            (click)="autofillTextNA()">N/A</button>
    </div>
    <textarea aaAutosize [minRows]="5" [maxRows]="20" class="form-control" name="{{question.questionText}}"
        [(ngModel)]="question.userAnswer" [appDynamicValidation]="questionAttrs" (focusout)="sanitizeAnswer()"
        [appFocus]="{questionId: question.questionId, questionMappingId: question.questionMappingId}"
        #form="ngModel"></textarea>
    <div *ngFor="let error of form.errors | keyvalue">
        <label class="errorText" *ngIf="error.key !== 'isRequired'"> {{error.value}} </label>
    </div>
</div>