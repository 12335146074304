<form #form="ngForm">

    <div *ngIf="crewSeqs && crewSeqs.options && crewSeqs.options.length > 0">
        <div>Crew sequence</div>
        <select name="crewSeqSelect" id="crewSeqSelect" class="form-control dropdown marginBottom"
            (change)="updateCrewSeqSelection()" [(ngModel)]="crewSeqs.userAnswer">
            <option *ngFor="let seq of crewSeqs.options" [ngValue]="seq">
                {{ seq.flightNumber | flightNumberDisplayPipe }} {{ seq.flightDate | date:'shortDate' : 'UTC'}}
                {{ seq.departureStation }} {{ seq.arrivalStation }} {{ seq.fleetDescription }} {{ seq.tailNumber }}
            </option>
        </select>
        <div class="hr-section">
            <hr class="inline-hr1">
            <label class="inline-hr2">OR</label>
            <hr class="inline-hr3">
        </div>
    </div>
    <div class="lineContainer flight-search-section">
        <div class="dynamicInputDiv inline20 left">
            <div class="required-field-container">
                <i *ngIf=" flightQuestions.flightNumber.attrs['isRequired']" class="icon-cers-required"></i>
                <label>Flight number</label>
            </div>
            <input type="number" class="form-control" id="flightNumber"
                (change)="clearCrewSeqSelection(); formatFlightNumber(flightResults.flightNumber);"
                [(ngModel)]="flightResults.flightNumber" name="flightNumber"
                [appDynamicValidation]="flightQuestions.flightNumber.attrs" #flt="ngModel">
            <div *ngFor="let error of flt.errors | keyvalue">
                <div class="validation-fail" *ngIf="error.key !== 'isRequired'"> {{error.value}} </div>
            </div>
        </div>

        <div class="dynamicInputDiv inline30">
            <div class="required-field-container">
                <i *ngIf="flightQuestions.flightDate.attrs['isRequired']" class="icon-cers-required"></i>
                <label>Departure date</label>
            </div>
            <input matInput [matDatepicker]="picker" placeholder="mm/dd/yyyy" class="form-control date"
                (change)="clearCrewSeqSelection()" [(ngModel)]="flightResults.flightDate" name="departureDate"
                (click)="picker.open()" [appDynamicValidation]="flightQuestions.flightDate.attrs" #date="ngModel">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker (opened)="toggleScrollLock(true)" (closed)="toggleScrollLock(false)" #picker>
            </mat-datepicker>
            <div *ngFor="let error of date.errors | keyvalue | dateValidationPipe">
                <div class="validation-fail" *ngIf="error.key !== 'isRequired'"> {{error.value}} </div>
            </div>
        </div>

        <div class="dynamicInputDiv inline50 right" [ngModelGroup]="flightResults.departureStation">
            <div class="required-field-container">
                <i *ngIf="flightQuestions.departureStation.attrs['isRequired']" class="icon-cers-required"></i>
                <label>Departure station</label>
            </div>
            <input type="text" class="form-control" maxlength="3" id="departureCity" name="departureCity"
                (input)="replaceInput($event)" (change)="clearCrewSeqSelection()"
                [(ngModel)]="flightResults.departureStation"
                [appDynamicValidation]="flightQuestions.departureStation.attrs" [matAutocomplete]="auto" #brd="ngModel">
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of options" [value]="option.airportId">
                    {{option.airportId + ' - ' + option.airportDesc}}
                </mat-option>
            </mat-autocomplete>
            <div *ngFor="let error of brd.errors | keyvalue">
                <div class="validation-fail" *ngIf="error.key !== 'isRequired'"> {{error.value}} </div>
            </div>
        </div>
    </div>
    <hr *ngIf="crewSeqs && crewSeqs.options && crewSeqs.options.length > 0">
    <div class=" search-buttons-div">
        <button type="submit" class="btn-primary search-buttons" (click)="searchFlight()">Search</button>
        <button class="btn-cancel leftMargin search-buttons" (click)="clear()">Clear</button>
    </div>
</form>
