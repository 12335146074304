import { Pipe, PipeTransform } from '@angular/core';
import { ICategory } from './new-report-store/list/list.model';
import * as _ from 'lodash';

@Pipe({ name: 'categoryInactive' })
export class CategoryInactivePipe implements PipeTransform {
    transform(listOfCategories: ICategory[]): any {
        return listOfCategories.filter(category => !category.categoryActive);
    }
}

@Pipe({
    name: 'categoryListHasAdditionalInfo'
})
export class CategoryListHasAdditionalInfoPipe implements PipeTransform {
    constructor() { }
    transform(categoryList: ICategory[]): boolean {
        return _.filter(categoryList, category => category?.attrs?.categoryAdditionalInfo?.length > 0).length > 0;
    }
}
