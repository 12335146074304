<modal #tagsListModal (onClose)="onClose()">
    <modal-header>
        <app-aa-spinner [loading]="isLoading"></app-aa-spinner>
        <ng-container *ngIf="!isLoading">
            <ng-container *ngIf="existingTags && existingTags.length > 0">
                <h4>Existing tags</h4>
                <div *ngFor="let tag of existingTags">
                    <ng-container *ngIf="!tag.systemRequired">
                        <i (click)="removeTagMapping(tag)" class="icon-medium icon-delete"></i>
                        <span style="padding-left: 10px">{{ tag.tagVal }}</span>
                    </ng-container>
                </div>
            </ng-container>
            <h4 *ngIf="!existingTags || existingTags.length == 0">Add tag</h4>
        </ng-container>
    </modal-header>
    <modal-content>
        <h4 *ngIf="existingTags && existingTags.length > 0">Add new tag</h4>
        <div *ngIf="allTagsToDisplay && allTagsToDisplay.length > 0">
            <table class="reportHistory-table table table-hover table-striped">
                <thead>
                </thead>
                <tbody>
                    <tr *ngFor="let tag of allTagsToDisplay">
                        <td>{{ tag.tagVal }}</td>
                        <td class="btn-add-n-select" (click)="addTagMapping(tag)"><a>Add</a></td>
                    </tr>
                </tbody>
            </table>

            <div class="d-flex justify-content-between p-2">
                <ngb-pagination
                    [collectionSize]="allTagsToDisplay.length"
                    [(page)]="page"
                    [pageSize]="pageSize"
                    (pageChange)="reRender()"
                >
                </ngb-pagination>
            </div>
        </div>
    </modal-content>
    <modal-footer>
        <button type="button" class="btn btn-cancel" (click)="tagsListModal.close()">Close</button>
    </modal-footer>
</modal>
