<div class="reports-tool-bar">
    <div class="clear-filter">
        <button class="clear-filter" (click)="clearAllFilter()">Clear filter</button>
    </div>
</div>

<div class="table-container">
    <mat-table #table matSort [dataSource]="dataSource" matSortActive="{{ (myReports$ | async).list.options.sort.id}}" matSortDirection="{{ (myReports$ | async).list.options.sort.start}}" matSortDisableClear>

        <ng-container matColumnDef="reportId">
            <mat-header-cell *matHeaderCellDef class="cell cell-small">
                <label class="header-row" mat-sort-header> ID </label>
                <div class="filter-div">
                    <input class="form-control" (keyup)="applyFilter()" [(ngModel)]="tableOptions.filter.reportId" placeholder="Filter id">
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let tableData" class="cell cell-small">
                <label class="trowId">{{tableData.reportId}}</label>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="title">
            <mat-header-cell class="cell cell-large" *matHeaderCellDef>
                <label class="header-row" mat-sort-header> Title </label>
                <div class="filter-div">
                    <input class="form-control" [(ngModel)]="tableOptions.filter.title" (keyup)="applyFilter()" placeholder="Filter title">
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let tableData" class="cell cell-large">
                <label class="trow">{{tableData.title}}</label>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="submitDate">
            <mat-header-cell *matHeaderCellDef class="cell cell-small">
                <label class="header-row" mat-sort-header>Sub. Date </label>
                <div class="filter-div">
                    <input class="form-control" [(ngModel)]="tableOptions.filter.submitDate" (keyup)="applyFilter()" placeholder="mm/dd/yyyy">
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let tableData" class="cell cell-small">
                <label class="trow"> {{tableData.submitDate | date:'MM/dd/yyyy'}} </label>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="flightNumber">
            <mat-header-cell *matHeaderCellDef class="cell cell-small">
                <label class="header-row" mat-sort-header>Flight num</label>
                <div class="filter-div">
                    <input class="form-control" [(ngModel)]="tableOptions.filter.flightNumber" (keyup)="applyFilter()" placeholder="Filter flight">
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let tableData" class="cell cell-small">
                <label class="trow"> {{tableData.flightNumber}}</label>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="flightDate">
            <mat-header-cell *matHeaderCellDef class="cell cell-small">
                <label class="header-row" mat-sort-header>Flight date</label>
                <div class="filter-div">
                    <input class="form-control" [(ngModel)]="tableOptions.filter.flightDate" (keyup)="applyFilter()" placeholder="mm/dd/yyyy">
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let tableData" class="cell cell-small">
                <label class="trow"> {{tableData.flightDate | date:'MM/dd/yyyy' : 'UTC'}} </label>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="departureStation">
            <mat-header-cell *matHeaderCellDef class="cell cell-small">
                <label class="header-row" mat-sort-header> Departure </label>
                <div class="filter-div">
                    <input class="form-control" [(ngModel)]="tableOptions.filter.departureStation" (keyup)="applyFilter()" placeholder="Filter departure">
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let tableData" class="cell cell-small">
                <label class="trow"> {{tableData.departureStation}} </label>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="arrivalStation">
            <mat-header-cell *matHeaderCellDef class="cell cell-small">
                <label class="header-row" mat-sort-header> Arrival </label>
                <div class="filter-div">
                    <input class="form-control" [(ngModel)]="tableOptions.filter.arrivalStation" (keyup)="applyFilter()" placeholder="Filter arrival">
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let tableData" class="cell cell-small">
                <label class="trow"> {{tableData.arrivalStation}}</label>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef class="cell cell-small">
                <label class="header-row" mat-sort-header> Status </label>
                <div class="filter-div">
                    <input class="form-control" [(ngModel)]="tableOptions.filter.status" (keyup)="applyFilter()" placeholder="Filter status">
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let tableData" class="cell cell-small">
                <label class="trow"> {{tableData.status}} </label>
            </mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="gotoReportSummary(row)"></mat-row>

    </mat-table>

    <mat-paginator #paginator [length]="dataSource.data.length" [pageSize]="10" [showFirstLastButtons]="true">
    </mat-paginator>
    <h3 *ngIf="!dataSource.data.length  && !(myReports$ | async).isLoading && !isParsingTable" class="text-center"> No results found </h3>
</div>
<app-aa-spinner [loading]="(myReports$ | async).isLoading || isParsingTable">
</app-aa-spinner>