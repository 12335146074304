<ol class="breadcrumb">
    <li>
        <a routerLink="/home">
            <i class="icon-home"></i> Home</a>
    </li>
    <li class="active">To do</li>
</ol>
<h1>
    To do
</h1>
<div class="pending-container" *ngIf="newReportService.isPwa">
    <h3>Unfinished reports</h3>
</div>

<div *ngIf="newReportService.isPwa">
    <app-table-unfinished-reports [employeeId]=employeeId>unfinished report table here...</app-table-unfinished-reports>
    <h3>To-do reports</h3>
</div>

<div>
    <app-table-todo-reports *ngIf="profileId == 4" [employeeId]=employeeId>Todo reports</app-table-todo-reports>
    <app-table-todo-report *ngIf="profileId != 4" [employeeId]=employeeId>Todo reports</app-table-todo-report>
</div>
