import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { IPassengerModel } from './pax-search.component';
import { ICrewModel } from './crew-search.component';
import { IEmployeeModel } from './employee-search.component';

@Pipe({ name: 'formatPaxNameDisplay' })
export class FormatPaxNameDisplay implements PipeTransform {
    transform(value: IPassengerModel): string {
        let values = [value.firstName + ' ' + value.lastName, value.seat];
        values = values.filter(Boolean);
        return values.join(', ');
    }
}

@Pipe({ name: 'formatCrewNameDisplay' })
export class FormatCrewNameDisplay implements PipeTransform {
    transform(value: ICrewModel): string {
        return value.firstname + ' ' + value.lastname;
    }
}

@Pipe({ name: 'formatEmployeeNameDisplay' })
export class FormatEmployeeNameDisplay implements PipeTransform {
    transform(value: IEmployeeModel): string {
        let values = [value.firstName + ' ' + value.lastName, value.employeeId, value.jobTitle, value.stationCode];
        values = values.filter(Boolean);
        return values.join(', ');
    }
}

