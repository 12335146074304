import { Injectable, OnDestroy } from '@angular/core';
import { NewReportState, getNewReportState, getSelectedCategory } from 'app/new-report/new-report-store/index';
import { ICategory, LoadCategoriesState } from 'app/new-report/new-report-store/list';
import { EncrDecrService } from './encrDecryption/encrDecr.service';
import { Store } from '@ngrx/store';
import { State } from 'app/store';
import { getUser } from 'app/store/user';
import { DatabaseService } from './database.service';
import { LoadWizardQuestions, LoadWizardSection } from 'app/new-report/new-report-store/wizard/wizard.actions';
import { Router } from '@angular/router';
import { SSEHubSettingsService, ISetting } from 'app/shared/SSEHubClient';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// DB object
export interface IUnfinishedReportMetaData {
    unfinishedReportId: number; // unique integer for this user and report
    unfinishedReportName: string; // format: 'ID: 3 Customer Misconduct' - note '3' is unique integer for this user
    employeeId: number;
    unfinishedDate: Date; // date originally saved; used as key in DB
    category: string;
    report: string; // NewReportState -> json -> encrypted
}

@Injectable()
export class UnfinishedReportDatabaseService implements OnDestroy {
    category: ICategory;
    employeeId: any;
    nextReportId: number; // ID to use for next report saved by this user
    private dbName = 'unfinishedReports';
    private dbStore = 'reports';
    private existingReportIDs: number[] = [];
    public unfinishedReportMetaDataList: IUnfinishedReportMetaData[] = [];
    private newReportState: NewReportState;
    private selectedCategoryDescription: string;
    public loadingUnfinishedReport = false; // prevents the wizard that overwrite a report loaded from DB
    private numberDaysOldToDelete = 30;
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private encrDecrService: EncrDecrService,
        private store: Store<State>,
        private db: DatabaseService,
        private router: Router,
        private settingService: SSEHubSettingsService
    ) {
        this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(v => this.employeeId = (v && v.id) ? v.id : '');

        this.nextReportId = 1;
        // this.retrieveUnfinishedReportList();

        // for saving unfinished reports from store
        this.store.select(getNewReportState).pipe(takeUntil(this.destroy$)).subscribe(v => this.newReportState = v);
        this.store.select(getSelectedCategory).pipe(takeUntil(this.destroy$)).subscribe((cat: ICategory) => {
            if (cat) { this.selectedCategoryDescription = (cat && cat.description) ? cat.description : 'No category selected'; }
        });

        // get settings from DB
        this.settingService.getAll().pipe(map((val: any) => {
            if (val && val.settings) {
                val.settings.map((setting: ISetting) => {
                    if (setting && setting.value && (setting.value !== '') && setting.key === 'UNFINISHED_REPORTS_DAYS_OLD') {
                        const days = Number(setting.value);
                        if (!isNaN(days) && (days > 0)) {
                            this.numberDaysOldToDelete = days; // otherwise 30 by default
                        }
                    }
                });
            }
        })).pipe(takeUntil(this.destroy$)).subscribe(
            (result) => {
                this.retrieveUnfinishedReportList();
            },
            (error) => { }
        );
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    saveUnfinishedReport(): Promise<any> {
        // get unique ID
        if (Array.isArray(this.existingReportIDs) && this.existingReportIDs.length && (this.existingReportIDs.length > 0)) {
            this.nextReportId = Math.max(...this.existingReportIDs) + 1;
        } else {
            this.nextReportId = 1;
        }

        const unfinishedReportData = <IUnfinishedReportMetaData>{};
        // unfinishedReportData.unfinishedReportName = 'ID: ' + this.nextReportID + ' ' + this.selectedCategoryDescription;
        unfinishedReportData.unfinishedReportName = this.selectedCategoryDescription;
        unfinishedReportData.unfinishedReportId = this.nextReportId;
        this.existingReportIDs.push(this.nextReportId);

        unfinishedReportData.employeeId = this.employeeId;
        unfinishedReportData.report = this.encrDecrService.set(JSON.stringify(this.newReportState));
        unfinishedReportData.unfinishedDate = new Date();
        unfinishedReportData.category = this.selectedCategoryDescription;

        return this.db.addReport(this.dbName, this.dbStore, unfinishedReportData, unfinishedReportData.unfinishedDate);
    }

    // Retrieves list of unfinished reports to display on UI
    retrieveUnfinishedReportList() {
        return this.db.getAllReports(this.dbName, this.dbStore).then((reports: IUnfinishedReportMetaData[]) => {
            if (reports) {
                this.unfinishedReportMetaDataList = [];
                reports.forEach((uR) => {
                    if (this.employeeId === uR.employeeId) {

                        const ageInMilliseconds = Date.now().valueOf() - uR.unfinishedDate.valueOf();
                        const ageInDays = ageInMilliseconds / (1000 * 60 * 60 * 24);
                        if (ageInDays > this.numberDaysOldToDelete) {
                            // delete
                            this.db.deleteReport(this.dbName, this.dbStore, uR.unfinishedDate);
                        } else {
                            // keep and display
                            this.unfinishedReportMetaDataList.push({ ...uR, report: JSON.parse(this.encrDecrService.get(uR.report.toString())) });
                        }
                    }
                    this.existingReportIDs.push(uR.unfinishedReportId);
                });
                return this.unfinishedReportMetaDataList;
            }
        });
    }

    // retrieves an unfinished report from IndexedDB, populates the store newReportState, then deletes report from DB
    retrieveUnfinishedReport(unfinishedDate: Date) {
        let newReport: any = null; // NewReportState
        this.unfinishedReportMetaDataList.forEach(report => { if (report.unfinishedDate === unfinishedDate) newReport = report.report; });
        this.loadingUnfinishedReport = true;
        if (newReport) {
            this.store.dispatch(new LoadCategoriesState(newReport.categories));
            this.store.dispatch(new LoadWizardQuestions(newReport.wizardQuestions));
            // this.store.dispatch(new LoadWizardNotes(newReport.wizardNotes));
            this.store.dispatch(new LoadWizardSection(newReport.wizardSection));

            this.router.navigate(['newreport/wizard/flight']);

            this.db.deleteReport(this.dbName, this.dbStore, unfinishedDate);
        }
    }

    deleteUnfinishedReport(unfinishedDate: Date) {
        this.db.deleteReport(this.dbName, this.dbStore, unfinishedDate);
    }

    doneLoadingUnfinishedReport() {
        this.loadingUnfinishedReport = false;
    }
}
