import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';


import { SSEHubClient } from './SSEHubClient.service';
import { SSEHubAirportService } from './airport.service';
import { SSEHubAttributesService } from './attributes.service';
import { SSEHubCategoriesService } from './categories.service';
import { SSEHubCrewService } from './crew.service';
import { SSEHubEmployeesService } from './employees.service';
import { SSEHubCERSPortalNotificationService } from './notification.service';
import { SSEHubFlightSequencesService } from './flightSequences.service';
import { SSEHubInvolvementsService } from './involvements.service';
import { SSEHubSettingsService } from './settings.service';
import { SSEHubProfileService } from './profile.service';
import { SSEHubReportHistoryService } from './report-history.service';
import { SSEHubSectionService } from './section.service';
import { SSEHubLogService } from './log.service';

@NgModule({
    declarations: [
    ],
    imports: [
        HttpClientModule
    ],
    providers: [
        SSEHubClient,
        SSEHubAirportService,
        SSEHubAttributesService,
        SSEHubCategoriesService,
        SSEHubCrewService,
        SSEHubEmployeesService,
        SSEHubCERSPortalNotificationService,
        SSEHubFlightSequencesService,
        SSEHubInvolvementsService,
        SSEHubSettingsService,
        SSEHubProfileService,
        SSEHubReportHistoryService,
        SSEHubSectionService,
        SSEHubLogService,
    ],
    exports: [
    ]
})

export class SSEHubClientModule {

}
