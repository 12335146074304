import { NgModule } from '@angular/core';

import { EncrDecrService } from './encrDecryption/encrDecr.service';
import { SubmittedReportService } from './submitted-report.service';
import { UnfinishedReportDatabaseService } from './unfinished-report-database.service';
import { DatabaseService } from './database.service';
import { OfflineSyncService } from './offline-sync.service';
import { SyncDatabaseService } from './sync-database.service';

@NgModule({
    declarations: [],
    imports: [],
    providers: [
        DatabaseService,
        OfflineSyncService,
        EncrDecrService,
        SubmittedReportService,
        UnfinishedReportDatabaseService,
        SyncDatabaseService
    ]
})
export class ReportServicesModule {}
