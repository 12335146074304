<div *ngIf="loading && style =='vertical'" class="loader-center">
    <div *ngIf="showAa" style="padding-bottom: 10px;padding-top:10px;"><img style="width: 50px;" src='assets/AAFlag.png'
            alt="American Airlines"></div>
    <div style="padding-bottom: 10px;">{{text}}</div>
    <div *ngIf="showSpinner" style="padding-bottom: 10px; padding-left: 10px;"><img src='assets/spinner.gif'></div>
</div>
<div *ngIf="loading && style =='horizontal'" class="loader-center">
    <table>
        <tr>
            <td style="padding: 5px;"><img *ngIf="showAa" style="width: 50px;" src='assets/AAFlag.png'
                    alt="American Airlines"></td>
            <td style="padding: 5px;"><img *ngIf="showSpinner" src='assets/spinner.gif'></td>
            <td style="padding: 5px;">{{text}}</td>
        </tr>
    </table>
</div>
