import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SectionInfo } from '@shared/reportStructure/categoryDetails/reportSections';
import { SSEHubSectionService } from '@shared/SSEHubClient/section.service';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { getUser } from '@app/store/user/user.reducer';
import { IUser, User } from '@app/store/user';
import { State } from '@app/store';
import { takeUntil } from 'rxjs/operators';
import {
    getCategoryId, getSelectedProfileId, getNotesSectionInfo, getImagesSectionInfo,
    getRecommendationsSectionInfo, getCategoryAttrs
} from '../../category-management-store';
import { SSEHubAttributesService } from 'app/shared/SSEHubClient';
import { SetCategoryAttrs } from '../../category-management-store/detail';
import { NotificationService } from 'app/shared/error-handler-notify/services';

export enum AttributeEntityGroups {
    Confirmation = 'categoryConfirmationText',
    AdditionalInfo = 'categoryAdditionalInfo',
    ProfileCategory = 'PROFILE_CATEGORY'
}

@Component({
    selector: 'app-miscellaneous-view',
    templateUrl: 'miscellaneous-view.component.html',
    styleUrls: ['../category-details.component.scss']
})

export class MiscellaneousViewComponent implements OnInit, OnDestroy {
    destroy$: Subject<void> = new Subject<void>();
    notesSectionInfo: SectionInfo;
    imagesSectionInfo: SectionInfo;
    recommendationsSectionInfo: SectionInfo;
    categoryId: number;
    profileId: number;
    includeAttachmentInEmail: boolean;
    canEdit = false;
    isEditingIncludeAttachmentInEmail = false;
    includeAttachmentInEmailValue: boolean;

    categoryAttributes = [
        // flags for confirmation text
        {
            header: 'Confirmation Text',
            entityGroup: AttributeEntityGroups.Confirmation,
            changedValue: '',
            savedValue: '',
            editing: false,
            SaveLoading: false
        },
        // flags for additional info text
        {
            header: 'Additional Category Information',
            entityGroup: AttributeEntityGroups.AdditionalInfo,
            changedValue: '',
            savedValue: '',
            editing: false,
            saveLoading: false
        }
    ]

    constructor(
        private store: Store<State>,
        private sseHubSectionService: SSEHubSectionService,
        private attributeService: SSEHubAttributesService,
        private sanitizer: DomSanitizer,
        private notification: NotificationService
    ) {
        this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(user => this.setEditPermissions(user));
    }

    ngOnInit() {
        this.store.select(getCategoryId).pipe(takeUntil(this.destroy$))
            .subscribe(id => this.categoryId = id);

        this.store.select(getSelectedProfileId).pipe(takeUntil(this.destroy$))
            .subscribe(id => this.profileId = id);

        this.store.select(getNotesSectionInfo).pipe(takeUntil(this.destroy$))
            .subscribe(section => this.notesSectionInfo = section);

        this.store.select(getImagesSectionInfo).pipe(takeUntil(this.destroy$))
            .subscribe(section => {
                this.imagesSectionInfo = section;
                this.includeAttachmentInEmail = section.includeInEmail;
            });

        this.store.select(getRecommendationsSectionInfo).pipe(takeUntil(this.destroy$))
            .subscribe(section => this.recommendationsSectionInfo = section);

        this.store.select(getCategoryAttrs).pipe(takeUntil(this.destroy$))
            .subscribe(attrs => {
                if (attrs) {
                    for (let catAtt of this.categoryAttributes) {
                        catAtt.savedValue = attrs.hasOwnProperty(catAtt.entityGroup) && attrs[catAtt.entityGroup] != null ? attrs[catAtt.entityGroup] : '';
                        catAtt.changedValue = catAtt.savedValue;
                    }
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onClickIncludeAttachmentInEmail() {
        if (!this.isEditingIncludeAttachmentInEmail) {
            return false;
        } else {
            return true;
        }
    }

    saveAttribute(field) {
        if (field.saveLoading) return;
        field.saveLoading = true;

        let json = this.createAttributeValJson(field.entityGroup);

        const attrObject = {
            entityKey: this.categoryId,
            entityType: this.profileId,
            entityGroup: AttributeEntityGroups.ProfileCategory,
            value: json
        };
        // check if it had original confirmation to create put or post
        this.attributeService.add(attrObject).pipe(
            takeUntil(this.destroy$)).subscribe({
                next: (v) => {
                    field.saveLoading = false;
                    field.editing = false;
                    // update store and component with the new saved confirmation text
                    if (v > 0) {
                        this.attributeUpdateStore(json);
                    } else {
                        this.notification.showError('Error saving attributes');
                    }
                },
                error: (err: any) => {
                    field.saveLoading = false;
                    this.notification.showError('Error saving attributes. Try again.');
                }
            });
    }

    createAttributeValJson(attributeBeingUpdated: string) {
        let json = {};
        for (const attribute of this.categoryAttributes) {
            let attr = attribute.entityGroup == attributeBeingUpdated ? attribute.changedValue.trim().replace(/"/g, '\"') : attribute.savedValue.trim().replace(/"/g, '\"');
            if (attr) {
                json[attribute.entityGroup] = attr;
            }
        }
        return json;
    }

    attributeUpdateStore(json) {
        this.store.dispatch(new SetCategoryAttrs(json));
    }

    private onEditIncludeAttachmentInEmail() {
        if (!this.isEditingIncludeAttachmentInEmail) {
            this.isEditingIncludeAttachmentInEmail = true;
            this.includeAttachmentInEmailValue = this.includeAttachmentInEmail;
        } else {
            this.includeAttachmentInEmail = this.includeAttachmentInEmailValue;
            this.isEditingIncludeAttachmentInEmail = false;
        }
    }

    private onIncludeAttachmentInEmailSave(): void {
        this.sseHubSectionService.modifyIncludeAttachmentInEmail(this.profileId, this.categoryId, this.includeAttachmentInEmail)
            .pipe(takeUntil(this.destroy$)).subscribe((value) => {
                this.isEditingIncludeAttachmentInEmail = false;
            }, (error) => {
                this.isEditingIncludeAttachmentInEmail = false;
                console.error(error);
            });
    }

    private setEditPermissions(user: IUser) {
        if (user) {
            this.canEdit = User.hasPrivilegeArray(user, [
                'WEB_ADMIN_CATEGORYMGNT_EDIT_' + this.profileId,
                'WEB_ADMIN_CATEGORYMGNT_EDIT_ALL',
                'WEB_SUADMIN'
            ]);
        }
    }
}
