import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'modal',
    styleUrls: ['./modal.component.scss'],
    templateUrl: 'modal.component.html'
})
export class CERSModalComponent implements OnInit {
    @ViewChild('modal') modal: ElementRef;
    isOpened = false;

    @Output()
    public onOpen = new EventEmitter(false);

    @Output()
    public onClose = new EventEmitter(false);

    @Output()
    public onSubmit = new EventEmitter(false);

    constructor() { }

    ngOnInit() { }

    open() {
        if (this.isOpened) return;

        this.isOpened = true;
        this.modal.nativeElement.style.display = 'block';
        document.body.className += ' modal-open';
        this.onOpen.emit();
    }

    close() {
        if (!this.isOpened) return;

        this.isOpened = false;
        setTimeout(() => {
            this.modal.nativeElement.style.display = 'none';
            this.onClose.emit();
        }, 150);
        document.body.className = document.body.className.replace(/modal-open\b/, '');
    }

    preventClose(evt) {
        evt.stopPropagation();
    }
}

@Component({
    selector: 'modal-header',
    template: `<ng-content></ng-content>`
})
export class CERSModalHeaderComponent { }

@Component({
    selector: 'modal-content',
    template: `<ng-content></ng-content>`
})
export class CERSModalContentComponent { }

@Component({
    selector: 'modal-footer',
    template: `<ng-content></ng-content>`
})
export class CERSModalFooterComponent { }
