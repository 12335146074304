import { Pipe, PipeTransform } from '@angular/core';
import { JsonToObjectPipe } from 'app/shared/pipes/json-to-object.pipe';

@Pipe({ name: 'attributeValuePipe' })
export class AttributeValuePipe implements PipeTransform {
    constructor(private jsonToObject: JsonToObjectPipe) {
    }
    transform(json: string, attribute: string): string {
        if (json && json.length > 0 && attribute && attribute.length > 0) {
            const attrsObj = this.jsonToObject.transform(json);
            return attrsObj.hasOwnProperty(attribute) ? attrsObj[attribute].toString() : '';
        } else {
            return '';
        }
    }
}
