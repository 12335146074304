import { Component, OnInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { Router } from '../../../../node_modules/@angular/router';
import { Subject } from 'rxjs';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';

@Component({
    selector: 'app-search-reports',
    templateUrl: './search-reports.component.html',
    styleUrls: ['./search-reports.component.scss']
})
export class ReportSearchComponent implements OnInit, OnDestroy {
    public searchForm: FormGroup;
    destroy$: Subject<void> = new Subject<void>();

    dropdownOptions = [
        { value: 'reportId', description: 'Report ID' },
        { value: 'etn', description: 'ETN number' }
    ];

    constructor(
        private formBuilder: FormBuilder,
        private router: Router) {
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        this.searchForm = this.formBuilder.group({
            employeeId: new FormControl('', [Validators.required, Validators.maxLength(8), Validators.pattern('^[0-9]{1,8}$')]),
            reportId: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.pattern('^[0-9]{6}$|^[0-9]{10}$')])
        });
    }

    search() {
        if (this.searchForm.valid) {
            const employeeId = this.searchForm.controls['employeeId'].value.toString();
            const reportIdOrEtn = this.searchForm.controls['reportId'].value.toString();
            this.router.navigate(['reporthistory/reportsearch/' + employeeId + '/' + reportIdOrEtn]);
        }
    }
}
