export interface ITime {
    hour: string;
    minute: string;
    amPm: string;
}
export interface IReportQuestionAnswer {
    questionMappingId: number;
    userAnswer: string;
    answerId: number;
    inputType: string;
}

export interface IJsonQuestionAnswer {
    questionMappingId: number;
    questionText: string;
    userAnswer: string;
    userAnswerArray?: Array<string>;
    answerId: number;
    inputType: string;
    identifier: string;
    recurrence?: number;
    tags?: Array<string>;
}

export interface IAttachments {
    attachments: Array<IReportImage>;
}

export interface IAssembledReport {
    mobileReportId: number;
    reportId: string;
    reportPk: 0;
    title: string;
    dateTimeSubmitted: Date;
    reportVersion: string;
    parsedReport: IReportJSON;
    employeeId: string;
    profileId: number;
    deviceType: string;
    deviceOs: string;
    deviceOsVersion: string;
    apiVersion: string;
    dateTimeTransmitted: Date;
    dateTimeReceived: Date;
    attachmentJson: string;
    status: number;
    reportJson: string;
}

export class AssembledReport implements IAssembledReport {
    public reportId: string;
    public employeeId: string;
    public profileId: number;
    public deviceType: string;
    public deviceOs: string;
    public deviceOsVersion: string;
    public apiVersion: string;
    public dateTimeReceived: Date;
    public dateTimeTransmitted: Date;
    public reportPk: 0;
    public attachmentJson: string;
    public status: number;
    public reportJson: string;

    constructor(
        public mobileReportId: number,
        public title: string,
        public reportVersion: string,
        public dateTimeSubmitted: Date,
        public parsedReport: IReportJSON
    ) {
        if (!parsedReport) {
            this.parsedReport = {
                eventId: 0,
                categoryId: null,
                employeeNumber: '',
                profileId: 0,
                proxyUser: '',
                attachments: null,
                questions: null
            };
        }
    }
}

export interface IReportJSON {
    eventId: number;
    categoryId: number;
    employeeNumber: string;
    profileId: number;
    proxyUser: string;
    attachments: any;
    questions: any;
}

export interface AttJson {
    attachment: string;
}

export interface IReportSubmitConfirmation {
    reportId: number;
    title: string;
    dateTimeSubmitted: Date;
    dateTimeTransmitted: Date;
    dateTimeReceived: Date;
}

export interface IReportImage {
    image: string;
    fileName: string;
    description: string;
}


export class InvolvementType {
    id: number;
    involvementTypeId: string;
    involvementTypeDescription: string;
}
