import * as newReportActions from './wizard.actions';
import { SectionInfo } from 'app/shared/reportStructure/categoryDetails/reportSections';
import { IError } from 'app/shared/common-ux/models/Error';
import { SessionLog } from './wizard.model';

export interface NewReportWizardSectionDetailModel {
    id: number;
    description: string;
    flight: SectionInfo;
    questions: SectionInfo;
    notes: SectionInfo;
    attachments: SectionInfo;
    isLoading: boolean;
    error: IError;
    sessionLog: {
        session: SessionLog[]
    };
}

export const initialSectionDetail: NewReportWizardSectionDetailModel = {
    id: null,
    description: null,
    flight: null,
    questions: null,
    notes: null,
    attachments: null,
    isLoading: false,
    error: null,
    sessionLog: {
        session: []
    }
};

export function sectionReducer(state = initialSectionDetail, action: newReportActions.Actions) {
    switch (action.type) {
        case newReportActions.LOAD_SECTION_DETAIL:
            return {
                ...state,
                isLoading: true
            };

        case newReportActions.POPULATE_SECTION_DETAIL:
            return {
                ...state,
                id: action.payload.id,
                description: action.payload.description,
                flight: action.payload.sections['flight'],
                questions: action.payload.sections['questions'],
                notes: action.payload.sections['notes'],
                attachments: action.payload.sections['images'],
                isLoading: false
            };

        case newReportActions.ERROR_SECTION:
            return {
                ...state,
                error: action.payload
            };

        case newReportActions.WIZARD_RESET:
            return initialSectionDetail;
        case newReportActions.WIZARD_UPDATE:
            return { ...state };
        case newReportActions.LOAD_WIZARD_SECTION:
            return { ...action.payload };
        case newReportActions.WIZARD_SESSION_LOG:
            const ret = Array.from(state.sessionLog.session);
            ret.push(action.payload);
            return {
                ...state,
                sessionLog: {
                    ...state.sessionLog,
                    session: ret
                }
            };
        default:
            return state;
    }
}

export const getFlightSectionDetailsSelector = (state: NewReportWizardSectionDetailModel) => state.flight;
export const getQuestionsSectionDetailsSelector = (state: NewReportWizardSectionDetailModel) => state.questions;
export const getNotesSectionDetailsSelector = (state: NewReportWizardSectionDetailModel) => state.notes;
export const getAttachmentsSectionDetailsSelector = (state: NewReportWizardSectionDetailModel) => state.attachments;
export const getWizardSessionLog = (state: NewReportWizardSectionDetailModel) => state.sessionLog;
