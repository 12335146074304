import { Component, ViewChild, Input, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { DynamicQuestionsService } from '@shared/SSEHubClient/dynamic-questions.service';
import { CategoryDetailService } from '../category-detail.service';
import * as _ from 'lodash';
import { AttributeValuePipe } from 'app/shared/pipes/attribute-value.pipe';
import { UtilitiesService } from 'app/shared/utilities/utilities.service';
import { MultiInputAttribute, AttributeKeyVal } from '@models';
// import { AnswerMultiInputComponent } from '../answer-multi-input/answer-multi-input.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-answer-edit',
    templateUrl: './answer-edit.component.html',
    styleUrls: ['./answer-edit.component.scss']
})
export class AnswerEditComponent implements OnDestroy {
    @ViewChild('answerEditdModal', { static: true }) public answerEditdModal;
    // @ViewChildren(AnswerMultiInputComponent) answerMultiInputComponent: QueryList<AnswerMultiInputComponent>;
    @Input() group: string;
    answer: any = {};
    answerObj: any = {};
    answerText: string;
    riskLevel: string;
    saftyLevel: string;
    answerInvalid = false;
    isLoading = true;
    hasError = false;
    isRiskNull = true;
    isSafteyNull = true;
    riskLevelEmpty = false;
    safetyLevelEmpty = false;
    riskLevelInvalid = false;
    safetyLevelInvalid = false;
    isReady = false;
    editMode = false;
    isPersonSearchEnabled = true;
    isPaxSeachSelected = false;
    isCrewSearchSelected = false;
    employeeSearchOption: string;
    employeeSearchDisplayOptions = [
        { value: '', description: '' },
        { value: 'fullSearch', description: 'Full Search' },
        { value: 'limitedSearch', description: 'Limited Search' }
    ];
    isMultiInputAmsAddress = false;
    multiInputAttribute: MultiInputAttribute;
    close = false;  // this is used to force the refresh of the child answer-multi-input component.
    destroy$: Subject<void> = new Subject<void>();

    constructor(public dynamicQuestionsService: DynamicQuestionsService,
        public categoryDetailService: CategoryDetailService,
        private attributeValue: AttributeValuePipe,
        private utilities: UtilitiesService) { }


    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    open(answer) {
        this.answer = {};
        this.answerObj = answer;
        this.answerText = answer.answerText;
        this.loadAnswerMetadata();
        this.isMultiInputAmsAddress = answer.hasOwnProperty('AnswerInputType') &&
            answer.answerInputType.toString().toLowerCase() === 'multi-amsaddress' ? true : false;
        if (this.isMultiInputAmsAddress) {
            this.multiInputAttribute = this.createMultiInputAttribute();
        }
        this.answerEditdModal.open();
    }

    onOpen(event?) {
        this.onReady();
        this.reset();
    }

    onClose(event?) {
        this.close = true;
        this.answerEditdModal.close();
    }

    onSave() {
        this.answerInvalid = false;
        this.riskLevelEmpty = false;
        this.safetyLevelEmpty = false;
        this.riskLevelInvalid = false;
        this.safetyLevelInvalid = false;

        if (this.answer.answerText === '') {
            this.answerInvalid = true;
        }
        // if (this.isMultiInputAmsAddress && !this.answerMultiInputComponent.first.multiInputForm.valid) {
        //     this.answerInvalid = true;
        //     this.answerMultiInputComponent.first.validateForm();
        // }
        if (this.answer.riskLevel === '') {
            this.riskLevelEmpty = true;
        }
        if (this.answer.safetyLevel === '') {
            this.safetyLevelEmpty = true;
        }
        if (!this.answerInvalid && !this.riskLevelEmpty && !this.safetyLevelEmpty && !this.riskLevelInvalid && !this.safetyLevelInvalid) {
            this.updateAnswer();
        }
    }

    updatePrinterAnswer($event) {
        this.answer.answerText = $event;
    }

    createMultiInputAttribute(): MultiInputAttribute {
        const multiInputAttribute: MultiInputAttribute = {
            multiInputType: this.answerObj.attrs['multiInputType'],
            optionalText: this.answerObj.attrs['optionalText'],
            defaultRequired: this.answerObj.attrs['defaultRequired'] === 'true' ? true : false,
            defaultChecked: this.answerObj.attrs['defaultChecked'] === 'true' ? true : false
        };
        return multiInputAttribute;
    }

    updateMultiInputAttribute($event: AttributeKeyVal) {
        this.answer.attrs = this.answer.attrs ?? new Object();
        Object.assign(this.answer.attrs, { [$event.name]: [$event.value] });
    }

    private updateAnswer() {
        this.startLoadingState();
        this.dynamicQuestionsService.updateAnswer(this.answer)
            .pipe(takeUntil(this.destroy$)).subscribe(
                (result) => {
                    if (result) {
                        this.answerObj.answerText = this.answer.answerText;
                        this.answerObj.attrs = this.answer.attrs;
                        this.answerEditdModal.close();
                    } else {
                        this.onError();
                    }
                },
                (error) => {
                    this.onError();
                }
            );
    }

    private startLoadingState() {
        this.isLoading = true;
        this.isReady = false;
        this.hasError = false;
    }



    private onReady() {
        this.isLoading = false;
        this.isReady = true;
        this.hasError = false;
    }

    private startEdit() {
        this.editMode = true;
    }

    private onError() {
        this.isLoading = false;
        this.isReady = false;
        this.hasError = true;
    }

    private loadAnswerMetadata(): void {
        this.dynamicQuestionsService.getAnswerMetadata(this.answerObj.answerId)
            .pipe(takeUntil(this.destroy$)).subscribe(
                (result) => {
                    if (result) {
                        this.answer = result;
                        if (this.answer.riskLevel != null) {
                            this.isRiskNull = false;
                        }
                        if (this.answer.safetyLevel != null) {
                            this.isSafteyNull = false;
                        }
                        if (this.answer.attrs) {
                            const attributes = this.answer.attrs ?? {};
                            this.isPaxSeachSelected = attributes.hasOwnProperty('paxSearch') ? attributes.paxSearch : false;
                            this.isCrewSearchSelected = attributes.hasOwnProperty('crewSearch') ? attributes.crewSearch : false;
                            this.employeeSearchOption = attributes.hasOwnProperty('employeeSearch') ? attributes.employeeSearch : '';
                        }
                        this.onReady();
                    } else {
                        this.onError();
                    }
                },
                (error) => {
                    this.onError();
                }
            );

        this.dynamicQuestionsService.loadFollowUpQuestions(this.categoryDetailService.state.profileId,
            this.categoryDetailService.state.categoryId, this.group, this.answerObj.answerId)
            .pipe(takeUntil(this.destroy$)).subscribe(questions => {
                this.isPersonSearchEnabled = _.filter(questions.questions,
                    (attrs) => {
                        return attrs && attrs.hasOwnProperty('personMapping') ? true : false;
                    }).length > 0;
            });
    }

    updateAttrs(attribute, attributeValue) {
        this.answer.attrs = this.answer.attrs ?? new Object();
        this.answer.attrs = Object.assign(this.answer.attrs, { [attribute]: [attributeValue] });
    }

    validateSafty(event: any) {
        this.safetyLevelEmpty = false;
        this.safetyLevelInvalid = this.validateOnkeyPress(event);
    }

    validateRiskLevel(event: any) {
        this.riskLevelEmpty = false;
        this.riskLevelInvalid = this.validateOnkeyPress(event);
    }

    validateOnkeyPress(event: any) {
        let isInvalid = false;
        const pattern = /[0-9 ]/;
        const inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar) || inputChar === ' ') {
            event.preventDefault();
            isInvalid = true;
        } else if
            (inputChar === '' || inputChar === null) {
            isInvalid = false;
        }
        return isInvalid;
    }

    private reset() {
        this.close = false;
        this.isRiskNull = true;
        this.isSafteyNull = true;
        this.editMode = false;
        this.answerInvalid = false;
        this.riskLevelEmpty = false;
        this.safetyLevelEmpty = false;
        this.riskLevelInvalid = false;
        this.safetyLevelInvalid = false;
    }

    private onSafetyLevelChange(event) {

        if (event) {
            this.answer.aafetyLevel = null;
            this.safetyLevelInvalid = false;
            this.safetyLevelEmpty = false;
        } else {
            this.answer.aafetyLevel = '';
        }

    }

    private onRiskyLevelChange(event) {

        if (event) {
            this.answer.riskLevel = null;
            this.riskLevelInvalid = false;
            this.riskLevelEmpty = false;
        } else {
            this.answer.riskLevel = '';
        }
    }
}
