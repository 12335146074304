import { Pipe, PipeTransform } from '@angular/core';

// these pipes are used to determine if a certain attribute exists

@Pipe({ name: 'displayPaxSearchPipe' })
export class DisplayPaxSearchPipe implements PipeTransform {
    transform(value: any): boolean {
        // console.log(value);
        if (value && value.attrs) {
            return value.attrs['paxSearch'];
        }
        return false;
    }
}

@Pipe({ name: 'displayCrewSearchPipe' })
export class DisplayCrewSearchPipe implements PipeTransform {
    transform(value: any): boolean {
        // console.log('crewsearch pipe');
        // console.log(value);
        if (value && value.attrs) {
            return value.attrs['crewSearch'];
        }
        return false;
    }
}

@Pipe({ name: 'displayEmployeeSearchPipe' })
export class DisplayEmployeeSearchPipe implements PipeTransform {
    transform(value: any): boolean {
        // console.log('employee search pipe');
        // console.log(value);
        if (value && value.attrs) {
            return value.attrs['employeeSearch'];
        }
        return false;
    }
}
