import { Component, ViewChild, EventEmitter, Output, OnInit, OnDestroy, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { NotificationService } from 'app/shared/error-handler-notify/services';
import { SSEHubEmployeesService } from 'app/shared/SSEHubClient';
import { NgForm, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ReportAssemblyService } from '../new-report.service';
import { AirportCode } from 'app/shared/SSEHubClient/airport.service';
import { UtilitiesService } from 'app/shared/utilities/utilities.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { Store } from '@ngrx/store';
import { State } from 'app/store';

export interface IEmployeeModel {
    firstName: string;
    lastName: string;
    employeeId: string;
    profileId: number;
    address1: string;
    address2: string;
    country: string;
    city: string;
    state: string;
    zip: string;
    passport: string;
    phoneWork: string;
    phoneHome: string;
    sex: string;
    emailWork: string;
    jobTitle: string;
    costCenter: string;
    personnelSubAreaId: string;
    baseStation: string;
    companyId: number;
    stationCode: string;
    departmentDesc: string;
    supEmployeeId: string;
    supFirstName: string;
    supLastName: string;
    supPhoneNumb: string;

}

export const limitedSearchAttributeArray: Array<string> = [
    'firstName',
    'lastName',
    'costCenter',
    'jobTitle',
    'employeeId',
    'stationCode',
    'departmentDesc',
    'supEmployeeId',
    'supFirstName',
    'supLastName',
    'supPhoneNumb'];

@Component({
    selector: 'app-employee-search',
    templateUrl: './employee-search.component.html',
    styleUrls: ['./modals.component.scss'],
})

export class EmployeeSearchComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: NgForm;
    @ViewChild('empSearchModal', { static: true }) public modal: any;
    @Input() limitedOrFull: string;
    @Output() selectedEmpData = new EventEmitter<IEmployeeModel>();

    public empForm: FormGroup;

    isSearching = false;
    searchError = false;
    empList: IEmployeeModel[];
    selectedEmp: IEmployeeModel;
    atLeastOneFieldRequired = true;
    resultLimit = 50;
    options: AirportCode[] = [];
    limitSelectedEmp: IEmployeeModel;

    // begin grid display parameters
    private gridApi;
    private gridColumnApi;
    quickSearchValue: string;
    rowSelection = 'single';
    rowData: any[];
    columnDefs = [
        {
            headerName: 'Emp. ID',
            field: 'employeeId',
            width: 90,
        },
        {
            headerName: 'Last name',
            field: 'lastName',
            sort: 'asc',
        },
        {
            headerName: 'First name',
            field: 'firstName',
        },
        {
            headerName: 'Job Title',
            field: 'jobTitle',
            width: 190,
        },
        {
            headerName: 'Station',
            field: 'stationCode',
            width: 90,
        },
        {
            headerName: 'Cost Center',
            field: 'costCenter',
            width: 130,
        },
        {
            headerName: 'Department',
            field: 'departmentDesc',
            width: 200,
        }
    ];
    gridOptions = {
        defaultColDef: {
            resizable: true,
            sortable: true,
            unSortIcon: true,
        },
        columnDefs: this.columnDefs,
        rowSelection: 'single',
        suppressColumnVirtualisation: true
    };
    style = {
        width: '100%',
        height: '314px',
    };
    // end grid display parameters

    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private empService: SSEHubEmployeesService,
        public datepipe: DatePipe,
        public notificationService: NotificationService,
        private formBuilder: FormBuilder,
        private newReportService: ReportAssemblyService,
        private utilities: UtilitiesService,
        private store: Store<State>
    ) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    ngOnInit() {
        this.empForm = this.formBuilder.group({
            id: new FormControl('', [Validators.maxLength(8), Validators.pattern('[0-9]*')]),
            firstName: new FormControl(''),
            lastName: new FormControl(''),
            station: new FormControl('', [Validators.maxLength(3), Validators.pattern('[a-zA-Z]{3}')]),
            jobTitle: new FormControl(''),
            department: new FormControl(''),
        });

        this.onChanges();
    }

    // validates that at least one field is filled out.
    onChanges(): void {
        this.empForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
            this.atLeastOneFieldRequired = this.utilities.isNullOrEmpty(val.id) &&
                this.utilities.isNullOrEmpty(val.firstName) &&
                this.utilities.isNullOrEmpty(val.lastName) &&
                this.utilities.isNullOrEmpty(val.station) &&
                this.utilities.isNullOrEmpty(val.jobTitle) &&
                this.utilities.isNullOrEmpty(val.department);
        });
    }

    // grid functions
    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.calculateSizing();
    }

    onQuickFilterChanged() {
        this.gridApi.setQuickFilter(this.quickSearchValue);
    }

    sizeToFit() {
        this.gridApi.sizeColumnsToFit();
    }

    calculateSizing() {
        const allColumnIds = [];
        this.gridColumnApi.getAllColumns().forEach(function (column) {
            if (!column.colDef.width) {
                allColumnIds.push(column.colId);
            }
        });
        this.gridColumnApi.autoSizeColumns(allColumnIds);
        if (this.gridColumnApi.columnController.scrollWidth > this.gridColumnApi.columnController.bodyWidth) {
            this.gridColumnApi.sizeColumnsToFit(this.gridColumnApi.columnController.scrollWidth - 1);
        }
    }
    // end grid functions

    open() {
        if (!window.navigator.onLine) {
            this.notificationService.showWarning('Employee search is not available while offline.');
        } else {
            this.reset();
            this.modal.open();
        }
    }

    replaceInput(val: any) {
        val.target.value = val.target.value.toUpperCase().replace(/[^a-zA-Z\s]/g, '');
        this.options = this.newReportService.getAirportCodeOptions(val.target.value);
        this.empForm.controls['station'].setValue(val.target.value);
    }

    reset() {
        this.empForm.reset();
        this.searchError = false;
        this.isSearching = false;
        this.selectedEmp = null;
        this.empList = null;
        this.quickSearchValue = null;
    }

    onClose($event) {
        this.reset();
    }

    onRowSelected($event) {
        if ($event.node.isSelected()) {
            this.selectedEmp = $event.node.data;
        }
    }

    // This is what auto populates fields based on all the data in selectedEmp.
    populatePersonData() {
        // These are the only value that are required to auto populate in limited search (limitedSearchAttributeArray).
        this.limitSelectedEmp = Object.keys(this.selectedEmp)
            .filter(key => limitedSearchAttributeArray.includes(key))
            .reduce((obj, key) => {
                obj[key] = this.selectedEmp[key];
                return obj;
            }, {}) as any;

        // This differentiates between different searches.
        if (this.limitedOrFull === 'fullSearch') {
            this.selectedEmpData.emit(this.selectedEmp);
            this.modal.close();
        } else if (this.limitedOrFull === 'limitedSearch') {
            // If any of the field comes up empty, then we populate that filed with 'Data not available.'
            Object.keys(this.limitSelectedEmp).forEach(key => {
                if (this.limitSelectedEmp[key] === null) {
                    this.limitSelectedEmp[key] = 'Data not available';
                }
            });
            this.selectedEmpData.emit(this.limitSelectedEmp);
            this.modal.close();
        } else {
            console.error('No employee search selected.');
            this.modal.close();
        }
    }

    // this forces a DOM update
    updateIsSearching(value: boolean) {
        this.isSearching = value;
    }

    // employee search
    empSearch() {
        const filter = [];
        if (this.empForm.value.id) {
            filter.push('employeeId::' + this.empForm.value.id);
        }
        if (this.empForm.value.firstName) {
            filter.push('firstName::' + this.empForm.value.firstName);
        }
        if (this.empForm.value.lastName) {
            filter.push('lastName::' + this.empForm.value.lastName);
        }
        if (this.empForm.value.station) {
            filter.push('stationCode::' + this.empForm.value.station);
        }
        if (this.empForm.value.jobTitle) {
            filter.push('jobTitle::' + this.empForm.value.jobTitle);
        }
        if (this.empForm.value.department) {
            filter.push('department::' + this.empForm.value.department);
        }

        this.updateIsSearching(true);
        this.empService.search(filter.join('|'), 'lastName', this.resultLimit.toString()).pipe(takeUntil(this.destroy$)).subscribe(
            employees => {
                if (!employees) {
                    this.searchError = true;
                } else {
                    this.empList = employees;
                }
            },
            (error) => {
                console.error('Employee search failed.');
                this.notificationService.showError('Employee search failed.');
            },
            () => {
                this.updateIsSearching(false);
            });
    }
}
