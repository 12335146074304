import { Component, ViewChild, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
    getIsFlight,
    getCrewSequences,
    getFlightSelection,
    State,
    getQuestions,
    getGeneralQuestionsError,
    getGeneralQuestionsIsLoading,
} from '@newreport/new-report-store/';
import {
    IFlightSequence,
    LoadCrewSequences,
    IDynamicQuestion,
    ChangeFlight,
    UpdateFlightPageStatus,
    UpdateFlightSelection,
    FlightSequence,
} from '../../new-report-store/wizard';
import { FormComponentService } from 'app/shared/common-ux/components/component-services/form-component.service';
import { WizardService, IReportInfo, FocusService, IFocusKeys } from '../wizard.service';
import { ConfirmCancelComponent } from '@wizardmodals/confirm-cancel.component';
import { SearchFlightComponent } from '@wizardmodals/search-flight.component';
import { ConfirmFlightChangeComponent } from '@wizardmodals/confirm-flight-change.component';
import { ReportAssemblyService } from '../../new-report.service';
import * as _ from 'lodash';
import { getTodo, IReportHistory } from 'app/report-history/report-history-store/list';
import { DatePipe } from '@angular/common';
import { CanComponentDeactivate } from 'app/can-deactivate.guard';
import { ConfirmNavigateComponent } from '@wizardmodals/confirm-navigate.component';
import { UtilitiesService } from 'app/shared/utilities/utilities.service';

@Component({
    selector: 'app-fill-out-report-wizard-flight',
    templateUrl: './flight.component.html',
    styleUrls: ['./steps.component.scss']
})
export class FlightComponent implements OnInit, OnDestroy, AfterViewInit, CanComponentDeactivate {
    groupName = 'Flight';
    @ViewChild('form', { static: true }) form: NgForm;
    @ViewChild(ConfirmCancelComponent, { static: true }) confirmCancel: ConfirmCancelComponent;
    @ViewChild(ConfirmNavigateComponent, { static: true }) confirmNavigate: ConfirmNavigateComponent;
    @ViewChild(SearchFlightComponent, { static: true }) searchFlightCom: SearchFlightComponent;
    @ViewChild(ConfirmFlightChangeComponent, { static: true }) flightChangeCom: ConfirmFlightChangeComponent;
    questions: Array<IDynamicQuestion> = [];
    generalQuestions: Array<IDynamicQuestion> = [];
    notesQuestions: Array<IDynamicQuestion>;
    reportInfo$: Observable<IReportInfo>;
    isLoading = true;
    error = null;
    crewSeqs: { options: Array<IFlightSequence>, userAnswer: any } = { options: [], userAnswer: {} };
    flightDetails: IFlightSequence;
    employeeId: number;
    isFlight = false;
    errorMessageCounter = 0;
    followupQuestions = [];
    clearCrewSeqText = 'Click here to select';
    flightNumber = '';
    candidateFlight: IFlightSequence;
    currentFlightDetails: IFlightSequence;
    onecrewseq: IFlightSequence;
    isTodo: boolean;
    viewReady = false;
    navigateAwaySelection$: Subject<boolean> = new Subject<boolean>();
    destroy$: Subject<void> = new Subject<void>();
    constructor(
        private store: Store<State>,
        private router: Router,
        private formComponentService: FormComponentService,
        private wizardService: WizardService,
        private focusService: FocusService,
        public newReportService: ReportAssemblyService,
        public datepipe: DatePipe,
        public utilities: UtilitiesService
    ) {
        this.store.dispatch(new UpdateFlightPageStatus('inProgress'));
        this.onecrewseq = {
            aircraftTypeDescription: '',
            arrivalStation: '',
            departureStation: '',
            interruptStation: '',
            employeeId: '',
            fleetDescription: this.clearCrewSeqText,
            flightDate: '',
            flightNumber: '',
            sequencePosition: '',
            tailNumber: ''
        };
    }

    onNavigate(isExitting) {
        return this.navigateAwaySelection$.next(isExitting);
    }

    canDeactivate(route: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.newReportService.isStateReportDirty(this.questions, this.generalQuestions, this.notesQuestions)
            && !nextState.url.startsWith('/newreport/wizard')) {
            this.confirmNavigate.displayModal();
            return this.navigateAwaySelection$;
        } else {
            return of(true);
        }
    }

    ngOnInit() {
        this.reportInfo$ = this.wizardService.getReportInfo();
        this.prepareQuestions();
        this.store.select(getTodo).pipe(takeUntil(this.destroy$)).subscribe(todo => this.getSelectedTodoReport(todo));
    }
    ngAfterViewInit() {
        setTimeout(() => {
            this.viewReady = true;
        }, 0);
    }
    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        this.validatePage();
    }

    validatePage() {
        this.formComponentService.validateAllFormFields(this.form.control);
        if (this.form.valid) {
            this.store.dispatch(new UpdateFlightPageStatus('complete'));
        } else {
            this.store.dispatch(new UpdateFlightPageStatus('incomplete'));
        }
    }

    cancelUpdate() {
        this.getCrewSequenceFromStore();

        const matachedOption = _.find(this.crewSeqs.options, opt => {
            return opt.flightNumber === this.flightDetails.flightNumber
                && opt.aircraftTypeDescription.toUpperCase() === this.flightDetails.aircraftTypeDescription.toUpperCase()
                && opt.arrivalStation.toUpperCase() === this.flightDetails.arrivalStation.toUpperCase()
                && opt.departureStation.toUpperCase() === this.flightDetails.departureStation.toUpperCase()
                && opt.fleetDescription.toUpperCase() === this.flightDetails.fleetDescription.toUpperCase()
                && (((new Date(opt.flightDate)).getTime() - (new Date(this.flightDetails.flightDate)).getTime()) === 0)
                && opt.tailNumber === this.flightDetails.tailNumber;
        });
        this.crewSeqs.userAnswer = matachedOption === undefined ? this.onecrewseq : matachedOption;
    }

    next() {
        this.validatePage();
        if (this.form.valid) {
            window.scrollTo(0, 0); // scroll to top every time this page loads
            this.router.navigate(['/newreport/wizard/questions']);
        } else {
            this.focusService.displayErrorMessage(++this.errorMessageCounter);
            const firstKey: IFocusKeys = this.focusService.getFirstInvalidKey(this.questions, this.form.form);
            if (firstKey) {
                this.focusService.setFocusKey(firstKey);
            }
        }
    }

    openSearchModal() {
        this.searchFlightCom.openSearchModal();
    }

    showOrHideCrewSequence($event) {

        const answer = $event.userAnswer.answerText.trim().toUpperCase();

        this.isFlight = ($event != null && $event.followupQuestions != null) ? this.flightDetailsVisible($event) : false;
        this.followupQuestions = $event.followupQuestions;

        this.store.dispatch(
            new ChangeFlight({
                isFlight: this.isFlight
            })
        );
        if (this.isFlight && this.flightDetails) {
            this.autoFillOutFlightDetail(this.flightDetails);
        } else if (this.flightDetails) {
            this.store.dispatch(new UpdateFlightSelection(null));
        }
        if (this.isTodo) {
            this.store.select(getTodo).pipe(takeUntil(this.destroy$)).subscribe(todo => this.getSelectedTodoReport(todo));
            this.autoFillOutFlightDetail(this.flightDetails);
        }
    }

    formatFlightNumber(flightNumber): string {
        return flightNumber.replace(/^0+/, '');
    }

    flightDetailsVisible(question) {

        let isFlightNumVisible = false;
        let isDepartDateVisible = false;
        let isDepartStatVisible = false;

        const flNuQuestion = this.findFlightDetailQuestion(question.followupQuestions, 'FLIGHTNUMBER');
        if (flNuQuestion) {
            isFlightNumVisible = true;
        }
        const flDtQuestion = this.findFlightDetailQuestion(question.followupQuestions, 'DEPARTUREDATE');
        if (flDtQuestion) {
            isDepartDateVisible = true;
        }
        const dpStQuestion = this.findFlightDetailQuestion(question.followupQuestions, 'DEPARTURESTATION');
        if (dpStQuestion) {
            isDepartStatVisible = true;
        }
        return (isFlightNumVisible && isDepartDateVisible && isDepartStatVisible);
    }

    findFlightDetailQuestion(questions, target) {
        return _.find(questions, (oneQ: object) => {
            if (oneQ && oneQ['attrs']) {
                const attrs: object = oneQ['attrs'];
                if (attrs && attrs['fieldName']) {
                    return attrs['fieldName'].trim().toUpperCase() === target;
                }
            }
        });
    }

    updateFlightFields(questions: Array<IDynamicQuestion>, flightDetail: IFlightSequence) {
        const offQuestion = this.findFlightDetailQuestion(questions, 'ARRIVALSTATION');
        if (offQuestion) {
            offQuestion['userAnswer'] = flightDetail ?
                (flightDetail.interruptStation ? flightDetail.interruptStation : flightDetail.arrivalStation) : null;
            if (!this.utilities.isNullOrEmpty(flightDetail.arrivalStation) && this.isTodo) {
                offQuestion['isReadOnly'] = true;
            }
        }
        const brdQuestion = this.findFlightDetailQuestion(questions, 'DEPARTURESTATION');
        if (brdQuestion) {
            brdQuestion['userAnswer'] = flightDetail ? flightDetail.departureStation : null;
            if (!this.utilities.isNullOrEmpty(flightDetail.departureStation) && this.isTodo) {
                brdQuestion['isReadOnly'] = true;
            }
        }
        const dateQuestion = this.findFlightDetailQuestion(questions, 'DEPARTUREDATE');
        if (dateQuestion) {
            dateQuestion['userAnswer'] = flightDetail ? flightDetail.flightDate : null;
            if (!this.utilities.isNullOrEmpty(flightDetail.flightDate) && this.isTodo) {
                dateQuestion['isReadOnly'] = true;
            }
        }
        const fltQuestion = this.findFlightDetailQuestion(questions, 'FLIGHTNUMBER');
        if (fltQuestion) {
            fltQuestion['userAnswer'] = flightDetail ? flightDetail.flightNumber : null;
            this.flightNumber = (flightDetail !== undefined && flightDetail != null) ? flightDetail.flightNumber : '';
            if (!this.utilities.isNullOrEmpty(flightDetail.flightNumber) && this.isTodo) {
                fltQuestion['isReadOnly'] = true;
            }
        }
        const fleetQuestion = this.findFlightDetailQuestion(questions, 'FLEETDESCRIPTION');
        if (fleetQuestion) {
            const answerObject = _.find(fleetQuestion.answers, (oneA) => {
                return oneA.answerText === (flightDetail ? flightDetail.fleetDescription : '');
            });
            fleetQuestion['userAnswer'] = answerObject;
            if (!this.utilities.isNullOrEmpty(flightDetail.fleetDescription) && this.isTodo) {
                fleetQuestion['isReadOnly'] = true;
            }
        }
        const tailQuestion = this.findFlightDetailQuestion(questions, 'TAILNUMBER');
        if (tailQuestion) {
            tailQuestion['userAnswer'] = flightDetail ? flightDetail.tailNumber : null;
            if (!this.utilities.isNullOrEmpty(flightDetail.tailNumber) && this.isTodo) {
                tailQuestion['isReadOnly'] = true;
            }
        }

        if (flightDetail.fleetDescription === this.clearCrewSeqText) {
            this.store.dispatch(new UpdateFlightSelection(null));
        } else {
            this.store.dispatch(new UpdateFlightSelection({ ...flightDetail }));
        }

    }

    findFlightQuestions() {
        if (this.questions && this.questions.length > 0) {
            for (let index = 0; index < this.questions.length; index++) {
                const element = this.questions[index];
                const flNuQuestion = this.findFlightDetailQuestion(element.followupQuestions, 'FLIGHTNUMBER');
                const dpStQuestion = this.findFlightDetailQuestion(element.followupQuestions, 'DEPARTURESTATION');
                const flDtQuestion = this.findFlightDetailQuestion(element.followupQuestions, 'DEPARTUREDATE');
                if (dpStQuestion && flDtQuestion && flNuQuestion) {
                    return element.followupQuestions;
                }
            }
        }
    }

    confirmFlightChange(seq: IFlightSequence) {

        this.candidateFlight = seq;
        let isQuestionsVisited = false;
        if (this.generalQuestions && this.generalQuestions.length > 0) {
            this.generalQuestions.forEach(a => {
                if (a.hasOwnProperty('userAnswer')) {
                    isQuestionsVisited = true;
                    return;
                }
            });
        }
        if (
            (
                (this.currentFlightDetails && this.currentFlightDetails.flightNumber
                    && this.currentFlightDetails.flightNumber.toString().trim() !== seq.flightNumber.toString().trim())
                || (this.currentFlightDetails && this.currentFlightDetails.departureStation &&
                    this.currentFlightDetails.departureStation !== seq.departureStation)
                || (this.currentFlightDetails && this.currentFlightDetails.arrivalStation &&
                    this.currentFlightDetails.arrivalStation !== seq.arrivalStation)
                || (this.currentFlightDetails && this.currentFlightDetails.fleetDescription &&
                    this.currentFlightDetails.fleetDescription !== seq.fleetDescription)
                || (this.currentFlightDetails && this.currentFlightDetails.tailNumber &&
                    this.currentFlightDetails.tailNumber !== seq.tailNumber)
                || (this.currentFlightDetails && this.currentFlightDetails.flightDate &&
                    (new Date(this.currentFlightDetails.flightDate)).toString() !== (new Date(seq.flightDate)).toString())
            )
            && isQuestionsVisited
        ) {
            this.flightChangeCom.flightDetail = this.candidateFlight;
            this.flightChangeCom.openModal();
        } else {
            this.autoFillOutFlightDetail(seq);
        }

    }

    autoFillOutFlightDetail(seq: IFlightSequence) {
        this.flightDetails = seq;

        if (this.isFlight && this.questions) {
            this.followupQuestions = this.findFlightQuestions();
            if (this.followupQuestions && this.followupQuestions.length > 0) {
                this.updateFlightFields(this.followupQuestions, seq);
            }
        }

        // clear crew sequence options to reflect the flight selected from flight search modal
        this.crewSeqs.userAnswer = _.find(this.crewSeqs.options, (onecrewseq) => {
            if (onecrewseq && seq) {
                if (onecrewseq.flightNumber === seq.flightNumber
                    && onecrewseq.flightDate === seq.flightDate
                    && onecrewseq.departureStation === seq.departureStation) {
                    return onecrewseq;
                }
            }
        });

        this.cancelUpdate();

    }

    getCrewSequenceFromStore() {
        this.store.select(getCrewSequences).pipe(takeUntil(this.destroy$)).subscribe((cq) => {
            this.crewSeqs = cq;
            if (this.crewSeqs && this.crewSeqs.options && this.crewSeqs.options.length > 0 &&
                !this.crewSeqs.options.find(cr => cr['fleetDescription'] === this.onecrewseq.fleetDescription)) {
                this.onecrewseq.fleetDescription = this.clearCrewSeqText;
                this.crewSeqs.options = [this.onecrewseq, ...this.crewSeqs.options];
                this.crewSeqs.userAnswer = this.onecrewseq;
            }
        });
    }

    prepareQuestions() {

        this.store.select(getQuestions('flight')).pipe(takeUntil(this.destroy$)).subscribe(q =>
            this.questions = q
        );

        this.store.select(getGeneralQuestionsIsLoading).pipe(takeUntil(this.destroy$)).subscribe(il => this.isLoading = il);
        this.store.select(getGeneralQuestionsError).pipe(takeUntil(this.destroy$)).subscribe(e => this.error = e);
        this.store.select(getIsFlight).pipe(takeUntil(this.destroy$)).subscribe(chosenFlight => this.isFlight = chosenFlight);

        this.store.select(getQuestions('general')).pipe(takeUntil(this.destroy$)).subscribe(a => {
            this.generalQuestions = a;
        });
        this.store.select(getQuestions('notes')).pipe(takeUntil(this.destroy$)).subscribe(notesQs => this.notesQuestions = notesQs);

        this.getCrewSequenceFromStore();



        if (!this.questions || (this.questions && this.questions.length < 1)) {

            let info: IReportInfo;
            this.reportInfo$.pipe(take(1)).pipe(takeUntil(this.destroy$)).subscribe(data => info = data);

            // this effect only loads crew sequences if the user's isFlightCrew property is true
            this.store.dispatch(new LoadCrewSequences());
        }

        this.store.select(getFlightSelection).pipe(takeUntil(this.destroy$)).subscribe(flightDetails => {
            this.flightDetails = flightDetails;
            if (flightDetails) {
                this.crewSeqs.userAnswer = _.find(this.crewSeqs.options, sequence => {
                    if (_.isEqual(sequence, flightDetails)) {
                        return sequence;
                    }
                });
                this.currentFlightDetails = flightDetails;
            }
        });
    }

    saveReport() {
        this.newReportService.saveUnfinishedReport();
    }

    getSelectedTodoReport(report: IReportHistory) {
        if (report === undefined || report == null) {
            this.isTodo = false;
        } else {
            if (report) {
                this.flightDetails = new FlightSequence();
                this.isTodo = true;
                this.flightDetails.flightNumber = !this.utilities.isNullOrEmpty(report.flightNumber) ? report.flightNumber : null;
                this.flightDetails.arrivalStation = !this.utilities.isNullOrEmpty(report.arrivalStation) ? report.arrivalStation : null;
                this.flightDetails.departureStation = !this.utilities.isNullOrEmpty(report.departureStation) ? report.departureStation : null;
                this.flightDetails.flightDate = !this.utilities.isNullOrEmpty(report.flightDate.toString()) ?
                    this.datepipe.transform((new Date(report.flightDate)), 'yyyy-MM-ddTHH:MM:SS', 'UTC') : null;
                this.flightDetails.fleetDescription = !this.utilities.isNullOrEmpty(report.fleetType) ? report.fleetType : null;
                this.flightDetails.tailNumber = !this.utilities.isNullOrEmpty(report.noseNumber) ? report.noseNumber : null;
                this.store.dispatch(
                    new UpdateFlightSelection({ ...this.flightDetails }));
            } else {
                this.isTodo = false;
            }
        }
    }

}


