import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { SSEHubReportHistoryService } from '../../../shared/SSEHubClient/report-history.service';
import { NotificationService } from '@shared/error-handler-notify/services/notification.service';
import { AdminDiscussion, DiscussionPerson } from '../../report-history';
import { ReportHistoryService } from 'app/report-history/report-history.service';
import { Store } from '@ngrx/store';
import { State } from 'app/store';
import { Observable, Subject } from 'rxjs';
import { GetAdminDiscussion, getAdminDiscussion, getReportDetailsReportId } from 'app/report-history/report-history-store/detail';
import { access } from 'fs';
import { ExpandCollapseService } from 'app/shared/common-ux/components/component-services/expand-collapse.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-request-response-summary',
    templateUrl: './request-response-summary.component.html',
    styleUrls: ['./request-response-summary.component.scss', '../summary.component.scss']
})

export class RequestResponseSummaryComponent implements OnInit, OnDestroy {
    @Input() allExpandState: Subject<any>;
    @ViewChild('section', { static: true }) sectionElem: ElementRef;
    @Input() featureAccessForCoreRecipient: boolean;
    @Input() featureAccessForFwdRecipient: boolean;
    displayRespondToRequestForm: boolean;
    displayRequestResponseForm: boolean;

    reportId: number;
    iconStyle = 'icon-expand icon-large';
    adminDiscussion: AdminDiscussion[];
    isLoading = false;
    adminDiscussion$: Observable<any>;
    destroy$: Subject<void> = new Subject<void>();
    constructor(
        private store: Store<State>,
        private expandCollapseService: ExpandCollapseService,
        private sseHubReportHistoryService: SSEHubReportHistoryService,
        private reportHistoryService: ReportHistoryService,
        private notificationService: NotificationService
    ) {
        this.adminDiscussion$ = this.store.select(getAdminDiscussion);
    }

    updateDisplayRespondToRequestForm(value: boolean) {
        this.displayRespondToRequestForm = value;
    }

    updateDisplayRequestResponseForm(value: boolean) {
        this.displayRequestResponseForm = value;
    }
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        this.store.select(getReportDetailsReportId).pipe(takeUntil(this.destroy$)).subscribe(result => this.reportId = result);
        this.store.dispatch(new GetAdminDiscussion(this.reportId));
        this.displayRespondToRequestForm = false;
        this.displayRequestResponseForm = false;
        this.allExpandState.pipe(takeUntil(this.destroy$)).subscribe(event => {
            if (event) {
                this.expandCollapseService.expand(this.sectionElem);
            } else {
                this.expandCollapseService.collapse(this.sectionElem);
            }
        });
    }

    private toggleIcon(): void {
        if (this.iconStyle === 'icon-expand icon-large') {
            this.iconStyle = 'icon-collapse icon-large';
        } else {
            this.iconStyle = 'icon-expand icon-large';
        }
    }

}
