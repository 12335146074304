import { Action } from '@ngrx/store';
import { ICategory } from './list.model';
import { CategoriesState } from '.';

export const LOAD_PROFILE_CATEGORIES = '[NewReport] loadProfileCategories';
export const LOAD_CATEGORIES_STATE = '[CategoryManagement List] loadCategoriesState';
export const POPULATE_PROFILE_CATEGORIES = '[NewReport] populateProfileCategories';
export const SET_SELECTED_CATEGORY = '[NewReport] setSelectedCategory';
export const RESET_SELECTED_CATEGORY = '[NewReport] resetSelectedCategory';
export const ERROR = '[NewReport] error';

export class LoadProfileCategories implements Action {
    readonly type = LOAD_PROFILE_CATEGORIES;
    constructor(public profileId: number) { }
}
export class LoadCategoriesState implements Action {
    readonly type = LOAD_CATEGORIES_STATE;
    constructor(public payload: CategoriesState) { }
}
export class PopulateProfileCategories implements Action {
    readonly type = POPULATE_PROFILE_CATEGORIES;
    constructor(public payload: Array<ICategory>) { }
}

export class SetSelectedCategory implements Action {
    readonly type = SET_SELECTED_CATEGORY;
    constructor(public payload: ICategory) { }
}

export class ResetSelectedCategory implements Action {
    readonly type = RESET_SELECTED_CATEGORY;
    constructor() { }
}

export class Error implements Action {
    readonly type = ERROR;
    constructor() { }
}

export type Actions
    = LoadProfileCategories
    | LoadCategoriesState
    | PopulateProfileCategories
    | SetSelectedCategory
    | ResetSelectedCategory
    | Error;
