import * as _ from 'lodash';

export interface IUser {
    id: string;
    status: number;
    profileId: number;
    isFlightCrew?: boolean;
    firstName: string;
    lastName: string;
    address1: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    jobTitle: string;
    homePhone: string;
    workPhone: string;
    costCenter: string;
    personnelSubAreaId: string;
    baseStation: string;
    privileges: Array<IPrivilege>;
    isProxyUser?: boolean;
}

export class User {

    static getNaturalName(user: IUser): string {
        return user.lastName + ', ' + user.firstName;
    }
    static nameAndProfile(user: IUser): string {
        return this.getNaturalName(user) + ' (' + user.profileId + ')';
    }
    static getPrivileges(user: IUser) {
        return _.map(user.privileges, 'PrivilegeName');
    }
    static getPrivilegesMatching(user: IUser, nameFragment: string) {
        return _.filter(user.privileges, (p) => { return p.privilegeName.indexOf(nameFragment) !== -1; }).map(p => p.privilegeName);
    }
    static hasPrivilege(user: IUser, name: string): boolean {
        if (!user) return false;
        return (_.some(user.privileges, { privilegeName: name }) ? true : false);
    }
    static hasPrivilegeSubStr(user: IUser, nameFragment: string): boolean {
        const has = _.filter(user.privileges, (p) => { return p.privilegeName.indexOf(nameFragment) !== -1; }).length;
        return (has) ? true : false;
    }
    static hasPrivilegeArray(user: IUser, names: Array<string>) {
        const has = _.filter(user.privileges, (p) => { return names.indexOf(p.privilegeName) !== -1; }).length;
        return (has) ? true : false;
    }
    static isActiveEmployee(user: IUser) {
        // This used to only allow active employees (user.status === 3).
        // We are now allowing LOA employees to log in as well
        return user && user.status && user.status !== 2;
    }

    static new(obj: any) {
        return {
            'id': obj.employeeId,
            'status': obj.employeeStatus,
            'profileId': obj.profileId,
            'isFlightCrew': obj.isFlightCrew,
            'firstName': obj.firstName,
            'lastName': obj.lastName,
            'address1': obj.address1,
            'city': obj.city,
            'state': obj.state,
            'zip': obj.zip,
            'country': obj.country,
            'jobTitle': obj.jobTitle,
            'homePhone': obj.homePhone,
            'workPhone': obj.workPhone,
            'costCenter': obj.costCenter,
            'personnelSubAreaId': obj.personnelSubAreaId,
            'baseStation': obj.baseStation,
            'privileges': []
        };
    }
}

export interface IPrivilege {
    id: string;
    privilegeName: string;
}
