import { Component, EventEmitter, Input, Output, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { IDynamicQuestion, UpdateQuestionsByParentAnswer } from '../new-report-store/wizard';
import * as _ from 'lodash';
import { PaxSearchComponent } from '@wizardmodals/pax-search.component';
import { CrewSearchComponent } from '@wizardmodals/crew-search.component';
import { EmployeeSearchComponent, limitedSearchAttributeArray } from '@wizardmodals/employee-search.component';
import { RandomNumberGenerator } from '../wizard/wizard.service';
import { ExtractQuestionUrls } from 'app/shared/common-ux/models/ExtractQuestionUrls';
import { FeatureFlagService } from 'app/shared/feature-flag/feature-flag.service';
import { Subject } from 'rxjs';
import { LoggerService } from '@shared/error-handler-notify/services';
import { SSEHubEmployeesService } from 'app/shared/SSEHubClient';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '@newreport/new-report-store';

export const flightSequenceDictionary = {
    AIRCRAFTTYPEDESCRIPTION: 'aircraftTypeDescription',
    ARRIVALSTATION: 'arrivalStation',
    DEPARTURESTATION: 'departureStation',
    EMPLOYEEID: 'employeeId',
    FLEETDESCRIPTION: 'fleetDescription',
    DEPARTUREDATE: 'flightDate',
    FLIGHTNUMBER: 'flightNumber',
    SEQUENCEPOSITION: 'sequencePosition',
    TAILNUMBER: 'tailNumber'
};

export const paxMappingDictionary = {
    // column in SOS_PERSON table: service return
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    PNR: 'pnr',
    PAX_NUMBER: 'paxNumber',
    PAX_TYPE: 'paxType',
    SEAT: 'seat'
};

export const crewMappingDictionary = {
    // column in SOS_PERSON table: service return
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    EMPLOYEE_ID: 'employeeId',
    BASE_STATION: 'crewBase',
    SEAT: 'seat',
};

export const employeeMappingDictionary = {
    // column in SOS_PERSON table: service return
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    EMPLOYEE_ID: 'employeeId',
    ADDRESS1: 'address1',
    ADDRESS2: 'address2',
    COUNTRY: 'country',
    CITY: 'city',
    STATE: 'state',
    ZIP: 'zip',
    PASSPORT: 'passport',
    WORK_PHONE: 'phoneWork',
    HOME_PHONE: 'phoneHome',
    SEX: 'sex',
    EMAIL_ADDRESS: 'emailWork',
    JOB_TITLE: 'jobTitle',
    COST_CENTER: 'costCenter',
    PERSONNEL_SUBAREA_ID: 'personnelSubAreaId',
    BASE_STATION: 'stationCode',
    STATION: 'stationCode',
    DEPARTMENT: 'departmentDesc',
    SUPV_EMPLOYEE_ID: 'supEmployeeId',
    SUPV_FIRST_NAME: 'supFirstName',
    SUPV_LAST_NAME: 'supLastName',
    SUPV_WORK_PHONE: 'supPhoneNumb'
};

@Component({
    exportAs: 'dynamicForm',
    selector: 'app-dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit, OnDestroy {
    @ViewChild(PaxSearchComponent, { static: true }) paxSearchModal: PaxSearchComponent;
    @ViewChild(CrewSearchComponent, { static: true }) crewSearchModal: CrewSearchComponent;
    @ViewChild(EmployeeSearchComponent, { static: true }) empSearchModal: EmployeeSearchComponent;

    @Input() profileId: number;
    @Input() categoryId: number;
    @Input() groupName: string;
    @Input() questions: Array<IDynamicQuestion>;
    @Input() paxSearch: boolean;
    @Output() answerEvent$: EventEmitter<any> = new EventEmitter<any>();
    @Input() failedApiCall = false;
    @Output() multiGroupEvent$: EventEmitter<any> = new EventEmitter<any>();
    @Input() parentAnswer: object;
    usedPersonSearch = false;
    hasSessionLog = false;
    followupQuestions = [];
    limitedOrFull = '';

    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private randomNumberGenerator: RandomNumberGenerator,
        public featureFlag: FeatureFlagService,
        public logger: LoggerService,
        private empService: SSEHubEmployeesService,
        private store: Store<State>,
    ) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    ngOnInit() {
        // Will freeze/disable specific questions related to limited employee search.
        if (
            this.parentAnswer &&
            this.parentAnswer['attrs']['employeeSearch'] === 'limitedSearch' &&
            this.questions
        ) {
            this.questions.forEach(q => {
                if (limitedSearchAttributeArray.includes(employeeMappingDictionary[q.attrs['personMapping']])) {
                    Object.assign(q.attrs, { 'limitedSearch': true });
                    Object.assign(q.attrs, { 'naOption': false });
                    if (q.userAnswer) {
                        this.usedPersonSearch = true;
                    }
                }
            });
        }
    }

    openPaxSearch() {
        this.paxSearchModal.open();
    }

    openCrewSearch() {
        this.crewSearchModal.open();
    }

    openEmpSearch() {
        this.empSearchModal.open();
    }

    populateSelectedPersonInfo($event) {
        this.usedPersonSearch = true;
        this.questions.forEach(question => {
            let reset = false;
            Object.keys($event).map(key => {
                if (question.attrs) {
                    const attrObj = question.attrs || {};
                    if (attrObj.hasOwnProperty('personMapping') &&
                        (paxMappingDictionary[attrObj['personMapping']] === key ||
                            crewMappingDictionary[attrObj['personMapping']] === key ||
                            employeeMappingDictionary[attrObj['personMapping']] === key)) {
                        question.userAnswer = $event[key];
                        reset = true;
                        // Displaying past employee injuries.
                        if (key === 'employeeId' && attrObj['displayInjuries']) {
                            this.empService.getInjuriesByEmployeeId($event[key]).pipe(takeUntil(this.destroy$)).subscribe(injuries => {
                                if (injuries) {
                                    const fiveYearsAgo = new Date();
                                    fiveYearsAgo.setFullYear(fiveYearsAgo.getFullYear() - 5);
                                    question.injuries = _.filter(injuries, (injury) => {
                                        const injuryDate = new Date(injury.InjuryDate);
                                        return injuryDate > fiveYearsAgo;
                                    });
                                } else {
                                    question.injuries = [];
                                }
                            });
                        }
                    }
                }
            });
            if (!reset) {
                if (question.answerInputType.toLowerCase() === 'checkbox') {
                    question.answers.forEach(a => a.isChecked = false);
                } else {
                    question.userAnswer = null;
                }
            }
        });
    }

    // this adds a group/involvement
    // we have groupName and parentAnswer
    // dispatch to the store to update question with that groupName under the specified parentAnswer.
    // if parentAnswer is undefined then we know it's root.
    multiGroupEvent(question, i) {
        if (question && question.answerInputType) {
            const newGroup = _.cloneDeep(question);
            // newInvolvement.answerInputType = 'INVOLVEMENT';
            newGroup.answerInputType = newGroup.answers[0].answerText.toLowerCase() === 'involvement-answer' ? 'INVOLVEMENT' : 'QUESTIONGROUP';
            const identifier = this.randomNumberGenerator.getUniqueNumber();
            newGroup.uniqueIdentifier = identifier;
            newGroup.questionMappingId = Math.floor(newGroup.questionMappingId) + identifier;
            this.questions.splice(i, 0, newGroup);

            if (!this.canAddAnotherGroup(question)) {
                const hiddenGroup = _.cloneDeep(question);
                hiddenGroup.answerInputType = 'HIDDEN_GROUP';
                this.questions.splice(++i, 1, hiddenGroup);
            }

            this.assignRecurrence(Math.floor(question.questionMappingId));

            this.store.dispatch(new UpdateQuestionsByParentAnswer({ groupName: this.groupName, parentAnswer: this.parentAnswer, questions: this.questions }));

        }
    }

    assignRecurrence(questionMappingId) {
        const recurrentQuestions = this.questions.filter(x => (Math.floor(x.questionMappingId) === questionMappingId &&
            !x.answerInputType.toLowerCase().includes('hidden') && !x.answerInputType.toLowerCase().includes('multi') &&
            !x.answerInputType.toLowerCase().includes('optional')));
        recurrentQuestions.forEach((question, index) => question.recurrence = index + 1);
    }

    // this adds a group/involvement
    optionalGroupEvent(question, i) {
        if (question && question.answerInputType) {
            if (question.answerInputType.toUpperCase() === 'OPTIONAL_GROUP') {
                this.multiGroupEvent(question, i);
            }
            if (this.canAddAnotherGroup(question)) {
                const newGroup = _.cloneDeep(question);
                newGroup.answerInputType = 'MULTI_GROUP';
                this.questions.splice(++i, 1, newGroup);
            } else {
                const hiddenGroup = _.cloneDeep(question);
                hiddenGroup.answerInputType = 'HIDDEN_GROUP';
                this.questions.splice(++i, 1, hiddenGroup);
            }
            this.store.dispatch(new UpdateQuestionsByParentAnswer({ groupName: this.groupName, parentAnswer: this.parentAnswer, questions: this.questions }));
        }
    }

    removeGroupEvent(question, i) {
        if (question && i >= 0) {
            this.questions.splice(i, 1);
            if (question.uniqueIdentifier) {
                this.randomNumberGenerator.removeUniqueNumber(question.uniqueIdentifier);
            }
        }

        const tempQuestionMappingId = Math.floor(question.questionMappingId);

        const sameType = _.filter(this.questions, function (q) {
            return q.questionMappingId === tempQuestionMappingId &&
                (q.answerInputType === 'MULTI_GROUP' || q.answerInputType === 'HIDDEN_GROUP');
        });
        if (sameType.length === 1) {
            const newGroup = _.cloneDeep(sameType[0]);
            i = _.findIndex(this.questions, newGroup);
            newGroup.answerInputType = 'OPTIONAL_GROUP';
            this.questions.splice(i, 1, newGroup);
        }

        this.assignRecurrence(Math.floor(question.questionMappingId));
        this.store.dispatch(new UpdateQuestionsByParentAnswer({ groupName: this.groupName, parentAnswer: this.parentAnswer, questions: this.questions }));
    }

    childAnswerEvent(event) {
        // append any followup questions to the new answer
        let answerId = null;
        let followups = null;
        // set the followup questions for the new answer to either the backupFollowupQuestion or followupQuestions
        if (event.answer) {
            answerId = event.answer.answerId;
            followups = event.answer.backupFollowupQuestions ? _.cloneDeep(event.answer.backupFollowupQuestions) : event.answer.followupQuestions ? _.cloneDeep(event.answer.followupQuestions) : null;
        } else if (event.userAnswer) {
            answerId = event.userAnswer.answerId;
            followups = event.userAnswer.backupFollowupQuestions ? _.cloneDeep(event.userAnswer.backupFollowupQuestions) : event.userAnswer.followupQuestions ? _.cloneDeep(event.userAnswer.followupQuestions) : null;
        }

        if (answerId && event.question) {
            // delete or reset old FollowupQuestions associated with a different answer
            if (event.answer && event.answer.followupQuestions && event.answer.backupFollowupQuestions) {
                // const backupQuestions = _.cloneDeep(event.answer.backupFollowupQuestions)
                const uniqueIdentifiers = Array.from(new Set(event.answer.followupQuestions.filter(q => q.uniqueIdentifier)));
                if (uniqueIdentifiers.length > 0 || event.answer.followupQuestions.filter(x => x.answerInputType.toUpperCase() == 'MULTI_GROUP' || x.answerInputType.toUpperCase() == 'HIDDEN_GROUP').length > 0) {
                    for (const identifier in uniqueIdentifiers) {
                        this.randomNumberGenerator.removeUniqueNumber(identifier);
                    }
                    this.store.dispatch(new UpdateQuestionsByParentAnswer({ groupName: this.groupName, parentAnswer: event.answer, questions: followups }));
                }

                event.answer.followupQuestions = followups;
                delete event.answer.backupFollowupQuestions;
            }
            if (event.question.followupQuestions) {
                delete event.question.followupQuestions;
            }

            // TODO: add switch for involvement related questions, uses another input
            if (followups) {

                // these are the scenarios where the followupQuestions property on the answer object have been modified.
                if ((event.question.answerInputType.toUpperCase() === 'CHECKBOX' || event.question.answerInputType.toUpperCase() === 'MULTI' || followups.filter(x => x.answerInputType.toUpperCase() == 'INVOLVEMENT' || x.answerInputType.toUpperCase() == 'QUESTIONGROUP').length > 0) && !event.answer.backupFollowupQuestions) {
                    event.answer.backupFollowupQuestions = _.cloneDeep(followups);
                }

                this.refreshQuestionAttrs(false, event);

                // this means this question appear multiple times. We need to make sure that all followup questions are associated to this parent question
                if (event.question.uniqueIdentifier) {
                    followups.forEach((question) => {
                        question.uniqueIdentifier = event.question.uniqueIdentifier;
                        question.questionMappingId += event.question.uniqueIdentifier;
                        if (question.answerInputType.toUpperCase() === 'CHECKBOX') {
                            question.answers.forEach(answer => {
                                if (answer.questionMappingId) {
                                    answer.uniqueIdentifier = event.question.uniqueIdentifier;
                                    answer.questionMappingId += event.question.uniqueIdentifier;
                                }
                            });
                        }
                    });
                }
                let followupQuestions = null; // question, except for checkbox will be answer
                if (event.question.answerInputType.toUpperCase() === 'CHECKBOX') {
                    if (event.answer.isChecked) {
                        event.answer.followupQuestions = followups;
                        followupQuestions = event.answer.followupQuestions;
                    }
                } else {
                    event.question.followupQuestions = followups;
                    followupQuestions = event.question.followupQuestions;

                    followups.forEach(q => {
                        const newexUtil = new ExtractQuestionUrls(q);
                        if (newexUtil && newexUtil !== undefined) {
                            q.hyperLinks = newexUtil.hyperLinks;
                            q.displayQuestionText = newexUtil.displayQuestionText;
                        }

                    });
                }

                // mods for groups/involvements such as adding the link to add another group/involvement.
                if (followupQuestions && followupQuestions.length > 0) {
                    for (let i = 0; i < followupQuestions.length; i++) {
                        followupQuestions[i].isInvolvement =
                            followupQuestions[i].answerInputType.toUpperCase() === 'INVOLVEMENT' || followupQuestions[i].answerInputType.toUpperCase() === 'QUESTIONGROUP';
                        if (followupQuestions[i].isInvolvement) {
                            const question = followupQuestions[i];

                            const attr = question.attrs ?? {};
                            // this adds the link to add another of this group/involvement type
                            if (attr['isRequired'] && this.canAddAnotherGroup(followupQuestions[i])) {
                                const multiGroup: IDynamicQuestion = _.cloneDeep(question);
                                delete attr['isRequired'];
                                multiGroup.attrs = Object.keys(attr).length < 1 ? {} : attr;
                                multiGroup.answerInputType = 'MULTI_GROUP';
                                followupQuestions.splice(++i, 0, multiGroup);
                            } else {
                                question.answerInputType = 'OPTIONAL_GROUP';
                            }
                        }
                    }
                }
            }


            event.question['isReadOnly'] = false;
            this.answerEvent$.emit(event.question);

        }
    }

    refreshQuestionAttrs(apiCallFailed: boolean, event) {
        if (event.myform) {
            if (apiCallFailed) {
                const errorMessage = { 'message': '!! -- Manually Trigger Validator Directive -- !!' };
                this.logger.error(errorMessage);
            }

            const attrs = event.question.attrs === null ? {} : event.question.attrs;
            attrs['failedAPICall'] = apiCallFailed;
            // question Attrs is string
            event.question.attrs = attrs;
            // Validator attrs is json object
            event.myform._rawValidators[0].attrs = attrs;
            // manually trigger validator directive
            event.myform.control.markAsTouched({ onlySelf: true });
            event.myform.control.updateValueAndValidity();

            if (apiCallFailed) {
                const errMessage = { 'message': '!! -- Display Failed Getting Followup Question Validation Message -- !!' };
                this.logger.error(errMessage);
            }
        }
    }

    canAddAnotherGroup(question): boolean {
        const groups = (this.questions.filter(i => Math.floor(i.questionMappingId) === Math.floor(question.questionMappingId) &&
            !i.answerInputType.toUpperCase().includes('BACKUP'))).length - 1;
        const attrs = question.attrs;
        // We do this check right after we've added a group/involvement but that new group/involvement is not yet in the questions array
        // The +1 here accounts for that
        if (attrs.maxInvolvements) {
            return groups < attrs['maxInvolvements'];
        }
        if (attrs.maxQuestionGroups) {
            return groups < attrs['maxQuestionGroups'];
        }
        return true;
    }

}

