import { Component, ViewChild, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { QuestionAnswer } from '@shared/common-ux/models/QuestionAnswer';
import { DynamicQuestionsService } from '@shared/SSEHubClient/dynamic-questions.service';
import { CategoryDetailService } from '../category-detail.service';
import { AttributeValuePipe } from 'app/shared/pipes/attribute-value.pipe';
import { JsonToObjectPipe } from 'app/shared/pipes/json-to-object.pipe';
import { UtilitiesService } from 'app/shared/utilities/utilities.service';
import { AttributeKeyVal } from '@models';
// import { AnswerMultiInputComponent } from '../answer-multi-input/answer-multi-input.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-answer-add',
    templateUrl: './answer-add.component.html',
    styleUrls: ['./answer-add.component.scss']
})
export class AnswerAddComponent implements OnDestroy {
    @ViewChild('answerAddModal', { static: true }) public modal;
    @ViewChild('answerForm', { static: true }) public answerForm;
    // @ViewChildren(AnswerMultiInputComponent) answerMultiInputComponent: QueryList<AnswerMultiInputComponent>;
    answer: QuestionAnswer = new QuestionAnswer();
    question: any;
    isLoading = true;
    isAnswerValid = false;
    hasError = false;
    isReady = false;
    close = false;  // this is used to force the refresh of the child answer-multi-input component.
    // isMultiInputAmsAddress = false;
    destroy$: Subject<void> = new Subject<void>();

    constructor(public dynamicQuestionsService: DynamicQuestionsService,
        public categoryDetailService: CategoryDetailService,
        public attributeValue: AttributeValuePipe,
        public jsonToObjectPipe: JsonToObjectPipe,
        public utilities: UtilitiesService) {
    }
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    open(question) {
        this.reset();
        this.question = question;
        // if (this.question.answerInputType.toLowerCase() === 'multi') {
        //     this.isMultiInputAmsAddress =
        //         this.question.attrs['dataType']?.toLowerCase() === 'amsaddress';
        // }
        this.close = false;
        this.modal.open();
    }

    reset() {
        this.answer = new QuestionAnswer();
        // this.isMultiInputAmsAddress = false;
    }

    onOpen(event?) {
        this.onReady();
    }

    onClose(event?) {
        this.close = true;
        this.answer.answer = '';
        this.isAnswerValid = false;
        this.answerForm.reset();
    }

    updateAnswer($event) {
        this.answer.answer = $event;
    }

    updateAttribute($event: AttributeKeyVal) {
        // initialize this.answer.attrs if it's undefined otherwise Object.assign will not work
        this.answer.attrs = this.answer.attrs ?? new Object();
        Object.assign(this.answer.attrs, { [$event.name]: $event.value });
    }

    onSubmit() {

        ///TODO Remove commented code later

        //if (!this.isMultiInputAmsAddress || (this.isMultiInputAmsAddress && this.answerMultiInputComponent.first.multiInputForm.valid)) {
            // if (this.answer.attrs &&
            //     this.answer.attrs['multiInputType']?.toLowerCase() !== 'userinputaddress') {
            //     this.answer.answer = this.answer.answer.trim();
            // }
            if (this.answer.answer) {
                this.answerAdd(this.answer);
            } else {
                this.isAnswerValid = true;
            }
        // } else {
        //     if (this.isMultiInputAmsAddress && !this.answerMultiInputComponent.first.multiInputForm.valid) {
        //         this.answerMultiInputComponent.first.validateForm();
        //     }
        // }
    }

    private answerAdd(answer) {
        this.startLoadingState();
        answer.questionMappingId = this.question.questionMappingId;
        this.dynamicQuestionsService.addAnswer(answer)
            .pipe(takeUntil(this.destroy$)).subscribe((value) => {
                this.categoryDetailService.answerAdded(this.question);
                this.modal.close();
            }),
            (error) => {
                this.onError(error);
                this.modal.close();
            };
    }

    private startLoadingState() {
        this.isLoading = true;
        this.isReady = false;
        this.hasError = false;
    }
    private onError(message) {
        if (message) {
            console.error(message);
        }
        this.isLoading = false;
        this.isReady = false;
        this.hasError = true;
    }
    private onReady() {
        this.isLoading = false;
        this.isReady = true;
        this.hasError = false;
    }
}
