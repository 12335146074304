import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DynamicQuestionsService } from '@shared/SSEHubClient/dynamic-questions.service';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { FeatureFlagService } from 'app/shared/feature-flag/feature-flag.service';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from 'app/shared/error-handler-notify/services';
import { TagService } from 'app/shared/SSEHubClient/tag.service';

@Component({
    selector: 'app-tags-list',
    templateUrl: './tags-list.component.html',
    styleUrls: ['./tags-list.component.scss']
})
export class TagListComponent implements OnInit, OnDestroy {
    // @Input() questionMappingId: number;
    @ViewChild('tagsListModal') public tagsListModal;
    dtTrigger: Subject<any> = new Subject();
    dtOptions: any;
    questionMappingId: any;
    existingTags: any;
    allTags: any;
    allTagsToDisplay: any;
    categoryId: number;
    profileId: number;
    isLoading = true;
    destroy$: Subject<void> = new Subject<void>();

    // ngb pagination
    page = 1;
    pageSize = 10;

    constructor(
        public dynamicQuestionsService: DynamicQuestionsService,
        public featureFlag: FeatureFlagService,
        public notificationService: NotificationService,
        public tagService: TagService
    ) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        this.setDataTableOptions();
    }

    onClose() {
        this.tagsListModal.close();
    }

    open(question) {
        this.questionMappingId = question.questionMappingId;
        this.dtTrigger = new Subject();
        this.existingTags = [];
        this.startLoadingState();
        this.getTagMetadata();
        this.getAllTags();
        this.tagsListModal.open();
    }

    filterTags() {
        this.allTagsToDisplay = this.allTags;
        if (this.allTags && this.allTags.length > 0 && this.existingTags && this.existingTags.length > 0) {
            const tags = this.existingTags.map(t => t.tagVal);
            this.allTagsToDisplay = this.allTags.filter(t => !tags.includes(t.tagVal));
        }
    }

    getTagMetadata() {
        this.tagService.getMappedTags(this.questionMappingId).pipe(takeUntil(this.destroy$)).subscribe(
            (result) => {
                if (result) {
                    this.existingTags = result;
                    this.filterTags();
                    this.reRender();
                    this.onReady();
                }
            },
            (error) => {
                this.onError(error);
            }
        );
    }

    getAllTags() {
        this.tagService.getAllTags().pipe(takeUntil(this.destroy$)).subscribe(
            (result) => {
                if (result) {
                    this.allTags = result;
                    this.filterTags();
                    setTimeout(() => {
                        this.dtTrigger.next(true);
                    });
                    this.onReady();
                }
            },
            (error) => {
                this.onError(error);
            }
        );
    }

    addTagMapping(tag: any) {
        this.startLoadingState();
        this.dynamicQuestionsService.addTagMapping(this.questionMappingId, tag).pipe(takeUntil(this.destroy$)).subscribe(
            (result) => {
                this.onReady();
                if (result.isSuccess) {
                    this.existingTags = result.tags;
                    this.filterTags();
                    this.reRender();
                } else {
                    this.notificationService.showError(result.errorMessage);
                }
            },
            (error) => {
                this.notificationService.showError('Unable to map tag.');
                this.onError(error);
            }
        );
    }

    reRender(): void {
        // Call the dtTrigger to rerender again
        // The latest rxjs update needs a dummy value inside of next() here for it to work.
        this.dtTrigger.next(true);
    }

    removeTagMapping(tag) {
        this.startLoadingState();
        this.dynamicQuestionsService.removeTagMapping(this.questionMappingId, tag.tagVal).pipe(takeUntil(this.destroy$)).subscribe(
            (result) => {
                this.onReady();
                if (result.isSuccess) {
                    this.existingTags = result.tags;
                    this.filterTags();
                    this.reRender();
                } else {
                    this.notificationService.showError(result.errorMessage);
                }
            },
            (error) => {
                this.notificationService.showError('Unable to remove tag from mapping.');
                this.onError(error);
            }
        );
    }

    private startLoadingState() {
        this.isLoading = true;
    }

    private onError(error?: string) {
        if (error) {
            console.error('Error ' + error);
        }
        this.isLoading = false;
    }

    private onReady() {
        this.isLoading = false;
    }

    private setDataTableOptions() {
        this.dtOptions = {
            bDestroy: true,
            pagingType: 'full_numbers',
            order: [],
            'columns': [
                {
                    'name': 'TagValue',
                    'title': 'Tag',
                    'class': 'dtleft',
                },
                {
                    'targets': 'no-sort',
                    'class': 'dtcenter',
                    'orderable': false,
                    'searchable': false
                }
            ]
        };
    }
}
