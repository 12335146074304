import { Component, OnInit, ViewChild, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '@app/store';
import {
  LoadAllHistory, getAllHistory, IHistoryList, IReportHistory, IReportHistoryOptions, FilterAllHistory
} from 'app/report-history/report-history-store/list';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationService } from '@shared/error-handler-notify/services/notification.service';
import * as _ from 'lodash';
import { ReportHistorySearchPipe } from '../filter.pipe';
import { DatePipe } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IUser, User } from '@app/store/user';
import { getUser } from '@app/store/user/user.reducer';

@Component({
  selector: 'app-table-all-reports',
  templateUrl: './table-all-reports.component.html',
  styleUrls: ['./table-all-reports.component.scss'],
  providers: [ReportHistorySearchPipe, DatePipe]
})
export class TableAllReportsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() employeeId;
  @Input() isReportManagementAccess = false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns = ['reportId', 'title', 'employeeId', 'naturalName', 'submitDate', 'eventDate', 'flightNumber',
  'flightDate', 'fleetType', 'base', 'departureStation', 'arrivalStation', 'event', 'status', 'DaysOpen'];
  dataSource = new MatTableDataSource<any>();
  historyList: IReportHistory[] = [];
  tableOptions: IReportHistoryOptions = { filter: {}, sort: { id: 'submitDate', start: 'desc', disableClear: true }, pageIndex: 0 };
  allReports$: Observable<any>;
  isParsingTable = true;
  destroy$: Subject<void> = new Subject<void>();
  isGeneralMgr = false;

  constructor(
    private searchFilterPipe: ReportHistorySearchPipe,
    private notificationService: NotificationService,
    private router: Router,
    private store: Store<State>
  ) {
    this.allReports$ = this.store.select(getAllHistory);
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  ngOnInit() {
      // onUserLoaded depends on reportId, so call within the route subscription
      this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(user => this.onUserLoaded(user));
  }

  ngAfterViewInit() {

      // Timeout prevents concurrrency errors between component and UI
    setTimeout(() => {
      this.allReports$.pipe(takeUntil(this.destroy$)).subscribe(result => this.loadReportHistory(result));

      // Listen to and update pagination
      this.paginator.page.pipe(takeUntil(this.destroy$)).subscribe(p => {
        this.tableOptions.pageIndex = p.pageIndex;
        this.store.dispatch(new FilterAllHistory(this.tableOptions));
      });

      // Listen to and update sort
      // tslint:disable-next-line: angular-rxjs-takeuntil-before-subscribe
      this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(s => {
        if (this.tableOptions.sort.start !== s.direction) {
          this.tableOptions.sort.id = s.active;
          this.tableOptions.sort.start = s.direction === 'asc' ? 'asc' : 'desc';
          this.store.dispatch(new FilterAllHistory(this.tableOptions));
        }
      });

      this.isParsingTable = false;
    });
  }

  onUserLoaded(user: IUser) {
    if (user) {
        this.isReportManagementAccess = User.hasPrivilege(user, 'WEB_HISTORY_MANAGEMENT_ACCESS');
        this.isGeneralMgr = user.profileId === 7;
    }
  }

  filterTable() {
    if (this.paginator) {
        this.paginator.pageIndex = this.tableOptions.pageIndex;
    }
      if (this.historyList && this.tableOptions.filter && this.historyList.length > 0) {
          this.dataSource.data = this.searchFilterPipe.transform(this.historyList, this.tableOptions.filter);
      }
  }

  applyFilter() {
    this.tableOptions.pageIndex = 0;
    this.store.dispatch(new FilterAllHistory(this.tableOptions));
  }

  clearAllFilter() {
    this.tableOptions = { filter: {}, sort: { id: 'submitDate', start: 'desc', disableClear: true }, pageIndex: 0 };
    this.sort.sort(this.tableOptions.sort);
    this.applyFilter();
  }

  loadReportHistory(state: IHistoryList) {
      if (state && state.list && state.list.all && !state.isLoading && state.list.all.length) {

      this.historyList = state.list.all;
      this.tableOptions = state.list.options;
      this.dataSource = new MatTableDataSource(this.historyList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.filterTable();
    }
  }

  gotoReportSummary(reportHistory) {
    if (reportHistory != null) {
        const reportIdString = reportHistory.reportId;
        if (this.isReportManagementAccess || this.isGeneralMgr) {
            this.router.navigate(['reporthistory/' + reportIdString + '/summary']);
        } else if (!(this.isReportManagementAccess || this.isGeneralMgr)) {
            this.router.navigate(['myreports/' + reportIdString + '/summary']);
        }
    }
  }
}

