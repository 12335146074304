import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SSEHubClient } from './SSEHubClient.service';


@Injectable()
export class SSEHubInvolvementsService {
    constructor(
        private http: HttpClient,
        private sseHubClient: SSEHubClient
    ) { }

    getAllInvolvementTypes() {
        return this.http.get(this.sseHubClient.serviceUrl() + '/api/involvementTypes').toPromise();
    }
}
