import { Component, ViewChild, Input, OnDestroy } from '@angular/core';
import { DynamicQuestionsService } from '@shared/SSEHubClient/dynamic-questions.service';
import { CategoryDetailService } from '../category-detail.service';
import { NotificationService } from 'app/shared/error-handler-notify/services';
import * as _ from 'lodash';
import { Subject, pipe } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UtilitiesService } from 'app/shared/utilities/utilities.service';

@Component({
    selector: 'app-involvement-edit',
    templateUrl: './involvement-edit.component.html',
    styleUrls: ['./involvement-edit.component.scss']
})
export class InvolvementEditComponent implements OnDestroy {
    @ViewChild('involvementEditModal', { static: true }) public involvementEditModal;
    @Input() group: string;
    answer: any = {};
    answerObj: any = {};
    isLoading = true;
    hasError = false;
    isReady = false;
    isPersonSearchEnabled = true;
    isPaxSearchSelected = false;
    isCrewSearchSelected = false;
    employeeSearchOption: string;
    employeeSearchDisplayOptions = [
        { value: '', description: '' },
        { value: 'fullSearch', description: 'Full Search' },
        { value: 'limitedSearch', description: 'Limited Search' }
    ];
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        public dynamicQuestionsService: DynamicQuestionsService,
        public categoryDetailService: CategoryDetailService,
        public utilities: UtilitiesService,
        public notificationService: NotificationService) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    open(answer) {
        this.answerObj = answer;
        this.involvementEditModal.open();
        this.loadAnswerMetadata();
    }

    onOpen(event?) {
        this.onReady();
        this.reset();
    }

    onClose(event?) {
        this.involvementEditModal.close();
    }

    onSave() {
        this.startLoadingState();
        this.dynamicQuestionsService.updateAnswer(this.answer)
            .pipe(takeUntil(this.destroy$)).subscribe(
                (result) => {
                    if (result) {
                        this.involvementEditModal.close();
                    } else {
                        this.notificationService.showError('Unable to update selections.');
                        this.onError();
                    }
                },
                (error) => {
                    this.notificationService.showError('Unable to update selections.');
                    this.onError();
                }
            );
    }

    private startLoadingState() {
        this.isLoading = true;
        this.isReady = false;
        this.hasError = false;
    }

    private onReady() {
        this.isLoading = false;
        this.isReady = true;
        this.hasError = false;
    }

    private onError() {
        this.isLoading = false;
        this.isReady = false;
        this.hasError = true;
    }

    private loadAnswerMetadata(): void {

        this.startLoadingState();
        this.dynamicQuestionsService.getAnswerMetadata(this.answerObj.answerId)
            .pipe(takeUntil(this.destroy$)).subscribe(
                (result) => {
                    if (result) {
                        this.answer = result;
                        const attributes = this.answer.attrs ?? {};
                        this.isPaxSearchSelected = attributes.hasOwnProperty('paxSearch') ? attributes.paxSearch : false;
                        this.isCrewSearchSelected = attributes.hasOwnProperty('crewSearch') ? attributes.crewSearch : false;
                        this.employeeSearchOption = attributes.hasOwnProperty('employeeSearch') ? attributes.employeeSearch : '';
                        this.onReady();
                    } else {
                        this.onError();
                    }
                },
                (error) => {
                    this.onError();
                }
            );

        // determine if there are sub questions that would map to passenger search.
        this.dynamicQuestionsService.loadFollowUpQuestions(this.categoryDetailService.state.profileId,
            this.categoryDetailService.state.categoryId, this.group, this.answerObj.answerId)
            .pipe(takeUntil(this.destroy$)).subscribe(questions => {
                this.isPersonSearchEnabled = _.filter(questions.questions,
                    (question) => {
                        const attrs = question.attrs;
                        return attrs && attrs.hasOwnProperty('personMapping') ? true : false;
                    }).length > 0;
                this.onReady();
            }, (error) => {
                this.onError();
            });
    }

    updateAttrs(attribute, attributeValue) {
        this.answer.attrs = this.answer.attrs ?? new Object();
        if (!attributeValue && this.answer.attrs.hasOwnProperty(attribute)) {
            delete this.answer.attrs[attribute];
            return;
        }
        Object.assign(this.answer.attrs, { [attribute]: attributeValue });
    }

    reset() {
        this.isPersonSearchEnabled = true;
        this.isPaxSearchSelected = false;
        this.isCrewSearchSelected = false;
    }
}
