<div class="row">
    <ol class="breadcrumb">
        <li><a routerLink="/home"><i class="icon-home"></i> Home</a></li>
        <li><a routerLink="/admin/categorymanagement">Admin</a></li>
        <li><a routerLink="/admin/categorymanagement">Category management</a></li>
        <li>Tag management</li>
    </ol>
</div>

<div class="row align-items-center">
    <div class="button-icon col-auto ">
        <a (click)="gotoCategories()" class="icon-sec-chevron-left text-decoration-none"></a>
    </div>
    <div class="col pe-0">
        <div class="section-title-cers">Tag management</div>
    </div>
</div>

<div class="row my-2">
    <div class="col pe-0">
        <div class="section-line"></div>
    </div>
</div>

<div class="row py-3">
    <div class="d-flex justify-content-between align-items-center px-0">
        <kendo-textbox [(ngModel)]="searchTerm" (ngModelChange)="searchTags(true)" placeholder="Search tags..."
            class="search-textbox">
            <ng-template kendoTextBoxSuffixTemplate>
                <i class="icon-sec-search"></i>
            </ng-template>
        </kendo-textbox>
        <button kendoButton class="new-tag" (click)="toggleCreateNewTagDialog(true)">
            <div class="d-flex align-items-center justify-content-between">
                <i class='icon-sec-plus-outline new-tag-plus-icon'></i>
                <span>New tag</span>
            </div>
        </button>
    </div>
</div>

<!-- Tag grid -->
<div class="row px-0">
    <kendo-grid
        #grid
        id="scrollstyle"
        [kendoGridBinding]="filteredTags"
        [sortable]="true"
        [pageSize]="gridPageSize"
        [pageable]="true" 
        [height]="gridHeight" 
        (pageSizeChange)="onGridPageSizeChange($event)"
        filterable="menu">
        <kendo-grid-column field="tagVal" [headerClass]="'k-tagValue-header'" [filterable]="false"
            [class]="'k-tagValue-cell'">
            <ng-template kendoGridHeaderTemplate>
                <span class="k-column-title">Tag name</span>
                <span class="icon-sec-sort-default"></span>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="tagDisplayTxt" [headerClass]="'k-tagDisplayText-header'" [filterable]="false"
            [class]="'k-tagDisplayText-cell'">
            <ng-template kendoGridHeaderTemplate>
                <span class="k-column-title">Display text</span>
                <span class="icon-sec-sort-default"></span>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="tagDescription" title="Description" [filterable]="false" [sortable]="false"
            [headerClass]="'k-tagDescription-header'" [class]="'k-tagDescription-cell'">
        </kendo-grid-column>

        <kendo-grid-column field="piiInd" title="PII" filter="boolean" [sortable]="false"
            [headerClass]="'k-piiInd-header'" [class]="'k-piiInd-cell'">
            <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                let-filterService="filterService">
                <kendo-label class="custom-label-class" [for]="PIIFilter" text="PII Filter"></kendo-label>
                <kendo-dropdownlist #PIIFilter [data]="booleanOptions" [valuePrimitive]="true"
                    [value]="getCurrentFilterValue(filter, column.field)" [defaultItem]="defaultOption"
                    (valueChange)="onFilterChange($event, filterService, column.field)" textField="text"
                    valueField="value" [itemDisabled]="itemDisabled">
                </kendo-dropdownlist>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <span *ngIf="dataItem.piiInd">Yes</span>
                <span *ngIf="!dataItem.piiInd">No</span>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="systemRequired" title="System" [sortable]="false" filter="boolean"
            [headerClass]="'k-system-header'" [class]="'k-system-cell'">
            <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                let-filterService="filterService">
                <kendo-label class="custom-label-class" [for]="statusFilter" text="System Filter"></kendo-label>
                <kendo-dropdownlist #statusFilter [data]="booleanOptions" [valuePrimitive]="true"
                    [value]="getCurrentFilterValue(filter, column.field)" [defaultItem]="defaultOption"
                    (valueChange)="onFilterChange($event, filterService, column.field)" textField="text"
                    valueField="value" [itemDisabled]="itemDisabled">
                </kendo-dropdownlist>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <span *ngIf="dataItem.systemRequired">Yes</span>
                <span *ngIf="!dataItem.systemRequired">No</span>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="edit" title="" [sortable]="false" [filterable]="false" [headerClass]="'k-edit-header'"
            [class]="'k-edit-cell'">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span class="icon-sec-pencil-square" (click)="onEdit(dataItem)"></span>
            </ng-template>
        </kendo-grid-column>

        <ng-template kendoPagerTemplate let-currentPage="currentPage" let-totalPages="totalPages">
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-grid-spacer></kendo-grid-spacer>
            <kendo-pager-input></kendo-pager-input>
            <kendo-pager-info></kendo-pager-info>
            <kendo-grid-spacer></kendo-grid-spacer>
            <kendo-pager-page-sizes [pageSizes]="[10,15,20]"></kendo-pager-page-sizes>
        </ng-template>
    </kendo-grid>

</div>

<app-create-new-tag-modal *ngIf="isCreateNewTagModalOpen" (onClose)="toggleCreateNewTagDialog(false)"
    [showSaveAndApplyButton]="false"></app-create-new-tag-modal>
<app-edit-tag-modal *ngIf="isEditTagModalOpen" (onClose)="handleEditModalClose()" [tagData]="selectedEditTag"
    [showSaveAndApplyButton]="false"></app-edit-tag-modal>