import { Component, OnInit, OnDestroy } from '@angular/core';
import { SubmittedReportService, ISubmittedReport, SubmittedReportStates } from '@app/shared/report-services/submitted-report.service';
import { OfflineSyncService } from '@app/shared/report-services/offline-sync.service';
import { UserService } from 'app/shared/user/user.service';
import { Store } from '@ngrx/store';
import { getUser, User, IUser } from 'app/store/user';
import { MenuService } from 'app/shared/common-ux/components/nav-bar/menu.service';
import { State } from 'app/store';
import { IReportHistory, ResetTodo } from 'app/report-history/report-history-store/list';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';
import { filter } from 'lodash';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    providers: []
})
export class HomeComponent implements OnInit, OnDestroy {
    // disabling offline-sync for the time being
    // offlineReportsList: ISubmittedReport[];
    // offlineStates = SubmittedReportStates;
    user: IUser = null;
    isProxyUser: boolean;
    private resetTodo: IReportHistory;
    destroy$: Subject<void> = new Subject<void>();
    accessToken: string;

    constructor(
        private reportDBBService: SubmittedReportService,
        // disabling offline-sync for the time being
        // private syncService: OfflineSyncService,
        public userService: UserService,
        public menuService: MenuService,
        private store: Store<State>,
        private authService: PingAuthenticationService
    ) {
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        // Get user access token.
        this.authService.getAuthorizationHeaderValue().pipe(takeUntil(this.destroy$))
            .subscribe(data => this.accessToken = data);

        // Get user profile (Not sure why this is needed here).
        let profile;
        this.authService.profile$.pipe(takeUntil(this.destroy$))
            .subscribe(data => profile = data);

        this.store.dispatch(new ResetTodo(this.resetTodo));

        // flag database
        this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(user => {
            this.menuService.setPrivileges(user);
            this.user = user;
            if (this.user !== null) {
                this.isProxyUser = this.user.isProxyUser;
            }

            // disabling offline-sync for the time being
            // this.offlineReportsList = this.reportDBBService.getSubmittedReportList();
            // this.reportDBBService.newOfflineReportEvent.pipe(takeUntil(this.destroy$)).subscribe((reports) => {
            //     this.offlineReportsList = reports;
            // });
        });
    }

    retryUpload(report: ISubmittedReport) {
        this.reportDBBService.uploadSubmittedReport(report);
    }

    // disabling offline-sync for the time being
    // sync() {
    //     this.syncService.sync();
    // }
}
