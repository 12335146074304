import { Injectable, OnDestroy } from '@angular/core';
import { Subject, forkJoin } from 'rxjs';
import * as _ from 'lodash';
import { DynamicQuestionsService } from '@shared/SSEHubClient/dynamic-questions.service';
import { takeUntil } from 'rxjs/operators';

export class SortableObject {
    displayText: string;
    id: number;
    answerInputType: string;
}

export class DisplayOrderMetadata {
    profileId: number;
    categoryId: number;
    groupName: string;
    newQuestionMappingIdOrder: Array<number>;
}

@Injectable()
export class DisplayOrderService implements OnDestroy {
    destroy$: Subject<void> = new Subject<void>();
    private saveStateSubject = new Subject<any>();
    saveState = this.saveStateSubject.asObservable();

    constructor(private dynamicQuestionsService: DynamicQuestionsService) {
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    questionsToSortableObjects(questions): SortableObject[] {
        const sortableObjectsArray = new Array<SortableObject>();
        questions.forEach(question => {
            const obj = new SortableObject();
            obj.displayText = question.questionText;
            obj.id = question.questionMappingId;
            obj.answerInputType = question.answerInputType;
            sortableObjectsArray.push(obj);
        });
        return sortableObjectsArray;
    }

    saveQuestionsDisplayOrder(sortableObjectsArray: SortableObject[], sourceEntity): boolean {
        this.showProgress();

        // create the display order object
        const tempArray = [];
        sortableObjectsArray.forEach(q => tempArray.push(q.id));
        const displayOrderMetadata: DisplayOrderMetadata = {
            profileId: sourceEntity.profileId,
            categoryId: sourceEntity.categoryId,
            groupName: sourceEntity.group,
            newQuestionMappingIdOrder: tempArray
        };

        this.dynamicQuestionsService.updateDisplayOrder(displayOrderMetadata).pipe(takeUntil(this.destroy$)).subscribe(
            (result) => {
                this.hideProgress();
            },
            (error) => {
                this.hideProgress();
                console.error(error);
            });

        return true;
    }

    answersToSortableObjects(answers): SortableObject[] {
        const sortableObjectsArray = new Array<SortableObject>();
        answers.forEach(answer => {
            const obj = new SortableObject();
            obj.displayText = answer.answerText;
            obj.id = answer.answerId;
            sortableObjectsArray.push(obj);
        });
        return sortableObjectsArray;
    }

    saveAnswersDisplayOrder(sortableObjectsArray: SortableObject[], answers, sourceEntity): boolean {
        let index = 100;
        const tasks$ = [];
        sortableObjectsArray.forEach(sortableObject => {
            const currentAnswer = this.findAnswer(answers, sortableObject);
            if (currentAnswer) {
                currentAnswer.displayOrder = index;
                index = index + 100;
                const observableResult = this.dynamicQuestionsService.updateAnswer(currentAnswer);
                tasks$.push(observableResult);
            }
        });
        const source = forkJoin(...tasks$);
        source.pipe(takeUntil(this.destroy$)).subscribe(
            (result) => {
                this.hideProgress();
            },
            (error) => {
                this.showError(error);
            });
        return true;
    }

    private findQuestion(questions, sortableObject: SortableObject): any {
        // Lodash _.find was failing test for some strange reason, hence writing custom find
        for (const question of questions) {
            if (question.questionMappingID === sortableObject.id) {
                return question;
            }
        }
        return null;
    }

    private findAnswer(answers, sortableObject: SortableObject): any {
        // Lodash _.find was failing test for some strange reason, hence writing custom find
        for (const answer of answers) {
            if (answer.answerId === sortableObject.id) {
                return answer;
            }
        }
        return null;
    }
    private showProgress() {
        this.saveStateSubject.next(<any>{ processing: true });
    }

    private hideProgress() {
        this.saveStateSubject.next(<any>{ processing: false });
    }

    private showError(error) {
        console.log(error);
        this.saveStateSubject.next(<any>{ processing: true });
    }
}


