import { Component, OnInit, HostBinding, OnDestroy, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@app/store';
import { IUser, User, getUser } from './store/user';
import { UserService } from './shared/user/user.service';
import { ErrorHandlerService } from '@shared/error-handler/error-handler.service';
import { environment } from '../environments/environment';
import { Observable, Subject, Subscription } from 'rxjs';
import { MenuService } from './shared/common-ux/components/nav-bar/menu.service';
import { SubmittedReportService } from './shared/report-services/submitted-report.service';
import { FeatureFlagService } from './shared/feature-flag/feature-flag.service';
import { map, takeUntil } from 'rxjs/operators';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: []
})
export class AppComponent implements OnInit, OnDestroy {
    isLoading = true;
    appError = false;
    authError = false;
    inactiveEmployeeError = false;
    errorMessage = '';
    appReady = false;
    private user$: Observable<any>;
    isSidenavOpen = false;
    underMaintenance = false;
    @HostBinding('style.overflow') overflow = 'initial';
    destroy$: Subject<void> = new Subject<void>();

    identity$: Observable<User>;
    firstName$: Observable<string>;
    lastName$: Observable<string>;
    userAaId: string;
    userWorkGroup: string;
    userManagerId: string;
    userStation: string;

    isMobile: boolean = false;
    resizeObservable$: Observable<Event>
    resizeSubscription$: Subscription

    constructor(
        private store: Store<State>,
        private userService: UserService,
        private errorHandlerService: ErrorHandlerService,
        private menuService: MenuService,
        private flagService: FeatureFlagService,
        private submittedReportService: SubmittedReportService,
        private authService: PingAuthenticationService,
    ) {
        this.user$ = this.store.select(getUser);

        userService.errorUserLoaded$.pipe(takeUntil(this.destroy$)).subscribe(error => this.onAppError(error));
        errorHandlerService.appError$.pipe(takeUntil(this.destroy$)).subscribe(error => this.onAppError(error));
        errorHandlerService.authError$.pipe(takeUntil(this.destroy$)).subscribe(error => this.onAuthError(error));
        errorHandlerService.inactiveEmployeeError$.pipe(takeUntil(this.destroy$)).subscribe(error => this.onInactiveEmployeeError(error));

        menuService.responsive.pipe(takeUntil(this.destroy$)).subscribe(opened => {
            this.isSidenavOpen = opened;
            this.overflow = (opened) ? 'hidden' : 'initial';
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        this.identity$ = this.authService.identity$;
        this.firstName$ = this.authService.profile$.pipe(map(u => u.given_name));
        this.lastName$ = this.authService.profile$.pipe(map(u => u.last_name));
        this.authService.profile$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
            this.userAaId = data.uid;
            this.userWorkGroup = data.work_group;
            this.userManagerId = data.manager_id;
            this.userStation = data.location;  // for future enhancements
        });

        // Check if website is in maintenance mode. but wait to make the call until authorization has completed
        this.authService.OnSignInComplete.pipe(takeUntil(this.destroy$)).subscribe(_ => {
            this.flagService.hasFeatureFlag('WEB_FLAG_MAINTENANCE').pipe(takeUntil(this.destroy$)).subscribe(m => {
                if (m) this.onUnderMaintenance();
            });
        });

        // Handle Unauthorized requests(http status code: 401) from your backend services.
        this.authService.OnUnauthorizedRequest.pipe(takeUntil(this.destroy$)).subscribe((_url: string) => {
            this.authService.startSignOut({
                redirect_uri: environment.appUrl
            });
        });

        this.user$.pipe(takeUntil(this.destroy$)).subscribe(u => {
            this.onUserLoaded(u);
        });
        this.isMobile = this.isMobileDevice();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isMobile = window.innerWidth < 1000; 
    }
    
    isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)
    }

    signIn() {
        window.location.href = environment.loginUrl;
    }

    onUserLoaded(user: IUser) {
        if (user !== null && !this.underMaintenance && !this.inactiveEmployeeError) {
            this.isLoading = false;
            this.appError = false;
            this.authError = false;
            this.inactiveEmployeeError = false;
            this.errorMessage = '';
            this.appReady = true;

            // flag database
            this.menuService.setPrivileges(user);
            if (User.hasPrivilege(user, 'FLAG_WEB_FILLOUTREPORT')) {
                this.submittedReportService.syncPendingSubmittedReports();
                window.addEventListener('online', () => {
                    // this callback on the online event needs to be wrapped
                    // in a function to mantain the local scope on 'this'
                    this.submittedReportService.syncPendingSubmittedReports();
                });
            }
        }
    }

    onAppError(error: any) {
        this.isLoading = false;
        this.appError = true;
        this.authError = false;
        this.inactiveEmployeeError = false;
        this.appReady = false;
    }

    onAuthError(error: any) {
        this.isLoading = false;
        this.appError = false;
        this.authError = true;
        this.inactiveEmployeeError = false;
        this.appReady = false;
    }

    onInactiveEmployeeError(error: any) {
        this.isLoading = false;
        this.appError = false;
        this.authError = false;
        this.inactiveEmployeeError = true;
        this.appReady = false;
        this.errorMessage = error;
    }

    onUnderMaintenance() {
        this.underMaintenance = true;
        this.isLoading = false;
        this.appError = false;
        this.authError = false;
        this.appReady = false;
    }
}
