import { Injectable, EventEmitter } from '@angular/core';
import { MenuItem, menuItems, MenuList, subCategoryMgnt } from '../nav-bar/menu';
import { IUser, User } from '@app/store/user';

@Injectable()
export class MenuService {
  items: MenuList;
  responsive: EventEmitter<any>;
  constructor(
  ) {
    this.items = menuItems;
    this.responsive = new EventEmitter<boolean>();
  }

  public setPrivileges(user: IUser) {
    if (user) {
      const suAdmin = User.hasPrivilege(user, 'WEB_SUADMIN');
      const categoryMgnt = User.hasPrivilegeSubStr(user, 'WEB_ADMIN_CATEGORYMGNT');
      if (suAdmin || categoryMgnt) {
        this.items.admin.active = true;
      } else {
        this.items.admin.active = false;
      }
    }
  }
  public toggleResponsive(state: boolean) {
      this.responsive.emit(state);
  }

}
