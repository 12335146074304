<app-aa-spinner-no-logo [loading]="isLoading">
</app-aa-spinner-no-logo>
<div *ngIf="flightInfo != null">
    <h2>Flight info</h2>
    <div class="row">
        <div class="flight-info col-md-3 col-sm-12">
            <div class="summary-item">
                <span class="summary-header">Flight number</span>
                <span class="summary-value">{{flightInfo.flightId}}</span>
            </div>
            <div class="summary-item">
                <span class="summary-header">Departure station</span>
                <span class="summary-value">{{flightInfo.departureStation}}</span>
            </div>
            <div class="summary-item">
                <span class="summary-header">Departure terminal/gate</span>
                <span class="summary-value">{{flightInfo.departureTerminal}}/{{flightInfo.departureGate}}</span>
            </div>
        </div>
        <div class="col-md-3 col-sm-12">
            <div class="summary-item">
                <span class="summary-header">Flight date <!--time--></span>
                <span *ngIf="flightInfo.flightDate" class="summary-value">{{flightInfo.flightDate | date : "MM/dd/yyyy"
                    : 'UTC'}}</span>
                <span *ngIf="!flightInfo.flightDate" class="summary-value">-</span>
            </div>
            <div class="summary-item">
                <span class="summary-header">Arrival station:</span>
                <span class="summary-value">{{flightInfo.arrivalStation}}</span>
            </div>
            <div class="summary-item">
                <span class="summary-header">Arrival terminal/gate</span>
                <span class="summary-value">{{flightInfo.arrivalTerminal}}/{{flightInfo.arrivalGate}}</span>
            </div>
        </div>
        <div class="col-md-3 col-sm-12">
            <div class="summary-item">
                <span class="summary-header">Nose number</span>
                <span class="summary-value">{{flightInfo.tailNumber}}</span>
            </div>
            <div class="summary-item">
                <span class="summary-header">Interrupt station</span>
                <span class="summary-value">{{flightInfo.interruptStation}}</span>
            </div>
        </div>
        <div class="col-md-3 col-sm-12">
            <div class="summary-item">
                <span class="summary-header">Fleet</span>
                <span class="summary-value">{{flightInfo.fleetDescription}}</span>
            </div>
        </div>
        <div class="col-md-3 col-sm-12">
            <div class="summary-item">
                <span class="summary-header">Phase of flight</span>
                <span class="summary-value">{{flightInfo.flightPhaseDescription}}</span>
            </div>
        </div>
    </div>
</div>