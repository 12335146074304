import { Question } from './QuestionAnswer';
import { IQuestion } from 'app/new-report/new-report-store/wizard/wizard.model';

export class ExtractQuestionUrls {
    public questionObj: Question | IQuestion;
    public hyperLinks: Array<any>;
    public displayQuestionText = '';
    constructor(question: Question | IQuestion) {
        this.questionObj = question;
        this.hyperLinks = this.extractUrls(this.questionObj.questionText);
        if (this.hyperLinks && this.hyperLinks !== undefined && this.hyperLinks.length > 0) {
            this.displayQuestionText = this.questionObj.questionText;
            this.hyperLinks.forEach(alink => {
                this.displayQuestionText = this.displayQuestionText.replace(alink, '');
            });
        }
    }
    public extractUrls(questionText: string) {
        const geturl = new RegExp(
            '(^|[ \t\r\n])((ftp|http|https|gopher|mailto|news|nntp|telnet|wais|file|prospero|aim|webcal):(([A-Za-z0-9$_.+!*(),;/?:@&~=-])|%[A-Fa-f0-9]{2}){2,}(#([a-zA-Z0-9][a-zA-Z0-9$_.+!*(),;/?:@&~=%-]*))?([A-Za-z0-9$_+!*();/?:~-]))'
            , 'g'
        );
        const hyperlinks = questionText.match(geturl);
        return hyperlinks;
    }
}
