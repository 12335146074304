import { Component, OnInit, OnDestroy } from '@angular/core';
import { FeatureFlagService } from 'app/shared/feature-flag/feature-flag.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit, OnDestroy {
    text: string;
    destroy$: Subject<void> = new Subject<void>();
    constructor(private flagService: FeatureFlagService) {}

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    ngOnInit() {
        this.flagService.getFeatureFlag('WEB_FLAG_MAINTENANCE').pipe(takeUntil(this.destroy$)).subscribe(flag => {
            if (flag && flag.value) {
                this.text = flag.value;
            }
        });
    }
}
