<br>
<div class="section-header">
    <h2>{{sectionInfo.name}}</h2>
    <div>
        <app-overflow-menu [profileId]=profileId [context]="'general-question-section'"
            (callAction)="openConfirmModal($event)"></app-overflow-menu>
    </div>
</div>
<app-confirmation-popup #resetGeneralQuestions [title]="'Reset General Question Section'"
    [message]="'This action is going to delete all questions in the general questions section. Do you want to proceed?'"
    (callAction)="resetSection($event)">
</app-confirmation-popup>


<div class="divider"></div>

<app-aa-spinner-no-logo [loading]="(flags$ | async).isLoading"></app-aa-spinner-no-logo>

<div *ngIf="(flags$ | async).hasError">
    <p class="errorText">Error Loading Questions Data.</p>
</div>

<div [hidden]="!(flags$ | async).isReady">
    <!-- <app-section-visibility [profileId]=profileId [categoryId]=categoryId [sectionInfo]="sectionInfo" [sectionId]="9">
    </app-section-visibility> -->
    <!-- General Questions -->
    <!-- <h4 class="subHeader">General</h4> -->
    <div *ngFor="let question of generalQuestions;let i=index;" class="panel-body">
        <app-question-answer-dynamic-component [question]=question [profileId]=profileId [categoryId]=categoryId
            [group]='"General"' [index]=i [context]="'general-question'"
            (actionAddQuestion)="actionAddGeneralQuestion($event)" (actionEditQuestion)="actionEditQuestion($event)"
            (actionEditMetaData)="actionEditMetaData($event)" (actionRemoveQuestion)="actionRemoveQuestion($event)"
            (actionAddAnswer)="actionAddAnswer($event)" (actionEditAnswer)="actionEditAnswer($event)"
            (actionRemoveAnswer)="actionRemoveAnswer($event)" (actionSortQuestion)="actionSortQuestion($event)"
            (actionSortAnswer)="actionSortAnswer($event)" (actionAddTemplate)="actionAddTemplate($event)"
            (actionEditGroup)="actionEditGroup($event)" (actionManageTags)="actionManageTags($event)">
        </app-question-answer-dynamic-component>
    </div>
    <div *ngIf="generalQuestions.length==0" class="panel-body">
        <p>This category doesn't have any General Questions</p>
    </div>
    <br>
    <div class="section-summary add-question-link">
        <app-open-modal-button *ngIf="hasEditAccess" [title]="'Add Question'" [modal]="questionsListModal"
            [profileId]=profileId [group]='"General"' [iconClass]="'icon-small icon-add'"></app-open-modal-button>
    </div>
    <div class="section-summary add-question-link">
        <div *ngIf="hasEditAccess" class="add-button" (click)="actionAddTemplate($event)">
            <i class='icon-small icon-add'></i>
            <a>Add Template</a>
        </div>
    </div>
    <br>

</div>
<br />

<app-tags-list #editTagsModal></app-tags-list>
<app-question-add #createNewQuestion [group]="newGroupName"></app-question-add>
<app-questions-list #questionsListModal [group]="newGroupName"></app-questions-list>
<app-answer-add #answerAddModal></app-answer-add>
<app-answer-edit #answerEditModal [group]="newGroupName"></app-answer-edit>
<app-involvement-edit #editInvolvementModal [group]="newGroupName"></app-involvement-edit>
<app-question-edit [profileId]=profileId [categoryId]=categoryId #editQuestionModal></app-question-edit>
<app-template-add #addTemplateModal [groupName]="newGroupName" [profileId]="profileId" [categoryId]="categoryId">
</app-template-add>
<app-confirmation-popup #confirmQuestionRemove [title]="'Remove Question'"
    [message]="'Are you sure you want to remove the selected Question?'" (callAction)="onQuestionDelete()">
</app-confirmation-popup>

<app-confirmation-popup #confirmAnswerRemove [title]="'Remove Answer'"
    [message]="'Are you sure you want to remove the selected Answer?'" (callAction)="onAnswerDelete()">
</app-confirmation-popup>

<app-display-order-edit #sortOrderEditModal></app-display-order-edit>