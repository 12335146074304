<div class="button-image-div" *ngIf="parentAnswer | displayPaxSearchPipe">
    <button id="paxSearch" class="button-image" title="Passenger search" (click)="openPaxSearch()" text="Passenger Search"></button>
    <label class="button-text" (click)="openPaxSearch()">Passenger Search</label>
</div>
<div class="button-image-div" *ngIf="parentAnswer | displayCrewSearchPipe">
    <button id="crewSearch" class="button-image" title="Crew search" (click)="openCrewSearch()" text="Crew Search"></button>
    <label class="button-text" (click)="openCrewSearch()">Crew Search</label>
</div>
<div class="button-image-div" *ngIf="parentAnswer | displayEmployeeSearchPipe">
    <button id="empSearch" class="button-image" title="Employee search" (click)="openEmpSearch()" text="Employee Search"></button>
    <label class="button-text" (click)="openEmpSearch()">Employee Search</label>
    <div>
        <label *ngIf="this.parentAnswer['attrs']['employeeSearch'] === 'limitedSearch'">
            <span [ngClass]="{'font-red': !usedPersonSearch, 'font-green': usedPersonSearch}" id="limSearch-statement">
                Greyed out fields are populated via the Employee Search button.
            </span>
        </label>
    </div>
</div>
<div *ngFor="let question of questions; let i = index;">
    <ng-container appDynamicQuestion [question]="question" [profileId]="profileId" [categoryId]="categoryId" [groupName]="groupName" (answerEvent$)="childAnswerEvent($event)" (multiGroupEvent$)="multiGroupEvent($event, i)" (optionalGroupEvent$)="optionalGroupEvent($event, i)"
        (removeGroupEvent$)="removeGroupEvent($event, i)">
    </ng-container>
    <div class="childForm">
        <app-dynamic-form *ngIf="question.followupQuestions" (answerEvent$)="childAnswerEvent($event)" (multiGroupEvent$)="multiGroupEvent($event)" (optionalGroupEvent$)="optionalGroupEvent($event, i)" (removeGroupEvent$)="removeGroupEvent($event, i)" [profileId]="profileId"
            [categoryId]="categoryId" [groupName]="groupName" [questions]="question.followupQuestions" [parentAnswer]="this.question.userAnswer">
        </app-dynamic-form>
    </div>
</div>

<app-pax-search (selectedPaxData)="populateSelectedPersonInfo($event)"></app-pax-search>
<app-crew-search (selectedCrewData)="populateSelectedPersonInfo($event)"></app-crew-search>
<app-employee-search [limitedOrFull]="parentAnswer && parentAnswer['attrs'] && parentAnswer['attrs']['employeeSearch'] ? parentAnswer['attrs']['employeeSearch'] : ''"
    (selectedEmpData)="populateSelectedPersonInfo($event)"></app-employee-search>
