<nav>
    <div ngClass="{{!(proxyMode$ | async) ? 'titlebar-container' : 'titlebar-container proxy-mode-bar' }}">
        <div class="invisible-div">
            <!-- USED FOR POSITIONING THE PROXY MODE TEXT. DO NOT REMOVE -->
        </div>
        <div class="proxy-mode" *ngIf="(proxyMode$ | async)">
            PROXY MODE
        </div>
        <div class="titlebar-right btn-group">
            <div ngbDropdown class="nav-item profile" role="presentation">
                <button type="button" class="nav-link" ngbDropdownToggle>Hello, {{ userName }}</button>
                <ul class="profile-dropdown" ngbDropdownMenu>
                    <li *ngIf="isAdminUser" >
                        <a class="navbar-link" routerLink="/admin/categorymanagement" ngbDropdownItem>
                            Admin
                        </a>
                    </li>
                    <li >
                        <a class="navbar-link" routerLink="/notificationverification" ngbDropdownItem>
                            Notification verification
                        </a>
                    </li>
                    <li *ngIf="isReportManagementAccess || isGeneralMgr" >
                        <a class="navbar-link" routerLink="reporthistory/reportsearch" id="history_search_reports" ngbDropdownItem>Report
                            Search</a>
                    </li>
                    <li class="proxy" *ngIf="isProxyUser && !(proxyMode$ | async)" >
                        <div>Proxy as user: </div>
                        <div class="user-search-control">
                            <input type="text" placeholder="Enter user id" [(ngModel)]="proxyUserId"
                                (keyup.enter)="loadProxyUser()">
                            <button (click)="loadProxyUser()">Go</button>
                        </div>
                    </li>
                    <li *ngIf="(proxyMode$ | async)"><Button ngbDropdownItem class="logout exitProxy" (click)="exitProxyMode()" >Exit Proxy
                            Mode</Button></li>
                    <li ><Button class="logout" routerLink="/logout" ngbDropdownItem><i
                                class="profile-icon fas fa-sign-out-alt"></i>Logout</Button></li>
                </ul>
            </div>
        </div>
    </div>
	
</nav>