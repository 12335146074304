export interface MenuItem {
    text: string;
    route: string;
    submenu: Array<MenuItem>;
    icon: string;
    active: boolean;
}

export interface MenuList {
    home: MenuItem;
    newreport: MenuItem;
    reporthistory: MenuItem;
    myreports: MenuItem;
    todo: MenuItem;
    admin: MenuItem;
    offlinesync: MenuItem;
    help: MenuItem;
}

export let menuItems: MenuList = {
    home: {
        text: 'Home',
        route: 'home',
        submenu: null,
        icon: 'icon-medium icon-home',
        active: true
    },
    newreport: {
        text: 'Fill out report',
        route: 'newreport',
        submenu: null,
        icon: 'icon-medium icon-edit',
        active: true
    },
    // This will be for superusers/admin and managers who have a dropdown(my reports and all reports) via report history.
    reporthistory: {
        text: 'Report history',
        route: 'reporthistory',
        submenu: null,
        icon: 'icon-medium icon-clock',
        active: true
    },
    // This will be for the normal users who can only view their own reports.
    myreports: {
        text: 'My reports',
        route: 'myreports',
        submenu: null,
        icon: 'icon-medium icon-clock',
        active: true
    },
    todo: {
        text: 'To do',
        route: 'todo',
        submenu: null,
        icon: 'icon-medium icon-clock',
        active: true
    },
    admin: {
        text: 'Admin',
        route: 'admin/categorymanagement',
        submenu: null,
        icon: 'icon-medium icon-tools',
        active: false
    },
    offlinesync: {
        text: 'Sync my data',
        route: 'offlinesync',
        submenu: null,
        icon: 'icon-medium icon-clock',
        active: false
    },
    help: {
        text: 'Help',
        route: 'help',
        submenu: null,
        icon: 'icon-medium icon-help',
        active: true
    },
};

export let subCategoryMgnt: MenuItem = {
    text: 'Category Management',
    route: 'admin/categorymanagement',
    submenu: null,
    icon: '',
    active: true
};
