import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Subscription, Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// services
import { SSEHubCategoriesService } from '@shared/SSEHubClient/categories.service';
import { CategoryDetailService } from '../category-detail.service';
import { QuestionViewService } from '../question-view/question-view.service';
import { DynamicQuestionsService } from 'app/shared/SSEHubClient/dynamic-questions.service';

// interfaces
import { SectionInfo } from '@shared/reportStructure/categoryDetails/reportSections';
import { Question } from '@shared/common-ux/models/QuestionAnswer';

// state management
import { State } from '@app/store';
import { getNotesSectionInfo, getCategoryId, getSelectedProfileId, getNotesSection, getNotesQuestions, getCategoryDescription } from '../../category-management-store';
import * as Actions from '../../category-management-store/detail/sections.actions';
import { ISectionInfo } from '../../category-management-store/detail';
import { SelectQuestionForQuestionMappingTag } from '../../category-management-store/tag';

@Component({
    selector: 'app-notes-view',
    templateUrl: 'notes-view.component.html',
    styleUrls: ['notes-view.component.scss'],
})

export class NotesViewComponent implements OnInit, OnDestroy {
    destroy$: Subject<void> = new Subject<void>();
    private subscriptions: Subscription = new Subscription();
    notesQuestions: Question[];
    sectionInfo$: Observable<ISectionInfo>;
    group = 'Notes';
    sectionInfo: SectionInfo;
    categoryId: number;
    profileId: number;
    categoryDescription: string;
    @ViewChild('addTemplateModal', { static: true }) public addTemplateModal;
    @ViewChild('editQuestionModal', { static: true }) public editQuestionModal;
    @ViewChild('confirmQuestionRemove', { static: true }) public confirmQuestionRemove;
    @ViewChild('sortOrderEditModal', { static: true }) public sortOrderEditModal;

    constructor(
        private router: Router,
        private store: Store<State>,
        private sseHubCategoriesService: SSEHubCategoriesService,
        private categoryDetailService: CategoryDetailService,
        public questionViewService: QuestionViewService,
        public dynamicQuestionsService: DynamicQuestionsService
    ) { }

    ngOnInit() {

        this.store.select(getNotesQuestions).pipe(takeUntil(this.destroy$))
            .subscribe(questions => this.notesQuestions = questions);
        this.store.select(getCategoryId).pipe(takeUntil(this.destroy$))
            .subscribe(id => this.categoryId = id);
        this.store.select(getSelectedProfileId).pipe(takeUntil(this.destroy$))
            .subscribe(id => this.profileId = id);
        this.store.select(getNotesSectionInfo).pipe(takeUntil(this.destroy$))
            .subscribe(section => this.sectionInfo = section);
        this.store.select(getCategoryDescription).pipe(takeUntil(this.destroy$))
            .subscribe(description => this.categoryDescription = description);

        this.sectionInfo$ = this.store.select(getNotesSection);

        this.startLoadingState();
        this.loadNotesQuestions();

        const questionAddedSubscription = this.categoryDetailService.questionAddedorModified$.pipe(takeUntil(this.destroy$)).subscribe(
            questionType => {
                if (questionType === 'Notes') {
                    this.startLoadingState();
                    this.loadNotesQuestions();
                }
            });
        this.subscriptions.add(questionAddedSubscription);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
    }

    actionSortQuestion(eventSource) {
        this.sortOrderEditModal.open(eventSource);
    }

    private loadNotesQuestions(): void {
        this.store.dispatch(new Actions.LoadNotesQuestions(this.profileId, this.categoryId, this.group));
    }

    private loadTemplateQuestions(): void {
        const templateMetadata = {
            profileId: this.profileId,
            categoryId: this.categoryId,
            templateName: 'Template-Notes',
            groupName: this.group
        };

        this.dynamicQuestionsService.addTemplateQuestions(0, templateMetadata)
            .pipe(takeUntil(this.destroy$)).subscribe((result) => {
                this.loadNotesQuestions();
            },
                (error) => this.store.dispatch(new Actions.CategorySectionsError(this.group,
                    'Error reseting notes questions.', 'Load Template Questions failed : ' + error))
            );
    }

    private deleteNotesQuestions(): void {
        this.sseHubCategoriesService.deleteSection(this.profileId, this.categoryId, this.group)
            .pipe(takeUntil(this.destroy$)).subscribe(
                (result) => {
                    this.loadTemplateQuestions();
                },
                (error) => this.store.dispatch(new Actions.CategorySectionsError(this.group,
                    'Error deleting notes questions.', 'Deleting Notes Questions failed ' + error))
            );
    }

    resetSection(event) {
        this.startLoadingState();
        this.deleteNotesQuestions();
        this.store.dispatch(new Actions.ResetViewBySection(this.group));
    }

    actionAddTemplate(eventData) {
        this.addTemplateModal.open();
    }

    actionEditMetaData(event) {
        this.editQuestionModal.open(event);
    }

    actionRemoveQuestion(eventData) {
        this.categoryDetailService.setSelectedQuestion(eventData);
        this.confirmQuestionRemove.open();
    }

    actionManageTags(event) {
        this.store.dispatch(new SelectQuestionForQuestionMappingTag({questionMappingId: event.questionMappingId, questionText: event.questionText, categoryDescription: this.categoryDescription})); 
        this.router.navigate(['/admin/categorymanagement/profile/' + this.profileId + '/category/' + this.categoryId + '/question/'+event.questionMappingId+'/tags']);
    }

    onQuestionDelete() {
        this.startLoadingState();
        if (this.categoryDetailService.state.selectedQuestion && this.categoryDetailService.state.selectedQuestion.questionMappingId) {
            this.sseHubCategoriesService.deleteQuestionsByMappingId(this.categoryDetailService.state.selectedQuestion.questionMappingId)
                .pipe(takeUntil(this.destroy$)).subscribe(
                    (result) => {
                        if (result) {
                            this.loadNotesQuestions();
                        } else {
                            this.store.dispatch(new Actions.CategorySectionsError(this.group));
                        }
                    },
                    (error) => this.store.dispatch(new Actions.CategorySectionsError(this.group, 'Error deleting question.'))
                );
        }
    }

    private startLoadingState() {
        this.store.dispatch(new Actions.SetLoadingState('notes'));
    }
}
