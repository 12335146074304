<modal #answerEditdModal (onOpen)="onOpen($event)" (onClose)="onClose($event)">
    <modal-header>
        <h4>Edit Answer</h4>
    </modal-header>
    <modal-content>
        <app-aa-spinner [loading]="isLoading"></app-aa-spinner>
        <div *ngIf="!isLoading">
            <ng-container *ngIf='!isMultiInputAMSAddress'>
                <div style="display: -webkit-inline-box" *ngIf="!editMode">
                    <h5>{{answer.answerText}}</h5>
                    <div (click)="startEdit()" style="margin-top: 5px;" class="edit-section-icon"> <i
                            class="icon-medium icon-edit"></i> </div>
                </div>
                <div class="text-edit" *ngIf="editMode">
                    <div>
                        <input type="text" [(ngModel)]="answer.answerText">
                        <div *ngIf="answerInvalid" class="validation-fail">
                            Answer cannot be empty
                        </div>
                    </div>
                </div>

                <table *ngIf="!isLoading">
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="padding10">Risk Level :</td>
                            <td><input maxlength="3" (keypress)="validateRiskLevel($event)" [disabled]="isRiskNull"
                                    class="margin10" type="text" [(ngModel)]="answer.riskLevel" />
                                <div *ngIf="riskLevelInvalid" class="validation-fail">
                                    Textbox only accepts numeric values</div>
                                <div *ngIf="riskLevelEmpty" class="validation-fail">Risk Level cannot be empty</div>
                            </td>
                            <td><label><input class="margin10" type="checkbox"
                                        (ngModelChange)="onRiskyLevelChange($event)"
                                        [(ngModel)]="isRiskNull" />N/A</label></td>
                        </tr>
                        <tr>
                            <td class="padding10">Safety Level :</td>
                            <td><input maxlength="3" (keypress)="validateSafty($event)" [disabled]="isSafteyNull"
                                    class="margin10" type="text" [(ngModel)]="answer.safetyLevel" />
                                <div *ngIf="safetyLevelEmpty" class="validation-fail">
                                    Safety Level cannot be empty</div>
                                <div *ngIf="safetyLevelInvalid" class="validation-fail">
                                    Textbox only accepts numeric values</div>
                            </td>
                            <td><label><input class="margin10" type="checkbox"
                                        (ngModelChange)="onSafetyLevelChange($event)"
                                        [(ngModel)]="isSafteyNull" />N/A</label>
                            </td>
                        </tr>
                        <tr *ngIf='isPersonSearchEnabled'>
                            <td class="padding10">Display passenger search :</td>
                            <input type="checkbox" [(ngModel)]="isPaxSeachSelected" class="checkbox"
                                (ngModelChange)="updateAttrs('paxSearch', $event)" class="margin10" />
                        </tr>
                        <tr *ngIf='isPersonSearchEnabled'>
                            <td class="padding10">Display crew search :</td>
                            <input type="checkbox" [(ngModel)]="isCrewSearchSelected" class="checkbox"
                                (ngModelChange)="updateAttrs('crewSearch', $event)" class="margin10" />
                        </tr>
                        <tr *ngIf='isPersonSearchEnabled'>
                            <td class="option-text" [ngClass]="{'disabledText' : !isPersonSearchEnabled}">Display
                                employee search :</td>
                            <td>
                                <select name="empSearchType" id="empSearchType" [disabled]="!isPersonSearchEnabled"
                                    [(ngModel)]="employeeSearchOption"
                                    (change)="updateAttrs('employeeSearch', employeeSearchOption)">
                                    <option *ngFor="let option of employeeSearchDisplayOptions" [value]="option.value">
                                        {{ option.description }} </option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>

            <app-answer-multi-input *ngIf="!close && isMultiInputAMSAddress" [attributeSelections]="multiInputAttribute"
                [existingAnswerText]="answerText" [addOrEdit]="'edit'"
                (newAttribute)="updateMultiInputAttribute($event)" (answerText)="updatePrinterAnswer($event)">
            </app-answer-multi-input>
        </div>
    </modal-content>
    <modal-footer>
        <button type="button" class="btn btn-cancel" (click)="onClose()">Close</button>
        <button type="submit" class="btn btn-primary" (click)="onSave()">Save</button>
    </modal-footer>
</modal>