<div class="dynamicInputDiv" [ngModelGroup]="question.questionMappingId">
    <label>
        <i *ngIf="questionAttrs['isRequired']" class="icon-cers-required"></i>
        {{ question.questionText | sentenceCasePipe }}
    </label>
    <div>
        <button *ngIf="questionAttrs['naOption']" type="button" class="btn-dynamic-form"
            (click)="autofillTextNA()">N/A</button>
    </div>
    <input type="text" [(ngModel)]="question.userAnswer" class="form-control dropdown"
        name="{{ question.questionText }}" [appDynamicValidation]="questionAttrs"
        [appFocus]="{questionId: question.questionId, questionMappingId: question.questionMappingId}" #form="ngModel"
        (focusout)="sanitizeAnswer()" />
    <div *ngFor="let error of form.errors | keyvalue">
        <label class="errorText" *ngIf="error.key !== 'isRequired'">
            {{ error.value }}
        </label>
    </div>
</div>