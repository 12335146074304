import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { SectionInfo } from '@shared/reportStructure/categoryDetails/reportSections';
import { Answer, Question } from '@shared/common-ux/models/QuestionAnswer';
import { SSEHubCategoriesService } from '@shared/SSEHubClient/categories.service';
import { } from '@shared/error-handler-notify/services/notification.service';
import { LoggerService, NotificationService } from '@shared/error-handler-notify/services';
import * as _ from 'lodash';
import { Observable, Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { CategoryDetailService } from '../category-detail.service';
import { QuestionViewService } from './question-view.service';
import { Store } from '@ngrx/store';
import { getUser } from '@app/store/user/user.reducer';
import { State } from '@app/store';
import * as Actions from '../../category-management-store/detail/sections.actions';
import {
    getQuestionsFlags, getGeneralQuestions,
    getQuestionsSectionInfo, getSelectedProfileId, getCategoryId,
    getCategoryDescription
} from '../../category-management-store';
import { takeUntil } from 'rxjs/operators';
import { FeatureFlagService } from 'app/shared/feature-flag/feature-flag.service';
import { DynamicQuestionsService } from 'app/shared/SSEHubClient';
import { PopulateSelectionsForTemplate } from '../../category-management-store/template/template.actions';
import { SelectQuestionForQuestionMappingTag } from '../../category-management-store/tag';

@Component({
    selector: 'app-question-view',
    templateUrl: 'question-view.component.html',
    styleUrls: ['./question-view.component.scss']
})

export class GeneralQuestionViewComponent implements OnInit, OnDestroy {
    sectionInfo: SectionInfo;
    categoryId: number;
    profileId: number;
    categoryDescription: string;
    @ViewChild('editQuestionModal', { static: true }) public editQuestionModal;
    @ViewChild('answerAddModal', { static: true }) public answerAddModal;
    @ViewChild('answerEditModal', { static: true }) public answerEditModal;
    @ViewChild('addTemplateModal', { static: true }) public addTemplateModal;
    @ViewChild('editInvolvementModal', { static: true }) public editInvolvementModal;
    @ViewChild('questionsListModal', { static: true }) public questionsListModal;
    @ViewChild('confirmQuestionRemove', { static: true }) public confirmQuestionRemove;
    @ViewChild('confirmAnswerRemove', { static: true }) public confirmAnswerRemove;
    @ViewChild('sortOrderEditModal', { static: true }) public sortOrderEditModal;
    @ViewChild('createNewQuestion', { static: true }) public createNewQuestion;
    @ViewChild('resetGeneralQuestions', { static: true }) public resetGeneralQuestions;

    flags$: Observable<any>;
    private subscriptions: Subscription = new Subscription();
    destroy$: Subject<void> = new Subject<void>();
    public generalQuestions: Question[] = new Array<Question>();
    group = 'questions';
    newGroupName = 'General';
    hasEditAccess = false;

    constructor(
        private router: Router,
        private categoryDetailService: CategoryDetailService,
        private sseHubCategoriesService: SSEHubCategoriesService,
        public dynamicQuestionsService: DynamicQuestionsService,
        public logger: LoggerService,
        public notificationService: NotificationService,
        public questionViewService: QuestionViewService,
        private store: Store<State>,
        public featureFlag: FeatureFlagService
    ) { }

    ngOnInit() {
        this.store.select(getCategoryId).pipe(takeUntil(this.destroy$))
            .subscribe(id => this.categoryId = id);
        this.store.select(getSelectedProfileId).pipe(takeUntil(this.destroy$))
            .subscribe(id => this.profileId = id);
        // section info
        this.store.select(getQuestionsSectionInfo).pipe(takeUntil(this.destroy$))
            .subscribe(section => this.sectionInfo = section);
        this.store.select(getCategoryDescription).pipe(takeUntil(this.destroy$))
            .subscribe(description => this.categoryDescription = description);
       
        this.startLoadingState();
        this.loadGeneralQuestions();
        this.setSubscriptions();
        this.flags$ = this.store.select(getQuestionsFlags);

        this.store.select(getGeneralQuestions).pipe(takeUntil(this.destroy$)).subscribe(questions => {
            if (this.categoryDetailService.state.canAdminFillOutReport) {
                this.generalQuestions = questions;
            } else {
                this.generalQuestions =
                    _.filter(questions, (qs) => { return qs.answerInputType && qs.answerInputType.toUpperCase() !== 'INVOLVEMENT'; });
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
    }
    private handleCreateNewModalChange(data: any) {
        this.newGroupName = data.group;
        if (data.group === 'Flight') { return; }

        if (data.status === 'Open') {
            this.createNewQuestion.open();
        }
        if (data.status === 'Close' || data.status === 'Save') {
            this.loadGeneralQuestions();
            this.questionsListModal.open();
        }
    }
    private loadGeneralQuestions(): void {
        this.store.dispatch(new Actions.LoadGeneralQuestions(this.profileId, this.categoryId));
    }

    private deleteAllGeneralQuestions(): void {
        this.sseHubCategoriesService.deleteSection(this.profileId, this.categoryId, 'General')
            .pipe(takeUntil(this.destroy$)).subscribe(
                (result) => {
                    this.store.dispatch(new Actions.LoadGeneralQuestions(this.profileId, this.categoryId));
                },
                (error) => {
                    this.notificationService.showError('Error deleting general questions.');
                    this.onError('Deleting Flight Questions failed ' + error);
                }
            );
    }

    openConfirmModal(e: string) {
        // these strings are emitted from overflow-menu.component.ts when an item in overflow-menu is selected
        if (e.includes('resetGeneralQuestions')) this.resetGeneralQuestions.open();
    }


    resetSection(event) {
        this.startLoadingState();
        this.deleteAllGeneralQuestions();
        this.store.dispatch(new Actions.ResetViewBySection(this.newGroupName));
    }

    public editFligt(): void {
        this.router.navigate(['/admin/categorymanagement/profile/' + this.profileId + '/category/' + this.categoryId + '/flight/']);
    }

    validateEditAccess() {
        this.hasEditAccess = this.categoryDetailService.state.hasEditAccess;
    }

    private startLoadingState() {
        this.store.dispatch(new Actions.SetLoadingState(this.group));
    }

    private onError(message?: string, notification?: string) {
        this.store.dispatch(new Actions.CategorySectionsError(this.group, message, notification));
    }

    actionAddTemplate(eventData) {
        const existingTemplates = _(this.generalQuestions).map('TemplateName').uniq().filter(x => x !== null).value();
        const answer = { answerId: 0 } as Answer;
        this.store.dispatch(new PopulateSelectionsForTemplate({
            selectedAnswer: answer,
            existingTemplates: existingTemplates
        }));
        this.newGroupName = 'General';
        this.addTemplateModal.open();
    }

    actionEditMetaData(event) {
        this.editQuestionModal.open(event);
    }

    actionAddInvQuestion(eventData) {
        // Triggered by the context menu
        this.newGroupName = 'INVOLVEMENTQUESTIONS';
        this.questionViewService.setSelectedAnswer(eventData);
        this.questionsListModal.open();
    }

    actionAddGeneralQuestion(eventData) {
        // Triggered by the context menu
        this.newGroupName = 'General';
        this.questionViewService.setSelectedAnswer(eventData);
        this.questionsListModal.open();
    }

    actionAddReportedByQuestion(eventData) {
        // Triggered by the context menu
        this.newGroupName = 'ReportedBy';
        this.questionViewService.setSelectedAnswer(eventData);
        this.questionsListModal.open();
    }

    actionAddAnswer(event) {
        event.type = 'Question';
        this.answerAddModal.open(event);
    }

    actionEditAnswer(event) {
        event.type = 'Question';
        this.answerEditModal.open(event);
    }

    actionEditGroup(event) {
        event.type = 'Question';
        this.editInvolvementModal.open(event);
    }

    actionRemoveQuestion(eventData) {
        this.categoryDetailService.setSelectedQuestion(eventData);
        this.confirmQuestionRemove.open();
    }

    actionRemoveAnswer(eventData) {
        this.categoryDetailService.setSelectedAnswer(eventData);
        this.confirmAnswerRemove.open();
    }

    openSortModal(groupName: string) {
        if (groupName.toLowerCase() === 'general') {
            this.sortOrderEditModal.open(this.generalQuestions);
        }
    }

    actionSortQuestion(eventSource) {
        this.sortOrderEditModal.open(eventSource);
    }

    actionSortAnswer(eventSource) {
        this.sortOrderEditModal.open(eventSource);
    }

    actionManageTags(event) {
        this.store.dispatch(new SelectQuestionForQuestionMappingTag({questionMappingId: event.questionMappingId, questionText: event.questionText, categoryDescription: this.categoryDescription}));
        this.router.navigate(['/admin/categorymanagement/profile/' + this.profileId + '/category/' + this.categoryId + '/question/'+event.questionMappingId+'/tags']);
    }

    private setSubscriptions() {
        const createNewModalSubsctiption = this.questionViewService.createNewModalStatus$.pipe(takeUntil(this.destroy$)).subscribe(
            data => {
                this.handleCreateNewModalChange(data);
            });
        this.subscriptions.add(createNewModalSubsctiption);

        const categoryInitialized = this.categoryDetailService.categoryInitialized$.pipe(takeUntil(this.destroy$)).subscribe(
            data => {
                this.loadGeneralQuestions();
            });
        this.subscriptions.add(categoryInitialized);

        const answerSubscription = this.categoryDetailService.answerAdded$.pipe(takeUntil(this.destroy$)).subscribe(
            parentQuestion => {
                if (parentQuestion.type === 'Question') {
                    this.startLoadingState();
                    this.loadGeneralQuestions();
                    // this.loadInvolvementTypesAndQuestions();
                }
            });
        this.subscriptions.add(answerSubscription);

        const questionAddedSubscription = this.categoryDetailService.questionAddedorModified$.pipe(takeUntil(this.destroy$)).subscribe(
            questionType => {
                if (questionType === 'General') {
                    this.loadGeneralQuestions();
                }
                // if (questionType === 'INVOLVEMENTQUESTIONS' || questionType === 'ReportedBy') {
                //     this.loadInvolvementTypesAndQuestions();
                // }
            });
        this.subscriptions.add(questionAddedSubscription);
        this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(user => this.validateEditAccess());
    }

    onQuestionDelete() {
        this.startLoadingState();
        if (this.categoryDetailService.state.selectedQuestion && this.categoryDetailService.state.selectedQuestion.questionMappingId) {
            this.sseHubCategoriesService.deleteQuestionsByMappingId(this.categoryDetailService.state.selectedQuestion.questionMappingId)
                .pipe(takeUntil(this.destroy$)).subscribe(
                    (result) => {
                        if (result) {
                            this.loadGeneralQuestions();
                            // this.loadInvolvementTypesAndQuestions();
                        } else {
                            this.onError('');
                        }
                    },
                    (error) => {
                        this.notificationService.showError('Error deleting question.');
                        this.onError('Error ' + error);
                    }
                );
        }
    }

    onAnswerDelete() {
        this.startLoadingState();
        if (this.categoryDetailService.state.selectedAnswer && this.categoryDetailService.state.selectedAnswer.answerId) {
            this.sseHubCategoriesService.deleteAnswerById(this.categoryDetailService.state.selectedAnswer.answerId)
                .pipe(takeUntil(this.destroy$)).subscribe(
                    (result) => {
                        if (result) {
                            this.loadGeneralQuestions();
                            // this.loadInvolvementTypesAndQuestions();
                        } else {
                            this.onError('');
                        }
                    },
                    (error) => {
                        this.notificationService.showError('Error deleting answer.');
                        this.onError('Error ' + error);
                    }
                );
        }
    }
}

