import { Injectable } from '@angular/core';

@Injectable()
// TODO: This needs to be moved into database table
export class QuestionConstants {
    public static answerInputType = [
        'Textbox',
        'Dropdown',
        'CheckBox',
        'Radio',
        'Date',
        'Time',
        'Number',
        'Involvement',
        // 'Multi',
        'QuestionGroup'
    ];

    public static multiInputTypes = [
        { id: 'amsAddress', multiInputTypeDescription: 'AMS Addresses' },
    ];
}


export class MultiInputType {
    id: string;
    multiInputTypeDescription: string;
}

