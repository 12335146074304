import { Component, OnInit, ViewChild, Input, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { DynamicQuestionsService } from '@shared/SSEHubClient/dynamic-questions.service';
import { Question } from '@shared/common-ux/models/QuestionAnswer';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { ActivatedRoute, Params } from '@angular/router';
import { CategoryDetailService } from '../category-detail.service';
import { QuestionViewService } from '../question-view/question-view.service';
import { FeatureFlagService } from 'app/shared/feature-flag/feature-flag.service';
import { takeUntil } from 'rxjs/operators';
import { SortColumn, SortEvent, TableSortableHeader } from 'app/shared/directives/sortable.directive';

const compare = (v1: string | number, v2: string | number) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);


@Component({
    selector: 'app-questions-list',
    templateUrl: './questions-list.component.html',
    styleUrls: ['./questions-list.component.scss']
})
export class QuestionsListComponent implements OnInit, OnDestroy {
    parentAnswerId = -1;
    parentData: any;
    @Input() group: string;
    @ViewChild('questionListModal') public questionListModal;
    @ViewChildren(TableSortableHeader) headers: QueryList<TableSortableHeader>;
    dtTrigger: Subject<any> = new Subject();
    questions: any;
    categoryId: number;
    profileId: number;
    questionId: any;
    isLoading = true;
    hasError = false;
    isReady = false;
    newQuestions: Question = new Question();
    groupName: string;
    destroy$: Subject<void> = new Subject<void>();
    searchText:string = '';
    // ngb pagination
    page = 1;
	pageSize = 10;
    collectionSize:number;
    pagedQuestions:any[];

    constructor(
        private activatedRoute: ActivatedRoute,
        public dynamicQuestionsService: DynamicQuestionsService,
        private categoryDetailService: CategoryDetailService,
        private questionViewService: QuestionViewService,
        public featureFlag: FeatureFlagService
    ) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    ngOnInit() {
        this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
            this.profileId = params['profileId'];
            this.categoryId = params['categoryId'];
        });

    }

    refreshQuestions(){
        this.pagedQuestions = this.questions.filter(x=> x.questionText.toLowerCase().match(this.searchText.toLowerCase()) || x.answerInputType.toLowerCase().match(this.searchText.toLowerCase()))
            this.collectionSize = this.pagedQuestions.length;
            this.pagedQuestions = this.pagedQuestions.map((category, i) => ({ id: i + 1, ...category })).slice(
                (this.page-1) * this.pageSize,
                (this.page-1) * this.pageSize + this.pageSize,
            );
    }

    onSort({ column, direction }: SortEvent) {
		// resetting other headers
		this.headers.forEach((header) => {
			if (header.sortable !== column) {
				header.direction = '';
			}
		});
        let questions = this.sort(column, direction);
        this.pagedQuestions = questions.filter(x=> x.questionText.toLowerCase().match(this.searchText.toLowerCase()) || x.answerInputType.toLowerCase().match(this.searchText.toLowerCase()))
        this.collectionSize = this.pagedQuestions.length;
        this.pagedQuestions = this.pagedQuestions.map((category, i) => ({ id: i + 1, ...category })).slice(
            (this.page-1) * this.pageSize,
            (this.page-1) * this.pageSize + this.pageSize,
        );
	}

    sort(column: SortColumn, direction: string){
        if (direction === '' || column === '') {
            return this.questions;
        } else {
             return [...this.questions].sort((a, b) => {
                const res = compare(a[column], b[column]);
                return direction === 'asc' ? res : -res;
            });
        }
    }

    onOpen(event?) {
        this.dtTrigger = new Subject();
        this.loadQuestions(0);

    }
    onClose(event?) {
        this.questionListModal.close();
    }
    open(groupName: string) {

        if (groupName) { this.group = groupName; }
        this.parentData = null;
        this.parentAnswerId = null;
        const parentAnswer = this.questionViewService.selectedAnswer;
        if (parentAnswer && parentAnswer.answerId) {
            this.parentData = parentAnswer;
            this.parentAnswerId = parentAnswer.answerId;
        }
        this.questionListModal.open();
    }
    openNewQuestionModal() {
        this.questionListModal.close();
        this.questionViewService.openCreateNew(this.group);
    }

    public addQuestionToCategory(question: any) {
        // this.addQuest = question;
        this.questionId = question.questionId;
        let isRoot = true;
        this.groupName = this.group;
        // ReferenceSK is used to map Involvement type to Questions. This is used only for Involvement questions
        if (this.group === 'INVOLVEMENTQUESTIONS') {
            const selectedCategory = this.categoryDetailService.state;
            if (selectedCategory && selectedCategory.selectedInvolvementType) {
                question.referenceSk = selectedCategory.selectedInvolvementType.id;
            }
        }

        // setting ReferenceSK to 6 to map Questionsto reportedBy. This is used only for Reported by questions
        if (this.group === 'ReportedBy') {
            question.referenceSk = '6';
            this.groupName = 'INVOLVEMENTQUESTIONS';
        }

        if (this.parentAnswerId > 0) {
            isRoot = false;
        }

        const addQuestMetaData = {
            'categoryId': 0,
            'questionId': question.questionId,
            'question': '',
            'inputType': question.answerInputType,
            'isRequired': question.isRequired,
            'displayOrder': question.displayOrder,
            'isRoot': isRoot,
            'min': 0,
            'max': 0,
            'profileId': 0,
            'groupName': this.groupName,
            'referenceSk': (question.involvementTypes && question.involvementTypes.length === 1) ?
                question.involvementTypes[0].referenceSk : question.referenceSk,
            'validateRules': Object.assign({}, question.answerInputType.toLowerCase() === 'number' ? { minValue: 0 } : {}),
            'answerId': this.parentAnswerId
        };
        this.questionViewService.mapQuestionToCategory(this.profileId, this.categoryId, addQuestMetaData, null, this.onError);
    }

    // This fixes the subscribe depreciation issue. We will need to do this in other areas.
    private loadQuestions(newQuestionId): void {
        this.startLoadingState();
        this.dynamicQuestionsService.getQuestions().pipe(takeUntil(this.destroy$)).subscribe({
            next: (result) => {
                if(result) {
                    if (this.categoryDetailService.state.canAdminFillOutReport) {
                        this.questions = _.filter(result, q => { // filter out involvement questions without involvement types
                            return ((q.answerInputType && q.answerInputType.toUpperCase() !== 'INVOLVEMENT') || // non-involvement questions
                                (q.answerInputType && q.answerInputType.toUpperCase() === 'INVOLVEMENT'
                                    && q.involvementTypes && q.involvementTypes.length > 0));
                        });
                        this.questions.forEach(a => a.referenceSk = 0);
                    } else {
                        this.questions =
                            _.filter(result, (questions) => { return questions.answerInputType && questions.answerInputType.toUpperCase() !== 'INVOLVEMENT'; });
                    }
                    this.refreshQuestions();

                    setTimeout(() => {
                        // The latest rxjs update needs a dummy value inside of next() here for it to work.
                        this.dtTrigger.next(true);
                    });
                    this.onReady();
                }
            },
            error: (error) => {
                console.log(error);
            }
        });
    }

    private startLoadingState() {
        this.isLoading = true;
        this.isReady = false;
        this.hasError = false;
    }

    private onError(error?: string) {
        if (error) {
            console.error('Error ' + error);
        }
        this.isLoading = false;
        this.isReady = false;
        this.hasError = true;
    }

    private onReady() {
        this.isLoading = false;
        this.isReady = true;
        this.hasError = false;
    }
}
