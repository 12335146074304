<div class="panel-group" id="accordion">
    <app-report-detail-section [section]="'Discussion'" [title]="'Messages'" [allExpandState]="allExpandState">
    </app-report-detail-section>
    <div #section id="Discussion" class="panel-collapse collapse">
        <div class="panel-body">
            <p class="errorText" *ngIf="(adminDiscussion$ | async).error">{{ (adminDiscussion$ | async).error }}</p>
            <app-aa-spinner [loading]="(adminDiscussion$ | async).isLoading"></app-aa-spinner>
            <div *ngIf="!(adminDiscussion$ | async).isLoading && !(adminDiscussion$ | async).error">
                <div *ngFor="let discussion of (adminDiscussion$ | async).detail | SortByTimeStampPipe : 'date'"
                    class="requestResponse">
                    <label class="description-timestamp">
                        {{discussion.Submitter.lastName}}, {{discussion.Submitter.firstName}}
                        on {{discussion.date | date:'MM/dd/yyyy'}} at {{discussion.date |
                        date:'shortTime'}}
                    </label>
                    <label class="description-timestamp italic" *ngIf="discussion.Request">
                        Requested From:
                        <ng-container *ngFor="let responder of discussion.responders; let last = last">
                            {{ responder.firstName }} {{responder.lastName}}
                            <ng-container *ngIf="!last">,</ng-container>
                        </ng-container>
                    </label>
                    <label class="description-text">
                        <b class="description-timestamp" *ngIf="discussion.request">Request:
                        </b>
                        <b class="description-timestamp" *ngIf="!discussion.request">Response:
                        </b>
                        {{discussion.comment}}
                    </label>
                </div>
                <label *ngIf="!(adminDiscussion$ | async).detail.length > 0"> No messages to show</label>
                <app-request-response-inline
                    *ngIf="featureAccessForCoreRecipient && (!displayRespondToRequestForm || (!displayRequestResponseForm && !displayRespondToRequestForm))"
                    (requestResponseFormDisplaying)="updateDisplayRequestResponseForm($event)">
                </app-request-response-inline>
                <app-respond-to-request-inline
                    *ngIf="featureAccessForFwdRecipient && (!displayRequestResponseForm || (!displayRequestResponseForm && !displayRespondToRequestForm))"
                    (respondToRequestFormDisplaying)="updateDisplayRespondToRequestForm($event)">
                </app-respond-to-request-inline>
            </div>
        </div>
    </div>
</div>
