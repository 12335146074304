import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({ name: 'alphabetize' })
export class AlphabetizePipe implements PipeTransform {
    transform(value: string[], order): string[] {
        if (value && value.length > 1) {
            return _.orderBy(value, null, [order]);
        } else {
            return value;
        }
    }
}
