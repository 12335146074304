import { SectionInfo } from 'app/shared/reportStructure/categoryDetails/reportSections';

export interface IQuestion {
    questionMappingId?: number;
    questionId?: number;
    groupName: string;
    questionText: string;
    attrs?: any;
    answerInputType: string;
    mandatory: boolean;
    categoryId?: number;
    displayOrder?: number;
    referenceId?: number;
    answers?: Array<IAnswer>;
    isInvolvement?: boolean;
    isReadOnly: boolean;
    hyperLinks: string[];
    displayQuestionText: string;
}

// not currently used for testing but want to go this way
export interface IQuestionAttrs {
    isRequired?: boolean;
    minLength?: number;
    type?: string;
    maxLength?: number;
    dataType?: string;
    stationCodeValidations?: boolean;
    minDate?: number;
    maxDate?: number;
    uppercase?: boolean;
    disallowSpecialChars?: boolean;
    fieldName?: string;
    paxSearch?: boolean;
    personMapping?: string;
    isActive?: boolean;
    visibleRows?: number;
    naOption?: boolean;
    isHidden?: boolean;
    minValue?: number;
    maxInvolvements?: number;
    employeeSearch?: string;
    maxValue?: number;
    negative?: boolean;
    maxlength?: number;
    crewSearch?: boolean;
    displayPropertyKey?: string;
    autoPopulateValuesKey?: string;
    valuePropertyKey?: string;
    includeInEmail?: boolean;
    disallowOwnEmployeeId?: boolean;
    displayInjuries?: boolean;
    defaultValues?: string[];
    defaultRequired?: boolean;
    multiInputType?: string;
    maxEmailLength?: number;
    hideFromReportDetail?: boolean;
    removeLeadingZeros?: boolean;
    maxQuestionGroups?: number;
    textValue?: string;
    categoryConfirmationText?: string;
    categoryAdditionalInfo?: string;
    // only used in UI and not API. This is a horrible place and needs refactoring
    failedAPICall?: string;
}

export type PageStatusTypes = 'inProgress' | 'complete' | 'incomplete';

export interface IEmployeeInjuries {
    employeeId: number;
    injuryDate: Date;
    bodyPart: string;
    nature: string;
    cause: string;
}

export interface IDynamicQuestion extends IQuestion {
    followupQuestions?: Array<IQuestion>;
    backupFollowupQuestions?: Array<IQuestion>;
    userAnswer?: string | object | IAnswer;
    uniqueIdentifier?: number;
    injuries?: Array<IEmployeeInjuries>;
    isReadOnly: boolean;
    recurrence?: number;
}

export class Question {
    static new(obj: any) {
        return {
            categoryId: obj.categoryId,
            questionId: obj.questionId,
            questionMappingId: obj.questionMappingId,
            question: obj.question, // QuestionText
            inputType: obj.inputType, // AnswerInputType
            'IsRequired?': obj.isRequired,
            mandatory: obj.mandatory,
            displayOrder: obj.displayOrder,
            'isRoot?': obj.isRoot,
            'min?': obj.min,
            'max?': obj.max,
            'profileId?': obj.profileId,
            'groupName?': obj.groupName,
            'referenceSk?': obj.referenceSk,
            'validateRules?': obj.validateRules,
            'answerId?': obj.answerId,
            'template?': obj.template,
            'referenceId?': obj.referenceId,
            'answers?': obj.answers,
            'isInvolvement': obj.isInvolvement,
            'isReadOnly': obj.isReadOnly,
            'attrs?': obj.attrs
        };
    }
}

export interface IAnswer {
    questionMappingId: number;
    answerId: number; // AnswerID
    answerText: string; // AnswerText
    displayOrder: number;
    riskLevel?: number;
    safetyLevel?: number;
    followupQuestions?: Array<IQuestion>;
    isChecked?: boolean;
    multiInputText?: string;
    multiInputType?: string;
    attrs?: object;
    uniqueIdentifier?: number;
}

export class Answer {
    static new(obj: any) {
        return {
            questionMappingId: obj.questionMappingId,
            id: obj.id,
            answer: obj.answer,
            displayOrder: obj.displayOrder,
            riskLevel: obj.riskLevel,
            safetyLevel: obj.safetyLevel,
            followupQuestions: obj.followupQuestions,
        };
    }
}

// visibility === 0 is 'hidden'
// visibility === 1 is 'visible
// visibility === 2 is 'required'
export const visibilityDictionary = {
    0: 'hidden',
    1: 'visible',
    2: 'required'
};

export interface IFlightDetails {
    flightNumber: string;
    flightDate: string;
    departureStation: string;
    arrivalStation: string;
}

export interface IFlightSequence {
    aircraftTypeDescription: string;
    arrivalStation: string;
    interruptStation: string;
    departureStation: string;
    employeeId: string;
    fleetDescription: string;
    flightDate: string;
    flightNumber: string;
    sequencePosition: string;
    tailNumber: string;
}
export class FlightSequence implements IFlightSequence {
    aircraftTypeDescription: string;
    arrivalStation: string;
    interruptStation: string;
    departureStation: string;
    employeeId: string;
    fleetDescription: string;
    flightDate: string;
    flightNumber: string;
    sequencePosition: string;
    tailNumber: string;
    isChecked: boolean;

    constructor(flightNum?: string,
        departureSt?: string,
        flightDate?: string,
        arrSt?: string,
        interruptSt?: string,
        fleetDesc?: string,
        tailNum?: string) {

        this.flightNumber = flightNum ? flightNum : null;
        this.departureStation = departureSt ? departureSt : null;
        this.flightDate = flightDate ? flightDate : null;
        this.arrivalStation = arrSt ? arrSt : null;
        this.interruptStation = interruptSt ? interruptSt : null;
        this.fleetDescription = fleetDesc ? fleetDesc : null;
        this.tailNumber = tailNum ? tailNum : null;
        this.isChecked = false;
    }

}

export interface NotesSectionModel {
    notes: SectionInfo;
    attachments: SectionInfo;
}

export class SessionLog {
    source: string;
    message: string;
    optional1: string;
    optional2: string;
}
