<div class="dynamicInputDiv" [ngModelGroup]="question.questionMappingId">
    <label>
        <i *ngIf="questionAttrs['isRequired']" class="icon-cers-required"></i>
        {{ question.questionText | sentenceCasePipe }}
    </label>
    <input matInput [matDatepicker]="picker" placeholder="mm/dd/yyyy" class="form-control date" [(ngModel)]="question.userAnswer" (ngModelChange)="updateStoreIfFlightDetailQuestion($event)" name="{{question.questionText}}" [appDynamicValidation]="questionAttrs"
        (click)="picker.open()" [appFocus]="{questionId: question.questionId, questionMappingId: question.questionMappingId}" [disabled]="question.isReadOnly" #form="ngModel">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker (opened)="toggleScrollLock(true)" (closed)="toggleScrollLock(false)" #picker></mat-datepicker>
    <div *ngFor="let error of form.errors | keyvalue | dateValidationPipe">
        <label class="errorText" *ngIf="error.key !== 'isRequired'"> {{error.value}} </label>
    </div>
    <app-confirm-flight-change [flightDetail]="currentFlightDetails" (confirmUpdate)="saveToStore($event)" (cancelUpdate)="cancelUpdate()"></app-confirm-flight-change>
</div>
