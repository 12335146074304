import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ToastEvent, ToastType } from '../toaster/toast-models';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    toastEvents: Observable<ToastEvent>;
    private _toastEvents = new Subject<ToastEvent>();

    constructor() {
        this.toastEvents = this._toastEvents.asObservable();
    }

    show(type: ToastType, message: string, title?: string) {
        switch (type) {
            case 'success':
                this.showSuccess(message, title);
                break;
            case 'error':
                this.showError(message, title);
                break;
            case 'info':
                this.showInfo(message, title);
                break;
            case 'warning':
                this.showWarning(message, title);
                break;
        }
    }

    showSuccess(message: string, title?: string) {
        this._toastEvents.next({ message: message, title: title, type: ToastType.Success });
    }
    showError(message: string, title?: string) {
        this._toastEvents.next({ message: message, title: title, type: ToastType.Error });
    }
    showWarning(message: string, title?: string) {
        this._toastEvents.next({ message: message, title: title, type: ToastType.Warning });
    }
    showInfo(message: string, title?: string) {
        this._toastEvents.next({ message: message, title: title, type: ToastType.Info });
    }

}
