<h4 *ngIf="(reportInfo$ | async).CategoryDescription" class="categoryTitle">
    Category: {{ (reportInfo$ | async).categoryDescription }}</h4>
<h1>Questions</h1>
<p><i class="icon-cers-required"></i><i class="fontRed">Required</i></p>
<div>
    <app-aa-spinner [loading]="isLoadingQuestions"></app-aa-spinner>
    <form #form="ngForm">
        <app-dynamic-form *ngIf="!isLoadingQuestions && questions && questions.length > 0"
            [profileId]="(reportInfo$ | async).profileId" [categoryId]="(reportInfo$ | async).categoryId"
            [groupName]="groupName" [questions]="questions">
        </app-dynamic-form>
    </form>
    <div class="wizardNavButtons btn-drop-container">
        <div class="left">
            <button class="btn-prev" (click)="previous()">Previous</button>
            <!--<button class="btn-save" (click)="saveReport()" *ngIf="newReportService.isPwa">Save</button>-->
        </div>
        <div class="right">
            <button class="btn-cancel" (click)="confirmCancel.displayModal()">Cancel</button>
            <button class="btn-next" (click)="next()">Next</button>
            <button class="btn-drop-cancel" (click)="confirmCancel.displayModal()">Cancel</button>
        </div>
    </div>
</div>
<app-confirm-navigate (navResult)="onNavigate($event)"></app-confirm-navigate>
<app-confirm-cancel></app-confirm-cancel>