import { Injectable } from '@angular/core';
import { IUser } from 'app/store/user/user.model';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, BehaviorSubject, Subject } from 'rxjs';
import { take, map, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { getUser } from 'app/store/user';
import {
    getSelectedCategory, getNotesSectionDetails, getAttachmentsSectionDetails, State
} from '@newreport/new-report-store';
import {
    IDynamicQuestion, NotesSectionModel, LoadAttachmentSettings, LoadAllQuestions, ResetGeneralQuestions
} from '../new-report-store/wizard';
import * as _ from 'lodash';
import { FormGroup } from '@angular/forms';
import { NotificationService } from 'app/shared/error-handler-notify/services';
import { SectionInfo } from 'app/shared/reportStructure/categoryDetails/reportSections';

export interface IReportInfo {
    employeeId: number;
    profileId: number;
    categoryId: number;
    categoryDescription: string;
}

export interface IFocusKeys {
    questionId: number;
    questionMappingId: number;
    skip?: boolean;
}

export interface IMaxInvolvements {
    questionMappindId: number;
    maxInvolvements: number;
    involvementsAdded: number;
}

@Injectable()
export class RandomNumberGenerator {
    private generatedNumbers = new Set();

    constructor(
    ) { }

    getUniqueNumber() {
        let num = Math.random();
        while (this.generatedNumbers.has(num)) {
            num = Math.random();
        }
        this.generatedNumbers.add(num);
        return num;
    }

    removeUniqueNumber(num) {
        this.generatedNumbers.delete(num);
    }
}

@Injectable()
export class FocusService {
    private focusKey = new BehaviorSubject<IFocusKeys>(null);

    constructor(
        private notificationService: NotificationService
    ) { }

    setFocusKey(keys: IFocusKeys) {
        this.focusKey.next(keys);
    }

    getFocusKey(): Observable<IFocusKeys> {
        return this.focusKey.asObservable();
    }

    getFirstInvalidKey(questions: Array<IDynamicQuestion>, form: FormGroup): IFocusKeys {
        let answered = null;
        if (questions) {
            for (const question of questions) {
                if (question && question.questionMappingId) {
                    if (!form.controls[question.questionMappingId].valid) {
                        return { questionId: question.questionId, questionMappingId: question.questionMappingId };
                    }
                    if (question.followupQuestions) {
                        answered = this.getFirstInvalidKey(question.followupQuestions, form);
                    }
                    if (question.answerInputType && question.answerInputType.toUpperCase() === 'CHECKBOX') {
                        for (const answer of question.answers) {
                            if (answer.followupQuestions) {
                                answered = this.getFirstInvalidKey(answer.followupQuestions, form);
                            }
                            if (answered) {
                                break;
                            }
                        }
                    }
                    if (answered) {
                        break;
                    }
                }
            }
        }
        return answered;
    }
    displayErrorMessage(numberOfNexts: number) {
        if (numberOfNexts < 5) {
            this.notificationService.showWarning('Some required fields are incomplete.');
        } else {
            this.notificationService.showInfo(
                'Some required fields are incomplete. Page must be complete to navigate with NEXT button.');
        }
    }
}

@Injectable()
export class WizardService {

    private reportInfo$: Observable<IReportInfo>;
    private user$: Observable<IUser>;
    public notesSection: NotesSectionModel;
    private waitingToLoadNotes: boolean;
    destroy$: Subject<void> = new Subject<void>();


    constructor(
        private store: Store<State>,
        private router: Router
    ) {
        this.reportInfo$ = this.getUserAndCategory();
        this.user$ = this.store.select(getUser);
        this.waitingToLoadNotes = false;
        this.getNotesSection().pipe(takeUntil(this.destroy$)).subscribe(v => {
            if (v.attachments && v.notes && this.waitingToLoadNotes) {
                this.loadNotesNow();
                this.waitingToLoadNotes = false;
            }
            this.notesSection = v;
        });
    }

    getUser(): Observable<IUser> {
        return this.user$;
    }

    getUserAndCategory(): Observable<IReportInfo> {

        // combineLatest needs to use pipe and map
        return combineLatest(
            [this.store.select(getUser),
            this.store.select(getSelectedCategory)]).pipe(
                map(results => ({
                    employeeId: results[0] ? results[0].id : null,
                    profileId: results[0] ? results[0].profileId : null,
                    categoryId: results[1] ? results[1].id : null,
                    categoryDescription: results[1] ? results[1].description : null,
                }))
            );
    }

    getNotesSection() {
        // combineLatest needs to use pipe and map
        return combineLatest(
            [this.store.select(getNotesSectionDetails),
            this.store.select(getAttachmentsSectionDetails)]).pipe(
                map(results => ({
                    notes: results[0], attachments: results[1]
                })));
    }

    getReportInfo(): Observable<IReportInfo> {
        return this.reportInfo$;
    }

    loadAllQuestions() {
        let info: IReportInfo;
        this.reportInfo$.pipe(take(1)).subscribe(i => info = i);
        if (info.profileId && info.categoryId) {
            this.store.dispatch(
                new LoadAllQuestions(info.profileId, info.categoryId)
            );
        } else {
            this.router.navigate(['newreport']);
        }
    }

    resetGeneralQuestions() {
        this.store.dispatch(new ResetGeneralQuestions());
    }

    loadNotes() {
        if (!this.notesSection.notes || !this.notesSection.attachments) {
            this.waitingToLoadNotes = true;
        } else {
            this.loadNotesNow();
        }
    }

    loadNotesNow() {
        let info: IReportInfo;
        this.reportInfo$.pipe(take(1)).subscribe(i => info = i);
        if (info.profileId || info.categoryId) {
            // this.store.dispatch(new LoadNotes(info.profileId, info.categoryId, this.notesSection));
            this.store.dispatch(new LoadAttachmentSettings);
        } else {
            this.router.navigate(['newreport']);
        }
    }
}
