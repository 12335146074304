import {
    Directive, Input, ViewContainerRef, OnInit,
    ComponentFactoryResolver, OnChanges, ComponentRef, EventEmitter, Output
} from '@angular/core';
import { AutotextComponent } from './autotext/autotext.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DateComponent } from './date/date.component';
import { RadioComponent } from './radio/radio.component';
import { SelectComponent } from './select/select.component';
import { TextComponent } from './textbox/textbox.component';
import { TextareaComponent } from './textarea/textarea.component';
import { TimeComponent } from './time/time.component';
import * as _ from 'lodash';
import { IDynamicQuestion } from '../new-report-store/wizard';
import { NumberComponent } from './number/number.component';
import { MultiGroupComponent } from './group/multi-group.component';
import { OptionalGroupComponent } from './group/optional-group.component';
import { GroupComponent } from './group/group.component';
// import { MultiInputComponent } from './multi-input/multi-input.component';

export const templateTypes = {
    DATE: DateComponent,
    TEXTAREA: TextareaComponent,
    RADIO: RadioComponent,
    RADIOBUTTON: RadioComponent,
    DROPDOWN: SelectComponent,
    READONLY: TextComponent,
    TEXTBOX: TextComponent,
    AUTOPOPULATETEXT: AutotextComponent,
    CHECKBOX: CheckboxComponent,
    TIME: TimeComponent,
    NUMBER: NumberComponent,
    INVOLVEMENT: GroupComponent,
    QUESTIONGROUP: GroupComponent,
    MULTI_GROUP: MultiGroupComponent,
    OPTIONAL_GROUP: OptionalGroupComponent,
    // MULTI: MultiInputComponent
};

@Directive({
    selector: '[appDynamicQuestion]',
})
export class DynamicQuestionDirective implements OnInit, OnChanges {
    @Input() question: IDynamicQuestion;
    @Input() profileId: number;
    @Input() categoryId: number;
    @Input() groupName: string;
    @Output() answerEvent$: EventEmitter<any> = new EventEmitter();
    @Output() multiGroupEvent$: EventEmitter<any> = new EventEmitter();
    @Output() optionalGroupEvent$: EventEmitter<any> = new EventEmitter();
    @Output() removeGroupEvent$: EventEmitter<any> = new EventEmitter();

    component: ComponentRef<{
        question: IDynamicQuestion,
        profileId: number,
        categoryId: number,
        groupName: string,
        answerEvent$: EventEmitter<any>,
        multiGroupEvent$: EventEmitter<any>,
        optionalGroupEvent$: EventEmitter<any>,
        removeGroupEvent$: EventEmitter<any>,
        questionAttrs: object
    }>;

    constructor(
        private container: ViewContainerRef,
    ) { }

    ngOnChanges() {
        if (this.component) {
            this.component.instance.question = this.question;
            this.component.instance.profileId = this.profileId;
            this.component.instance.categoryId = this.categoryId;
            this.component.instance.groupName = this.groupName;
            this.component.instance.answerEvent$ = this.answerEvent$;
            this.component.instance.multiGroupEvent$ = this.multiGroupEvent$;
            this.component.instance.optionalGroupEvent$ = this.optionalGroupEvent$;
            this.component.instance.removeGroupEvent$ = this.removeGroupEvent$;
            this.component.instance.questionAttrs = this.question.attrs;
        }
    }

    ngOnInit() {
        // do not render hidden involvements.
        // This is when we have reached the max number of that involvement type and the user is not allowed to add any more.

        // console.log('in directive');
        // console.log(this.question);
        if (this.question.answerInputType.toUpperCase() === 'HIDDEN_GROUP' ||
            this.question.answerInputType.toUpperCase() === 'BACKUP_GROUP' ||
            this.question.answerInputType.toUpperCase() === 'BACKUP_MULTI') {
            return;
        }

        if (this.question.answerInputType.toUpperCase() === 'INVOLVEMENT' || this.question.answerInputType.toUpperCase() === 'QUESTIONGROUP') {
            this.question.userAnswer = this.question.answers[0];
        }

        if (this.question.attrs) {
            for (const key of Object.keys(this.question.attrs)) {
                if (this.question.attrs[key] === null) {
                    delete this.question.attrs[key];
                }
            }
        }
        const attributes = this.question.attrs ?? new Object();

        if (attributes &&
            attributes['fieldName']?.toLowerCase() === 'flightnumber') {
            this.question.answerInputType = 'NUMBER';
            attributes['minValue'] = 1;
            attributes['maxValue'] = 9999;
        }

        const component = templateTypes[this.question.answerInputType.toUpperCase()];

        this.component = this.container.createComponent(component);
        this.component.instance.question = this.question;
        this.component.instance.profileId = this.profileId;
        this.component.instance.categoryId = this.categoryId;
        this.component.instance.groupName = this.groupName;
        this.component.instance.answerEvent$ = this.answerEvent$;
        this.component.instance.multiGroupEvent$ = this.multiGroupEvent$;
        this.component.instance.optionalGroupEvent$ = this.optionalGroupEvent$;
        this.component.instance.removeGroupEvent$ = this.removeGroupEvent$;
        this.component.instance.questionAttrs = attributes;

        if ((this.question.answerInputType.toUpperCase() === 'INVOLVEMENT' ||
            this.question.answerInputType.toUpperCase() === 'QUESTIONGROUP' ||
            this.question.answerInputType.toUpperCase() === 'MULTI_GROUP' ||
            this.question.answerInputType.toUpperCase() === 'OPTIONAL_GROUP')
            && this.question.followupQuestions === undefined) {
            this.component.instance.answerEvent$.emit({
                question: this.question,
                answer: this.question.userAnswer
            });
        }
    }
}
