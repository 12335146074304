import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'app/shared/error-handler-notify/services';
import { Store } from '@ngrx/store';
import { State } from 'app/store';
import * as Actions from '../category-management-store/tag/tag.actions';
import { ITagDetails, ITagErrors } from '../category-management-store/tag';
import { takeUntil } from 'rxjs/operators';
import { getUpdateTagError, getUpdateTagSuccess } from '../category-management-store';
import { Subject } from 'rxjs';
import { availableTagDescription } from '../question-tag-management/question-tag-management.component';


@Component({
    selector: 'app-edit-tag-modal',
    templateUrl: './edit-tag-modal.component.html',
    styleUrl: './edit-tag-modal.component.scss'
})

export class EditTagModalComponent {
    @Input() showSaveAndApplyButton: boolean = true;
    @Output() actionAddTagMapping: EventEmitter<any> = new EventEmitter();
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Input() tagData: availableTagDescription; // tag data to edit

    destroy$: Subject<void> = new Subject<void>();

    opened: boolean;    // indicates if the main window is open or closed
    confirmExitDialogOpened: boolean;    // indicates if the confirm exit popup us open or closed
    editTagFormGroup!: FormGroup;   // new editTag form group

    // max length values for the fields
    displayMaxLength = 100;
    descriptionMaxLength = 200;

    // character count for the fields
    displayCharCount: number;
    descriptionCharCount: number;
    
    // error text to display below the field
    descriptionError = '';
    displayError = '';

    constructor(public notificationService: NotificationService, private store: Store<State>,) { }
    ngOnInit(): void {
        this.open();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private open(): void {
        this.resetErrorHints();
        this.initializeCharCounts();
        this.editTagFormGroup = new FormGroup({
            name: new FormControl({ value: this.tagData.tagVal, disabled: true }),
            display: new FormControl(this.tagData.tagDisplayTxt, { nonNullable: true, validators: [Validators.required, Validators.maxLength(this.displayMaxLength), Validators.minLength(1)] }),
            description: new FormControl(this.tagData.tagDescription, { nonNullable: true, validators: [Validators.required, Validators.maxLength(this.descriptionMaxLength), Validators.minLength(1)] }),
            systemRequired: new FormControl(this.tagData.systemRequired, { nonNullable: true, validators: [Validators.required] }),
            pii: new FormControl(this.tagData.piiInd, { nonNullable: true, validators: [Validators.required] }),
        });
        this.opened = true; // opens the window
    }

    private initializeCharCounts() {
        this.displayCharCount = this.tagData.tagDisplayTxt.length;
        this.descriptionCharCount = this.tagData.tagDescription.length;
    }
    
    private resetErrorHints() {
        this.displayError = '';
        this.descriptionError = '';
    }

    private close(): void {
        this.onClose.emit();
    }

    // updates for the char counts
    public onDisplayValueChange(ev: string): void {
        this.displayCharCount = ev.length;
    }
    public onDescriptionValueChange(ev: string): void {
        this.descriptionCharCount = ev.length;
    }

    // save, save & apply, and discard actions
    public onSaveAndApply() {
        this.onSave(true);
    }
    public onSave(mapQuestion?: boolean) {
        this.resetErrorHints();

        if (!this.validateForm()) {
            this.editTagFormGroup.markAllAsTouched();
            this.notificationService.showError("Please complete all required fields.");
            return;
        }

        const editedTag: ITagDetails = {
            tagVal: this.editTagFormGroup.get('name')?.value,
            tagDescription: this.editTagFormGroup.get('description')?.value,
            tagDisplayTxt: this.editTagFormGroup.get('display')?.value,
            systemRequired: this.editTagFormGroup.get('systemRequired')?.value,
            piiInd: this.editTagFormGroup.get('pii')?.value,
        }

        this.store.dispatch(new Actions.UpdateTag({ tag: editedTag }));

        this.store.select(getUpdateTagSuccess).pipe(takeUntil(this.destroy$)).subscribe(success => {
            if (success) {
                if (mapQuestion) {
                    this.actionAddTagMapping.emit(editedTag.tagVal);
                }
                this.close();
            }
        });

        this.store.select(getUpdateTagError).pipe(takeUntil(this.destroy$)).subscribe(errors => {
            const errorsObject = errors as ITagErrors;
            for (const key of Object.keys(errorsObject)) {
                if (errors[key]) {
                    if (key == 'TagDescription' ) {
                        this.descriptionError = errors[key][0];
                    }
                    if (key == 'TagDisplayTxt') { 
                        this.displayError = errors[key][0];
                    }
                }
            }
        });
    }

    public onDiscard() {
        // check if any fields have been changed from their default values
        // if so, then display a popup asking if the user really wants to navigate away and lose their changes
        for (let key of Object.keys(this.editTagFormGroup.controls)) {
            const currentValue = this.editTagFormGroup.get(key)?.value; // if not found then equals undefined
            let defaultValue = null;
            switch (key) {
                case ('name'):
                    defaultValue = this.tagData.tagVal;
                    break;
                case ('display'):
                    defaultValue = this.tagData.tagDisplayTxt;
                    break;
                case ('description'):
                    defaultValue = this.tagData.tagDescription;
                    break;
                case ('pii'):
                    defaultValue = this.tagData.piiInd;
                    break;
                case ('systemRequired'):
                    defaultValue = this.tagData.systemRequired;
                    break;
                default: {
                    defaultValue == null; // if it ends up here then we have a big problem
                }
            }

            // the field has been changed (i.e. no longer equals default value)
            // open the dialog asking if the user is sure they want to exit and lose their changes
            if (currentValue !== defaultValue) {
                this.confirmExitDialogOpened = true;
                this.opened = false;
                return;
            }
        }
        this.close();
    }

    // yes or no exit the main popup. this comes from the confirm exit popup
    public onConfirmExit(exit: boolean) {
        if (exit) { // we are exiting out of the process
            this.confirmExitDialogOpened = false; // close the dialog
            this.close();
            return;
        }
        // we want to return to the edit tag popup
        this.confirmExitDialogOpened = false;
        this.opened = true;
    }

    private validateForm(): boolean {
        const isNameValid = this.editTagFormGroup.controls['name'].errors === null;
        const isDisplayValid = this.editTagFormGroup.controls['display'].errors === null;
        const isDescriptionValid = this.editTagFormGroup.controls['description'].errors === null;
        const isSystemRequiredValid = this.editTagFormGroup.controls['systemRequired'].errors === null;
        const isPiiValid = this.editTagFormGroup.controls['pii'].errors === null;

        return isNameValid && isDisplayValid && isDescriptionValid && isSystemRequiredValid && isPiiValid;
    }
}
