import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-report-detail-section',
    templateUrl: './report-detail-section.component.html',
    styleUrls: ['./report-detail-section.component.scss']
})
export class ReportDetailSectionComponent implements OnInit, OnDestroy {
    collapseIcon = 'icon-collapse icon-medium';
    expandIcon = 'icon-expand icon-medium';
    iconStyle = this.expandIcon;
    destroy$: Subject<void> = new Subject<void>();
    @Input() section: string;
    @Input() title: string;
    @Input() allExpandState?: Subject<boolean>;

    constructor() { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        if (this.allExpandState) {
            this.allExpandState.pipe(takeUntil(this.destroy$)).subscribe((expanded) => {
                if (expanded) {
                    this.iconStyle = this.expandIcon;
                } else {
                    this.iconStyle = this.collapseIcon;
                }
            });
        }
    }
    toggleIcon(): void {
        if (this.iconStyle === this.expandIcon) {
            this.iconStyle = this.collapseIcon;
        } else {
            this.iconStyle = this.expandIcon;
        }
    }

}
