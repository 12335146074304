import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getUser, State } from 'app/store';
import { getCategoryDetails, getSelectedQuestionMappingText, getAllTags, getQuestionMappingTags, getSelectedCategory, getAddTagToQuestionMappingError } from '../category-management-store';
import * as Actions from '../category-management-store/tag/tag.actions';
import { LoadCategoryDetail } from '../category-management-store/detail';
import { DynamicQuestionsService } from 'app/shared/SSEHubClient/dynamic-questions.service';
import { CompositeFilterDescriptor, filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import { LoggerService } from 'app/shared/error-handler-notify/services';
import { ITagDetails } from '../category-management-store/tag';
import { FilterService, GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { IUser, User } from 'app/store/user';

export interface availableTagDescription extends ITagDetails {
    isMapped: boolean;
    isActiveTag: boolean;
}
@Component({
    selector: 'app-question-tag-management',
    templateUrl: './question-tag-management.component.html',
    styleUrl: './question-tag-management.component.scss'
})

export class QuestionTagManagementComponent implements OnInit, OnDestroy {
    destroy$: Subject<void> = new Subject<void>();
    category$: Observable<string>;
    categoryId: number;
    profileId: number;
    questionMappingId: string;
    mappedTagVals: string[] = [];
    availableTags: availableTagDescription[] = []; // Original data
    filteredTags: availableTagDescription[] = []; // Filtered data for grid
    selectedTags: string[] = [];
    questionText$: Observable<string>;
    isITUser: boolean = false;
    tagToRemove: any;
    questionMappingErrorMessage: string
    searchTerm: string = '';
    statusOptions = [{ text: 'Active', value: true }, { text: 'Inactive', value: false }];
    booleanOptions = [{ text: 'Yes', value: true }, { text: 'No', value: false }];
    defaultOption = { text: 'Select', value: null };
    isCreateNewTagModalOpen = false;
    confirmRemoveTagDialogOpened = false;
    isEditTagModalOpen = false;
    selectedEditTag: availableTagDescription;
    addTagUserErrorModalOpened = false; // Flag to open the error modal
    @ViewChild('grid') grid: GridComponent;

    constructor(
        private store: Store<State>,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private dynamicQuestionsService: DynamicQuestionsService,
        private logger: LoggerService,
    ) { }

    // Only IT Admins should be able to see System tags.
    public setPrivileges(user: IUser) {
        if (user) {
            this.isITUser = User.hasPrivilege(user, 'WEB_SUADMIN');
        }
    }

    ngOnInit() {
        this.store.select(getUser).pipe(
            take(1)
        ).subscribe(user => {
            this.setPrivileges(user);
        });

        this.activatedRoute.paramMap.pipe(
            switchMap(params => {
                this.profileId = +params.get('profileId');
                this.categoryId = +params.get('categoryId');
                this.questionMappingId = params.get('questionMappingId');

                this.category$ = this.getCategoryDetails(this.profileId, this.categoryId);
                this.questionText$ = this.getQuestionMetadata(this.questionMappingId);

                return this.prepareTagCollections();
            }),
            takeUntil(this.destroy$)
        ).subscribe(tags => {
            this.availableTags = tags.filter(tag => {
                const activeTag = this.checkTagActiveStatus(tag.effectiveDate, tag.inactiveDate);
                // If isITUser then show all tags (active and inactive), else show only active non-system tags.
                return this.isITUser || (activeTag && !tag.systemRequired);
            }).map(tag => ({
                ...tag,
                isActiveTag: this.checkTagActiveStatus(tag.effectiveDate, tag.inactiveDate)
            }))
            this.searchTags(false);
        });

        this.store.select(getAddTagToQuestionMappingError).pipe(
            takeUntil(this.destroy$)
        ).subscribe(errorMessage => {
            if (errorMessage && errorMessage !== "") {
                this.questionMappingErrorMessage = errorMessage;
                this.addTagUserErrorModalOpened = true;
            }
        });
    }

    // Fetch the category details from the store's getSelectedCategory selector if not available in the store
    // then dispatch an action to load the category details and select from getCategoryDetails selector
    getCategoryDetails(profileId: number, categoryId: number): Observable<string> {
        return this.store.select(getSelectedCategory).pipe(
            switchMap(category => {
                if (category) {
                    return of(category);
                } else {
                    this.store.dispatch(new LoadCategoryDetail(profileId, categoryId));
                    return this.store.select(getCategoryDetails).pipe(map(category => category.description));
                }
            })
        );
    }


    prepareTagCollections() {
        // Checking privilege.
        const suAdmin = this.isITUser;
        if (suAdmin) {
            // Dispatch actions to load all tags and mapped tags
            // Fetch all tags and mapped tags for IT users.
            this.store.dispatch(new Actions.LoadAllTags({ includeSystemRequired: null, includeInactive: null }));
            this.store.dispatch(new Actions.LoadTagsForQuestionMapping({ questionMappingId: this.questionMappingId, includeSystemRequired: true }));
        } else {
            // Dispatch actions to load all tags and mapped tags
            // Fetch only non-system tags for non-IT users.
            this.store.dispatch(new Actions.LoadAllTags({ includeSystemRequired: false, includeInactive: false }));
            this.store.dispatch(new Actions.LoadTagsForQuestionMapping({ questionMappingId: this.questionMappingId, includeSystemRequired: false }));
        }
        // Select the data from the store
        return combineLatest({
            allTags: this.store.select(getAllTags),
            mappedTags: this.store.select(getQuestionMappingTags)
        }).pipe(
            debounceTime(0),
            map(({ allTags, mappedTags }) => {
                if (allTags.length > 0) {
                    // Filter tags based on privileges.
                    return this.getAvailableTagsToMap(allTags, mappedTags);
                }
            })
        );
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getQuestionMetadata(questionMappingId: string): Observable<string> {
        return this.store.select(getSelectedQuestionMappingText).pipe(
            switchMap(questionText => {
                if (questionText) {
                    // questionText is available in the store
                    return of(questionText);
                } else {
                    // questionText is not available in the store, make a network call
                    return this.dynamicQuestionsService.getQuestionsMetaData(questionMappingId).pipe(
                        map(data => {
                            return data.question;
                        }),
                        catchError(error => {
                            this.logger.error('Error fetching question metadata:', error);
                            return of(null);
                        })
                    );
                }
            })
        );
    }

    getAvailableTagsToMap(allTags, mappedTags): availableTagDescription[] {
        const mappedTagVals = new Set(mappedTags.map(tag => tag.tagVal));
        //store the mapped tag values for showing the mapped tags in the multiselect
        this.mappedTagVals = Array.from(mappedTagVals) as string[];
        this.selectedTags = Array.from(mappedTagVals) as string[];
        return allTags.map(tag => ({ ...tag, isMapped: mappedTagVals.has(tag.tagVal) }))
    }

    gotoActiveCategory() {
        this.router.navigate(['/admin/categorymanagement/profile/' + this.profileId + '/category/' + this.categoryId + '/summary']);
    }

    searchTags(resetGrid: boolean): void {
        if (!this.searchTerm) {
            this.filteredTags = [...this.availableTags];
        } else {
            const searchFilters: FilterDescriptor[] = [{
                field: "tagVal",
                operator: "contains",
                value: this.searchTerm
            },
            {
                field: "tagDisplayTxt",
                operator: "contains",
                value: this.searchTerm
            },
            {
                field: "tagDescription",
                operator: "contains",
                value: this.searchTerm
            }];

            let compositeFilter: CompositeFilterDescriptor = { logic: "or", filters: searchFilters };
            this.filteredTags = filterBy(this.availableTags, compositeFilter);
        }
        if (resetGrid) {
            this.grid.pageChange.emit({ skip: 0, take: this.grid.pageSize });
        }
    }

    getCurrentFilterValue(
        filter: CompositeFilterDescriptor,
        fieldName: string
    ): any {
        const currentFilter = (filter.filters as FilterDescriptor[]).find(
            (f) => f.field === fieldName
        ) as FilterDescriptor;

        return currentFilter ? currentFilter.value : null;
    }

    onFilterChange(
        value: boolean,
        filterService: FilterService,
        fieldName: string
    ): void {
        filterService.filter({
            filters: [{ field: fieldName, operator: 'eq', value: value }],
            logic: 'or',
        });
    }

    // Check if the tag is active based on effective and inactive dates.
    private checkTagActiveStatus(effectiveDate: Date, inactiveDate?: Date): boolean {
        const currentDate = new Date();
        if (!inactiveDate) {
            return true;
        }
        return effectiveDate <= currentDate && inactiveDate >= currentDate;
    }

    public toggleCreateNewTagDialog(open: boolean): void {
        this.isCreateNewTagModalOpen = open;
    }

    removeTag(tag: any): void {
        this.tagToRemove = tag;
        this.confirmRemoveTagDialogOpened = true;
    }

    public onRemoveTagConfirm(remove: boolean): void {
        this.confirmRemoveTagDialogOpened = false;

        if (!remove) {
            return;
        }
        if (this.tagToRemove && this.questionMappingId) {
            const actionToRemoveQuestionTag = new Actions.RemoveTagFromQuestionMapping({
                questionMappingId: this.questionMappingId,
                tagVal: this.tagToRemove,
                includeSystemRequired: this.isITUser
            });
            this.store.dispatch(actionToRemoveQuestionTag);
        } else {
            this.logger.error('Tag or questionMappingId is not set');
        }
    }


    public onApply() {
        //selectedtags is the  array of selectedTags minus the mappedtagvals
        const selectedTags = this.selectedTags.filter((tagVal) => !this.mappedTagVals.includes(tagVal));
        this.applyTagMappings(selectedTags);
    }

    public applyTagMappings(tags: string[]): void {
        this.store.dispatch(new Actions.AddTagToQuestionMapping({
            questionMappingId: this.questionMappingId,
            tagVal: tags,
            includeSystemRequired: this.isITUser
        }));
    }

    public onEdit(dataItem: any): void {
        if (!this.isEditTagModalOpen) {
            this.isEditTagModalOpen = true;
            this.selectedEditTag = dataItem;
        }
    }

    public handleEditModalClose() {
        this.isEditTagModalOpen = false;
    }

    public closeAddTagErrorModal() {
        this.addTagUserErrorModalOpened = false;
    }

    public myRowSelectionKey(context): string {
        return context.dataItem.tagVal;
    };

    public rowClass = (args: RowClassArgs) => {
        if (args.dataItem.isMapped) {
            return { 'k-selected': false };
        }
    };
    
    itemDisabled(itemArgs: { dataItem: any; index: number }) {
        return itemArgs.dataItem.value === null;
    }
}
