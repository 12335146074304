<h4 *ngIf="(reportInfo$ | async).categoryDescription" class="categoryTitle">
    Category: {{ (reportInfo$ | async).categoryDescription }}</h4>
<h1>Notes</h1>
<p> <i class="icon-cers-required"></i> <i class="fontRed">Required</i> </p>
<div>
    <app-aa-spinner [loading]="isLoading"></app-aa-spinner>
    <form #form="ngForm">
        <app-dynamic-form *ngIf="!isLoading && notesQuestions && notesQuestions.length > 0"
            [profileId]="(reportInfo$ | async).profileId" [categoryId]="(reportInfo$ | async).categoryId"
            [groupName]="groupName" [questions]="notesQuestions">
        </app-dynamic-form>
    </form>
    <div *ngIf="attachmentVisibility !== 'hidden'">
        <h2 class="additionalInfo">Attachments</h2>
        <p class="errorText" *ngIf="attachmentVisibility === 'required'">Required</p>
        <p class="errorText" *ngIf="this.attachments.length >= this.maxAttachments">
            The number of attachments is limited to {{maxAttachments}}
        </p>
        <div *ngIf="attachments && attachments.length > 0" class="attachments">
            <app-attachment-view [canEdit]="true"></app-attachment-view>
        </div>
        <div #focusAttachments *ngIf="this.attachments.length < this.maxAttachments"
            [ngClass]="{'borderInvalid': displayInvalidAttachmentsBorder, 'borderValid': !displayInvalidAttachmentsBorder}">
            <input class="hidden" type="file" name="file" (change)="onFileSelect($event)" #fileInput>
            <div (click)="fileInput.click(); removeInvalidAttachmentsBorder();" class="addnew">
                <i class="icon-small icon-add"></i>
                <a style="padding-left: 5px">Add attachment</a>
            </div>
        </div>
    </div>
    <div class="wizardNavButtons btn-drop-container">
        <div class="left">
            <button class="btn-prev" (click)="previous()">Previous</button>
            <!--<button class="btn-save" (click)="saveReport()" *ngIf="newReportService.isPwa">Save</button>-->
        </div>
        <div class="right">
            <button class="btn-cancel" (click)="confirmCancel.displayModal()">Cancel</button>
            <button class="btn-next" (click)="next()">Next</button>
            <button class="btn-drop-cancel" (click)="confirmCancel.displayModal()">Cancel</button>
        </div>
    </div>
</div>
<app-confirm-navigate (navResult)="onNavigate($event)"></app-confirm-navigate>
<app-confirm-cancel></app-confirm-cancel>