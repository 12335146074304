<div *ngIf="involvement">
    <a href="#{{ involvement.personSk }}" data-bs-toggle="collapse" aria-expanded="false"
        aria-controls="{{ involvement.personSk }}" class="btn involvement-link"
        (click)="displayInvolvement = !displayInvolvement" role="button">
        <i [ngClass]="{'icon-expand' : !displayInvolvement, 'icon-collapse': displayInvolvement }"></i>
        <h4 class="involvement-icon-text"> {{ nameToDisplay }} </h4>
    </a>
    <div class="involvements">
        <div [id]="involvement.personSk" class="collapse">
            <div class="row">
                <div *ngFor="let section of personDetailsArray" [class]='columnsClass'>
                    <h4 class="section-header"> {{ section.sectionTitle }} </h4>
                    <div *ngFor=" let question of section.details" class="summary-item">
                        <span class="summary-header">{{ question.question }}</span>
                        <span class="summary-value">{{ question.answer }}</span>
                    </div>
                </div>
            </div>
            <div *ngIf="involvement.followupQuestions && involvement.followupQuestions.length > 0" class="row">
                <div class="involvement-questions col-sm-12">
                    <h4 class="section-header">Questions</h4>
                    <app-question-list [questionsAndAnswers]="involvement.followupQuestions"></app-question-list>
                </div>
            </div>
        </div>
    </div>
</div>