<modal #confirmModal>
    <modal-header>
        <h4>{{title}}</h4>
    </modal-header>
    <modal-content>
        {{message}}
    </modal-content>
    <modal-footer>
        <button type="button" class="btn-cancel" (click)="cancel()">Cancel</button>
        <button type="button" class="btn-primary" (click)="ok()">Continue</button>
    </modal-footer>
</modal>
