import { Injectable } from '@angular/core';
import { switchMap, map, mergeMap, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { NotificationService } from '@shared/error-handler-notify/services/notification.service';
import { SSEHubReportHistoryService } from 'app/shared/SSEHubClient/report-history.service';
import { ReportHistoryService } from 'app/report-history/report-history.service';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import * as detailActions from './detail.actions';
import { IReportDetail, Narrative } from './detail.model';
import * as _ from 'lodash';

@Injectable()
export class ReportDetailEffects {

    
    getReportDetail$: Observable<Action> = createEffect(() => this.action$.pipe(
        ofType<detailActions.GetReportDetail>(detailActions.GET_REPORT_DETAIL),
        mergeMap(action => {
            return this.sseHubReportHistoryService.getReportDetail(action.reportId).pipe(
                map((result) => {
                    const parsedDetail: IReportDetail = this.parseDetail(result);
                    if (parsedDetail) {
                        return new detailActions.PopulateReportDetails(parsedDetail);
                    } else {
                        this.notificationService.showError('Report not found');
                        return new detailActions.ReportDetailError('Report not found');
                    }
                }),
                catchError(e => {
                    console.log(e);
                    this.notificationService.showError('Error loading report details');
                    return of(new detailActions.ReportDetailError('Error loading report details'));
                })
            );
        })
    ));

    
    getReportSearchDetail$: Observable<Action> = createEffect(() => this.action$.pipe(
        ofType<detailActions.GetReportSearchDetail>(detailActions.GET_REPORT_SEARCH_DETAIL),
        mergeMap(action => {
            const etn = action.reportIdOrEtn.length === 6 ? action.reportIdOrEtn : null;
            const reportId = action.reportIdOrEtn.length === 10 ? action.reportIdOrEtn : null;
            return this.sseHubReportHistoryService.getReportSearchDetail(action.employeeId, reportId, etn).pipe(
                map((result) => {
                    const parsedDetail: IReportDetail = this.parseDetail(result);
                    if (parsedDetail) {
                        return new detailActions.PopulateReportDetails(parsedDetail);
                    } else {
                        this.notificationService.showError('Report not found');
                        return new detailActions.ReportDetailError('Report not found');
                    }
                }),
                catchError(e => {
                    console.log(e);
                    this.notificationService.showError('Report not found');
                    return of(new detailActions.ReportDetailError('Report not found'));
                })
            );
        })
    ));

    
    getReportDetailSectionLoader$: Observable<Action> = createEffect(() => this.action$.pipe(
        ofType<detailActions.GetReportDetail>(detailActions.GET_REPORT_DETAIL_SECTION_LOADER),
        mergeMap(action => {
            return this.sseHubReportHistoryService.getReportDetail(action.reportId).pipe(
                map((result) => {
                    const parsedDetail: IReportDetail = this.parseDetail(result);
                    if (parsedDetail) {
                        return new detailActions.PopulateReportDetails(parsedDetail);
                    } else {
                        this.notificationService.showError('Report not found');
                        return new detailActions.ReportDetailError('Report not found');
                    }
                }),
                catchError(e => {
                    this.notificationService.showError('Error loading report details');
                    return of(new detailActions.ReportDetailError('Error loading report details'));
                })
            );
        })
    ));

    
    sendRequestResponse$: Observable<Action> = createEffect(() => this.action$.pipe(
        ofType<detailActions.RequestResponse>(detailActions.SEND_REQUEST_RESPONSE),
        switchMap((action) => {
            return this.sseHubReportHistoryService.requestResponse(action.reportId, action.forwardObj).pipe(
                map((result) => {
                    if (result) {
                        return new detailActions.GetAdminDiscussion(action.reportId);
                    } else {
                        this.notificationService.showError('Error sending requeset');
                        return null;
                    }
                }),
                catchError(error => {
                    console.log(error);
                    this.notificationService.showError('Error sending requeset');
                    return of(null);
                })
            );
        })
    ));

    
    sendRespondToReq$: Observable<Action> = createEffect(() => this.action$.pipe(
        ofType<detailActions.RespondToRequest>(detailActions.SEND_RESPOND_TO_REQUEST),
        switchMap((action) => {
            return this.sseHubReportHistoryService.respondToRequest(action.reportId, action.respondObject).pipe(
                map((result) => {
                    if (result) {
                        return new detailActions.GetAdminDiscussion(action.reportId);
                    } else {
                        this.notificationService.showError('Error sending response');
                        return null;
                    }
                }),
                catchError(error => {
                    console.log(error);
                    this.notificationService.showError('Error sending response');
                    return of(null);
                })
            );
        })
    ));

    
    getAdminDiscussion$: Observable<Action> = createEffect(() => this.action$.pipe(
        ofType<detailActions.GetAdminDiscussion>(detailActions.GET_ADMIN_DISCUSSION),

        switchMap((action) => {
            return this.sseHubReportHistoryService.getAdminDiscussion(action.reportId).pipe(
                map((result) => {
                    const newDetail = this.parseAdmin(result);
                    return new detailActions.PopulateAdminDiscussion(newDetail);
                }),
                catchError(error => {
                    console.log(error);
                    this.notificationService.showError('Error getting Messages');
                    return of(new detailActions.GetAdminDiscussionError('Error getting Messages'));
                })
            );
        })
    ));

    
    getReportStatusList$: Observable<Action> = createEffect(() => this.action$.pipe(
        ofType<detailActions.GetReportStatusList>(detailActions.GET_REPORT_STATUS_LIST),
        switchMap((action) => {
            return this.sseHubReportHistoryService.getReportStatus().pipe(
                map((result: any) => {
                    if (result) {
                        return new detailActions.PopulateReportStatusList(result);
                    } else {
                        this.notificationService.showError('Unable to fetch Status-List');
                        return new detailActions.GetReportStatusListError('Unable to fetch Status-List');
                    }
                }),
                catchError(error => {
                    console.log(error);
                    this.notificationService.showError('Unable to fetch Status-List');
                    return of(new detailActions.GetReportStatusListError('Unable to fetch Status-List'));
                })
            );
        })
    ));

    
    getReportAuth$: Observable<Action> = createEffect(() => this.action$.pipe(
        ofType<detailActions.GetReportAuthorization>(detailActions.GET_REPORT_AUTH),
        switchMap((action) => {
            return this.sseHubReportHistoryService.getReportAuthorization(action.reportId, action.loggedinUser).pipe(
                map((user: any) => {
                    if (user) {
                        return new detailActions.PopulateReportAuthorization(user);
                    } else {
                        this.notificationService.showError('Error getting report auth');
                        return null;
                    }
                }),
                catchError(e => {
                    console.log(e);
                    this.notificationService.showError('Error getting report auth');
                    return of(null);
                })
            );
        })
    ));

    
    setReportStatus$: Observable<Action> = createEffect(() => this.action$.pipe(
        ofType<detailActions.SetReportStatus>(detailActions.SET_REPORT_STATUS),
        switchMap((action) => {
            return this.sseHubReportHistoryService.setReportStatus(action.reportId, action.reportStatus, action.responderId).pipe(
                map((result) => {
                    if (result) {
                        return new detailActions.GetReportDetail(action.reportId);
                    } else {
                        this.notificationService.showError('Error setting report status');
                        return null;
                    }
                }),
                catchError(e => {
                    console.log(e);
                    this.notificationService.showError('Error setting report status');
                    return of(null);
                })
            );
        })
    ));

    
    reportReply$: Observable<Action> = createEffect(() => this.action$.pipe(
        ofType<detailActions.AddReply>(detailActions.ADD_REPLY),
        switchMap((action) => {
            return this.sseHubReportHistoryService.reply(action.reportId, action.replyObject).pipe(
                map((result) => {
                    if (result) {
                        return new detailActions.GetReportDetailSectionLoader(action.reportId);
                    } else {
                        this.notificationService.showError('Error adding reply');
                        return null;
                    }
                }),
                catchError(e => {
                    console.log(e);
                    this.notificationService.showError('Error adding reply');
                    return of(null);
                })
            );
        })
    ));

    
    reportAttachemt$: Observable<Action> = createEffect(() => this.action$.pipe(
        ofType<detailActions.AddAttachment>(detailActions.ADD_ATTACHMENT),
        switchMap((action) => {
            return this.sseHubReportHistoryService.saveAttachment(action.reportId, action.attachmentObject).pipe(
                map((result) => {
                    if (result) {
                        return new detailActions.GetReportDetailSectionLoader(action.reportId);
                    } else {
                        this.notificationService.showError('Error adding attachment');
                        return null;
                    }
                }),
                catchError(e => {
                    console.log(e);
                    this.notificationService.showError('Error adding attachment');
                    return of(null);
                })
            );
        })
    ));

    
    reportNarrative$: Observable<Action> = createEffect(() => this.action$.pipe(
        ofType<detailActions.AddNarrative>(detailActions.ADD_NARRATIVE),
        switchMap((action) => {
            return this.sseHubReportHistoryService.addNarrative(action.narrativeObject).pipe(
                map((result) => {
                    if (result) {
                        return new detailActions.GetReportDetailSectionLoader(action.narrativeObject.reportId);
                    } else {
                        if (action.narrativeObject.narrativeTypeCd.endsWith('N')) {
                            this.notificationService.showError('Error adding description');
                        } else {
                            this.notificationService.showError('Error adding recommendation');
                        }
                        return null;
                    }
                }),
                catchError(e => {
                    console.log(e);
                    if (action.narrativeObject.narrativeTypeCd.endsWith('N')) {
                        this.notificationService.showError('Error adding description');
                    } else {
                        this.notificationService.showError('Error adding recommendation');
                    }
                    return of(null);
                })
            );
        })
    ));

    
    getRecipientList$: Observable<Action> = createEffect(() => this.action$.pipe(
        ofType<detailActions.GetRecipientList>(detailActions.GET_RECIPIENT_LIST),
        switchMap((action) => {
            return this.sseHubReportHistoryService.getReportRecipientList(action.reportId).pipe(
                map((recipientList) => {
                    if (recipientList) {
                        const newRecipList: any = recipientList;
                        return new detailActions.PopulateRecipientList(newRecipList);
                    } else {
                        this.notificationService.showError('Error getting Recipient-List');
                        return null;
                    }
                }),
                catchError(e => {
                    console.log(e);
                    this.notificationService.showError('Error getting Recipient-List');
                    return of(null);
                })
            );
        })
    ));

    
    getActivityLog$: Observable<Action> = createEffect(() => this.action$.pipe(
        ofType<detailActions.GetActivityLog>(detailActions.GET_ACTIVITY_LOG),
        switchMap((action) => {
            return this.sseHubReportHistoryService.getReportActivityLog(action.reportId).pipe(
                map((activityLog) => {
                    if (activityLog) {
                        const newActivityList: any = activityLog;
                        return new detailActions.PopulateActivityLog(newActivityList);
                    } else {
                        this.notificationService.showError('Unable to fetch Activity-Log');
                        return new detailActions.GetActivityLogError('Unable to fetch Activity-Log');
                    }
                }),
                catchError(e => {
                    console.log(e);
                    this.notificationService.showError('Unable to fetch Activity-Log');
                    return of(new detailActions.GetActivityLogError('Unable to fetch Activity-Log'));
                })
            );
        })
    ));

    parseAdmin(valueDiscussion) {
        valueDiscussion = valueDiscussion || [];

        const adminDetails = [];
        if (valueDiscussion.length) {
            valueDiscussion.forEach((d, i, a) => {
                adminDetails.push(
                    {
                        date: d.createDate,
                        submitter: d.author,
                        comment: d.comment,
                        responders: d.recipients,
                        request: d.type === 'FORWARD' ? true : false
                    }
                );
            });
        }
        return adminDetails;
    }

    parseDetail(detail) {
        let parsed: IReportDetail = null;

        if (detail && detail.reportId) {
            parsed = detail;

            // Default if null
            detail.responseRequested = detail.responseRequested || '-';
            detail.reportTypeDesc = detail.reportTypeDesc || '-';
            detail.permCrewBase = detail.permCrewBase || '-';
            detail.seat = detail.seat || '-';
            detail.equipment = detail.equipment || '-';
            detail.division = detail.division || '-';
            detail.reportStatusDesc = detail.reportStatusDesc || '-';

            detail.flight = detail.flight || {};
            detail.flight.interruptStation = detail.flight.interruptStation || '-';
            detail.flight.tailNumber = detail.flight.tailNumber || '-';
            detail.flight.fleetDescription = detail.flight.fleetDescription || '-';
            detail.flight.arrivalGate = detail.flight.arrivalGate || '-';
            detail.flight.arrivalStation = detail.flight.arrivalStation || '-';
            detail.flight.arrivalTerminal = detail.flight.arrivalTerminal || '-';
            detail.flight.departureGate = detail.flight.departureGate || '-';
            detail.flight.departureStation = detail.flight.departureStation || '-';
            detail.flight.departureTerminal = detail.flight.departureTerminal || '-';
            detail.flight.flightId = detail.flight.flightId || '-';
            detail.flight.flightPhaseDescription = detail.flight.flightPhaseDescription || '-';

            parsed.involvements.forEach((value, index) => value.personSk = index);

            this.reportHistoryService.reportDetailInfoLoaded(parsed);
        }

        return parsed;
    }


    constructor(
        private action$: Actions,
        private notificationService: NotificationService,
        private sseHubReportHistoryService: SSEHubReportHistoryService,
        private reportHistoryService: ReportHistoryService,
    ) { }

}
