import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'phone'
})

@Injectable()
export class PhoneNotificationPipe implements PipeTransform {
    constructor() { }

    transform(val: string): string {
        // transform phone number to (xxx)xxx-xxxx format
        return `(${val.substring(0, 3)}) ${val.substring(3, 6)}-${val.substring(6, 10)}`;
    }
}
