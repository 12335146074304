<div class="panel-group" id="accordion">
    <app-report-detail-section [section]="'attachment'" [title]="'Attachment'" [allExpandState]="allExpandState">
    </app-report-detail-section>
    <div #section id="attachment" class="panel-collapse collapse">
        <app-aa-spinner [loading]="attSummary.isLoading"></app-aa-spinner>
        <div *ngIf="!attSummary.isLoading" class="panel-body ">
            <div *ngFor="let reportAttachment of attSummary.attachments | SortByTimeStampPipe : 'CreatedDate'"
                class="attachments">
                <div class="attachment-timestamp">
                    <span *ngIf="attSummary.getSubmitterName(reportAttachment)">
                        {{attSummary.getSubmitterName(reportAttachment)}} on
                    </span> {{reportAttachment.createdDate | date:'MM/dd/yyyy'}} at
                    {{reportAttachment.createdDate | date:'shortTime'}}
                </div>
                <div class="attachment-title">
                    <a class="attachment-title"
                        (click)="attSummary.viewAttachment(reportAttachment)">{{reportAttachment.fileName}}</a>
                </div>
                <label class="attachment-description">{{reportAttachment.description}}</label>
            </div>
            <label *ngIf="attSummary.attachments.length <= 0"> No Attachment Provided</label>
            <app-add-attachment *ngIf="!isReportClosed()" [employeeId]="responderEmployeeId"
                [descriptionRequired]="true" (onSave)="onSave($event)"> </app-add-attachment>
        </div>
    </div>
</div>
