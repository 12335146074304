import { Injectable, OnDestroy } from '@angular/core';
import { SSEHubSettingsService } from '@ssehub/settings.service';
import { Flag } from './feature-flag.directive';
import { Observable, Subject, Subscriber } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface FlagSubscription {
    subscriber: Subscriber<any>;
    flag: string;
}

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagService implements OnDestroy {
    flags: Flag[] = [];
    hasLoaded = false;
    hasFlagSubs: FlagSubscription[] = [];
    getFlagSubs: FlagSubscription[] = [];
    destroy$: Subject<void> = new Subject<void>();

    constructor(private settingServices: SSEHubSettingsService) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    loadFlags() {
        this.hasLoaded = true;
        this.settingServices.get('web_flags').pipe(takeUntil(this.destroy$)).subscribe(settings => {
            if (settings) {
                this.flags = settings;
                this.hasFlagSubs.map(comp => {
                    this.hasFeatureFlagHelper(comp.subscriber, comp.flag);
                });
                this.getFlagSubs.map(comp => {
                    const f = this.getFlag(comp.flag);
                    comp.subscriber.next(f);
                });
            }
        });
    }
    // returns an observable and adds a local subscription to notify the observable
    // whether or not it has the flag available
    hasFeatureFlag(flag: string): Observable<boolean> {
        if (!this.hasLoaded) this.loadFlags();
        return new Observable<boolean>(subscriber => {
            this.hasFeatureFlagHelper(subscriber, flag);
            this.hasFlagSubs.push({ subscriber, flag });
        });
    }

    // returns an observable and adds a local subscription to give the flag
    // to the observable if it has it, otherwise it gives observable null
    getFeatureFlag(flag: string): Observable<Flag> {
        if (!this.hasLoaded) this.loadFlags();
        return new Observable<Flag>(subscriber => {
            const f = this.getFlag(flag);
            subscriber.next(f);
            this.getFlagSubs.push({ subscriber, flag });
        });
    }

    // return whether the specified flag exists and is enabled
    hasFeatureFlagHelper(observer: Subscriber<boolean>, featureFlag: string) {
        const f = this.getFlag(featureFlag);
        if (f && f.value && (Number(f.value) !== 0)) {
            observer.next(true);
        } else {
            observer.next(false);
        }
    }

    // find specific flag in array of flags and return it. Returns null if no match.
    getFlag(flag: string): Flag {
        let match = null;
        this.flags.map(f => { // look for the flag in avilable feature flags
            if (f.key.toUpperCase().includes(flag.toUpperCase())) {
                match = f;
            }
        });
        return match;
    }

}
