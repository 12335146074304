<div class="section-summary">
    <label>Section Visibility : </label>
    <div *ngIf="!isEditingSectionVisibility && verifySectionVisibility()">
        <label>{{getVisibilityDescription()}}</label>
        <div *ngIf="canEdit" (click)="onEditSectionVisibility()" class="edit-section-icon">
            <i class="icon-medium icon-edit"></i>
        </div>
    </div>
    <div *ngIf="!isEditingSectionVisibility && !verifySectionVisibility()">
        <label class="validation-fail">Set Section Visibility</label>
        <div *ngIf="canEdit" (click)="onEditSectionVisibility()" class="edit-section-icon">
            <i class="icon-medium icon-edit"></i>
        </div>
    </div>
    <div *ngIf="isEditingSectionVisibility" class="edit-section-visibility">
        <select name="sectionvisibility" id="sectionVisibility" class="form-control dropdown" [(ngModel)]="currentVisibility">
            <option *ngFor="let option of sectionVisibilityOptions" [value]="option.value">{{option.description}}</option>
        </select>
        <button type="button" class="button-cancel" (click)="onEditSectionVisibility()">Cancel</button>
        <button type="button" class="button-save" (click)="onSectionVisibilitySave()"> Save </button>
    </div>
</div>