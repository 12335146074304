import { Component, OnInit, OnDestroy } from '@angular/core';
import { ResetTodo, IReportHistory } from 'app/report-history/report-history-store/list';
import { State } from '@app/store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
    private resetTodo: IReportHistory;

    constructor(private store: Store<State>) {
        }

    ngOnInit() {
        this.store.dispatch(new ResetTodo(this.resetTodo));
  }

}
