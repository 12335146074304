import { Directive, Input, ElementRef, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors, ValidatorFn, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { getUser } from 'app/store/user';
import { ReportAssemblyService } from 'app/new-report/new-report.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { State } from '../new-report-store';

@Directive({
    selector: '[appDynamicValidation]',
    providers: [DatePipe, { provide: NG_VALIDATORS, useExisting: DynamicValidationDirective, multi: true }]
})
export class DynamicValidationDirective implements Validator, OnDestroy {
    @Input('appDynamicValidation') attrs: object;
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private el: ElementRef,
        private datePipe: DatePipe,
        private store: Store<State>,
        private newReportService: ReportAssemblyService,
    ) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    checkDuplicateString(emails: string[]): boolean {
        // Check for duplicates
        const sorted_arr = emails.slice().sort();
        let dup = false;
        for (let i = 0; i < sorted_arr.length - 1; i++) {
            if (sorted_arr[i + 1] === sorted_arr[i]) {
                dup = true;
            }
        }
        return dup;
    }
    validate(control: AbstractControl): ValidationErrors {
        const err: ValidationErrors = {};
        // this calls constantly, like the subscribe never resolves?

        let type = null; // get the input type
        if (this.el.nativeElement) {
            if (this.el.nativeElement.type) {
                type = this.el.nativeElement.type;
            } else if (this.el.nativeElement.tagName) {
                type = this.el.nativeElement.tagName.toLowerCase();
            }
        }

        // get the right value depending on input type
        let value = control.value;
        if (type === 'select-one') {
            value = (control.value) ? control.value.answerText : '';
            if (typeof (value) === 'string') {
                value = value.trim();
            }
        }
        if (typeof value === 'string' && (type === 'text' || type === 'textarea')) {
            value = (control.value) ? control.value : '';
            value = value.trim();
        }

        // Validation for limited search.
        if (this.attrs['limitedSearch']) {
            // Make sure the isRequired attribues also works within this.
            if (this.attrs && this.attrs['isRequired']) {
                if (value !== 0 && !value) {
                    err['isRequired'] = 'Required';
                }
            }
            if (!value) {
                err['isRequired'] = 'Required';
            }
        }

        // if its a checkbox and is required, iterate over parent controls to find one valid
        if (type === 'checkbox' && this.attrs && this.attrs['isRequired']) {
            const form: any = control.parent;
            let valid = false;
            if (form) {
                valid = _.find(form.controls, ['value', true]);
            }
            return (!valid) ? { isRequired: 'Required' } : {};
        }

        // required
        if (this.attrs && this.attrs['isRequired']) {
            if (value !== 0 && !value) {
                err['isRequired'] = 'Required';
            }
        }

        if (this.attrs && this.attrs['failedAPICall']) {
            err.failedAPICall = 'Failed to get followup question(s)';
        }
        if (type === 'number' && this.el.nativeElement.validity.badInput) {
            err.isNaN = 'Value must be a number';
        }

        if (this.attrs && (value || value === 0)) {

            // max length (sometimes the key has a capital L)
            if (this.attrs['maxLength']) {
                if (value.length > this.attrs['maxLength']) {
                    err.maxLength = 'Max length is: ' + this.attrs['maxLength'];
                }
            }
            // min length (sometimes the key has a capital L)
            if (this.attrs['minLength']) {
                if (value.length < this.attrs['minLength']) {
                    err.minLength = 'Minimum length is: ' + this.attrs['minLength'];
                }
            }

            // min date
            if (this.attrs['minDate']) {
                const todayD = new Date();
                const minDate = new Date();

                minDate.setDate(todayD.getDate() - this.attrs['minDate']);
                const controlDate = new Date(value);

                if (controlDate < minDate) {
                    err.minDate = 'Must be after ' + this.datePipe.transform(minDate, 'MM/dd/yyyy');
                }
            }
            // max date
            if (this.attrs['maxDate']) {
                const todayD = new Date();
                const maxDate = new Date();

                maxDate.setDate(todayD.getDate() + this.attrs['maxDate']);
                const controlDate = new Date(value);

                if (controlDate > maxDate) {
                    err.maxDate = 'Must be before ' + this.datePipe.transform(maxDate, 'MM/dd/yyyy');
                }
            }

            // ensure hours are between 0 and 23
            if (this.attrs.hasOwnProperty('hours24')) {
                if (isNaN(value) || value < 0 || value > 23) {
                    err.hours24 = 'Hours must be between 00 (12am) and 23 (11pm)';
                }
            }

            // ensure minutes are between 0 and 59
            if (this.attrs.hasOwnProperty('mins60')) {
                if (isNaN(value) || value < 0 || value > 59) {
                    err.mins60 = 'Minutes must be between 00 and 59';
                }
            }

            // min hour
            if (this.attrs.hasOwnProperty('minHour')) {
                if (value < this.attrs['minHour']) {
                    err.minHour = 'Hour must be between 1 and 12';
                }
            }

            // max hour
            if (this.attrs.hasOwnProperty('maxHour')) {
                if (value > this.attrs['maxHour']) {
                    err.maxHour = 'Hour must be between 1 and 12';
                }

            }
            // min minute
            if (this.attrs.hasOwnProperty('minMinute')) {
                if (value < this.attrs['minMinute']) {
                    err.minHour = 'Minute must be between 0 and 59';
                }
            }
            // max minute
            if (this.attrs.hasOwnProperty('maxMinute')) {
                if (value > this.attrs['maxMinute']) {
                    err.minHour = 'Minute must be between 0 and 59';
                }
            }

            // email
            if (this.attrs['email']) {
                if (!(/\S+@\S+\.\S+/.test(value))) {
                    err.email = 'Must be a valid email address';
                }
            }
            // phone
            if (this.attrs['phone']) {
                if (!(/^\\+?[0-9]{3}[0-9]{9}$|[0]{1}[0-9]{9}$/.test(value))) {
                    err.phone = 'Must be a valid phone number';
                }
            }
            // negative
            if (this.attrs['negative']) {
                if (value < 0) {
                    err.negative = 'Value cannot be negative';
                }
            }
            // maxValue
            if (this.attrs.hasOwnProperty('maxValue')) {
                if (value > this.attrs['maxValue']) {
                    err.maxValue = 'Must be less than ' + this.attrs['maxValue'];
                }
            }
            // minValue
            if (this.attrs.hasOwnProperty('minValue')) {
                if (value < this.attrs['minValue']) {
                    err.minValue = 'Must be greater than ' + this.attrs['minValue'];
                }
            }
            // isNaN
            if (this.attrs['isNaN'] || type === 'number') {
                if (isNaN(value)) {
                    err.isNaN = 'Value must be a number';
                }
            }
            // disallowSpecialChars
            if (this.attrs['disallowSpecialChars']) {
                if (/[^a-zA-Z0-9]/.test(value)) {
                    err.disallowSpecialChars = 'Only alphanumeric characters are allowed';
                }
                if (/[^a-zA-Z]/.test(value) && this.attrs['autoPopulateValuesKey'] &&
                    this.attrs['autoPopulateValuesKey'].toUpperCase() === 'STATIONCODES') {
                    err.disallowSpecialChars = 'Only alphabetic characters are allowed';
                }
            }
            // disallow users own employeeID
            if (this.attrs['disallowOwnEmployeeId']) {
                let userId = null;
                this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(user => userId = user.id);
                const compare1 = value.toString().replace(/^0+/, '');
                const compare2 = userId.toString().replace(/^0+/, '');
                if (compare1 === compare2) {
                    err.disallowOwnEmployeeId = 'Cannot be your own employee ID';
                }
            }

            // validation for additionalAAEmails data type
            if (this.attrs['dataType'] && this.attrs['dataType'].includes('additionalAAEmails')) {
                const emails: string[] = control.value.split(';');
                if (emails && emails.length > 0) {
                    emails.map((email) => {
                        const validFormat = (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
                        if (validFormat) {
                            const validDomain = email.split('@')[1];
                            if (validDomain.toUpperCase() !== 'AA.COM') err.invalidEmailDomain = 'Only emails from AA.com are allowed';
                        } else {
                            err.invalidEmail = 'One or more invalid emails present';
                        }
                    });

                    if (this.checkDuplicateString(emails)) {
                        err.duplicateEmail = 'Duplicate AA email addresses detected';
                    }
                }
            }
            // validation for additionalAAEmails data type
            if (this.attrs['dataType'] && this.attrs['dataType'].includes('additionalEmails')) {
                const emails: string[] = control.value.split(';');
                if (emails && emails.length > 0) {
                    emails.map((email) => {
                        const validFormat = (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
                        if (!validFormat) {
                            err.invalidEmail = 'One or more invalid emails present';
                        }
                    });
                }
                if (this.checkDuplicateString(emails)) {
                    err.duplicateEmail = 'Duplicate email addresses detected';
                }
            }
            // validation for emails data type
            if (this.attrs['maxEmailLength']) {
                const emails: string[] = control.value.split(';');
                if (emails && emails.length > 0) {
                    emails.map((email) => {
                        const validFormat = (email.length < this.attrs['maxEmailLength'] + 1);
                        if (!validFormat) {
                            err.invalidEmail = `An email exceeds the ${this.attrs['maxEmailLength']} character length`;
                        }
                    });
                }
            }
            // station code validations (min, max, and station code validation)
            if (this.attrs['stationCodeValidations']) {
                // Max length
                if (!this.attrs['maxLength']) {    // an override scenario
                    if (value.length > 4) {
                        err.maxlength = 'Maximum length is: 4';
                    }
                }
                // Min length
                if (value.length < 3) {
                    err.minlength = 'Minimum length is: 3';
                }
                // Station code validation.
                const stCodes = [];
                this.newReportService.getAirportCodeOptions(value).map(s => {
                    if (s.airportId.toUpperCase().indexOf(value) > -1) {
                        stCodes.push(s.airportId);
                    }
                });
                if (stCodes.includes(value) === false) {
                    err.stationCodeValidations = 'This is not a valid station code.';
                }
            }
        }
        return err;
    }
}

// this is for AMSAddresses
@Directive({
    selector: '[noDuplicatesValidator]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: NoDuplicatesValidator, multi: true }
    ]
})
export class NoDuplicatesValidator implements Validator, OnChanges {
    @Input('noDuplicatesValidator') obj: { 'array': any, 'propertyName'?: string, 'identifier'?: string, 'identifierPropertyName'?: string };
    validator: ValidatorFn;

    onChange: () => void;

    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {
        if ('obj' in changes) {
            if (this.onChange) this.onChange();
        }
    }

    registerOnValidatorChange(fn: () => void): void {
        this.onChange = fn;
    }

    validate(control: FormControl): ValidationErrors {
        const err: ValidationErrors = {};

        if (control.value != null && control.value.toString().length > 0) {
            let arrayToSearch = this.obj.array;
            if (this.obj.identifier) {
                arrayToSearch = arrayToSearch.filter(i => i[this.obj.identifierPropertyName] !== this.obj.identifier);
            }
            if (this.obj.propertyName) {
                arrayToSearch = arrayToSearch.map(a => { return a[this.obj.propertyName]; });
            }

            if (arrayToSearch.length > 0) {
                const filteredArray = arrayToSearch.filter(x => {
                    if (typeof x === 'number' && !Number.isNaN(x)) {    // is a number
                        return x === control.value;
                    } else {
                        if (x && x.length > 0 && control.value && control.value.length > 0) {
                            return (x.toUpperCase() === control.value.toUpperCase());
                        }
                    }
                });
                if (filteredArray.length >= 1) {
                    err.duplicate = 'Duplicate';
                }
            }
        }

        return err;
    }
}

// this is for AMSAddresses
@Directive({
    selector: '[isRequiredAMSAddressValidator]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: IsRequiredAMSAddressValidator, multi: true }
    ]
})
export class IsRequiredAMSAddressValidator implements Validator {
    @Input('isRequiredAMSAddressValidator') attrs: any;

    constructor() { }

    validate(formGroup: FormGroup): ValidationErrors {
        const err: ValidationErrors = {};

        if (this.attrs['isRequired']) {
            let valid = false;
            if (formGroup) {
                for (const form in formGroup.controls) {
                    if (form) {
                        const controls = formGroup.get(form)['controls'];
                        switch (form.toLowerCase()) {
                            case 'amsidform':
                            case 'amsaddressform':
                                valid = _.find(controls, ['value', true]);
                                break;
                            case 'opsaddressform':
                            case 'dispatchaddressform':
                                valid = _.find(controls, function (c) { return (/^[0-9]+$/.test(c.value)); });
                                break;
                            case 'userinputaddressform':
                                valid = _.find(controls, function (c) { return c.value && c.value.length > 0; });
                                break;
                        }

                        if (valid) { // found at least one address so we're good
                            return err;
                        }
                    }
                }
            }
            if (!valid) {
                err['isRequired'] = 'Required';
            }
        }
        return err;
    }
}
