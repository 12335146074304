import { Component, OnInit, ViewChild, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { SSEHubEmployeesService } from '@shared/SSEHubClient/employees.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { ReportHistoryForward } from '../../../report-history';
import { NotificationService } from '@shared/error-handler-notify/services/notification.service';
import { timeout, catchError, takeUntil } from 'rxjs/operators';
import { of, Subscription, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from 'app/store';
import { getUser } from 'app/store/user';
import { getReportDetailsReportId, RequestResponse } from 'app/report-history/report-history-store/detail';

@Component({
    selector: 'app-request-response-inline',
    templateUrl: './request-response-inline.component.html',
    styleUrls: ['./request-response-inline.component.scss']
})
export class RequestResponseInlineComponent implements OnInit, OnDestroy {
    @ViewChild('searchModal', { static: true }) public searchModal;
    @Output() requestResponseFormDisplaying = new EventEmitter();

    displayRequestResponseForm = false;
    responderEmployeeId: number;
    reportId: number;

    dtOptions: any = {};
    employees = [];
    responders = [];
    text = '';
    forwardObject: ReportHistoryForward = new ReportHistoryForward;
    modifiers: any = {};

    // search request events
    searchEvent$: Subscription;

    // column search variables
    corpaaId: string;
    firstName: string;
    lastName: string;
    stationCode: string;
    jobTitle: string;
    department: string;

    // form validation controls
    forwardForm: FormGroup;
    searchForm: FormGroup;
    firstNameFormControl: FormControl;
    lastNameFormControl: FormControl;
    searchFormControl: FormControl;
    commentFormControl: FormControl;
    stationSearchFormControl: FormControl;
    departmentSearchFormControl: FormControl;
    jobTitleFormControl: FormControl;

    // flags
    isLoading = false;
    showResponders = false;
    showSearch = false;
    isValid = true;
    isExist = false;
    textInvalid = false;
    isSearch = false;
    disableSend = false;
    timeoutError = false;
    tooltipHover: number;
    tooltipClick: number;
    destroy$: Subject<void> = new Subject<void>();
    constructor(
        private store: Store<State>,
        private sseHubEmployeesService: SSEHubEmployeesService,
        private notificationService: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(user => this.responderEmployeeId = user.id);
        this.store.select(getReportDetailsReportId).pipe(takeUntil(this.destroy$)).subscribe(result => this.reportId = result);
        this.setDataTableOptions();
        this.createFormControls();
        this.createForm();
        this.modifiers = {
            preventOverflow: { enabled: true }
        };
    }

    reset() {
        this.displayRequestResponseForm = false;
        this.requestResponseFormDisplaying.emit(this.displayRequestResponseForm);
        this.textInvalid = false;
        this.text = '';
        this.responders = [];
    }

    onAdd() {
        this.displayRequestResponseForm = true;
        this.requestResponseFormDisplaying.emit(this.displayRequestResponseForm);
    }

    onSave() {
        if (this.responders.length === 0) {
            this.isValid = false;
            return;
        }
        if (!this.text || !this.text.trim()) {
            this.textInvalid = true;
            return;
        }

        this.disableSend = true;
        this.forwardObject.message = this.text;
        this.forwardObject.responderEmployeeId = this.responderEmployeeId;
        this.forwardObject.recipientEmployeeIds = [];
        for (const responder of this.responders) {
            this.forwardObject.recipientEmployeeIds.push(responder.employeeId);
        }

        this.store.dispatch(new RequestResponse(this.reportId, this.forwardObject));
        this.disableSend = false;
        this.reset();
    }

    openSearch(event?) {
        this.isSearch = true;
        this.searchForm.reset();
        this.searchModal.open();
        this.showSearch = false;
        this.setDataTableOptions();
        this.resetResponderTable();
        this.closeTooltip();
    }

    closeSearch(event?) {
        this.searchModal.close();
        this.searchEvent$.unsubscribe();
        this.closeTooltip();
        this.isExist = false;
        this.isLoading = false;
        this.timeoutError = false;
        this.corpaaId = '';
        this.firstName = '';
        this.lastName = '';
        this.stationCode = '';
        this.department = '';
        this.jobTitle = '';
    }

    removeResponder(responder) {
        this.responders = _.filter(this.responders, function (i) { return i.employeeId !== responder.employeeId; });
    }

    private setDataTableOptions() {
        this.dtOptions = {
            'paging': true,
            'searching': false,
            'scrollCollapse': true,
            'ordering': true,
            'scroller': true,
            'lengthChange': false,
            'columns': [
                {
                    'name': 'corpaa',
                    'title': 'Emp ID',
                    'class': 'dtleft',
                    'width': '60px'
                },
                {
                    'name': 'firstName',
                    'title': 'Name',
                    'class': 'dtleft',
                    'width': '150px'
                },
                {
                    'name': 'jobTitle',
                    'title': 'Job Title',
                    'width': '200px',
                },
                {
                    'name': 'department',
                    'title': 'Department',
                    'width': '250px',
                },
                {
                    'name': 'station',
                    'title': 'Station',
                    'width': '60px',
                },
                {
                    'targets': 'no-sort',
                    'orderable': false,
                    'searchable': false
                }
            ]
        };
    }

    createFormControls() {
        this.commentFormControl = new FormControl('');
        this.firstNameFormControl = new FormControl('', [Validators.pattern('[A-Za-z _ -]*[A-Za-z][A-Za-z _ -]*')]);
        this.lastNameFormControl = new FormControl('', [Validators.pattern('[A-Za-z _ -]*[A-Za-z][A-Za-z _ -]*')]);
        this.stationSearchFormControl = new FormControl('', [Validators.pattern('[A-Za-z _ -]*[A-Za-z][A-Za-z _ -]*')]);
        this.jobTitleFormControl = new FormControl('');
        this.departmentSearchFormControl = new FormControl('');
        this.searchFormControl = new FormControl('', [Validators.pattern('[0-9]*')]);
    }

    createForm() {
        this.searchForm = new FormGroup({
            firstNameFormControl: this.firstNameFormControl,
            lastNameFormControl: this.lastNameFormControl,
            searchFormControl: this.searchFormControl,
            stationSearchFormControl: this.stationSearchFormControl,
            jobTitleFormControl: this.jobTitleFormControl,
            departmentSearchFormControl: this.departmentSearchFormControl
        });

        this.forwardForm = new FormGroup({
            commentFormControl: this.commentFormControl
        });
    }

    public searchEmployees() {
        let filter = '';
        this.employees = [];
        if (this.searchEvent$) {
            this.searchEvent$.unsubscribe();
        }
        if (!this.searchForm.invalid && (this.corpaaId || this.firstName || this.lastName || this.stationCode || this.department || this.jobTitle)) {
            if (this.corpaaId) {
                filter = 'employeeId::' + this.corpaaId + '|';
            }

            if (this.firstName) {
                filter = filter + 'firstName::' + this.firstName + '|';
            }

            if (this.lastName) {
                filter = filter + 'lastName::' + this.lastName + '|';
            }

            if (this.stationCode) {
                filter = filter + 'stationCode::' + this.stationCode + '|';
            }
            if (this.jobTitle) {
                filter = filter + 'jobTitle::' + this.jobTitle + '|';
            }
            if (this.department) {
                filter = filter + 'department::' + this.department + '|';
            }
            filter += 'profilegroup=MgmtProfiles|';
            this.isLoading = true;


            this.searchEvent$ = this.sseHubEmployeesService.search(filter, null, null).pipe(
                timeout(20000),
                catchError(e => {
                    this.timeoutError = true;
                    this.showSearch = false;
                    this.isExist = false;
                    this.isLoading = false;
                    return of(null);
                })
            ).pipe(takeUntil(this.destroy$)).subscribe(
                value => {
                    if (value != null) {
                        this.employees = value;
                        this.isLoading = false;
                        this.showSearch = true;
                        this.timeoutError = false;
                        this.isExist = false;
                    }
                },
                error => {
                    this.isLoading = false;
                    this.notificationService.showError('Error finding employee.');
                }
            );

        } else {
            this.showSearch = false;
            this.timeoutError = false;
            this.isLoading = false;
        }
    }

    addEmployee(employee) {
        if (!_.some(this.responders, employee)) {
            this.responders.push(employee);
            this.isValid = true;
            this.isExist = false;
        } else {
            this.isExist = true;
        }
    }

    resetResponderTable() {
        this.showResponders = false;
        setTimeout(() => this.showResponders = true, 5);
    }

    toggleTooltipHover(event, responder) {
        if (responder.employeeId === this.tooltipHover) {
            this.tooltipHover = 0;
        } else {
            this.tooltipHover = responder.employeeId;
        }
    }

    toggleTooltipClick(event, responder) {
        if (responder.employeeId === this.tooltipClick) {
            this.tooltipClick = 0;
        } else {
            this.tooltipClick = responder.employeeId;
        }
        event.stopPropagation();
    }

    closeTooltip() {
        this.tooltipClick = 0;
    }
}
