import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-aa-spinner',
    templateUrl: './aa-spinner.component.html'
})
export class AASpinnerComponent {
    @Input() loading: boolean;
    @Input() text = 'Loading...';
    @Input() showAa = true;
    @Input() showSpinner = true;
    @Input() style = 'vertical';
    @Input() page: string;
    constructor() { }
}
