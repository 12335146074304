<modal #questionListModal (onOpen)="onOpen()" (onClose)="onClose($event)">
    <modal-header>
        <h4>Add new question to category</h4>
    </modal-header>
    <modal-content>
        <app-aa-spinner [loading]="isLoading"></app-aa-spinner>
        <div *ngIf="!isLoading" class="row">
            <label for="table-complete-search" class="col-sm-auto col-form-label">Search Question:</label>
            <div class="col-sm-auto">
                <input id="table-complete-search" type="text" class="form-control" name="searchText"
                    [(ngModel)]="searchText" (keyup)="refreshQuestions()" />
            </div>
        </div>
        <div *ngIf="!isLoading">
            <table class="reportHistory-table table table-hover table-striped">
                <thead>
                    <tr>
                        <th scope="col" sortable="answerInputType" (sort)="onSort($event)">Type</th>
                        <th scope="col" sortable="questionText" (sort)="onSort($event)">Question</th>
                        <th scope="col">Involvement</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let q of pagedQuestions ">
                        <td>{{q.answerInputType | capitalizeInputType}}</td>
                        <td>{{q.questionText}}</td>
                        <td>
                            <select *ngIf="q.involvementTypes.length >= 2" name="involvementDetail"
                                id="involvementDetail" [(ngModel)]="q.referenceSk">
                                <option selected value="0">Click here</option>
                                <option *ngFor="let involvement of q.involvementTypes; let i = index" [ngValue]="involvement.referenceSk">{{ involvement.involvementName }}</option>
                            </select>
                            <label
                                *ngIf="q.involvementTypes.length === 1">{{ q.involvementTypes[0].involvementName }}</label>
                        </td>
                        <td class="btn-add-n-select" (click)="addQuestionToCategory(q)"><a>Add</a></td>
                    </tr>
                </tbody>
            </table>

            <div class="d-flex justify-content-between p-2">
                <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" [maxSize]="3"
                [rotate]="true"
                [ellipses]="false"
                [boundaryLinks]="true"
                        (pageChange)="refreshQuestions()">
                    </ngb-pagination>
                    <div class="page-items">
                        <label for="pageSize">Items per page: </label>
                        <select class="form-select" style="width: auto" name="pageSize" [(ngModel)]="pageSize"
                            (ngModelChange)="refreshQuestions()">
                            <option [ngValue]="10">10</option>
                            <option [ngValue]="25">25</option>
                            <option [ngValue]="50">50</option>
                            <option [ngValue]="100">100</option>
                        </select>
                    </div>
            </div>
        </div>
    </modal-content>
    <modal-footer>
        <button type="button" class="btn btn-cancel" (click)="questionListModal.close()">Close</button>
        <button type="button" class="btn btn-primary" (click)="openNewQuestionModal()">Create New</button>
    </modal-footer>
</modal>
