import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, DatePipe } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { CommonUXModule } from '@shared/common-ux/common-ux.module';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AgGridModule } from 'ag-grid-angular';
import { TableUnfinishedReportsComponent } from './table-unfinished-reports/table-unfinished-reports.component';
import { TableTodoReportComponent } from './table-todo-reports/table-todo-report.component';


// Modal Components
// NgRx

/** config angular i18n **/
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { UnfinishedReportDatabaseService } from 'app/shared/report-services/unfinished-report-database.service';
import { ToDoComponent } from './to-do.component';

registerLocaleData(en);

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        NgbModule,
        CommonModule,
        CommonUXModule,
        AgGridModule,
        MatInputModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
    ],
    declarations: [
        TableUnfinishedReportsComponent,
        TableTodoReportComponent,
    ],
    providers: [
        DatePipe,
        UnfinishedReportDatabaseService,
        DeviceDetectorService,
    ],
    exports: [
        TableUnfinishedReportsComponent,
        TableTodoReportComponent,
        MatInputModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
    ]
})
export class ToDoModule { }
