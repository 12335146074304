<div class="attachment">
    <div *ngIf="!isAddNew" (click)="onAdd()" class="addnew">
        <i class="icon-small icon-add"></i>
        <a style="padding-left: 5px">Add attachment</a>
    </div>
    <app-aa-spinner [loading]="isLoading"></app-aa-spinner>
    <div *ngIf="isAddNew && !isLoading" class="form-group addnew">
        <form #fileUpload="ngForm">
            <input type="file" name="file" (change)="onFileSelect($event)">
        </form>
        <div *ngIf="isFileInValid" class="validation-fail">
            Invalid attachment type
        </div>
        <div *ngIf="isFileEmpty" class="validation-fail">
            You're trying to upload an empty file.
        </div>
        <div *ngIf=descriptionRequired>
            <label for="File Description">File description:</label>
            <textarea aaAutosize [minRows]="5" [maxRows]="20" class="form-control" id="fileDescription" required
                [(ngModel)]="newAttachment.description" (input)="onChangeDescTxt()" name="fileDescription"> </textarea>
            <div *ngIf="isDescriptionInValid" class="validation-fail">
                Required
            </div>
        </div>
        <div *ngIf="isAddNew && !isLoading">
            <button type="button" class="button-cancel" (click)="close()">Cancel</button>
            <button type="submit" class="button-save" (click)="onAttachmentSave()">Upload</button>
        </div>
    </div>
</div>