<modal #sortOrderEditModal (onClose)="onClose($event)">
    <modal-header>
        <h4>Edit Sort Order</h4>
    </modal-header>
    <modal-content>
        <app-aa-spinner [loading]="isLoading"></app-aa-spinner>
        <div *ngIf="!isLoading">
            <h5>Drag and drop rows to re-order.</h5>
            <div cdkDropList class="sort-main container" (cdkDropListDropped)="drop($event)">
                <div class="sort-row" *ngFor="let sortableObj of sortableObjectList" cdkDrag>
                        <h5>
                            <i class="icon-menu"></i>
                            {{sortableObj.displayText}}
                        </h5>
                </div>
            </div>
        </div>
    </modal-content>
    <modal-footer>
        <button type="button" class="btn btn-cancel" (click)="sortOrderEditModal.close()">Close</button>
        <button type="submit" class="btn btn-primary" (click)="onSubmit()">Save</button>
    </modal-footer>
</modal>
