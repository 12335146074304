import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { SSEHubClient } from './SSEHubClient.service';
import { IReportHistory } from 'app/report-history/report-history-store/list';
import { takeUntil } from 'rxjs/operators';
import { Narrative } from 'app/report-history/report-history-store/detail/detail.model';

@Injectable()
export class SSEHubReportHistoryService implements OnDestroy {
    destroy$: Subject<void> = new Subject<void>();
    constructor(
        private http: HttpClient,
        private sseHubClient: SSEHubClient
    ) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getEmployeeReportHistory(employeeId, filterString) {
        return this.http.get(
            this.sseHubClient.serviceUrl() + '/api/reportHistory/' + encodeURIComponent(employeeId) + '/myReports');
    }

    getEmployeeAllReportHistory(employeeId) {
        return this.http.get(
            this.sseHubClient.serviceUrl() + '/api/reportHistory/' + encodeURIComponent(employeeId) + '/allReports');
    }

    getEmployeeTodoReportHistory(employeeId) {
        return this.http.get(
            this.sseHubClient.serviceUrl() + '/api/reportHistory/' + encodeURIComponent(employeeId) + '/todoReports');
    }

    // This hasn't been written yet.
    getEmployeeTodoList(employeeId) {
        return this.http.get(
            this.sseHubClient.serviceUrl() + '/api/employees/' + encodeURIComponent(employeeId) + '/todo');
    }

    getAdminDiscussion(reportId) {
        return this.http.get(
            this.sseHubClient.serviceUrl() + '/api/reportHistory/' + encodeURIComponent(reportId) + '/adminDiscussion');
    }

    // This is in UserReportsController in the API and we might need to move it later.
    getEmployeeReportManagementAccess(employeeId): Promise<any> {
        return this.http.get(
            this.sseHubClient.serviceUrl() + '/api/employees/' + encodeURIComponent(employeeId) + '/reportManagementAccess').toPromise();
    }

    getReportDetail(reportId) {
        return this.http.get(
            this.sseHubClient.serviceUrl() + '/api/reportHistory/' + encodeURIComponent(reportId) + '/reportDetails');
    }

    getReportSearchDetail(employeeId: string, reportId: string, etn: string) {
        let url = '/api/reportHistory/searchforIOD?employeeId=' + employeeId;
        if (reportId) {
            url = url + '&reportId=' + reportId;
        }
        if (etn) {
            url = url + '&etn=' + etn;
        }
        return this.http.get(this.sseHubClient.serviceUrl() + url);
    }

    getReportActivityLog(reportId) {
        return this.http.get(
            this.sseHubClient.serviceUrl() + '/api/reportHistory/' + encodeURIComponent(reportId) + '/activityLog');
    }

    // This hasn't been written yet.
    getReportRecipientList(reportId) {
        return this.http.get(
            this.sseHubClient.serviceUrl() + '/api/reportHistory/' + encodeURIComponent(reportId) + '/recipients');
    }
    saveAttachment(reportId, attachmentMetadata) {
        return this.http.post(
            this.sseHubClient.serviceUrl() + '/api/reportHistory/' + encodeURIComponent(reportId) + '/attachments/', attachmentMetadata);
    }

    // This will return ReportId, FileName, Description, GUId, CreateDate, EmployeId, FirstName, LastName, and ImageString (base64 format).
    getAttachment(reportId, guId, employeeId): Observable<any> {
        return this.http.get(
            this.sseHubClient.serviceUrl() + '/api/reportHistory/' + encodeURIComponent(reportId) + '/' + encodeURIComponent(guId)
            + '/' + encodeURIComponent(employeeId) + '/attachment');
    }

    // This hasn't been written yet.
    addNarrative(narrativeObj: Narrative) {
        return this.http.post(
            this.sseHubClient.serviceUrl() + '/api/reportHistory/' + encodeURIComponent(narrativeObj.reportId)
            + '/narrative', narrativeObj);
    }

    getReportStatus() {
        return this.http.get(
            this.sseHubClient.serviceUrl() + '/api/reportHistory/status');
    }

    getReportAuthorization(reportId, employeeId) {
        return this.http.get(
            this.sseHubClient.serviceUrl() + `/api/reportHistory/authorizations/${encodeURIComponent(reportId)}/${encodeURIComponent(employeeId)}`);
    }

    // This hasn't been written yet.
    requestResponse(reportId, data) {
        return this.http.post(
            this.sseHubClient.serviceUrl() + '/api/reportHistory/' + encodeURIComponent(reportId)
            + '/responders', data);
    }

    // This hasn't been written yet.
    respondToRequest(reportId, data) {
        return this.http.post(
            this.sseHubClient.serviceUrl() + '/api/reportHistory/' + encodeURIComponent(reportId) + '/responseToRequest', data);
    }

    // This hasn't been written yet.
    reply(reportId, replyValue) {
        return this.http.post(
            this.sseHubClient.serviceUrl() + '/api/reportHistory/' + encodeURIComponent(reportId)
            + '/replies', replyValue);
    }

    // This hasnt been written yet.
    setReportStatus(reportId, statusSk, employeeId) {
        return this.http.put(
            this.sseHubClient.serviceUrl() + '/api/reportHistory/' + encodeURIComponent(reportId)
            + '/reportStatus/' + statusSk + '?employeeId=' + employeeId, null);
    }

}
