import {
    Component, OnInit, ViewChild, AfterViewInit, OnDestroy
} from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Params } from '@angular/router';
import { ReportDetail } from '../report-history';
import {
    GetReportDetail, GetReportStatusList, GetReportAuthorization, IHistoryAuthorizations, SetReportStatus, ReportDetailReset, GetReportSearchDetail
} from 'app/report-history/report-history-store/detail';
import { Observable, Subject } from 'rxjs';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { getUser } from '@app/store/user/user.reducer';
import { IUser, User } from '@app/store/user';
import { State } from '@app/store';
import {
    getReportDetails,
    getReportDetailsEmployeeId,
    getReportDetailsReportStatusDesc,
    getReportDetailsReportStatusSk,
    getReportDetailsStatusList,
    getReportAuth
} from 'app/report-history/report-history-store/detail/detail.reducer';
import { RemoveClosedReport } from 'app/report-history/report-history-store/list';
import { takeUntil } from 'rxjs/operators';
import { Router } from '../../../../node_modules/@angular/router';

@Component({
    selector: 'app-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit, AfterViewInit, OnDestroy {
    isReportSearch = false;
    reportId: number;
    allExpand: Subject<any> = new Subject();
    reportSk: number;
    report: ReportDetail;
    reportStatus: number;
    isReportNotFound = false;
    inValidStatus = false;
    isLoading = false;
    user: IUser;
    submitterId: number;
    reportStatusDesc;
    authorizations: IHistoryAuthorizations;
    @ViewChild('activityLogModal', { static: false }) public activityLogModal;
    @ViewChild('replyModal', { static: false }) public replyModal;
    @ViewChild('recipientListModal', { static: false }) public recipientListModal;
    reportsDetails$: Observable<any>;
    reportStatusList$: Observable<any>;
    destroy$: Subject<void> = new Subject<void>();
    route: ActivatedRouteSnapshot;
    isReportManagementAccess = false;
    isGeneralMgr = false;

    constructor(
        private store: Store<State>,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {
        this.reportsDetails$ = this.store.select(getReportDetails);
        this.reportStatusList$ = this.store.select(getReportDetailsStatusList);
    }

    ngOnDestroy(): void {
        this.store.dispatch(new ReportDetailReset());
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {

        this.store.dispatch(new GetReportStatusList());

        this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
            const base64ReportId = params['reportId'];
            const employeeId = params['employeeId'];
            const reportIdOrEtn = params['reportIdOrEtn'];

            this.isReportSearch = employeeId && reportIdOrEtn;

            if (this.isReportSearch) {
                this.store.dispatch(new GetReportSearchDetail(employeeId, reportIdOrEtn));

                this.reportsDetails$.pipe(takeUntil(this.destroy$)).subscribe(details => {
                    if (!details.isLoading) {
                        if (details && details.detail) {
                            this.reportId = details.detail.ReportID;
                        }
                        // if no report details are loaded in the store then we send the user back to the report search page
                        if (!(this.reportId > 0)) {
                            this.router.navigate(['reporthistory/reportsearch']);
                        }
                        this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(user => this.onUserLoaded(user));
                    }
                });
            } else {
                if (base64ReportId) {
                    const reportid = base64ReportId;
                    if (reportid) {
                        this.reportId = +reportid;
                    }
                }
                // onUserLoaded depends on reportId, so call within the route subscription
                this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(user => this.onUserLoaded(user));

                this.fetchReportDetails();
            }
        });
    }

    ngAfterViewInit() {
        this.reportsDetails$.pipe(takeUntil(this.destroy$)).subscribe(detail => this.loadReports(detail));
    }

    fetchReportDetails() {
        this.store.dispatch(new GetReportDetail(this.reportId));
    }

    loadReports(detail) {
        if (detail.detail && !detail.isLoading) {
            this.report = detail.detail;
            this.isReportNotFound = false;

            this.store.select(getReportDetailsReportStatusSk).pipe(takeUntil(this.destroy$)).subscribe(result => this.reportStatus = result);
            this.store.select(getReportDetailsEmployeeId).pipe(takeUntil(this.destroy$)).subscribe(result => this.submitterId = result);
            this.store.select(getReportDetailsReportStatusDesc).pipe(takeUntil(this.destroy$)).subscribe(result => this.reportStatusDesc = result);

            this.store.select(getReportAuth).pipe(takeUntil(this.destroy$)).subscribe(result => this.authorizations = result.detail);
        } else {
            if (!detail.isLoading) {
                this.isReportNotFound = true;
            }
        }
    }

    onUserLoaded(user: IUser) {
        if (user) {
            this.user = user;
            this.store.dispatch(new GetReportAuthorization(this.reportId, this.user.id));
            this.isReportManagementAccess = User.hasPrivilege(user, 'WEB_HISTORY_MANAGEMENT_ACCESS');
            this.isGeneralMgr = user.profileId === 7;
        }
    }

    updateReportStatus() {
        this.inValidStatus = false;
        const statusSk = Number(this.reportStatus);

        if (statusSk === 5 && this.report.responseRequested === 'Y' && this.report.replies.length < 1) {
            this.inValidStatus = true;
            this.fetchReportDetails();
            return;
        }

        this.store.dispatch(new SetReportStatus(this.reportId, this.reportStatus, this.user.id));

        if (statusSk === 5) {
            this.store.dispatch(new RemoveClosedReport(this.reportId.toString()));
        }
    }

    openActivityLogs() {
        this.activityLogModal.open();
    }
    openReply() {
        this.replyModal.open();
    }

    isSubmitter() {
        if (Number(this.user.id) === Number(this.submitterId)) {
            // the user is a submitter
            return true;
        }
        return false;
    }

    isReportClosed() {
        const reportStatusDesc = 'Closed';
        if (this.reportStatusDesc === reportStatusDesc) {
            // the report is closed
            return true;
        }
        return false;
    }

    featureAccessForSubmitter() {
        if (this.isSubmitter()) {
            if (this.isReportClosed()) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    featureAccessForCoreRecipient() {
        if (this.isReportClosed()) {
            return false;
        } else {
            return this.isCoreAdministrator();
        }
    }

    featureAccessForFwdRecipient() {
        if (this.isReportClosed()) {
            return false;
        } else {
            return this.isAnyAdministrator();
        }
    }

    isCoreAdministrator(): boolean {
        let access = false;

        if (!this.isSubmitter() && this.authorizations && !this.isReportSearch) {
            if (
                User.hasPrivilege(this.user, 'WEB_SUADMIN') ||
                this.authorizations.isCoreRecipientUser ||
                this.authorizations.isReportAccessDepartmentUser
            ) {
                access = true;
            }
        }

        return access;
    }

    isAnyAdministrator(): boolean {
        let access = false;

        if (!this.isSubmitter() && this.authorizations) {
            if (
                User.hasPrivilege(this.user, 'WEB_SUADMIN') ||
                this.authorizations.isCoreRecipientUser ||
                this.authorizations.isReportAccessDepartmentUser ||
                this.authorizations.isForwardRecipientUser
            ) {
                access = true;
            }
        }

        return access;
    }

    printReportContent() {
        let body = document.getElementById('ReportContent').innerHTML;
        // these two lines expand all sections so they are visible for print
        body = body.replace(/panel-collapse collapse(?!\s+in)/g, 'panel-collapse collapse in ');
        body = body.replace(/style="height: 0px;">/g, 'style="height: auto;">');
        // this hides any element with the hide-print class
        body = body.replace(/hide-print/g, 'hidden-group');
        const head = document.head.innerHTML + '<style type="text/css">i,a { display: none !important; }</style>';
        const popupWin = window.open('', '_blank');
        popupWin.document.open();
        popupWin.document.write(
            '<html><head>' + head + '</head><body onload="setTimeout(function(){ window.print(); }, 500)">' + body + '</body></html>'
        );
        popupWin.document.close();
    }

    scrollToTop() {
        const scrollInterval = window.setInterval(() => {
            if (window.pageYOffset > 1) {
                // speed for scrolling(decreasing the position by half with a step
                // of 25 milliseconds provides smooth and easing animation)
                window.scrollTo(0, Math.round(window.pageYOffset * .5));
            } else {
                window.clearInterval(scrollInterval);
            }
        }, 25);
    }
}
