import { Component, OnInit, OnDestroy } from '@angular/core';
import { SSEHubNotificationService } from 'app/shared/SSEHubNotification/notification.service';
import { NotificationService } from '@shared/error-handler-notify/services';
import { FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';

import { Store } from '@ngrx/store';
import { State } from '@app/store';
import { getUser, IUser } from '@app/store/user';
import { IPhone, OptPhone } from './notifications.models';

import * as _ from 'lodash';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-notification',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
    phones: OptPhone[] = [];
    userId: string;
    form: FormGroup;
    errorMsg = '';
    isLoadingConsent = false;
    isDisabled = false;
    consentSuccess = false;
    optStatusFilter = 'SENT';
    termsAndConditionsUrl = 'https://newjetnet.aa.com/resources/statics/521195/disclaimer-terms-and-conditions.html?a=1551390392927';
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private sseHubNotificationService: SSEHubNotificationService,
        private userNotifyService: NotificationService,
        private formBuilder: FormBuilder,
        private store: Store<State>
    ) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    ngOnInit() {
        this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(user => {
            this.onUserLoaded(user);
        });
    }

    onUserLoaded(user: IUser) {
        if (user && user.id) {
            this.userId = user.id;
            this.createFormControls();
            this.getPhoneNumbers(this.userId);
        }
    }

    getPhoneNumbers(userId: string) {
        this.sseHubNotificationService.getPhoneNumbers(userId).pipe(takeUntil(this.destroy$)).subscribe(
            (phones: IPhone) => {
                if (phones) {
                    this.phones = _.filter(phones.notificationPhones, phone => phone.optStatus === this.optStatusFilter);
                    this.createFormControls();
                }
            },
            (error) => {
                this.userNotifyService.showError('Error getting list of phone numbers');
            }
        );
    }

    createFormControls() {
        // Create form controls for the phones' checkboxes
        const cntrl = this.phones.map(c => new FormControl(true));
        this.form = this.formBuilder.group({
            phones: new FormArray(cntrl)
        });
    }

    updatePhoneStatus(acceptPhones: OptPhone[], declinePhones: OptPhone[]) {
        // join all the http request to handle as one
        const acpt = acceptPhones.map((phone) => this.sseHubNotificationService.acceptOPTStatus(phone.phoneNumber, this.userId));
        const dcln = declinePhones.map((phone) => this.sseHubNotificationService.declineOPTStatus(phone.phoneNumber, this.userId));

        forkJoin(acpt.concat(dcln)).pipe(takeUntil(this.destroy$)).subscribe(
            (value) => {
                this.consentSuccess = true;
                this.isLoadingConsent = false;
                this.isDisabled = true;
                this.form.disable();
            },
            (error) => {
                this.errorMsg = 'Error processing consent';
                this.isLoadingConsent = false;
            }
        );
    }

    submit() {
        // reset flags
        this.consentSuccess = false;
        this.errorMsg = '';

        // filters phones based on user's selection
        const acceptPhones = [];
        const rejectedPhones = [];

        this.form.value.phones.map((v, i) => {
            (v) ? acceptPhones.push(this.phones[i]) : rejectedPhones.push(this.phones[i]);
        });

        this.isLoadingConsent = true;
        this.updatePhoneStatus(acceptPhones, rejectedPhones);
    }
}
