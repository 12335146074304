import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import {
    getGeneralQuestionsError, getGeneralQuestionsIsLoading, State, getQuestions
} from '@newreport/new-report-store';
import {
    UpdateGeneralQuestionsPageStatus,
    IDynamicQuestion
} from '../../new-report-store/wizard';
import { FormComponentService } from 'app/shared/common-ux/components/component-services/form-component.service';
import { WizardService, IReportInfo, FocusService } from '../wizard.service';
import { ConfirmCancelComponent } from '@wizardmodals/confirm-cancel.component';
import * as _ from 'lodash';
import { ReportAssemblyService } from '@newreport/new-report.service';
import { CanComponentDeactivate } from 'app/can-deactivate.guard';
import { ConfirmNavigateComponent } from '@wizardmodals/confirm-navigate.component';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-fill-out-report-wizard-questions',
    templateUrl: './questions.component.html',
    styleUrls: ['./steps.component.scss']
})
export class QuestionsComponent implements OnInit, OnDestroy, CanComponentDeactivate {
    groupName = 'general';
    @ViewChild('form', { static: true }) form: NgForm;
    @ViewChild(ConfirmCancelComponent, { static: true }) confirmCancel: ConfirmCancelComponent;
    @ViewChild(ConfirmNavigateComponent, { static: true }) confirmNavigate: ConfirmNavigateComponent;
    questions: Array<IDynamicQuestion>;
    flightQuestions: Array<IDynamicQuestion>;
    notesQuestions: Array<IDynamicQuestion>;
    reportInfo$: Observable<IReportInfo>;
    isLoadingQuestions = true;
    errorQuestions = null;
    error = null;
    errorMessageCounter = 0;
    navigateAwaySelection$: Subject<boolean> = new Subject<boolean>();
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<State>,
        private router: Router,
        private formComponentService: FormComponentService,
        private wizardService: WizardService,
        private focusService: FocusService,
        public newReportService: ReportAssemblyService,
    ) {
        this.store.dispatch(new UpdateGeneralQuestionsPageStatus('inProgress'));
    }

    onNavigate(isExitting) {
        return this.navigateAwaySelection$.next(isExitting);
    }

    canDeactivate(route: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.newReportService.isStateReportDirty(this.flightQuestions, this.questions, this.notesQuestions)
            && !nextState.url.startsWith('/newreport/wizard')) {
            this.confirmNavigate.displayModal();
            return this.navigateAwaySelection$;
        }
        return true;
    }

    ngOnInit() {

        this.reportInfo$ = this.wizardService.getReportInfo();

        // this.router.navigate(['/newreport']);
        this.store.select(getQuestions('general')).pipe(takeUntil(this.destroy$)).subscribe(questions => this.questions = questions);
        this.store.select(getGeneralQuestionsIsLoading).pipe(takeUntil(this.destroy$)).subscribe(il => this.isLoadingQuestions = il);
        this.store.select(getGeneralQuestionsError).pipe(takeUntil(this.destroy$)).subscribe(e => this.error = e);

        this.store.select(getQuestions('flight')).pipe(takeUntil(this.destroy$)).subscribe(flightQ => this.flightQuestions = flightQ);
        this.store.select(getQuestions('notes')).pipe(takeUntil(this.destroy$)).subscribe(notesQ => this.notesQuestions = notesQ);

        if (!this.isLoadingQuestions && (!this.questions || this.questions.length < 1)) {
            this.wizardService.loadAllQuestions();
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        this.validatePage();
    }

    validatePage() {
        this.formComponentService.validateAllFormFields(this.form.control);

        if (this.form.valid) {
            this.store.dispatch(new UpdateGeneralQuestionsPageStatus('complete'));
        } else {
            this.store.dispatch(new UpdateGeneralQuestionsPageStatus('incomplete'));
        }
    }

    next() {
        this.validatePage();
        if (this.form.valid) {
            window.scrollTo(0, 0); // scroll to top every time this page loads
            this.router.navigate(['/newreport/wizard/notes']);
        } else {
            this.focusService.displayErrorMessage(++this.errorMessageCounter);
            const firstKey = this.focusService.getFirstInvalidKey(this.questions, this.form.form);
            if (firstKey) {
                this.focusService.setFocusKey(firstKey);
            }
        }
    }

    previous() {
        window.scrollTo(0, 0); // scroll to top every time this page loads
        this.router.navigate(['/newreport/wizard/flight']);
    }

    saveReport() {
        this.newReportService.saveUnfinishedReport();
    }
}
