import * as detailAction from './detail.actions';
import {
    IReportSummary, IReportDetail, IRecipientListDetails, IActivityLogDetails,
    IReportStatusList, IReportAdminDiscussion, IReportAdminAuth, IActivityLog,
    IStatusList, IRecipientList, IHistoryAuthorizations
} from './detail.model';

export interface HistoryDetailStateModel {
    reportSummary: IReportSummary;
    recipientDetails: IRecipientListDetails;
    activityDetails: IActivityLogDetails;
    reportStatusList: IReportStatusList;
    adminDiscussion: IReportAdminDiscussion;
    reportAuth: IReportAdminAuth;
    // detailExtras: Array<IReportDetailExtras>;
}

export const initialDetailState: HistoryDetailStateModel = {
    reportSummary: {
        isLoading: true,
        detail: <IReportDetail>{},
        error: null
    },
    recipientDetails: {
        isLoading: true,
        detail: <IRecipientList>{},
        error: null
    },
    activityDetails: {
        isLoading: true,
        detail: [],
        error: null
    },
    reportStatusList: {
        isLoading: true,
        detail: <IStatusList>{},
        error: null
    },
    adminDiscussion: {
        isLoading: true,
        detail: [],
        error: null
    },
    reportAuth: {
        isLoading: true,
        detail: <IHistoryAuthorizations>{},
        error: null
    },
};

export function reportDetailReducer(state = initialDetailState, action: detailAction.Actions) {
    switch (action.type) {
        case detailAction.GET_ADMIN_DISCUSSION:
            return {
                ...state,
                adminDiscussion: {
                    ...state.adminDiscussion,
                    isLoading: true,
                    error: null
                },
            };

        case detailAction.GET_ADMIN_DISCUSSION_ERROR:
            return {
                ...state,
                adminDiscussion: {
                    ...state.adminDiscussion,
                    isLoading: false,
                    error: action.payload
                },
            };

        case detailAction.POPULATE_ADMIN_DISCUSSION:
            return {
                ...state,
                adminDiscussion: {
                    ...state.adminDiscussion,
                    isLoading: false,
                    detail: action.payload,
                    error: null
                },
            };
        case detailAction.GET_REPORT_AUTH:
            return {
                ...state,
                reportAuth: {
                    ...state.reportAuth,
                    isLoading: true,
                    error: null
                },
            };

        case detailAction.POPULATE_REPORT_AUTH:
            return {
                ...state,
                reportAuth: {
                    ...state.reportAuth,
                    isLoading: false,
                    detail: action.payload,
                    error: null
                },
            };
        case detailAction.GET_REPORT_DETAIL:
            return {
                ...state,
                reportSummary: {
                    ...state.reportSummary,
                    isLoading: true,
                    error: null
                },
            };
        case detailAction.GET_REPORT_SEARCH_DETAIL:
            return {
                ...state,
                reportSummary: {
                    ...state.reportSummary,
                    isLoading: true,
                    error: null
                },
            };
        case detailAction.GET_REPORT_DETAIL_SECTION_LOADER:
            return {
                ...state,
                reportSummary: {
                    ...state.reportSummary,
                    isLoading: false,
                    error: null
                },
            };

        case detailAction.POPULATE_REPORT_DETAIL:
            return {
                ...state,
                reportSummary: {
                    ...state.reportSummary,
                    isLoading: false,
                    detail: action.payload,
                    error: null
                },
            };

        case detailAction.GET_REPORT_STATUS_LIST:
            return {
                ...state,
                reportStatusList: {
                    ...state.reportStatusList,
                    isLoading: true,
                    error: null
                },
            };

        case detailAction.GET_REPORT_STATUS_LIST_ERROR:
            return {
                ...state,
                reportStatusList: {
                    ...state.reportStatusList,
                    isLoading: false,
                    error: action.payload
                },
            };

        case detailAction.POPULATE_REPORT_STATUS_LIST:
            return {
                ...state,
                reportStatusList: {
                    ...state.reportStatusList,
                    isLoading: false,
                    detail: action.payload,
                    error: null
                },
            };

        case detailAction.GET_RECIPIENT_LIST:
            return {
                ...state,
                recipientDetails: {
                    ...state.recipientDetails,
                    isLoading: true,
                    error: null
                },
            };

        case detailAction.POPULATE_RECIPIENT_LIST:
            return {
                ...state,
                recipientDetails: {
                    ...state.recipientDetails,
                    isLoading: false,
                    detail: action.payload,
                    error: null
                },
            };

        case detailAction.GET_ACTIVITY_LOG:
            return {
                ...state,
                activityDetails: {
                    ...state.activityDetails,
                    isLoading: true,
                    error: null
                }
            };

        case detailAction.GET_ACTIVITY_LOG_ERROR:
            return {
                ...state,
                activityDetails: {
                    ...state.activityDetails,
                    isLoading: false,
                    error: action.payload
                }
            };

        case detailAction.POPULATE_ACTIVITY_LOG:
            return {
                ...state,
                activityDetails: {
                    isLoading: false,
                    detail: action.payload,
                    error: null
                },
            };
        case detailAction.REPORT_DETAIL_ERROR:
            return {
                ...state,
                reportSummary: {
                    isLoading: false,
                    detail: null,
                    error: 'Error : Oops! something went wrong.'
                }
            };
        case detailAction.REPORT_DETAIL_RESET:
            return initialDetailState;

        default:
            return state;
    }
}

// SELECTORS
export const getReportDetails = state => state.reportDetail.reportSummary;

export const getReportDetailsisLoading = state => state.reportDetail.reportSummary.isLoading;

export const getReportDetailsReportId = state => state.reportDetail.reportSummary.detail.reportId;
export const getReportDetailsRecommendation = state => state.reportDetail.reportSummary.detail.suggestions;
export const getReportDetailsDescription = state => state.reportDetail.reportSummary.detail.notes;
export const getReportDetailsQuestions = state => state.reportDetail.reportSummary.detail.questionAnswers;
export const getReportDetailsInvolvements = state => state.reportDetail.reportSummary.detail.involvements;
export const getReportDetailsFlight = state => state.reportDetail.reportSummary.detail.flight;
export const getReportDetailsReportTypeDesc = state => state.reportDetail.reportSummary.detail.reportTypeDesc;
export const getReportDetailsReportStatusDesc = state => state.reportDetail.reportSummary.detail.reportStatusDesc;
export const getReportDetailsEmployeeId = state => state.reportDetail.reportSummary.detail.employeeId;
export const getReportDetailsReportStatusSk = state => state.reportDetail.reportSummary.detail.reportStatusSk;
export const getReportDetailsReplies = state => state.reportDetail.reportSummary.detail.replies;
export const getReportDetailsAttachments = state => state.reportDetail.reportSummary.detail.attachments;
export const getReportDetailsError = state => state.reportDetail.reportSummary.error;

export const getRecipientList = state => state.reportDetail.recipientDetails;
export const getActivityLog = state => state.reportDetail.activityDetails;
export const getReportDetailsStatusList = state => state.reportDetail.reportStatusList;
export const getAdminDiscussion = state => state.reportDetail.adminDiscussion;
export const getReportAuth = state => state.reportDetail.reportAuth;
// export const getdetailExtras = state => state.reportDetail.detailExtras;
