import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


import { SSEHubClient } from './SSEHubClient.service';
import { Observable } from 'rxjs';
import { SyncDatabaseService } from '../report-services/sync-database.service';

export interface ISetting {
    id: number;
    key: string;
    value: string;
    type: string;
    visibility: number;
    group: string;
}

@Injectable()
export class SSEHubSettingsService {

    constructor(
        private http: HttpClient,
        private sseHubClient: SSEHubClient,
        private syncDB: SyncDatabaseService
    ) { }

    getAll(): Observable<any> {
        const url = this.sseHubClient.serviceUrl() + '/api/settings';
        const offlineReq = new Observable(observer => {
            this.syncDB.getDetailsSettings().then((val) => {
                observer.next(val);
                observer.complete();
            });
        });
        return (window.navigator.onLine) ? this.http.get(url) : offlineReq;
    }

    get(group: string): Observable<any> {
        return this.http.get(
            this.sseHubClient.serviceUrl() + '/api/settings/' + encodeURIComponent(group));
    }

}
