import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Question, Answer } from '@shared/common-ux/models/QuestionAnswer';
import { DragDropModule, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DisplayOrderService, SortableObject } from './display-order.service';
import { DynamicQuestionsService } from '@shared/SSEHubClient/dynamic-questions.service';
import { CategoryDetailService } from '../../category-details/category-detail.service';
import { Guid } from '@shared/utilities/guid';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
    selector: 'app-display-order-edit',
    templateUrl: './display-order-edit.component.html',
    styleUrls: ['./display-order-edit.component.scss'],
    // imports: [CdkDropList, CdkDrag] - standalone only
})
export class DisplayOrderEditComponent implements OnInit, OnDestroy {
    isLoading = true;
    isAnswerValid = false;
    hasError = false;
    isReady = false;
    sourceEntity: any;
    dragulaBagId: string;
    questions: Question[] = new Array<Question>();
    answers: Answer[] = new Array<Answer>();
    sortableObjectList = new Array<SortableObject>();
    entityType: string;
    @ViewChild('sortOrderEditModal', { static: true }) public modal;
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private displayOrderService: DisplayOrderService,
        private dynamicQuestionsService: DynamicQuestionsService,
        private categoryDetailService: CategoryDetailService
    ) {

    }

    ngOnInit() {
        this.startLoadingState();
        this.displayOrderService.saveState
            .pipe(takeUntil(this.destroy$)).subscribe((state) => {
                if (state.processing === true) {
                    this.startLoadingState();
                } else if (state.processing === false) {
                    this.onReady();
                    this.closeWithEmit();
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    open(sourceEntity) {
        this.startLoadingState();
        this.sourceEntity = sourceEntity;
        this.entityType = this.getEntityType(sourceEntity);
        if (this.entityType === 'question') {
            if (sourceEntity.parentId > 0) {
                this.loadFollowupQuestions(sourceEntity);
            } else {
                this.loadGeneralQuestions(sourceEntity);
            }
        } else if (this.entityType === 'answer') {
            this.loadAnswers(sourceEntity);
        }
        this.modal.open();
    }

    closeWithEmit() {
        if (this.sourceEntity) {
            this.categoryDetailService.questionAddedorModified(this.sourceEntity.group);
        }
        this.sortableObjectList = new Array<SortableObject>();
        this.modal.close();
    }

    onClose(event?) {
        this.modal.close();
    }

    onSubmit() {
        this.startLoadingState();
        if (this.entityType === 'question') {
            this.displayOrderService.saveQuestionsDisplayOrder(this.sortableObjectList, this.sourceEntity);
        } else if (this.entityType === 'answer') {
            this.displayOrderService.saveAnswersDisplayOrder(this.sortableObjectList, this.answers, this.sourceEntity);
        }
    }

    private setupDragulaBag(): void {
        // TODO: remove this
        const guid = Guid.newGuid();
        this.dragulaBagId = `DragulaBag_${guid}`;
    }

    private loadGeneralQuestions(sourceEntity): void {
        this.dynamicQuestionsService.loadRootQuestions(sourceEntity.profileId, sourceEntity.categoryId, sourceEntity.group)
            .pipe(takeUntil(this.destroy$)).subscribe(
                (result) => {
                    if (result && result.questions) {
                        try {
                            const allQuestions = _.sortBy(result.questions, ['displayOrder']);
                            if (this.sourceEntity.group === 'INVOLVEMENTQUESTIONS') {
                                this.questions = _.filter(allQuestions, { 'referenceId': this.sourceEntity.referenceId });
                            } else {
                                this.questions = allQuestions;
                            }
                            this.sortableObjectList = this.displayOrderService.questionsToSortableObjects(this.questions);
                            this.onReady();
                        } catch (error) {
                            console.log(error);
                        }
                    } else {
                        this.onError('');
                    }
                },
                (error) => {
                    this.onError(error);
                }
            );
    }
    private loadFollowupQuestions(sourceEntity): void {
        if (sourceEntity === null) {
            return;
        }
        this.startLoadingState();
        this.dynamicQuestionsService.loadFollowUpQuestions(sourceEntity.profileId, sourceEntity.categoryId, sourceEntity.group, sourceEntity.parentId)
            .pipe(takeUntil(this.destroy$)).subscribe(
                (result) => {
                    if (result && result.questions) {
                        try {
                            this.questions = _.sortBy(result.questions, ['displayOrder']);
                            this.sortableObjectList = this.displayOrderService.questionsToSortableObjects(this.questions);
                            this.onReady();
                        } catch (error) {
                            this.onError(error);
                        }
                        this.onReady();
                    } else {
                        this.onError('');
                    }
                },
                (error) => {
                    this.onError(error);
                }
            );
    }

    private loadAnswers(sourceEntity): void {
        if (sourceEntity === null) {
            return;
        }
        this.dynamicQuestionsService.getAnswers(sourceEntity.questionMappingId)
            .pipe(takeUntil(this.destroy$)).subscribe(
                (result) => {
                    this.answers = _.sortBy(result, ['displayOrder']);
                    this.sortableObjectList = this.displayOrderService.answersToSortableObjects(this.answers);
                    this.onReady();
                },
                (error) => {
                    this.onError(error);
                }
            );
    }

    private getEntityType(sourceEntity): string {
        if (sourceEntity.hasOwnProperty('answerText')) {
            return 'answer';
        }
        return 'question';
    }

    private startLoadingState() {
        this.isLoading = true;
        this.isReady = false;
        this.hasError = false;
    }

    private onError(message) {
        if (message) {
            console.error(message);
        }
        this.isLoading = false;
        this.isReady = false;
        this.hasError = true;
    }

    private onReady() {
        this.isLoading = false;
        this.isReady = true;
        this.hasError = false;
    }

    // CDK changes
    drop(event: CdkDragDrop<SortableObject[]>) {
        console.log(event); // temporary debug
        moveItemInArray(this.sortableObjectList, event.previousIndex, event.currentIndex);
    }

}
