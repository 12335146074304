import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ParamMap } from '@angular/router';
import { Store } from '@ngrx/store';
import { getUser } from '@app/store/user/user.reducer';
import { InitializeUser as InitUser } from '@app/store/user/user.actions';
import { IUser, User, InitializeUser } from '@app/store/user';
import { State } from '@app/store';

import { Observable, of, combineLatest } from 'rxjs';
import { tap, filter, take, map, switchMap } from 'rxjs/operators';

@Injectable()
export class AdminRouteGuard implements CanLoad {


  constructor(
    private store: Store<State>,
    private router: Router,
  ) { }

  canLoad(route: Route): Promise<boolean> {
    // return Observable.of(true).toPromise();
    return this.getFromStoreOrAPI().pipe(
      map(u => {
        return this.isAdmin(<IUser>u).toPromise();
      })
    ).toPromise();
  }

  private isAdmin(user: IUser): any {
    const isAdmin = user ? User.hasPrivilegeSubStr(user, 'ADMIN') : false;

    if (!isAdmin) {
      this.router.navigate(['/home']);
    }
    return of(isAdmin);
  }

  private getFromStoreOrAPI(): Observable<any> {
    return this.store.select(getUser).pipe(
      tap(),
      map(user => user),
      take(1)
      );
  }
}
