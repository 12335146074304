import { Component, EventEmitter, ViewChild, OnInit, Output, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from '@shared/common-ux/components/ngbDate/ngb-date-parser-formatter.component';
import { IDynamicQuestion, IFlightSequence, FlightSequence, UpdateFlightSelection } from '../../new-report-store/wizard';
import { getFlightSelection, getQuestions, State } from '@newreport/new-report-store';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { flightSequenceDictionary } from '../dynamic-form.component';
import { ConfirmFlightChangeComponent } from '@wizardmodals/confirm-flight-change.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';

@Component({
    selector: 'app-date',
    templateUrl: './date.component.html',
    styleUrls: ['../dynamic-form.component.scss'],
    viewProviders: [
        { provide: ControlContainer, useExisting: NgForm },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true} },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ],
    providers: []
})

export class DateComponent implements OnInit, OnDestroy {
    question: IDynamicQuestion;
    @Output() answerEvent$ = new EventEmitter<any>();
    questionAttrs: object = {};
    @ViewChild('form', { static: true }) form: NgModel;
    fieldNameFlightAttribute;
    currentFlightDetails: IFlightSequence;
    @ViewChild(ConfirmFlightChangeComponent, { static: true }) flightChangeCom: ConfirmFlightChangeComponent;
    generalQuestions: Array<IDynamicQuestion> = [];
    destroy$: Subject<void> = new Subject<void>();

    constructor(private store: Store<State>) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    ngOnInit() {
        // console.log(this.questionAttrs);

        this.fieldNameFlightAttribute = this.questionAttrs && this.questionAttrs['fieldName'] ?
            flightSequenceDictionary[this.questionAttrs['fieldName'].trim().toUpperCase()] : null;
        if (this.fieldNameFlightAttribute) {
            this.currentFlightDetails = new FlightSequence();
            this.store.select(getFlightSelection).pipe(takeUntil(this.destroy$)).subscribe(flight => {
                if (flight) {
                    this.currentFlightDetails = flight;
                }
            });
            this.store.select(getQuestions('general')).pipe(takeUntil(this.destroy$)).subscribe(a => {
                this.generalQuestions = a;
            });
        }
    }

    toggleScrollLock(opened: boolean) {
        if (opened) {
            document.body.setAttribute('style', 'overflow: hidden;');
        } else {
            document.body.setAttribute('style', 'overflow: initial;');
        }
    }

    updateStoreIfFlightDetailQuestion($event) {
        let isQuestionsVisited = false;
        if (this.generalQuestions && this.generalQuestions.length > 0) {
            this.generalQuestions.forEach(a => {
                if (a.hasOwnProperty('userAnswer')) {
                    isQuestionsVisited = true;
                    return;
                }
            });
        }

        if (this.fieldNameFlightAttribute) {
            if (this.currentFlightDetails[this.fieldNameFlightAttribute] === null ||
                this.currentFlightDetails[this.fieldNameFlightAttribute] === undefined) {
                // first time entered
                this.saveToStore(null);
            } else if (isQuestionsVisited) {
                this.flightChangeCom.openModal();
            }
        }
    }

    saveToStore(flight: IFlightSequence) {
        this.currentFlightDetails[this.fieldNameFlightAttribute] = this.question.userAnswer;
        this.store.dispatch(new UpdateFlightSelection({ ...this.currentFlightDetails }));
    }

    cancelUpdate() {
        this.question.userAnswer = this.currentFlightDetails[this.fieldNameFlightAttribute];
    }

}
