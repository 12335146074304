import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { getUser } from '@app/store/user/user.reducer';
import { State } from '@app/store';
import { IUser, User } from '@app/store/user';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { environment } from '@app/../environments/environment';
import { Observable, Subject } from 'rxjs';
import {
    getSelectedList, SetSelectedList, LoadAllHistory, LoadTodoHistory,
    LoadMyHistory, getMyHistory, getTodoHistory, getAllHistory, IReportHistory, ResetTodo
} from './report-history-store/list';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Params } from '@angular/router';
import { FeatureFlagService } from 'app/shared/feature-flag/feature-flag.service';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';

@Component({
    selector: 'app-report-history',
    templateUrl: './report-history.component.html',
    styleUrls: ['./report-history.component.scss'],
    providers: [DatePipe]
})
export class ReportHistoryComponent implements OnInit, OnDestroy {
    isReportManagementAccess = false;
    selectedOption = 'myreports';
    iodFlag = false;
    isGeneralMgr = false;
    employeeId: string;
    userName: string;
    reports;
    cognosUrl = environment.cognosUrl;
    selectedList$: Observable<any>;
    private resetTodo: IReportHistory;
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        public featureFlag: FeatureFlagService,
        private store: Store<State>,
    ) {
        this.selectedList$ = this.store.select(getSelectedList);
        this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(user => this.onUserLoaded(user));
        this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => this.processRoute(params));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        this.featureFlag.hasFeatureFlag('FLAG_IOD_HISTORY').pipe(takeUntil(this.destroy$)).subscribe(f => this.iodFlag = f);

        this.store.dispatch(new ResetTodo(this.resetTodo));
    }

    onUserLoaded(user: IUser) {
        if (user) {
            this.userName = User.nameAndProfile(user);
            this.employeeId = user.id;
            this.isReportManagementAccess = User.hasPrivilege(user, 'WEB_HISTORY_MANAGEMENT_ACCESS');
            this.isGeneralMgr = user.profileId === 7;
            this.getHistoryLists();
        }
    }

    processRoute(params: Params) {
        if (params && params.option) {
            this.displayReports(params.option);
            if (params.option === 'myreports') {
                this.selectedOption = 'My Reports';
            } else if (params.option === 'reportsearch') {
                this.selectedOption = 'Report Search';
            } else if (params.option === 'allreports') {
                this.selectedOption = 'All Reports';
            } else {
                this.selectedOption = 'Unknown';
            }
        } else {
            this.displayReports('myreports');
            this.selectedOption = 'My Reports';
        }
    }

    displayReports(selectedReport: string) {
        this.store.dispatch(new SetSelectedList(selectedReport));
    }

    private getHistoryLists() {
        // Initialize lists if they haven't already been initialized

        this.store.select(getMyHistory).pipe(takeUntil(this.destroy$)).subscribe(myList => {
            if (myList.isLoading === null) {
                this.store.dispatch(new LoadMyHistory({
                    employeeId: this.employeeId,
                    filterString: ''
                }));
            }
        });

        this.store.select(getTodoHistory).pipe(takeUntil(this.destroy$)).subscribe(todoList => {
            if (todoList.isLoading === null) {
                this.store.dispatch(new LoadTodoHistory(this.employeeId));
            }
        });

        this.store.select(getAllHistory).pipe(takeUntil(this.destroy$)).subscribe(allList => {
            if (allList.isLoading === null) {
                this.store.dispatch(new LoadAllHistory(this.employeeId));
            }
        });
    }
}
