<ol class="breadcrumb">
    <li>
        <a class="active">
            <i class="icon-home"></i> Home</a>
    </li>
</ol>

<!-- disabling offline-sync for the time being -->
<!-- <button class="sync" (click)="sync()">Sync</button> -->
<!-- <div class="pending-container" *ngIf="offlineReportsList && offlineReportsList.length > 0">
    <h3>Submitted reports</h3>
    <mat-expansion-panel *ngFor="let report of offlineReportsList">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <h4>{{report.category}}
                    <i class="icon-warning" *ngIf="report.hasError"></i>
                    <i class="icon-circle-check" *ngIf="report.state === offlineStates.uploaded"></i>
                    <img class="report-uploading" src="assets/spinner.gif"
                        *ngIf="report.state === offlineStates.uploading">
                </h4>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <h4>Date: {{report.date | date: 'short'}}</h4>
        <h4>Status: <span [ngClass]="{'errorText': report.hasError}">{{report.state}}</span></h4>
        <button class="button-retry" *ngIf="report.hasError" (click)="retryUpload(report)">Retry upload</button>
    </mat-expansion-panel>
</div> -->

<div class="row">
    <div class="col-md-8">
        <h4>
            Welcome to the American Airlines Corporate Event Reporting System (CERS).
        </h4>
    </div>
    <div class="col-md-4">
        <img src="assets/working-together-team-members.jpg">
    </div>
</div>