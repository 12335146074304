import { Injectable } from '@angular/core';

@Injectable()
export class SSEHubNotificationEndpoints {
    public static urlList = {
        dev: 'http://htappd007480.qcorpaa.aa.com/SSEHub.Notification/Notifications',
        test: 'https://cers-test.qcorpaa.aa.com/SSEHub.Notification',
        stage: 'https://cers-stage.aa.com/SSEHub.Notification',
        prod: 'https://cers.aa.com/SSEHub.Notification'
    };
}
