import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SSEHubClient } from './SSEHubClient.service';
import { SyncDatabaseService } from '../report-services/sync-database.service';
export interface AirportCode {
    airportId: string;
    airportDesc: string;
    city: string;
}

@Injectable()
export class SSEHubAirportService {

    constructor(
        private http: HttpClient,
        private sseHubClient: SSEHubClient,
        private syncDB: SyncDatabaseService
    ) { }

    getAll(): Observable<any> {
        const url = this.sseHubClient.serviceUrl() + '/api/airport';
        const offlineReq = new Observable(observer => {
            this.syncDB.getAirportCodes().then((val) => {
                observer.next(val);
                observer.complete();
            });
        });
        return (window.navigator.onLine) ? this.http.get(url) : offlineReq;
    }

}
