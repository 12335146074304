import { Component, ViewChild, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { NotificationService } from 'app/shared/error-handler-notify/services';
import { IFlightSequence, UpdateFlightSelection } from '../new-report-store/wizard';
import { take, takeUntil } from 'rxjs/operators';
import { getFlightSelection, State } from '../new-report-store';
import { SSEHubCrewService } from 'app/shared/SSEHubClient';
import { UtilitiesService } from 'app/shared/utilities/utilities.service';
import { Subject } from 'rxjs';

export interface ICrewModel {
    firstname: string;
    lastname: string;
    employeeno: number;
    flightdate: string;
    flightno: string;
    departurestation: string;
    crewbase: string;
    flightposition: string;
    sequenceposition: string;
    operatingindicator: string;
    sequencenumber: string;
    sequenceorigindate: string;
    flightpositiondesc: string;
    permcrewbase: string;
    division: string;
    equipmentgroup: string;
    seat: string;
    sortorder: string;
    source: string;
}

@Component({
    selector: 'app-crew-search',
    templateUrl: './crew-search.component.html',
    styleUrls: ['./modals.component.scss']
})

export class CrewSearchComponent implements OnDestroy {

    @ViewChild('crewSearchModal', { static: true }) public modal: any;
    @Output() selectedCrewData = new EventEmitter<ICrewModel>();
    isSearching = false;
    searchError = false;
    close = false;  // this is used to force the refresh of the child flight-search component.
    crewList: ICrewModel[];
    selectedCrew: ICrewModel;
    flightResults: IFlightSequence = {
        aircraftTypeDescription: null,
        arrivalStation: null,
        departureStation: null,
        interruptStation: null,
        employeeId: null,
        fleetDescription: null,
        flightDate: null,
        flightNumber: null,
        sequencePosition: null,
        tailNumber: null
    };
    displayFlightSearch = true; // should we display the flight search options or can we get the flight data from the flight page?

    // begin grid display parameters
    private gridApi;
    private gridColumnApi;
    quickSearchValue: string;
    rowSelection = 'single';
    rowData: any[];
    defaultColDef = {
        sortable: true
    };
    columnDefs = [
        {
            headerName: 'First name',
            field: 'firstName',
        },
        {
            headerName: 'Last name',
            field: 'lastName',
        },
        {
            headerName: 'Emp. ID',
            field: 'employeeId',
            width: 90,
        },
        {
            headerName: 'Crew base',
            field: 'crewBase',
            width: 115,
        },
        {
            headerName: 'Position',
            field: 'flightPosition',
            width: 95,
        }
    ];
    gridOptions = {
        defaultColDef: {
            resizable: true,
            sortable: true,
            unSortIcon: true,
        },
        columnDefs: this.columnDefs,
        rowSelection: 'single',
        suppressColumnVirtualisation: true
    };
    style = {
        width: '100%',
        height: '314px',
    };
    // end grid display parameters

    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<State>,
        private crewService: SSEHubCrewService,
        public datepipe: DatePipe,
        public notificationService: NotificationService,
        public utilities: UtilitiesService

    ) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    // grid functions
    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.calculateSizing();
    }

    onQuickFilterChanged() {
        this.gridApi.setQuickFilter(this.quickSearchValue);
    }

    calculateSizing() {
        const allColumnIds = [];
        this.gridColumnApi.getAllColumns().forEach(function (column) {
            if (!column.colDef.width) {
                allColumnIds.push(column.colId);
            }
        });
        this.gridColumnApi.autoSizeColumns(allColumnIds);
        if (this.gridColumnApi.columnController.scrollWidth > this.gridColumnApi.columnController.bodyWidth) {
            this.gridColumnApi.sizeColumnsToFit(this.gridColumnApi.columnController.scrollWidth - 1);
            // the size parameter fixes a weird horizontal scroll that was only appearing at the end of the display and covering the last row
        }
    }
    // end grid functions

    open() {
        if (!window.navigator.onLine) {
            this.notificationService.showWarning('Crew search is not available while offline.');
        } else {
            this.reset();
            this.populateFlightDetails();
            if (!this.displayFlightSearch) {
                this.crewSearch();
            }
            this.modal.open();
        }
    }

    populateFlightDetails() {
        this.displayFlightSearch = true;
        this.store.select(getFlightSelection).pipe(take(1)).pipe(takeUntil(this.destroy$)).subscribe(flight => {
            if (flight && !this.utilities.isNullOrEmpty(flight.flightNumber)
                && !this.utilities.isNullOrEmpty(flight.flightDate) && !this.utilities.isNullOrEmpty(flight.departureStation)) {
                this.flightResults = flight;
                this.displayFlightSearch = false;
            }
        });
    }

    reset() {
        this.close = false;
        this.searchError = false;
        this.isSearching = false;
        this.selectedCrew = null;
        this.crewList = null;
        this.quickSearchValue = null;
    }

    onClose($event) {
        this.reset();
        this.close = true;
    }

    onRowSelected($event) {
        if ($event.node.isSelected()) {
            this.selectedCrew = $event.node.data;
        }
    }

    populatePersonData() {
        if (this.displayFlightSearch) {
            this.store.dispatch(
                new UpdateFlightSelection({ ...this.flightResults }));
        }
        this.selectedCrewData.emit(this.selectedCrew);
        this.modal.close();
    }

    // this forces a DOM update
    updateIsSearching(value: boolean) {
        this.isSearching = value;
    }

    flightSearch(event) {
        this.reset();
        if (event.flightNumber && event.flightDate) {
            this.flightResults = event;
            this.crewSearch();
        }
    }

    crewSearch() {
        this.updateIsSearching(true);
        const searchdate = this.datepipe.transform(this.flightResults.flightDate, 'yyyy-MM-dd', 'UTC');
        this.crewService.get(this.flightResults.flightNumber, searchdate, this.flightResults.departureStation).pipe(takeUntil(this.destroy$)).subscribe(
            crew => {
                if (!crew) {
                    this.searchError = true;
                } else {

                    this.crewList = _.orderBy(crew, function (crewMember) {
                        if (crewMember['deadhead']) {
                            crewMember['flightPosition'] = 'DH';
                        }

                        // This else if was used for the old SSEHUB API. The new API does not return operating indicator
                        // TODO: Determine if this code is really needed. Its only used in the modal display.
                        // else if (crewMember['OPERATINGINDICATOR'] === 'C' && crewMember['SEQUENCENUMBER'] !== '0') {
                        //     crewMember['FLIGHTPOSITION'] = 'CX';
                        // }
                    });
                }
            },
            (error) => {
                console.error('Crewsearch failed.');
                this.notificationService.showError('Crew search failed.');
            },
            () => {
                this.updateIsSearching(false);
            });
    }
}
