import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SSEHubClient } from './SSEHubClient.service';
import { IAssembledReport, IReportSubmitConfirmation } from '@models';
import { SyncDatabaseService } from '../report-services/sync-database.service';
import { catchError, retry } from 'rxjs/operators';
import { LoggerService } from '@shared/error-handler-notify/services';


@Injectable()
export class SSEHubNewReportService {
    constructor(
        private http: HttpClient,
        private sseHubClient: SSEHubClient,
        private syncDB: SyncDatabaseService,
        private logger: LoggerService
    ) { }

    getQuestions(profileId): Observable<any> {
        return this.http.get(this.sseHubClient.serviceUrl() + '/api/profiles/' + profileId + '/allQuestions');
    }

    getAnswers(profileId): Observable<any> {
        return this.http.get(this.sseHubClient.serviceUrl() + '/api/profiles/' + profileId + '/allAnswers');
    }

    getFollowups(profileId): Observable<any> {
        return this.http.get(this.sseHubClient.serviceUrl() + '/api/profiles/' + profileId + '/allFollowups');
    }

    getAllQuestionsAndAnswers(profileId, categoryId, groupName): Observable<any> {
        const url = this.sseHubClient.serviceUrl() + '/api/profiles/' + profileId + '/categories/'
            + categoryId + '/group/' + groupName + '/allQuestionsAndAnswers';
        const offlineReq = new Observable(observer => {
            this.syncDB.getAllQuestionsAndAnswers(profileId, categoryId, groupName).then((val) => {
                observer.next(val);
                observer.complete();
            });
        });
        return (window.navigator.onLine) ? this.http.get(url) : offlineReq;
    }

    submitReport(report: IAssembledReport): Observable<IReportSubmitConfirmation> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json; charset=utf-8'
            })
        };
        return this.http.post<IReportSubmitConfirmation>(this.sseHubClient.serviceUrl() + '/api/newReports', JSON.stringify(report), httpOptions);
    }
}

