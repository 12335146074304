import { IDynamicQuestion, IQuestion, WizardReset, WizardUpdate, IAnswer } from './new-report-store/wizard';
import { Question } from 'app/shared/common-ux/models/QuestionAnswer';
import * as _ from 'lodash';
import { Injectable, OnDestroy } from '@angular/core';
import { IUser } from 'app/store/user/user.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DatePipe } from '@angular/common';
import { AirportCode, SSEHubAirportService } from 'app/shared/SSEHubClient/airport.service';
import { Store } from '@ngrx/store';
import { getProxyMode, getProxyBackup } from 'app/store/user';
import { UnfinishedReportDatabaseService } from 'app/shared/report-services/unfinished-report-database.service';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { getTodo, IReportHistory } from 'app/report-history/report-history-store/list';
import { LoggerService } from '@shared/error-handler-notify/services';
import { UtilitiesService } from 'app/shared/utilities/utilities.service';
import {
    IReportQuestionAnswer, IAssembledReport, IReportImage,
    AssembledReport, IJsonQuestionAnswer, ITime
} from '@models';
import { FeatureFlagService } from 'app/shared/feature-flag/feature-flag.service';
import { State } from './new-report-store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class ReportAssemblyService implements OnDestroy {
    public categoryId: number;
    public title: string;
    public categoryDescription: string; // was category_id
    public flightQuestions: IQuestion[];
    public notes: IDynamicQuestion[];
    public attachments: IReportImage[] = new Array<IReportImage>();
    public questions: IReportQuestionAnswer[];
    public user: IUser;
    public proxyMode: boolean;
    public backupUser: IUser;
    public airportCodes: AirportCode[];
    public isPwa = false;
    private isTodo: boolean;
    private eventId: number;
    private todoReport: IReportHistory;
    private attachmentJson: string;
    private questionsJson: any;

    destroy$: Subject<void> = new Subject<void>();


    constructor(
        private deviceService: DeviceDetectorService,
        public datepipe: DatePipe,
        private airportService: SSEHubAirportService,
        private store: Store<State>,
        private unfinishedReportDBService: UnfinishedReportDatabaseService,
        private router: Router,
        public logger: LoggerService,
        public utilities: UtilitiesService,
        private flagService: FeatureFlagService
    ) {
        this.airportService.getAll().pipe(takeUntil(this.destroy$)).subscribe(codes => this.airportCodes = (codes) ? codes : []);
        this.store.select(getProxyMode).pipe(takeUntil(this.destroy$)).subscribe(proxymode => this.proxyMode = proxymode);
        this.store.select(getProxyBackup).pipe(takeUntil(this.destroy$)).subscribe(backupuser => this.backupUser = backupuser);
        this.isPwa = (window.matchMedia('(display-mode: standalone)').matches || (environment.envName && environment.envName.includes('dev')));
        this.store.select(getTodo).pipe(takeUntil(this.destroy$)).subscribe(todo => this.getSelectedTodoReport(todo));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    // check if in state we have a partially filled out report
    isStateReportDirty(flightQs: Array<IDynamicQuestion>, generalQs: Array<IDynamicQuestion>, notesQs: Array<IDynamicQuestion>) {
        return this.checkForAnswers(flightQs) || this.checkForAnswers(generalQs) || this.checkForAnswers(notesQs);
    }

    // this searches the input questions array to determine if any of the questions were answered
    // this is used when we're trying to determine whether or not to display the section
    checkForAnswers(questions): boolean {
        let answered = false;
        if (questions) {
            for (const question of questions) {
                if (question) {
                    if (question.userAnswer && question.answerInputType && question.answerInputType.toUpperCase() !== 'INVOLVEMENT') {
                        answered = true;
                        break;  // breaks out of for loop and returns true
                    } else if (question.isChecked) {
                        answered = true;
                        break; // breaks out of for loop and returns true
                    } else if (question.attrs &&
                        question.attrs['multiInputType']?.toLowerCase() === 'userinputaddress' &&
                        question.answerText) {
                        answered = true;
                        break;
                    }
                    if (question.followupQuestions) {
                        answered = this.checkForAnswers(question.followupQuestions);
                    }
                    if (question.answerInputType && (question.answerInputType.toUpperCase() === 'CHECKBOX')) { // || question.answerInputType.toUpperCase() === 'MULTI')) {
                        answered = this.checkForAnswers(question.answers);
                    }
                    if (answered) {
                        break;
                    }
                }
            }
        }
        return answered;
    }

    ConvertAttachmentJson() {
        try {
            const attJson = { 'Attachments': this.attachments };
            this.attachmentJson = JSON.stringify(attJson);
        } catch (error) {
            const errorMessage = { 'message': '--Attachments Convert Exception--' };
            this.logger.error(errorMessage);
            console.log(error.toString());
            throw new Error('Attachments Convert Exception');
        }
    }

    ConvertQuestionsJson() {
        try {
            this.questionsJson = this.extractReportQuestionsAndAnswers(this.questions);
        } catch (error) {
            const errorMessage = { 'message': '--Questions Convert Exception--' };
            this.logger.error(errorMessage);
            console.log(error.toString());
            throw new Error('Questions Convert Exception');
        }
    }

    getFlightObject(): any {
        return this.getFlight();
    }

    getQuestionJSON(): any {
        return _.cloneDeep(this.questionsJson);
    }

    // Truly just assign the report properties
    // No long processing for flattening questions/answers
    GetAssembledReport() {

        const report: IAssembledReport = new AssembledReport(0, null, null, new Date(), null);

        report.reportId = '';
        report.reportPk = 0;
        report.title = this.title;
        report.reportVersion = ' ';

        if (this.proxyMode) {
            report.employeeId = this.user.id;
            report.profileId = this.user.profileId;
            report.parsedReport.employeeNumber = this.user.id;
            report.parsedReport.profileId = this.user.profileId;
            report.parsedReport.proxyUser = this.backupUser.id;
        } else {
            report.employeeId = this.user.id;
            report.profileId = this.user.profileId;
            report.parsedReport.employeeNumber = this.user.id;
            report.parsedReport.profileId = this.user.profileId;
            report.parsedReport.proxyUser = '';
        }

        let device_os = this.deviceService.os;
        const isMobile = (this.deviceService.isMobile() || this.deviceService.isTablet());


        // !! Transformer is hardcoded to look at device_os in order to set the source_sk when it transforms data into the
        // SOS tables.Changes to the mobile OS strings(either 'iOS' or 'Android') will result in Transformer not seeing their source_sk properly)
        // device_type is currently being used in the CP tables ONLY for providing more info about the source (PWA vs not)
        if (device_os === 'Mac' && isMobile) {
            device_os = 'iOS';
        }
        report.deviceType = isMobile ? 'MOBILE' : 'DESKTOP';
        if (this.isPwa) {
            report.deviceType += '-PWA';
        }
        report.deviceOs = device_os;
        report.deviceOsVersion = this.deviceService.os_version;
        // !! ^^

        report.apiVersion = '';
        report.dateTimeSubmitted = new Date();
        report.dateTimeTransmitted = new Date();
        report.dateTimeReceived = new Date();

        // Attachments
        report.attachmentJson = this.attachmentJson;
        report.status = 0;

        // CategoryId
        report.parsedReport.categoryId = this.categoryId;
        // Flight
        //report.parsedReport.flight = this.flightJson;
        // Notes and Recommendations
        //report.parsedReport.notes = this.getAnswerAtIndex(this.notesJson, 0) ? this.notesJson[0].answerText : null;
        //report.parsedReport.recommendations = this.getAnswerAtIndex(this.recommendationsJson, 0) ? this.recommendationsJson[0].answerText : null;

        // All Questions
        report.parsedReport.questions = this.questionsJson;

        // Add logs if report.Questions has no questions
        // this.categoryId.toUpperCase().trim() !== 'IDONTKNOWANYTHING' ... changing to id = 11
        if ((report.parsedReport.questions && report.parsedReport.questions.length === 0 && this.categoryId !== 11)
            || report.parsedReport.questions === null
            || report.parsedReport.questions === undefined) {
            const errorMessage = { 'message': '!! -- Questions Section Has No General Questions/Answers -- !!' };
            this.logger.error(errorMessage);

            // Need to raise an exception to stop submitting the report
            throw new Error('You may have attempted to submit a blank report, please ensure all sections are updated and try again.');
        }

        // IsTodo
        if (this.isTodo) {
            report.parsedReport.eventId = this.eventId;
        }
        report.reportJson = JSON.stringify(report.parsedReport);
        report.parsedReport = null;

        return report;
    }

    private getFlight(): any {

        let flightObject = null;

        // THESE VARIABLBES ARE POPULATED FROM QUESTIONS THAT HAVE HARD-CODED ATTRIBUTE TAGS WHICH COME FROM DB.
        const flightNumber =
            this.getAnswerAtIndex(this.extractAnswers(this.findQuestionWithAttribute(this.flightQuestions, 'fieldName', 'FlightNumber')), 0);
        const flightDate =
            this.getAnswerAtIndex(this.extractAnswers(this.findQuestionWithAttribute(this.flightQuestions, 'fieldName', 'DepartureDate')), 0);
        const departureStation =
            this.getAnswerAtIndex(this.extractAnswers(this.findQuestionWithAttribute(this.flightQuestions, 'fieldName', 'DepartureStation')), 0);
        const arrivalStation =
            this.getAnswerAtIndex(this.extractAnswers(this.findQuestionWithAttribute(this.flightQuestions, 'fieldName', 'ArrivalStation')), 0);
        const noseNumber =
            this.getAnswerAtIndex(this.extractAnswers(this.findQuestionWithAttribute(this.flightQuestions, 'fieldName', 'TailNumber')), 0);
        const fleetType =
            this.getAnswerAtIndex(this.extractAnswers(this.findQuestionWithAttribute(this.flightQuestions, 'fieldName', 'FleetDescription')), 0);
        const phase =
            this.getAnswerAtIndex(this.extractAnswers(this.findQuestionWithAttribute(this.flightQuestions, 'fieldName', 'FlightPhase')), 0);
        // IsTodo
        if (this.isTodo) {

            const todoFlightNumber = !this.utilities.isNullOrEmpty(this.todoReport.flightNumber) ? this.todoReport.flightNumber : '';
            const todoFlightDate = !this.utilities.isNullOrEmpty(this.todoReport.flightDate.toString()) ?
                this.datepipe.transform(new Date(this.todoReport.flightDate).toDateString(), 'yyyy-MM-dd', 'UTC') : '';
            const todoDepartureStation = !this.utilities.isNullOrEmpty(this.todoReport.departureStation) ? this.todoReport.departureStation : '';
            const todoArrivalStation = !this.utilities.isNullOrEmpty(this.todoReport.arrivalStation) ? this.todoReport.arrivalStation : '';
            const todoFleetType = !this.utilities.isNullOrEmpty(this.todoReport.fleetType) ? this.todoReport.fleetType : '';
            const todoNoseNumber = !this.utilities.isNullOrEmpty(this.todoReport.noseNumber) ? this.todoReport.noseNumber : '';
            flightObject = {
                airline: 1,
                flightNumber: flightNumber ? flightNumber.answerText : todoFlightNumber,
                flightDate: flightDate ? new Date(flightDate.answerText) : todoFlightDate,
                departureStation: departureStation ? departureStation.answerText : todoDepartureStation,
                arrivalStation: arrivalStation ? arrivalStation.answerText : todoArrivalStation,
                noseNumber: noseNumber ? noseNumber.answerText : todoNoseNumber,
                fleetType: fleetType ? noseNumber.answerText : todoFleetType,
            };

        } else {
            flightObject = {
                airline: 1,
                flightNumber: flightNumber ? flightNumber.answerText : '',
                flightDate: flightDate ? new Date(flightDate.answerText) : '',
                departureStation: departureStation ? departureStation.answerText : '',
                arrivalStation: arrivalStation ? arrivalStation.answerText : '',
                noseNumber: noseNumber ? noseNumber.answerText : '',
                fleetType: fleetType ? fleetType.answerText : '',
                phase: phase ? phase.answerText : ''
            };
        }
        Object.keys(flightObject).forEach(k => flightObject[k] = flightObject[k].trim());
        return flightObject;
    }

    public extractReportQuestionsAndAnswers(questions: any): Array<IJsonQuestionAnswer> {

        const reportQuestionsAnswers: Array<IJsonQuestionAnswer> = [];

        if (questions && questions.length > 0) {

            questions.forEach(question => {

                // if (question.answerInputType &&
                //     question.answerInputType.toUpperCase() === 'MULTI' &&
                //     question.attrs &&
                //     question.attrs['dataType']?.toLowerCase() === 'amsaddress') {
                //     const multiAnswers = this.extractAnswers(question);
                //     if (multiAnswers.length > 0 && multiAnswers[0].answerArray.length > 0) {
                //         const q: IJsonQuestionAnswer = {
                //             'questionMappingId': question && question.questionMappingId ? Math.floor(question.questionMappingId) : 0,
                //             'userAnswer': '',
                //             'answerId': 0,
                //             'inputType': 'amsAddress',
                //             'userAnswerArray': multiAnswers[0].answerArray,
                //             'identifier': null,
                //         };
                //         if (question.recurrence > 0) {
                //             q.recurrence = question.recurrence;
                //         }
                //         reportQuestionsAnswers.push(q);
                //     }

                // } else {
                const answers = this.extractAnswers(question);
                if (answers && answers.length > 0) {    // an array of answers

                    answers.forEach(answer => {
                        if (question.answerInputType.toUpperCase() === 'NUMBER') {
                            question.answerInputType = 'TEXTBOX';
                        }
                        // const personDetail = question && question.attrs && question.attrs['personMapping'] &&
                        //     question.attrs['personMapping'].length > 0 ?
                        //     question.attrs['personMapping'] : null;
                        const onegroup: IJsonQuestionAnswer = {
                            'questionMappingId': question && question.questionMappingId ? Math.floor(question.questionMappingId) : 0,
                            'questionText': question && question.questionText ? question.questionText.toString().trim() : '',
                            'userAnswer': answer && answer.answerText ? answer.answerText.toString().trim() : '',
                            'answerId': answer && answer.answerId ? answer.answerId : 0,
                            'inputType': question && question.answerInputType ? question.answerInputType.toUpperCase() : '',
                            'identifier': question && question.attrs && question.attrs.hasOwnProperty('fieldName') ? question.attrs.fieldName : null, // this.getPersonDetail(personDetail),
                            'tags':  question && question.tags ? question.tags : []
                        };
                        if (question.recurrence > 0) {
                            onegroup.recurrence = question.recurrence;
                        }
                        if (question && question.answerInputType && (question.answerInputType.toUpperCase() === 'INVOLVEMENT' ||
                            question.answerInputType.toUpperCase() === 'QUESTIONGROUP' ||
                            question.recurrence > 0)) {
                            onegroup['followupQuestions'] = this.extractReportQuestionsAndAnswers(question.followupQuestions);
                        }
                        reportQuestionsAnswers.push(onegroup);
                    });
                }

                if (question && question.answerInputType && question.answerInputType.toUpperCase() !== 'INVOLVEMENT' &&
                    question.answerInputType.toUpperCase() !== 'QUESTIONGROUP' &&
                    !(question.recurrence > 0)) {
                    // recursive processing followup questions
                    if (question.followupQuestions && question.followupQuestions.length >= 1) {
                        reportQuestionsAnswers.push(...this.extractReportQuestionsAndAnswers(question.followupQuestions));
                    }
                    if (question.answerInputType && question.answerInputType.toUpperCase() === 'CHECKBOX') {
                        // followup question for checkboxes are in the answer not the question
                        for (const answer of question.answers) {
                            if (answer.isChecked && answer.followupQuestions) {
                                reportQuestionsAnswers.push(...this.extractReportQuestionsAndAnswers(answer.followupQuestions));
                            }
                        }
                    }
                }
                //}

            });
        }

        return reportQuestionsAnswers;
    }

    findQuestionWithAttribute(questions: any, attributeKey: string, attributeValue: string): Question {
        let questionToReturn = null; // new Question();
        if (questions) {
            for (const question of questions) {
                if (question) {
                    if (question.attrs && question.attrs.hasOwnProperty(attributeKey) &&
                        question.attrs[attributeKey].toUpperCase() === attributeValue.toUpperCase()) {
                        questionToReturn = question;
                        break;  // breaks out of for loop and returns true
                    }
                    if (question.followupQuestions) {
                        questionToReturn = this.findQuestionWithAttribute(question.followupQuestions, attributeKey, attributeValue);
                    }
                    if (question.answerInputType && question.answerInputType.toUpperCase() === 'CHECKBOX') {
                        questionToReturn = this.findQuestionWithAttribute(question.answers, attributeKey, attributeValue);
                    }
                    if (questionToReturn) {
                        break;
                    }
                }
            }
        }

        return questionToReturn;
    }

    findAllQuestionsWithAttribute(questions: any, attributeKey: string, attributeValue: string): Question[] {
        let questionToReturn = [];
        if (questions) {
            for (const question of questions) {
                if (question) {
                    if (question.attrs && question.attrs.hasOwnProperty(attributeKey) &&
                        question.attrs[attributeKey].toUpperCase() === attributeValue.toUpperCase()) {
                        questionToReturn.push(question);
                    }
                    if (question.followupQuestions) {
                        questionToReturn =
                            questionToReturn.concat(this.findQuestionWithAttribute(question.followupQuestions, attributeKey, attributeValue));
                    }
                    if (question.answerInputType && question.answerInputType.toUpperCase() === 'CHECKBOX') {
                        questionToReturn = questionToReturn.concat(this.findQuestionWithAttribute(question.answers, attributeKey, attributeValue));
                    }
                }
            }
        }

        return questionToReturn;
    }
    // returns an array. The array will have a single element for all except the checkbox which may have multiple
    // returning an array in all cases for consistency
    extractAnswers(question): any {
        let userAnswer = [];
        if (question && question.answerInputType) {
            switch (question.answerInputType.toUpperCase()) {

                case 'RADIO':
                case 'RADIOBUTTON':
                case 'DROPDOWN':
                    if (question.userAnswer) {
                        userAnswer.push(question.userAnswer);
                    }
                    break;

                case 'CHECKBOX':
                    const filtered = _.filter(question.answers, { 'isChecked': true });
                    userAnswer = filtered && filtered.length > 0 ? filtered : null;
                    break;

                case 'DATE':
                    if (question.userAnswer) {
                        question.userAnswer = this.convertDate(question.userAnswer);
                        if (question.answers && question.answers.length > 0) {
                            userAnswer.push({
                                ...question.answers[0],
                                answerText: question.userAnswer
                            });
                        } else {
                            userAnswer.push({
                                answerText: question.userAnswer
                            });
                        }
                    }
                    break;
                case 'NUMBER':
                case 'TEXTAREA':
                case 'TEXTBOX':
                    if (question.userAnswer) {
                        if (question.answers && question.answers.length > 0) {
                            userAnswer.push({
                                ...question.answers[0],
                                answerText: question.userAnswer
                            });
                        } else {
                            userAnswer.push({
                                answerText: question.userAnswer
                            });
                        }
                    }
                    break;

                case 'TIME':
                    if (question.userAnswer) {
                        if (question.answers && question.answers.length > 0) {
                            userAnswer.push({
                                ...question.answers[0],
                                answerText: this.convertTimeTo24Hr(question.userAnswer)
                            });
                        } else {
                            userAnswer.push({
                                answerText: this.convertTimeTo24Hr(question.userAnswer)
                            });
                        }
                    }
                    break;
                case 'INVOLVEMENT':
                case 'QUESTIONGROUP':
                    userAnswer.push({
                        answerText: (question && question.userAnswer && question.userAnswer.answerText) ? question.userAnswer.answerText : '',
                    });
                    break;
                // case 'MULTI':
                //     const multiAnswer = this.extractMultiInputAnswers(question);
                //     if (multiAnswer.length > 0) {
                //         userAnswer.push({
                //             'answerArray': multiAnswer
                //         });
                //     }
                //     break;

            }
        }
        return userAnswer;
    }

    // extractMultiInputAnswers(question): any {
    //     const userAnswer = [];
    //     if (question.answerInputType && question.answerInputType.toUpperCase() === 'MULTI') {
    //         if (question.attrs &&
    //             question.attrs['dataType']?.toLowerCase() === 'amsaddress') {
    //             userAnswer.push(...question.answers.filter(answer => {
    //                 const multiInputType = answer.attrs['multiInputType'] ? answer.attrs['multiInputType'].toLowerCase() : '';
    //                 switch (multiInputType) {
    //                     case 'amsid':
    //                         return !this.utilities.isNullOrEmpty(answer.multiInputText) && answer.answerText.length > 0 && answer.isChecked;
    //                     case 'opsaddress':
    //                     case 'dispatchaddress':
    //                         return !this.utilities.isNullOrEmpty(answer.multiInputText) && answer.answerText.length > 0;
    //                     case 'amsaddress':
    //                         return answer.isChecked && answer.answerText.length > 0;
    //                     case 'userinputaddress':
    //                         return answer.answerText.length > 0;
    //                 }
    //                 return false;
    //             }).map(a => {
    //                 const multiInputType = (a.attrs && a.attrs['multiInputType']) ? a.attrs['multiInputType'].toLowerCase() : '';
    //                 switch (multiInputType) {
    //                     case 'amsid':
    //                         return (a.multiInputText + a.answerText).toUpperCase();
    //                     case 'opsaddress':
    //                     case 'dispatchaddress':
    //                         return (a.answerText + a.multiInputText).toUpperCase();
    //                     default:
    //                         return a.answerText.toUpperCase();
    //                 }
    //             }));
    //         }
    //     }
    //     return userAnswer;
    // }

    extractHourMinuteAmPm(time: string): ITime {
        return {
            hour: time.match(/((0|[1-9])+)/)[1],
            minute: time.match(/:([0-9]+)/)[1],
            amPm: time.match(/([AaPp][Mm])$/)[1].toUpperCase()
        };
    }

    convertDate(date: string) {
        return this.datepipe.transform(date, 'MM/dd/yyyy', 'UTC');
    }

    convertTimeTo24Hr(time: string) {
        // check if its already in 24hr format
        if (!time.match(/((1[0-2]|0?[0-9]):([0-5][0-9]) ?([AaPp][Mm]))/)) {
            return time;
        }

        const timeParsed: ITime = this.extractHourMinuteAmPm(time);
        if (timeParsed.amPm === 'PM' && timeParsed.hour !== '12') {
            timeParsed.hour = (Number(timeParsed.hour) + 12).toString();
        }
        if (timeParsed.amPm === 'AM' && timeParsed.hour === '12') {
            timeParsed.hour = '00';
        }
        return (timeParsed.hour + ':' + timeParsed.minute + ':00');
    }

    private getAnswerAtIndex(answer: any, index: number): any {
        return answer && answer.length > 0 ? answer[index] : null;
    }

    getAirportCodeOptions(input: string) {
        let airportCodeOptions = [];
        if (input.length > 0) {
            airportCodeOptions = this.airportCodes.filter(s => s.airportId.startsWith(input));
            if (input.length > 2) {
                // this converts the descriptions to uppercase and compares it to the input value to check if user is entering
                // an airport's name instead of the station code id.
                const cityCodes = [];
                this.airportCodes.map(s => {
                    if (s.airportDesc.toUpperCase().indexOf(input) > -1) {
                        cityCodes.push(s);
                    }
                });
                // combine station code suggestions with the matching station names, ensuring no duplicates
                airportCodeOptions = Array.from(new Set(airportCodeOptions.concat(cityCodes)).values());
            }
        }
        return airportCodeOptions;
    }

    public saveUnfinishedReport() {
        this.store.dispatch(new WizardUpdate());
        this.router.navigate(['/todo']);
        this.unfinishedReportDBService.saveUnfinishedReport().then(() => {
            this.store.dispatch(new WizardReset());
        });
    }
    private getSelectedTodoReport(report: IReportHistory) {
        if (report === undefined || report == null) {
            this.isTodo = false;
            this.eventId = 0;
        } else {
            this.isTodo = true;
            this.eventId = Number(report.id);
            this.todoReport = report;
        }
    }

    checkForAdditionalEmailsQuestions(questions: IReportQuestionAnswer[]) {
        let emailList = '';
        // find all the additional emails questions
        if (questions && questions.length) {
            // the attrs for additional emails: 'additionalAAEmails', 'additionalEmails'
            let questionList = this.findAllQuestionsWithAttribute(questions, 'dataType', 'additionalEmails');
            if (questionList && questionList.length > 0) {
                questionList.forEach(q => {
                    if (q && q.userAnswer) {
                        emailList += (emailList) ? ';' + q.userAnswer : q.userAnswer;
                    }
                });
            }
            questionList = this.findAllQuestionsWithAttribute(questions, 'dataType', 'additionalAAEmails');
            if (questionList && questionList.length > 0) {
                questionList.forEach(q => {
                    if (q && q.userAnswer) {
                        emailList += (emailList) ? ';' + q.userAnswer : q.userAnswer;
                    }
                });
            }
        }
        return emailList;
    }

    getPersonDetail(personMappingText: string): string {
        if (personMappingText) {
            const personMappingDictionary = {
                // column in SOS_PERSON table: service value
                FIRST_NAME: 'firstName',
                LAST_NAME: 'lastName',
                ADDRESS1: 'address1',
                ADDRESS2: 'address2',
                CITY: 'city',
                STATE: 'state',
                COUNTRY: 'country',
                ZIP: 'zip',
                HOME_PHONE: 'phone',
                EMAIL_ADDRESS: 'email',
                BASE_STATION: 'baseStation',
                COST_CENTER: 'costCenter',
                DEPARTMENT: 'department',
                'OFF_MON:OFF_TUE:OFF_WED:OFF_THU:OFF_FRI:OFF_SAT:OFF_SUN': 'daysOff',
                'SHIFT_BEGIN_HOUR:SHIFT_BEGIN_MINUTE': 'shiftStart',
                'SHIFT_END_HOUR:SHIFT_END_MINUTE': 'shiftEnd',
                EMPLOYEE_ID: 'employeeId',
                SUPV_EMPLOYEE_ID: 'supvEmployeeId',
                SUPV_FIRST_NAME: 'supvFirstName',
                SUPV_LAST_NAME: 'supvLastName',
                SUPV_WORK_PHONE: 'supvPhone',
                PNR: 'pnr',
                REWARD_PROGRAM_ID: 'aadvantageNumber',
                SEAT: 'seat',
                BAG_TAG: 'bagTag',
                SEX: 'gender',
                AGE_YEARS: 'ageYears',
                DRIVERS_LICENSE: 'driversLicense',
                DRIVERS_STATE: 'driversState',
                MEDICAL_LICENSE: 'medicalLicense',
                PASSPORT_COUNTRY: 'passportCountry',
                PASSPORT: 'passport',
                VENDORCOMPANY: 'vendorCompany',
                VENDOREQUIPMENT: 'vendorEquipment',
                VENDORPHONE: 'vendorPhone',
                JOB_TITLE: 'jobTitle',
            };

            const mappedValue = personMappingDictionary[personMappingText];
            return mappedValue ?? null;
        }
        return null;
    }
}
