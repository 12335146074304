import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { SSEHubClient } from './SSEHubClient.service';
import { Observable } from 'rxjs';


@Injectable()
export class SSEHubCrewService {

    constructor(
        private http: HttpClient,
        private sseHubClient: SSEHubClient
    ) { }

    get(flightNumber, departDate, departStation): Observable<any> {
        return this.http.get(
            this.sseHubClient.serviceUrl() + '/api/crew/' + encodeURIComponent(flightNumber) + '/' +
            encodeURIComponent(departDate) + '/' + encodeURIComponent(departStation));
    }
}

