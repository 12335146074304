import { CategoryDetails } from './detail.model';
import * as DetailAction from './detail.actions';

export const initDetail: CategoryDetails = {
    id: null,
    description: '',
    categoryId: null,
    sections: null,
    attrs: null,
    hasError: false,
    isLoading: false,
};

export function categoryReducer(state = initDetail, action: DetailAction.Actions): CategoryDetails {
    switch (action.type) {
        case DetailAction.LOAD_CATEGORY_DETAIL:
            return { ...state, isLoading: true };

        // we want to do the same for both actions
        case DetailAction.POPULATE_CATEGORY_DETAIL:
            // set new variable to state and set loading to false
            const newDetail: CategoryDetails = { ...state, isLoading: false };

            // payload doesn't have the loading/error flags (different interface)
            // iterate through keys and copy the payload
            Object.keys(action.payload).map(k => newDetail[k] = action.payload[k]);
            return newDetail;

        case DetailAction.CATEGORY_DETAIL_ERROR:
            return { ...state, hasError: true };

        case DetailAction.CLEAR_CATEGORY_DETAIL:
            return initDetail;

        case DetailAction.SET_CATEGORY_ATTRS:
            return { ...state, attrs: action.payload };

        default:
            return state;
    }
}

export const selectors = {
    getCategoryDetails: (state: CategoryDetails) => state,
    getCategoryId: (state: CategoryDetails) => state.id,
    getFlightSectionInfo: (state: CategoryDetails) => state.sections.flight,
    getQuestionsSectionInfo: (state: CategoryDetails) => state.sections.questions,
    getNotesSectionInfo: (state: CategoryDetails) => state.sections.notes,
    getRecommendationsSectionInfo: (state: CategoryDetails) => state.sections.recommendations,
    getImagesSectionInfo: (state: CategoryDetails) => state.sections.images,
    getCategoryAttrs: (state: CategoryDetails) => state.attrs,
    getCategoryDescription: (state: CategoryDetails) => state.description, 
};

