import { Action } from '@ngrx/store';
import { IUser } from './user.model';

export const INITIALIZE = '[User] initializeUser';
export const POPULATE = '[User] populateUser';
export const PROXY_ADD = '[User] proxyAdd';
export const PROXY_REMOVE = '[User] proxyRemove';
export const ERROR = '[User] error';

export class InitializeUser implements Action {
    readonly type = INITIALIZE;

    constructor(public payload: string) { }
}

export class PopulateUser implements Action {
    readonly type = POPULATE;

    constructor(public payload: IUser) { }
}

export class ProxyUser implements Action {
    readonly type = PROXY_ADD;

    constructor(public payload: IUser) { }
}

export class ProxyRemove implements Action {
    readonly type = PROXY_REMOVE;
}

export class Error implements Action {
    readonly type = ERROR;
}

export type Actions
    = InitializeUser
    | PopulateUser
    | ProxyUser
    | ProxyRemove
    | Error;
