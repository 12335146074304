import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import * as _ from 'lodash';

import { FlightDetailInfo } from '../../report-history';
import { getReportDetailsFlight, getReportDetailsisLoading } from 'app/report-history/report-history-store/detail';
import { Store } from '@ngrx/store';
import { State } from 'app/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-flight-summary',
  templateUrl: './flight-summary.component.html',
  styleUrls: ['./flight-summary.component.scss', '../summary.component.scss']
})
export class FlightSummaryComponent implements OnInit, OnDestroy {
  flightInfo: FlightDetailInfo;
  isLoading = false;
  destroy$: Subject<void> = new Subject<void>();

  constructor(private store: Store<State>) { }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.store.select(getReportDetailsisLoading).pipe(takeUntil(this.destroy$)).subscribe(result => this.isLoading = result);
    this.store.select(getReportDetailsFlight).pipe(takeUntil(this.destroy$)).subscribe(result => this.flightInfo = result);
  }
}
