import { Injectable } from '@angular/core';
import { environment } from '@app/../environments/environment';
import { SSEHubNotificationEndpoints } from './SSEHubNotificationEndpoints.constants';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class SSEHubNotification {

    constructor(
    ) { }

    serviceUrl() {
        return SSEHubNotificationEndpoints.urlList[environment.envName || 'dev'];
    }

    // Global options for all SSEHub service calls
    getServiceOptions(): any {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json'
            })
        };
    }
}
