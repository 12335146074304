import { Component, ViewChild, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-confirm-navigate',
    template: `
    <modal modalClass="modal-md" #confirmNavigate>
        <modal-header>
            <h4>Confirm navigation</h4>
        </modal-header>
        <modal-content>
            <p>If you navigate away from filling out a report, all entered information will be lost. Do you wish to continue?</p>
        </modal-content>
        <modal-footer>
            <button class="btn-cancel" (click)="no()">No</button>
            <button class="btn-primary leftMargin remove" type="button" (click)="yes()">Yes</button>
        </modal-footer>
    </modal>
    `,
    styleUrls: ['./modals.component.scss']
})

export class ConfirmNavigateComponent {

    @ViewChild('confirmNavigate', { static: true }) public modal;
    @Output() navResult = new EventEmitter<boolean>();

    constructor(
    ) { }

    displayModal() {
        this.modal.open();
    }

    yes() {
        this.modal.close();
        this.navResult.emit(true);
    }

    no() {
        this.modal.close();
        this.navResult.emit(false);
    }
}
