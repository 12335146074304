<div class="row">
    <ol class="breadcrumb">
        <li><a routerLink='/home'><i class="icon-home"></i> Home</a></li>
        <li><a routerLink="/admin/categorymanagement">Admin</a></li>
        <li><a routerLink="/admin/categorymanagement">Category management</a></li>
        <li><a (click)="gotoActiveCategory()" class="new-link">{{ (category$ | async) }}</a></li>
        <li>Question Tags</li>
    </ol>
</div>
<div class="row">
    <div class="button-icon col-auto ">
        <a (click)="gotoActiveCategory()" class="icon-sec-chevron-left text-decoration-none"></a>
    </div>
    <div class="col pe-0">
        <div class="section-title-cers">Question tags</div>
        <div class="section-line"></div>
    </div>
</div>
<div class="row pt-2">
    <div class="col-3 ps-0 align-items-center">
        <kendo-label text="Question">
            <ng-container *ngIf="questionText$ | async as questionText">
                <kendo-textarea id='scrollstyle' [ngClass]="'question-text'" [readonly]="true" resizable="none"
                    [value]="questionText">
                </kendo-textarea>
            </ng-container>
        </kendo-label>
    </div>
    <div class="col-9 pe-0">
        <kendo-label text="Tag(s) applied">
            <kendo-multiselect [placeholder]="'None'" id='scrollstyle' [readonly]="true" [(ngModel)]="mappedTagVals">
                <ng-template kendoMultiSelectTagTemplate let-dataItem>
                    <div class="d-flex align-items-center">
                        <span>{{dataItem}}</span>
                        <button class="icon-sec-x-circle-outline" (click)="removeTag(dataItem)"></button>
                    </div>
                </ng-template>
            </kendo-multiselect>
        </kendo-label>
    </div>
</div>
<div class="row pt-4">
    <div class="col px-0">
        <div class="section-title-cers">Tags</div>
        <div class="section-line"></div>
    </div>
</div>
<div class="row py-3">
    <div class="d-flex justify-content-between align-items-center px-0">
        <kendo-textbox [(ngModel)]="searchTerm" (ngModelChange)="searchTags(true)" placeholder="Search tags..."
            class="search-textbox">
            <ng-template kendoTextBoxSuffixTemplate>
                <i class="icon-sec-search"></i>
            </ng-template>
        </kendo-textbox>
        <button kendoButton class="new-tag" (click)="toggleCreateNewTagDialog(true)">
            <div class="d-flex align-items-center justify-content-between">
                <i class='icon-sec-plus-outline new-tag-plus-icon'></i>
                <span>New tag</span>
            </div>
        </button>
        <button kendoButton (click)="onApply()" class="apply-tag">Apply</button>
    </div>
</div>
<div class="row px-0">
    <kendo-grid id='scrollstyle' #grid [kendoGridBinding]="filteredTags"
        [selectable]="{checkboxOnly: true, mode: 'multiple'}" [rowClass]="rowClass" [(selectedKeys)]="selectedTags"
        [kendoGridSelectBy]="myRowSelectionKey" [sortable]="true" [pageSize]="10" [pageable]="true" filterable="menu">
        <kendo-grid-checkbox-column [headerClass]="'k-checkBox-header'" [class]="'k-checkBox-cell'">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <input type="checkbox" kendoCheckBox [kendoGridSelectionCheckbox]="rowIndex"
                    [checked]="dataItem.isMapped" [disabled]="dataItem.isMapped" />
            </ng-template>
        </kendo-grid-checkbox-column>
        <kendo-grid-column field="tagVal" [headerClass]="'k-tagValue-header'" [filterable]="false"
            [class]="'k-tagValue-cell'">
            <ng-template kendoGridHeaderTemplate>
                <span class="k-column-title">Tag name</span>
                <i class="icon-sec-sort-default"></i>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="tagDisplayTxt" [headerClass]="'k-tagDisplayText-header'" [filterable]="false"
            [class]="'k-tagDisplayText-cell'">
            <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
                <span class="k-column-title">Display text</span>
                <i class="icon-sec-sort-default"></i>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="tagDescription" title="Description" [sortable]="false" [filterable]="false"
            [headerClass]="'k-tagDescription-header'" [class]="'k-tagDescription-cell'">
        </kendo-grid-column>
        <kendo-grid-column field="piiInd" title="PII" filter="boolean" [sortable]="false"
            [headerClass]="'k-piiInd-header'" [class]="'k-piiInd-cell'">
            <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                let-filterService="filterService">
                <kendo-label class="custom-label-class" [for]="PIIFilter" text="PII Filter"></kendo-label>
                <kendo-dropdownlist #PIIFilter [data]="booleanOptions" [valuePrimitive]="true"
                    [value]="getCurrentFilterValue(filter, column.field)" [defaultItem]="defaultOption"
                    (valueChange)="onFilterChange($event, filterService, column.field)" textField="text"
                    valueField="value" [itemDisabled]="itemDisabled">
                </kendo-dropdownlist>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <span *ngIf="dataItem.piiInd">Yes</span>
                <span *ngIf="!dataItem.piiInd">No</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column *ngIf="isITUser" field="systemRequired" [sortable]="false" filter="boolean"
            [headerClass]="'k-system-header'" [class]="'k-system-cell'" title="System">
            <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                let-filterService="filterService">
                <kendo-label class="custom-label-class" [for]="statusFilter" text="System Filter"></kendo-label>
                <kendo-dropdownlist #statusFilter [data]="booleanOptions" [valuePrimitive]="true"
                    [value]="getCurrentFilterValue(filter, column.field)" [defaultItem]="defaultOption"
                    (valueChange)="onFilterChange($event, filterService, column.field)" textField="text"
                    valueField="value" [itemDisabled]="itemDisabled">
                </kendo-dropdownlist>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <span *ngIf="dataItem.systemRequired">Yes</span>
                <span *ngIf="!dataItem.systemRequired">No</span>
            </ng-template>
        </kendo-grid-column>
        <!-- Hiding this column as it not needed, will be uncommented if needed in future. 
             Also uncomment 'status-chip', 'active-chip', 'inactive-chip' CSS classes whenever this column is added back to UI

        <kendo-grid-column *ngIf="isITUser" field="isActiveTag" [sortable]="false" [headerClass]="'k-status-header'"
            [class]="'k-status-cell'" title="Status">
            <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                let-filterService="filterService">
                <kendo-label [for]="statusFilter" class="custom-label-class" text="Status Filter"></kendo-label>
                <kendo-dropdownlist [data]="statusOptions" [valuePrimitive]="true"
                    [value]="getCurrentFilterValue(filter, column.field)" [defaultItem]="defaultOption"
                    (valueChange)="onFilterChange($event, filterService, column.field)" textField="text"
                    valueField="value" [itemDisabled]="itemDisabled">
                </kendo-dropdownlist>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <span *ngIf="dataItem.isActiveTag" label="Active" class="status-chip active-chip">Active</span>
                <span *ngIf="!dataItem.isActiveTag" label="Inactive" class="status-chip inactive-chip">Inactive</span>
            </ng-template>
        </kendo-grid-column>
        
        -->
        <kendo-grid-column *ngIf="isITUser" field="edit" [sortable]="false" [filterable]="false" title=""
            [headerClass]="'k-edit-header'" [class]="'k-edit-cell'">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span class="icon-sec-pencil-square" (click)="onEdit(dataItem)"></span>
            </ng-template>
        </kendo-grid-column>

        <ng-template kendoPagerTemplate let-currentPage="currentPage" let-totalPages="totalPages">
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-grid-spacer></kendo-grid-spacer>
            <kendo-pager-input></kendo-pager-input>
            <kendo-pager-info></kendo-pager-info>
            <kendo-grid-spacer></kendo-grid-spacer>
            <kendo-pager-page-sizes [pageSizes]="[10,15,20]"></kendo-pager-page-sizes>
        </ng-template>
    </kendo-grid>
</div>
<kendo-dialog *ngIf="addTagUserErrorModalOpened" [width]="550" (close)="closeAddTagErrorModal()">
    <kendo-dialog-titlebar>
        <div class="confirm-title-dialog">
            <span class="icon-sec-exclamation-circle-fill"></span>
            <div class="warning-title-dialog">WARNING: Unable to apply tags</div>
        </div>
    </kendo-dialog-titlebar>
    <p style="margin: 30px; text-align: center; font-size: 17px;">
        {{ questionMappingErrorMessage }}</p>

    <kendo-dialog-actions>
        <button kendoButton (click)="closeAddTagErrorModal()" class="button-primary">Ok</button>
    </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog *ngIf="confirmRemoveTagDialogOpened" [width]="550" (close)="onRemoveTagConfirm(false)">
    <kendo-dialog-titlebar>
        <div class="confirm-title-dialog">
            <span class="icon-sec-exclamation-circle-fill"></span>
            <div class="warning-title-dialog">WARNING: Are you sure?</div>
        </div>
    </kendo-dialog-titlebar>
    <p style="margin: 30px; text-align: center; font-size: 17px;">
        Are you sure you want to remove the tag from this question?</p>

    <kendo-dialog-actions>
        <button kendoButton (click)="onRemoveTagConfirm(false)" class="button-secondary">Cancel</button>
        <button kendoButton (click)="onRemoveTagConfirm(true)" class="button-primary">Remove tag</button>
    </kendo-dialog-actions>
</kendo-dialog>

<app-create-new-tag-modal *ngIf="isCreateNewTagModalOpen" (onClose)="toggleCreateNewTagDialog(false)"
    (actionAddTagMapping)="applyTagMappings([$event])">
</app-create-new-tag-modal>
<app-edit-tag-modal *ngIf="isEditTagModalOpen" (onClose)="handleEditModalClose()" [tagData]="selectedEditTag"
    (actionAddTagMapping)="applyTagMappings([$event])">
</app-edit-tag-modal>