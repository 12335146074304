
import { switchMap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { SSEHubReportHistoryService } from 'app/shared/SSEHubClient/report-history.service';
import { IReportHistory, ReportHistory } from './history.model';
import * as historyActions from './history.actions';
import { NotificationService } from '@shared/error-handler-notify/services/notification.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class HistoryEffects {

    
    loadMyHistory$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<historyActions.LoadMyHistory>(historyActions.LOAD_MY_HISTORY),
        switchMap(action => {
            return this.sseHubReportHistoryService.getEmployeeReportHistory(action.payload.employeeId, action.payload.filterString).pipe(
                map((results: Array<any>) => {
                    results = results || [];
                    const parsed: IReportHistory[] = [];
                    for (const r of results) {
                        parsed.push(ReportHistory.new(r));
                    }
                    return new historyActions.PopulateMyHistory(parsed);
                }),
                catchError(e => {
                    this.notificationService.showError(e);
                    return of(new historyActions.ErrorMyHistory(e));
                })
            );
        })));

    
    loadAllHistory$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<historyActions.LoadAllHistory>(historyActions.LOAD_ALL_HISTORY),
        switchMap(action => {
            return this.sseHubReportHistoryService.getEmployeeAllReportHistory(action.payload).pipe(
                map((results: Array<any>) => {
                    results = results || [];
                    const parsed: IReportHistory[] = [];
                    for (const r of results) {
                        parsed.push(ReportHistory.new(r));
                    }
                    return new historyActions.PopulateAllHistory(parsed);
                }),
                catchError(e => {
                    this.notificationService.showError(e);
                    return of(new historyActions.ErrorAllHistory(e));
                })
            );
        })));

    
    loadTodoHistory$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<historyActions.LoadTodoHistory>(historyActions.LOAD_TODO_HISTORY),
        switchMap(action => {
            return this.sseHubReportHistoryService.getEmployeeTodoReportHistory(action.payload).pipe(
                map((results: Array<any>) => {
                    results = results || [];
                    const parsed: IReportHistory[] = [];
                    for (const r of results) {
                        parsed.push(ReportHistory.new(r));
                    }
                    return new historyActions.PopulateTodoHistory(parsed);
                }),
                catchError(e => {
                    this.notificationService.showError(e);
                    return of(new historyActions.ErrorTodoHistory(e));
                })
            );
        })));

    
    loadTodoList$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<historyActions.LoadTodoList>(historyActions.LOAD_TODO_LIST),
        switchMap(action => {
            return this.sseHubReportHistoryService.getEmployeeTodoList(action.payload).pipe(
                map((results: Array<any>) => {
                    results = results || [];
                    const parsed: IReportHistory[] = [];
                    for (const r of results) {
                        parsed.push(ReportHistory.new(r));
                    }
                    return new historyActions.PopulateTodoList(parsed);
                }),
                catchError(e => {
                    this.notificationService.showError(e);
                    return of(new historyActions.ErrorTodoList(e));
                })
            );
        })));

    constructor(
        private actions$: Actions,
        private sseHubReportHistoryService: SSEHubReportHistoryService,
        private notificationService: NotificationService
    ) { }
}
