import { Component, EventEmitter, ViewChild, Input, Output } from '@angular/core';
import { NgForm, ControlContainer, NgModel } from '@angular/forms';
import { padEnd } from 'lodash';
import { IDynamicQuestion } from '../../new-report-store/wizard';

@Component({
    selector: 'app-group',
    templateUrl: './group.component.html',
    styleUrls: ['../dynamic-form.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})

export class GroupComponent {
    @Input() question: IDynamicQuestion;
    @Output() removeGroupEvent$: EventEmitter<any>;
    @ViewChild('form') form: NgModel;
    @ViewChild('confirmDelete', { static: true }) public modal: any;
    modalText: string;
    questionAttrs: object = {};

    remove() {
        this.modalText = 'Do you want to remove this question section from the report?';
        if (this.question.answerInputType.toUpperCase() === 'INVOLVEMENT') {
            this.modalText = 'Do you want to remove this person from the report?';
        }
        this.modal.open();
    }

    cancel() {
        this.modal.close();
    }

    confirm() {
        this.modal.close();
        this.removeGroupEvent$.emit(this.question);
    }
}
