<div ngbDropdown class="dropdown" *ngIf="canEdit">
    <a id="overflowMenu" class="overflow-menu-icon" ngbDropdownToggle>
        <i class="icon-more-13px icon-rotate-90"></i>
    </a>
    <ul ngbDropdownMenu aria-labelledby="overflowMenu" *ngIf="context ==='flight-question-section'">
        <li><a ngbDropdownItem (click)="resetSection()">Reset Flight Section</a></li>
    </ul>
    <ul ngbDropdownMenu aria-labelledby="overflowMenu" *ngIf="context === 'general-question-section'">
        <li><a ngbDropdownItem (click)="resetGeneralQuestion()">Reset General Questions Section</a></li>
    </ul>
    <ul ngbDropdownMenu aria-labelledby="overflowMenu" *ngIf="context === 'notes-question-section'">
        <li><a ngbDropdownItem (click)="resetSection()">Reset Notes Section</a></li>
    </ul>
    <ul ngbDropdownMenu aria-labelledby="overflowMenu"
        *ngIf="context === 'flight-question' || context === 'notes-question' || context === 'followup-question' || context  === 'involvement-question' || context === 'group-question'">
        <li><a ngbDropdownItem (click)="editMetaData()">Edit question</a></li>
        <li><a ngbDropdownItem (click)="sortQuestion()">Edit sort order</a></li>
        <li><a ngbDropdownItem (click)="removeQuestion()">Remove question</a></li>
        <li><a ngbDropdownItem (click)="manageTags()">Manage tags</a></li>
    </ul>

    <ul ngbDropdownMenu aria-labelledby="overflowMenu" *ngIf="context === 'flight-followup-question'">
        <li><a ngbDropdownItem (click)="editMetaData()">Edit question</a></li>
        <li><a ngbDropdownItem (click)="sortQuestion()">Edit sort order</a></li>
        <li><a ngbDropdownItem (click)="removeQuestion()" [class.disabled]="isDisabled">Remove question</a></li>
        <li><a ngbDropdownItem (click)="manageTags()">Manage tags</a></li>
    </ul>

    <ul ngbDropdownMenu aria-labelledby="overflowMenu" *ngIf="context === 'notes-followup-question'">
        <li><a ngbDropdownItem (click)="editMetaData()">Edit question</a></li>
        <li><a ngbDropdownItem (click)="sortQuestion()">Edit sort order</a></li>
        <li><a ngbDropdownItem (click)="removeQuestion()" [class.disabled]="isDisabled">Remove question</a></li>
        <li><a ngbDropdownItem (click)="manageTags()">Manage tags</a></li>
    </ul>

    <ul ngbDropdownMenu aria-labelledby="overflowMenu" *ngIf="context === 'general-question'">
        <li><a ngbDropdownItem (click)="editMetaData()">Edit question</a></li>
        <li><a ngbDropdownItem (click)="sortQuestion()">Edit sort order</a></li>
        <li><a ngbDropdownItem (click)="removeQuestion()" [class.disabled]="isDisabled">Remove question</a></li>
        <li><a ngbDropdownItem (click)="manageTags()">Manage tags</a></li>
    </ul>

    <ul ngbDropdownMenu aria-labelledby="overflowMenu" *ngIf="context === 'general-question-dropdown'">
        <li><a ngbDropdownItem (click)="editMetaData()">Edit question</a></li>
        <li><a ngbDropdownItem (click)="sortQuestion()">Edit sort order</a></li>
        <li><a ngbDropdownItem (click)="removeQuestion()" [class.disabled]="isDisabled">Remove question</a></li>
        <li><a ngbDropdownItem (click)="addAnswer()">Add answer</a></li>
        <li><a ngbDropdownItem (click)="manageTags()">Manage tags</a></li>
    </ul>
    <ul ngbDropdownMenu aria-labelledby="overflowMenu" *ngIf="context === 'followup-question-dropdown'
      || context === 'involvement-question-dropdown' || context === 'group-question-dropdown'">
        <li><a ngbDropdownItem (click)="editMetaData()">Edit question</a></li>
        <li><a ngbDropdownItem (click)="sortQuestion()">Edit sort order</a></li>
        <li><a ngbDropdownItem (click)="removeQuestion()">Remove question</a></li>
        <li><a ngbDropdownItem (click)="addAnswer()">Add answer</a></li>
        <li><a ngbDropdownItem (click)="manageTags()">Manage tags</a></li>
    </ul>
    <ul ngbDropdownMenu aria-labelledby="overflowMenu" *ngIf="context === 'flight-question-dropdown'
      || context === 'flight-followup-question-dropdown'">
        <li><a ngbDropdownItem (click)="editMetaData()">Edit question</a></li>
        <li><a ngbDropdownItem (click)="sortQuestion()">Edit sort order</a></li>
        <li><a ngbDropdownItem (click)="removeQuestion()" [class.disabled]="isDisabled">Remove question</a></li>
        <li><a ngbDropdownItem (click)="addAnswer()" [class.disabled]="isDisabled">Add answer</a></li>
        <li><a ngbDropdownItem (click)="manageTags()">Manage tags</a></li>
    </ul>
    <ul ngbDropdownMenu aria-labelledby="overflowMenu" *ngIf="context === 'answer'">
        <li><a ngbDropdownItem (click)="editAnswer()">Edit answer</a></li>
        <li><a ngbDropdownItem (click)="sortAnswer()">Edit sort order</a></li>
        <li><a ngbDropdownItem (click)="removeAnswer()" [class.disabled]="isDisabled">Remove answer</a></li>
        <li><a ngbDropdownItem (click)="addQuestion()">Add followup question</a></li>
        <li *ngIf="canAdminFillOutReport"><a ngbDropdownItem (click)="addTemplate()">Add template</a></li>
    </ul>
    <ul ngbDropdownMenu aria-labelledby="overflowMenu" *ngIf="context === 'answer-for-flight'">
        <li><a ngbDropdownItem (click)="editAnswer()">Edit answer</a></li>
        <li><a ngbDropdownItem (click)="sortAnswer()">Edit sort order</a></li>
        <li><a ngbDropdownItem (click)="removeAnswer()" [class.disabled]="isDisabled">Remove answer</a></li>
        <li><a ngbDropdownItem (click)="addQuestion()">Add followup question</a></li>
        <li *ngIf="canAdminFillOutReport"><a ngbDropdownItem (click)="addTemplate()">Add template</a></li>
    </ul>
    <ul ngbDropdownMenu aria-labelledby="overflowMenu" *ngIf="context === 'involvement'">
        <li><a ngbDropdownItem (click)="editMetaData()">Edit involvement</a></li>
        <li><a ngbDropdownItem (click)="removeGroupType()">Remove involvement</a></li>
        <li><a ngbDropdownItem (click)="addGroupDetail()">Add field</a></li>
        <li><a ngbDropdownItem (click)="manageTags()">Manage tags</a></li>    
    </ul>
    <ul ngbDropdownMenu aria-labelledby="overflowMenu" *ngIf="context === 'involvement-detail'">
        <li><a ngbDropdownItem (click)="removeInvolvementDetail()">Remove field</a></li>
    </ul>
    <ul ngbDropdownMenu aria-labelledby="overflowMenu" *ngIf="context === 'question-involvement'">
        <li><a ngbDropdownItem (click)="editMetaData()">Edit question</a></li>
        <li><a ngbDropdownItem (click)="sortQuestion()">Edit sort order</a></li>
        <li><a ngbDropdownItem (click)="removeQuestion()" [class.disabled]="isDisabled">Remove question</a></li>
        <li><a ngbDropdownItem (click)="manageTags()">Manage tags</a></li>
    </ul>
    <ul ngbDropdownMenu aria-labelledby="overflowMenu" *ngIf="context === 'question-group'">
        <li><a ngbDropdownItem (click)="editMetaData()">Edit question</a></li>
        <li><a ngbDropdownItem (click)="sortQuestion()">Edit sort order</a></li>
        <li><a ngbDropdownItem (click)="removeQuestion()" [class.disabled]="isDisabled">Remove question</a></li>
        <li><a ngbDropdownItem (click)="manageTags()">Manage tags</a></li>

    </ul>
    <ul ngbDropdownMenu aria-labelledby="overflowMenu"
        *ngIf="context === 'answer-involvement' || context === 'group-involvement'">
        <li><a ngbDropdownItem (click)="editGroup()">Edit involvement</a></li>
        <li><a ngbDropdownItem (click)="addQuestion()">Add followup question</a></li>
        <li *ngIf="canAdminFillOutReport"><a ngbDropdownItem (click)="addTemplate()">Add template</a></li>
    </ul>
    <ul ngbDropdownMenu aria-labelledby="overflowMenu" *ngIf="context === 'answer-group' || context === 'group-answer'">
        <li><a ngbDropdownItem (click)="addQuestion()">Add followup question</a></li>
        <li *ngIf="canAdminFillOutReport"><a ngbDropdownItem (click)="addTemplate()">Add template</a></li>
    </ul>
    <ul ngbDropdownMenu aria-labelledby="overflowMenu" *ngIf="context === 'multi-limited'">
        <li><a ngbDropdownItem (click)="editAnswer()">Edit answer</a></li>
        <li><a ngbDropdownItem (click)="sortAnswer()">Edit sort order</a></li>
        <li><a ngbDropdownItem (click)="removeAnswer()" [class.disabled]="isDisabled">Remove answer</a></li>
    </ul>
</div>