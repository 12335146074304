import { Injectable } from '@angular/core';
import { environment } from '@app/../environments/environment';
import { HttpHeaders } from '@angular/common/http';


@Injectable()
export class SSEHubClient {

    constructor(
    ) { }

    serviceUrl() {
        return environment.apiUri;
        // return SSEHubEndpoints.urlList[environment.envName || 'dev'];
    }

    // Global options for all SSEHub service calls
    getServiceOptions(): any {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json'
            })
        };

        return httpOptions;
    }
}
