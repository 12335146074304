import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class HighlightService {
  private _highlight = new BehaviorSubject(-1);
  hightlight$: Observable<number> = this._highlight.asObservable();

  constructor() { }

  highlightQuestion(id: number) {
    this._highlight.next(id);
  }
}
