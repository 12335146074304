<div class="section-header">
    <h2>{{sectionInfo.name}}</h2>
    <div>
        <app-overflow-menu [profileId]=profileId [context]="'notes-question-section'"
            (callAction)="resetNotesQuestions.open()">
        </app-overflow-menu>
    </div>
</div>
<div class="divider"></div>

<app-aa-spinner-no-logo [loading]="(sectionInfo$ | async).isLoading"></app-aa-spinner-no-logo>
<div *ngIf="(sectionInfo$ | async).hasError">
    <p class="errorText">Error Loading Notes Data.</p>
</div>

<div [hidden]="!(sectionInfo$ | async).isReady">
    <!-- <app-section-visibility [profileId]=profileId [categoryId]=categoryId [sectionInfo]="sectionInfo" [sectionId]="11">
    </app-section-visibility> -->

    <div *ngFor="let invquestion of notesQuestions; let i=index;" class="panel-body">
        <app-question-answer-dynamic-component [question]=invquestion [profileId]=profileId [categoryId]=categoryId
            [group]='"Notes"' [index]=i [context]="'notes-question'" (actionEditMetaData)="actionEditMetaData($event)"
            (actionAddQuestion)="actionAddQuestion($event)" (actionEditQuestion)="actionEditQuestion($event)"
            (actionRemoveQuestion)="actionRemoveQuestion($event)" (actionAddAnswer)="actionAddAnswer($event)"
            (actionEditAnswer)="actionEditAnswer($event)" (actionRemoveAnswer)="actionRemoveAnswer($event)"
            (actionSortQuestion)="actionSortQuestion($event)" (actionSortAnswer)="actionSortAnswer($event)"
            (actionAddTemplate)="actionAddTemplate($event)" (actionManageTags)="actionManageTags($event)">
        </app-question-answer-dynamic-component>

    </div>
    <div *ngIf="notesQuestions && notesQuestions.length==0" class="panel-body">
        <p>This category doesn't have any Notes Questions</p>
    </div>
    <br>
</div>
<br /><br />
<app-confirmation-popup #confirmQuestionRemove [title]="'Remove Question'"
    [message]="'Are you sure you want to remove the selected question?'" (callAction)="onQuestionDelete()">
</app-confirmation-popup>
<!-- <app-confirmation-popup #confirmAnswerRemove [title]="'Remove Answer'"
    [message]="'Are you sure you want to remove the selected Answer?'" (callAction)="onAnswerDelete()">
</app-confirmation-popup> -->
<app-confirmation-popup #resetNotesQuestions [title]="'Reset Section'"
    [message]="'This action is going to reset all notes section questions. Do you want to proceed?'"
    (callAction)="resetSection($event)">
</app-confirmation-popup>

<app-tags-list #editTagsModal></app-tags-list>
<app-answer-add #answerAddModal></app-answer-add>
<app-answer-edit #answerEditModal [group]='"Notes"'></app-answer-edit>
<app-question-edit #editQuestionModal [profileId]=profileId [categoryId]=categoryId></app-question-edit>
<app-question-add #createNewNotesQuestion [group]='"Notes"'></app-question-add>
<app-questions-list #notesQuestionsListModal [group]='"Notes"'></app-questions-list>
<app-display-order-edit #sortOrderEditModal></app-display-order-edit>
