<modal #createNewQuestion (onOpen)="onOpen()" (onClose)="onClose($event)">
    <modal-header>
        <h3>Create new question</h3>
    </modal-header>
    <modal-content>
        <form #createNewQuestionForm="ngForm">
            <label for="questionType" class="form-group">Answer Input Type:
                <select class="form-control" id="answerInputType" required [(ngModel)]="newQuestions.answerInputType"
                    name="answerInputType">
                    <option *ngFor="let type of answerInputType" [value]="type">{{type}}</option>
                </select>
                <div *ngIf="!newQuestions.answerInputType && submitted" class="validation-fail">
                    Input type is required
                </div>
            </label>

            <!-- If user selects multi-input for question-input type -->
            <!-- <div *ngIf="newQuestions.answerInputType === 'Multi'">
                <label for="questionType" class="form-group">Select a multiple input type
                    <select class="form-control" id="selectedIMultiInput" name="selectedMultiInput" required
                        [(ngModel)]="selectedMultiInput">
                        <option *ngFor="let input of allMultiInputs" [value]="input.id">
                            {{input.multiInputTypeDescription}}
                        </option>
                    </select>
                    <div *ngIf="!selectedMultiInput && submitted" class="validation-fail">
                        Multi input type is required
                    </div>
                </label>
            </div> -->

            <label class="form-group" *ngIf="newQuestions.answerInputType !== 'Involvement'" for="QuestionText">Question
                Text :
                <input type="text" class="form-control" id="QuestionText" required minlength="1"
                    [(ngModel)]="newQuestions.questionText" name="QuestionText" #QuestionText="ngModel" />
                <div *ngIf="(QuestionText.invalid) && (QuestionText.dirty || QuestionText.touched || textEmpty || submitted)"
                    class="validation-fail">
                    Question is required
                </div>
            </label>

            <!-- If user selects involvement for question-input type, then require an involvement type -->
            <div *ngIf="newQuestions.answerInputType === 'Involvement'">
                <label for="questionType" class="form-group">Select an involvement type
                    <select class="form-control" id="selectedInvolvement" required [(ngModel)]="selectedInvolvement"
                        name="selectedInvolvement">
                        <option *ngFor="let inv of allInvolvements" [value]="inv.Id">{{inv.involvementTypeDescription}}
                        </option>
                    </select>
                    <div *ngIf="!selectedInvolvement && submitted" class="validation-fail">
                        Involvement type is required
                    </div>
                </label>
                <label for="questionType" class="form-group">Enter an Involvement alias
                    <input type="text" [(ngModel)]="newQuestions.questionText" name="QuestionText">
                </label>
            </div>

        </form>
    </modal-content>
    <modal-footer>
        <button type="button" data-dismiss="modal" class=" btn btn-cancel"
            (click)="createNewQuestion.close();">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="saveNewQuestion(createNewQuestionForm);">Save</button>
    </modal-footer>
</modal>