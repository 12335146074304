import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { getUser } from '@app/store/user/user.reducer';
import { ErrorHandlerService } from '@shared/error-handler/error-handler.service';
import { State } from '../../store';
import { InitializeUser as InitUser } from '@app/store/user/user.actions';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';

@Injectable()
export class UserResolver implements Resolve<Action> {

    constructor(
        private store: Store<State>,
        private errorHandlerService: ErrorHandlerService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Action> {
        return this.store.select(getUser).pipe(
            tap((user) => {}),
            map(user => user),
            take(1)
        ).toPromise();
    }
}
