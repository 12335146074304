import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@app/store';
import * as _ from 'lodash';

import { Attachment } from 'app/report-history/report-history';
import { RemoveAttachment } from 'app/new-report/new-report-store/wizard';
import { getAttachments } from '@newreport/new-report-store';
import { AttachmentSummary } from 'app/shared/common-ux/models/AttachmentSummary';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SSEHubReportHistoryService } from 'app/shared/SSEHubClient';

@Component({
    selector: 'app-attachment-view',
    templateUrl: './attachment-view.component.html',
    styleUrls: ['../steps.component.scss']
})

export class AttachmentViewComponent implements OnInit, OnDestroy {
    @Input() canEdit = false;
    @ViewChild('confirmRemove', { static: true }) public confirmRemove;
    toRemove: Attachment;
    destroy$: Subject<void> = new Subject<void>();
    public attSummary: AttachmentSummary = new AttachmentSummary(this.store, this.sseHubReportHistoryService);

    constructor(
        private store: Store<State>,
        private sseHubReportHistoryService: SSEHubReportHistoryService,
    ) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    ngOnInit() {
        this.store.select(getAttachments).pipe(takeUntil(this.destroy$)).subscribe(attachments => {
            this.attSummary.loadAttachments((attachments));
        });
    }

    confirm(attch: Attachment) {
        this.toRemove = attch;
        this.confirmRemove.open();
    }

    remove() {
        this.store.dispatch(new RemoveAttachment(this.toRemove));
        this.confirmRemove.close();
    }

}
