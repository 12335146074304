<div class="panel-group" id="accordion">
    <app-report-detail-section [section]="'questionsList'" [title]="'Questions / answers'"
        [allExpandState]="allExpandState"></app-report-detail-section>
    <div #section id="questionsList" class="panel-collapse collapse">
        <div class="panel-body">
            <app-question-list [questionsAndAnswers]="questionAndAnswers"></app-question-list>
            <label *ngIf="!(questionAndAnswers.length > 0)"> No Questions / Answers</label>
            <!-- Involvements -->
            <h3 *ngIf="involvementDetails && involvementDetails.length > 0" class="involvement-header">People
                Involved</h3>
            <div *ngFor="let reportInvolvement of involvementDetails" class="question-container">
                <ng-container *ngIf="reportInvolvement.ValidateRules | hideFromReportDetailPipe">
                    <app-person-details [involvement]="reportInvolvement"></app-person-details>
                </ng-container>
            </div>
        </div>
    </div>
</div>
